import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { CustomClasses, Status, ToolbarItemType } from "../../enums";
import { Title } from "../../global.style";
import TestIds from "../../testIds";
import { IAlertProps } from "../alert/Alert";
import CustomResizeObserver from "../customResizeObserver";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import { Toolbar } from "../toolbar";
import {
    HeaderSubtitle,
    HeaderToolbarWrapper,
    StyledHeader,
    StyledHeaderContent,
    TitleWrapper,
    ToolbarSpacer
} from "./Header.styles";

interface IProps {
    title: string;
    subtitle?: string;
    subtitleStatus?: Status;
    icons?: IHeaderIcon[],
    beforeIconsContent?: React.ReactElement;
    afterIconsContent?: React.ReactElement;
    hotspotId?: string;
    ref?: React.Ref<React.ReactElement>;
    isDisabled?: boolean;
    disabledAlert?: IAlertProps;
    isReadOnly?: boolean;

    className?: string;
    style?: React.CSSProperties;
}

type IPropsExtended = IProps & WithDomManipulator & WithTranslation;

interface IDomProps {
    expand: boolean;
    ignore: boolean;
}

export interface IHeaderIcon {
    id: string;
    onClick?: () => void;
    /** Use instead of onClick  */
    link?: THistoryLocation;
    label?: string;
    iconName: string;
    isDisabled?: boolean;
    isLight?: boolean;
    // icon is enabled even though the Header itself is enabled
    ignoreMasterDisabled?: boolean;
}

class Header extends React.Component<IPropsExtended> {
    static defaultProps: Partial<IPropsExtended> = {
        icons: []
    };

    _refHeader = React.createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.updatePaddings();
    }

    componentDidUpdate(prevProps: Readonly<IPropsExtended>, prevState: Readonly<{}>, snapshot?: any): void {
        this.updatePaddings();
    }

    updatePaddings = () => {
        // this constant show maximum item offsetTop difference for items in the same row
        // we test using this, whether flex is wrapped or not (comparing offsetTop of first and last item)
        const HEADER_DIFF = 30;

        if (!this._refHeader.current) {
            return;
        }

        this.props.domManipulatorOrchestrator.registerCallback(
            () => {
                const children = this._refHeader.current?.childNodes;
                const first = children?.[0] as HTMLElement,
                    last = children?.[children.length - 1] as HTMLElement;
                const ignore = !first || !last;

                return {
                    ignore,
                    expand: !ignore && Math.abs(first.offsetTop - last.offsetTop) < HEADER_DIFF
                };
            }, (data: IDomProps) => {
                if (data.ignore) {
                    return;
                }

                const spaces = this._refHeader.current.getElementsByClassName(CustomClasses.SummarySpace);
                for (const sp of spaces) {
                    const space = sp as HTMLElement;
                    space.style.flexGrow = data.expand ? "1" : "0";

                    // if space is first element in the wrapped row, hide it
                    // we can't test hidden element tho, so first show it
                    space.style.display = "block";
                    if (space.offsetLeft === 0) {
                        space.style.display = "none";
                    }
                }
            },
            [this._refHeader]
        );
    };

    handleResize = () => {
        this.updatePaddings();
    };


    getToolbarIcons = (): IToolbarItem[] => {
        return this.props.icons.map(icon => this.convertHeaderIconToToolbarItem(icon));
    };

    convertHeaderIconToToolbarItem = (headerIcon: IHeaderIcon): IToolbarItem => {
        const isDisabled = headerIcon.isDisabled || (!headerIcon.ignoreMasterDisabled && this.props.isDisabled);
        const disabledAlert = isDisabled && this.props.disabledAlert;
        return {
            id: headerIcon.id,
            label: headerIcon.label,
            iconName: headerIcon.iconName,
            isDisabled,
            isLight: headerIcon.isLight,
            itemType: ToolbarItemType.Icon,
            itemProps: {
                link: headerIcon.link,
                alert: disabledAlert
            }
        };
    };

    handleToolbarIconClick = (id: string) => {
        const icon = this.props.icons.find(icon => icon.id === id);

        if (!icon.link) {
            icon?.onClick();
        }
    };

    renderToolbar = () => {
        const icons = this.getToolbarIcons();

        if (!icons?.length) {
            return null;
        }

        return (
            <>
                <ToolbarSpacer className={CustomClasses.SummarySpace}/>
                <HeaderToolbarWrapper>
                    <Toolbar maxVisibleItems={3} onItemChange={this.handleToolbarIconClick}>
                        {icons}
                    </Toolbar>
                </HeaderToolbarWrapper>
            </>
        );
    };

    render() {
        return (
            <StyledHeader className={this.props.className}
                          style={this.props.style}
                          data-testid={TestIds.Header}>
                <StyledHeaderContent ref={this._refHeader}>
                    <CustomResizeObserver onResize={this.handleResize}/>
                    {(this.props.title || this.props.subtitle) &&
                        <TitleWrapper>
                            <Title data-testid={TestIds.HeaderTitle} {...{ [HOTSPOT_ID_ATTR]: this.props.hotspotId }}>
                                {this.props.title}
                            </Title>
                            {this.props.subtitle &&
                                <HeaderSubtitle data-testid={TestIds.HeaderSubtitle} status={this.props.subtitleStatus}>
                                    {this.props.subtitle}
                                </HeaderSubtitle>
                            }
                        </TitleWrapper>
                    }
                    {this.props.beforeIconsContent}
                    {!this.props.isReadOnly && this.renderToolbar()}
                    {this.props.afterIconsContent}
                </StyledHeaderContent>
            </StyledHeader>
        );
    }

}

export default withDomManipulator(withTranslation(["Common"], { withRef: true })(Header));