import {
    clamp,
    composeRefHandlers,
    doesElementContainsElement,
    getValue,
    isNotDefined,
    isObjectEmpty
} from "@utils/general";
import { cloneDeep } from "lodash";
import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { ThemeProvider, withTheme } from "styled-components/macro";

import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import {
    ActionState,
    BorderSize,
    IconSize,
    RowAction,
    RowType,
    Sort,
    Status,
    TableAddingRowType,
    TableSizes,
    TextAlign,
    ToggleState
} from "../../enums";
import { IModifierKeys, IToString, TRecordType } from "../../global.types";
import TestIds from "../../testIds";
import { PropsWithTheme, themes } from "../../theme";
import animationFrameThrottle from "../../utils/animationFrameThrottle";
import memoize from "../../utils/memoize";
import memoizeOne from "../../utils/memoizeOne";
import { WithBusyIndicator, withBusyIndicator } from "../busyIndicator/withBusyIndicator";
import { IconButton } from "../button";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../focusManager/FocusManager";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import { BinIcon, CloseIcon, IProps as IIconProps, LockFilledIcon, LockIcon, RefreshIcon } from "../icon";
import { ScrollBar } from "../scrollBar";
import { getRowFromArray, updateRowInArray } from "../smart/smartTable/SmartTable.utils";
import Tooltip, { ITooltipProps } from "../tooltip";
import { NoData } from "./NoData";
import { BodyCell, HeaderCell, IRowFocusEvent, IRowMouseEvent, IRowProps, Row } from "./Rows";
import { Label, LabelTooltipWrapper, RowShadow, RowShadowWrapper, RowStretcher, SimpleBodyCell } from "./Rows.styles";
import {
    ActionCheckBoxStyled,
    ActionIconWrapper,
    ActionRadioBoxStyled,
    AutoSizerWrapper,
    BeforeRowContentWrapper,
    FakeHeight,
    HeaderActionWrapper,
    HeaderActionWrapperPositioner,
    HeaderTooltipIcon,
    IconBar,
    IconBarScroll,
    InnerWrapper,
    MetaColumn,
    MetaColumnContent,
    MetaColumnLabel,
    NewRowContent,
    RowIcon,
    RowShadowScroll,
    StyledBody,
    StyledHeader,
    StyledTable,
    TableWrapper,
    WholeTableShadowWrapper
} from "./Table.styles";
import { NEW_ROW_ID, OVERHANG_ROW_COUNT } from "./Table.types";
import {
    getAction,
    getLeavesColumns,
    isMetaColumn,
    isRowSelected,
    isRowValueCellValueObject,
    isTableWithoutHeader
} from "./TableUtils";
import { WithContextMenu, withContextMenu, WithContextMenuProps } from "./withContextMenu";

const ROW_HEIGHT = TableSizes.RowHeight;

export interface ISort {
    id: TId;
    sort: Sort;
}

export type TId = IToString /*| number*/;

export interface ICellValueObject {
    value: React.ReactNode;
    tooltip: string | React.ReactNode;
    // true by default
    onlyShowTooltipWhenChildrenOverflowing?: boolean;
    afterContent?: React.ReactNode;
    hoverContent?: React.ReactNode;
}

export type TCellValue = string | number | ICellValueObject;

export interface IColumnResizeEvent {
    id: TId;
    columnIndex: number;
    oldWidth: number;
    width: number;
    // offset from original width
    delta: number;
    // offset from previous resize event
    stepDelta: number;
    // hierarchy level
    level: number;
    end: boolean;
}

export interface ISticky {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

export interface IRowValues {
    [key: string]: TCellValue | (() => TCellValue);
}

const newRowTypes = [TableAddingRowType.New, TableAddingRowType.Copy, TableAddingRowType.Custom];

export interface IRow {
    /** Unique row id used in events and react key prop. */
    id: TId;
    /** Id for testing purposes, can be different from id.toString() */
    dataId?: string;
    /** Used for shared hover state between multiple rows */
    groupId?: TId;
    /** Object with key-value pairs corresponding to the table columns. Value can also be object {value, title}*/
    values: IRowValues;
    /** Object with key-value pairs corresponding to the table columns. Use overflow: visible instead of hidden.
     * Expected use is for some complex custom content. Don't forget to take care of the overflow and text ellipsis when used. */
    allowOverflow?: Record<string, boolean>;
    selected?: boolean;
    sticky?: ISticky;
    /** If used, renders colored line in the left part of the row */
    statusHighlight?: Status;
    statusHighlightTooltip?: ITooltipProps["content"];
    label?: string;
    open?: boolean;
    /** Number of all the rows, for virtualization. Used together with rows prop.
     * Required for all groups.*/
    rowCount?: number;
    type?: RowType;
    originalIndex?: number;
    /** Whether to show caret on the right side of the row (clickable)*/
    drilldown?: boolean | React.ReactElement | ((row: IRow) => React.ReactElement);
    level?: number;
    hierarchy?: string;
    rows?: IRow[];
    tooltip?: string;
    /** Loading state */
    isLoading?: boolean;
    /** Whether to show a small lock in front of the row. */
    isLocked?: boolean;
    isDisabled?: boolean;
    /** Whether to use special "highlighted" background */
    isHighlighted?: boolean;
    isBold?: boolean;
    /** Renders a divider line under the row */
    isDivider?: boolean;
    /** Can be used to store some custom data, passed in onClick event */
    customData?: any;
    content?: any;
    canUnlock?: boolean;
}

interface IUnfoldRow extends IRow {
    group?: TId;
    groupIndex?: number;
}

export interface IColumn {
    /** Unique column id used in events and react key prop. */
    id: string;
    label: string;
    info?: string;
    // width of the column
    width?: number;
    // min width of just the content of the column.
    // used automatically for stretchContent columns by TableWithAutoSizedColumns
    // can be smaller than width in case the column label longer than content.
    minWidth?: number;
    textAlign?: TextAlign;
    /** Disables sort for this column */
    disableSort?: boolean;
    /** Whole column rendered on white background */
    isHighlighted?: boolean;
    border?: BorderSize;
    afterContentMinWidth?: number;
    isSticky?: boolean;
    /** Divider lines won't be rendered for cells of this column.
     * So far, only used on SimpleTable, but could be applied to Table as well. */
    ignoreDividers?: boolean;
    /** Makes all text in the column bold.
     * So far, only used on SimpleTable, but could be applied to Table as well. */
    isBold?: boolean;
    hasGreyHeader?: boolean;
    /**
     * Flag to render the cell content of each row to the same width of the max width content for given column.
     * Typically used to align icons based on the widest text of that column. */
    stretchContent?: boolean;
}

export interface IMetaColumn {
    label: string;
    columns: TColumn[];
    info?: string;
    isHighlighted?: boolean;
    border?: BorderSize;
    textAlign?: TextAlign;
}

export type TColumn = IColumn | IMetaColumn;

export interface ILoadMoreItemsEvent {
    startIndex: number;
    stopIndex: number;
    group?: TId;
}

export interface IScrollEvent {
    scrollTop: number;
    scrollLeft: number;
}

export interface IActionRendererArgs {
    actionState: ActionState;
    onClick: (...args: any) => void;
    isDisabled: boolean;
    isMainToggle: boolean;
    rowId?: TId;
    isLocked?: boolean;
    canUnlock?: boolean;
}

export interface IRowAction {
    actionType: RowAction;
    /** All following props are optional, when not provided, default behavior is used (managed by
     * SmartODataTableBase or SmartReportTable. E.g. customRender is provided or checkboxes are rendered.
     * Tables also can manage selecting particular rows, etc...
     **/
    toggleState?: ToggleState;
    onToggleChange?: (toggleState: ToggleState) => void;
    onClick?: (rowId: TId, row?: IRowProps) => void;
    getActionState?: (rowId: TId, row: IRowProps) => ActionState;
    render?: (args: IActionRendererArgs) => React.ReactNode;
    isSingleSelect?: boolean;
    showIconsOnHover?: ((rowId: TId, row: IRowProps) => boolean) | boolean;
    /** Custom tooltip shown on action hover */
    tooltip?: (rowId: TId) => string;
}

export interface ITableProps extends WithContextMenuProps {
    tableId?: string;
    /**
     * Rows of the table. Can be of different row types.
     */
    rows: IRow[];
    columns: TColumn[];
    /** Number of all the rows in first level, for virtualization. Used together with rows prop.
     * if rowCount not given, length of the rows array is used instead (table doesn't expect that more rows than the given rows exists)*/
    rowCount?: number;
    selectedRows?: IToString[];
    minimumBatchSize?: number;
    minimumRenderedRows?: number;
    style?: React.CSSProperties;
    onLoadMoreItems?: (props: ILoadMoreItemsEvent) => void;
    onSortChange?: (props: ISort[]) => void;
    onGroupToggle?: (id: TId) => void;
    /** Disables sort for all columns */
    disableSort?: boolean;
    onScroll?: (props: IScrollEvent) => void;
    /** Disables resize cursor change and functionality. Used in Dashboard tables.  */
    disableColumnResize?: boolean;
    onColumnResize?: (props: IColumnResizeEvent) => void;
    onRowSelect?: (id: TId, props: IRowProps, modifiers?: IModifierKeys) => void;
    onRowContextMenuSelection?: (id: TId, props: IRowProps, modifiers?: IModifierKeys) => void;
    onDragStart?: (id: TId, props: IRowProps, event: React.DragEvent<HTMLDivElement>) => void;
    sort?: ISort[];
    passRef?: React.Ref<HTMLDivElement>;
    tableWrapperRef?: React.Ref<HTMLDivElement>;
    noDataText?: string;
    hasSimplifiedNoData?: boolean;
    addingRow?: TableAddingRowType;
    /* Can be used with any addingRow type, new row will be add as child of this row. If null, new row will be insert as first row. */
    addingRowParent?: TId;
    onAddingRowCancel?: () => void;
    /** Event for the original designs of adding new hierarchical rows */
    onRowAdd?: (args: IRowAddEvent) => void;
    disableVirtualization?: boolean;
    // if explicitly set to false, there won't be additional 6px padding for the status token on the left
    useStatusHighlight?: boolean;
    isForPrint?: boolean;
    rowAction?: IRowAction;
    /** row state icon in front of the row (in front if action if both are active at same time) */
    rowIcon?: (id: TId, row: IRowProps, rowAction: IRowAction) => React.ComponentType<IIconProps>;
    contentBefore?: (id: TId, row: IRowProps) => React.ReactElement;
    hierarchy?: string;
    isList?: boolean;
    isHoverDisabled?: boolean;
    customColors?: ICustomTableColors;
    customNewRowLabel?: React.ReactNode;
    customBusyContent?: React.ReactNode;
    withoutShadows?: boolean;
}

export interface ICustomTableColors {
    rowBackgroundColor?: string;
    rowBorderColor?: string;
}

export interface IRowAddEvent {
    siblingRow: IRow;
    type: TableAddingRowType;
    parent: IRow;
}

type TExtendedTableProps =
    ITableProps
    & WithTranslation
    & WithBusyIndicator
    & WithContextMenu
    & WithDomManipulator
    & PropsWithTheme;

interface IState {
    // if any table row has drilldown action,
    // we need to add padding so that the icon doesn't block last column value
    // => store it into state
    hasDrilldownAction?: boolean;
}

class Table extends PureComponent<TExtendedTableProps, IState> {
    static defaultProps: Partial<ITableProps> = {
        minimumBatchSize: 0,
        minimumRenderedRows: 0,
        columns: [],
        rows: [],
        sort: []
    };

    state: Partial<IState> = {
        hasDrilldownAction: false
    };

    _headerRef = React.createRef<HTMLDivElement>();
    _bodyRef = React.createRef<HTMLDivElement>();
    _scrollRef = React.createRef<HTMLDivElement>();
    _tableRef = React.createRef<HTMLDivElement>();

    _iconBarRef = React.createRef<HTMLDivElement>();
    _iconBarScrollRef = React.createRef<HTMLDivElement>();
    _rowShadowRef = React.createRef<HTMLDivElement>();
    _rowShadowScrollRef = React.createRef<HTMLDivElement>();

    _prevRows: IRow[];
    _prevColumns: TColumn[];

    private _firstScroll = true;

    // for each level of nesting, holds value whether there is at least one row with group caret on that level
    _hasGroups: TRecordType<boolean> = {};

    _maxWidthFirstColumn = TableSizes.MaxColumnWidth;
    _minWidthFirstColumn = TableSizes.MinColumnWidth;

    _unfoldedRows: IUnfoldRow[];
    _toggledGroupId: TId;

    componentDidMount(): void {
        this.init();
        this.alignHeader();

        if (!this.props.busy) {
            this.onLoaded();
        }
    }

    componentDidUpdate(prevProps: TExtendedTableProps) {
        // if (prevProps.scrollTop !== this.props.scrollTop && this._scrollRef.current.scrollTop !== this.props.scrollTop) {
        //     this._scrollRef.current.scrollTop = this.props.scrollTop;
        // }
        this.init();
        this.handleToggledGroup();
        this.alignHeader();

        if (prevProps.busy && !this.props.busy) {
            this.onLoaded();
        }
        if ((!prevProps.addingRow && this.props.addingRow) || (this.props.addingRow && prevProps.addingRowParent !== this.props.addingRowParent)) {
            this.scrollToNewRow();
        } else if ((!prevProps.minimumRenderedRows && this.props.minimumRenderedRows) || (prevProps.busy && !this.props.busy)) {
            // this indicates that the data has probably changed => we want to scroll top when new data are loaded
            this.scrollTop();
        }

        // if some row has action (drilldown), we need to add same padding to the last column as well,
        // otherwise, if the last column is aligned to the right, it would have different position then the row values
        // we use first row to determine if drilldown action is used,
        // same logic is used in TableWithAutoSizedColumns when computing columns widths
        // change if needed
        const hasDrilldownAction = this.props.rows?.length > 0 && this.props.rows[0] && !!getAction(this.props.rows[0], this.props.isForPrint);

        if (this.state.hasDrilldownAction !== hasDrilldownAction) {
            this.setState({
                hasDrilldownAction
            });
        }
    }

    alignHeader() {
        this.props.domManipulatorOrchestrator.registerCallback<{ width: number }>(
            () => ({
                width: this._iconBarRef.current?.clientWidth
            }),
            ({ width }) => {
                if (this._headerRef.current) {
                    this._headerRef.current.style.marginLeft = `${width ?? 0}px`;
                }
            },
            [this._iconBarRef, this._headerRef]
        );
    }

    onLoaded = () => {
        setTimeout(() => {
            this._tableRef.current?.dispatchEvent(
                new CustomEvent("tableLoaded", { bubbles: true })
            );
        });
    };

    init = () => {
        if (this._firstScroll) {
            this.scrollToRow(this.props.selectedRows?.[0]);
        }
    };

    /** Returns list of column leaves */
    getColumns = () => {
        return getLeavesColumns(this.props.columns);
    };

    handleToggledGroup = () => {
        // if group was toggled, we need to set the group hover for the newly rendered child rows
        if (this._toggledGroupId) {
            this.setHover(null, this._toggledGroupId, true);
            this._toggledGroupId = null;
        }
    };

    isRowLoaded = (index: number): boolean => {
        if (!this._unfoldedRows) {
            return false;
        }

        return this._unfoldedRows && !!this._unfoldedRows[index];
    };

    getTranslateOffset = (scrollTop: number): number => {
        const offset = Math.floor(scrollTop / ROW_HEIGHT) * ROW_HEIGHT - ROW_HEIGHT * OVERHANG_ROW_COUNT;
        return offset < 0 ? 0 : offset;
    };

    getScrollTop = () => {
        // scrollTop doesn't have to be required property
        return this._scrollRef.current ? this._scrollRef.current.scrollTop : 0;
    };

    scrollTop = (): void => {
        if (this._scrollRef.current) {
            this._scrollRef.current.scrollTop = 0;
        }
    };

    scrollToNewRow = (): void => {
        this.scrollToRow(NEW_ROW_ID);
    };

    handleScrollThrottled = ({ scrollTop, scrollLeft }: { scrollTop: number, scrollLeft: number }) => {
        // synchronize horizontal scrollbar with header
        this._headerRef.current.scrollLeft = scrollLeft;

        if (!this.shouldDisableVirtualization()) {
            const translate = this.getTranslateOffset(scrollTop);

            // compensate for the shadows padding
            // translation for virtualization
            this._bodyRef.current.style.transform = `translateY(${translate}px)`;

            const paddingTop = scrollTop > 0 ? 0 : TableSizes.ShadowPadding;

            this._rowShadowScrollRef.current.scrollTop = scrollTop;
            this._rowShadowScrollRef.current.style.paddingTop = `${paddingTop}px`;
            this._rowShadowScrollRef.current.style.top = `${-paddingTop}px`;

            this._rowShadowRef.current.style.transform = `translateY(${translate}px)`;
            this._rowShadowRef.current.style.top = `${paddingTop}px`;
            this._rowShadowRef.current.style.height = `calc(100% - ${TableSizes.ShadowPadding}px - ${paddingTop}px)`;

            if (this._iconBarRef.current) {
                this._iconBarRef.current.style.transform = `translateY(${translate}px)`;
                // synchronize vertical with the right icon bar section
                this._iconBarScrollRef.current.scrollTop = scrollTop;
            }

            if (this.props.onScroll) {
                this.props.onScroll({ scrollTop, scrollLeft });
            }

            // move focus outline with the scrollbar, so that it is visually pleasing
            // and doesn't end in the middle of the table when scrolled all the way to the right
            const focusedElement = document.activeElement as HTMLElement;
            if (focusedElement && focusedElement.getAttribute("role") && doesElementContainsElement(this._bodyRef.current, focusedElement)) {
                this.shiftRowFocusElement(focusedElement, scrollLeft);
            }

            // other option is to set new visible rows into state here, seems slower
            this.forceUpdate();
        }
    };

    handleScroll = (event: React.UIEvent) => {
        const target = event.target as HTMLDivElement;

        this.handleScrollThrottled({ scrollTop: target.scrollTop, scrollLeft: target.scrollLeft });
    };

    scrollToRow = (id: IToString) => {
        if (!this._unfoldedRows || this._unfoldedRows.length === 0 || isNotDefined(id) || !this._scrollRef.current) {
            return;
        }

        this._firstScroll = false;

        const rowIndex = this._unfoldedRows.findIndex((row) => row?.id.toString() === id.toString());

        if (rowIndex < 0) {
            return;
        }

        const rowPosition = Math.max(0, ROW_HEIGHT * rowIndex - ROW_HEIGHT);

        setTimeout(() => {
            if (this._scrollRef.current) {
                this._scrollRef.current.scrollTop = rowPosition;
            }
        });
    };

    handleColumnResize = animationFrameThrottle((params: IColumnResizeEvent) => {
        if (!this._tableRef.current) {
            return;
        }

        let width = params.width;
        let diff = params.stepDelta;

        if (params.columnIndex === 0) {
            const firstColumnWidth = this.getColumns()?.[0]?.width;

            width = firstColumnWidth + params.delta;

            if (!isObjectEmpty(this._hasGroups)) {
                const clampedWidth = clamp(width, this._minWidthFirstColumn, this._maxWidthFirstColumn);

                diff = params.stepDelta - (width - clampedWidth);
                width = clampedWidth;
            }
        }

        // performance optimization
        // update column widths with javascript and only call parents onColumnResize after the resize event ends
        const columnDivs = this._tableRef.current.querySelectorAll(`[data-column-index='${params.columnIndex}']`);
        columnDivs.forEach(columnDiv => {
            let flexDiv = columnDiv.parentElement;

            if (!flexDiv.style.flex) {
                flexDiv = flexDiv.parentElement;
            }

            const [grow, shrink, basis] = flexDiv.style.flex.split(" ");
            let newWidth = parseInt(basis) + diff;

            newWidth = clamp(newWidth, TableSizes.MinColumnWidth, TableSizes.MaxColumnWidth);

            flexDiv.style.flex = `${grow} ${shrink} ${newWidth}px`;
            flexDiv.style.width = `${newWidth}px`;
        });

        if (!this.props.disableColumnResize && this.props.onColumnResize && params.end) {
            this.props.onColumnResize({
                ...params,
                width
            });
        }
    });

    getColumnStickyValues = (isSticky: boolean, columns: IColumn[] = [], index: number) => {
        if (!isSticky) {
            return null;
        }

        let left = 0;
        let right = 0;

        for (let j = 0; j < columns.length; j++) {
            if (j !== index && columns[j].isSticky) {
                if (j < index) {
                    left += columns[j].width;
                } else if (j > index) {
                    right += columns[j].width;
                }
            }
        }

        return { left, right };
    };

    getRowsStickyValues = (isSticky: ISticky, rows: IRow[], index: number) => {
        if (!isSticky) {
            return null;
        }

        let top = 0;
        let bottom = 0;

        for (let j = 0; j < rows.length; j++) {
            if (j !== index && rows[j] && rows[j].sticky) {
                if (j < index) {
                    top += ROW_HEIGHT;
                } else if (j > index) {
                    bottom += ROW_HEIGHT;
                }
            }
        }

        return { top, bottom };
    };

    getRowColumnValue = memoize((row: IRow, column: IColumn): TCellValue => {
        return getValue(row.values[column.id]);
    }, (row: IRow, column: IColumn) => `${row.id?.toString()}-${column.id}`);

    getRowProperty = (row: IRow, column: IColumn, prop: "tooltip" | "value" | "onlyShowTooltipWhenChildrenOverflowing") => {
        let value;

        if (row) {
            value = this.getRowColumnValue(row, column);

            if (isRowValueCellValueObject(value)) {
                if (this.props.isForPrint) {
                    // todo doesn't work for some cases without title
                    // for printing, we render just title instead of icons
                    prop = "tooltip";
                }
                value = value[prop];
            }
        }

        return value;
    };

    getRowValue = (row: IRow, column: IColumn) => {
        return this.getRowProperty(row, column, "value");
    };

    getRowTooltip = (row: IRow, column: IColumn): string => {
        return (this.getRowProperty(row, column, "tooltip") as string);
    };

    getRowTooltipVisibility = (row: IRow, column: IColumn): boolean => {
        return (this.getRowProperty(row, column, "onlyShowTooltipWhenChildrenOverflowing") as boolean);
    };

    setHover = (rowId: TId, groupId: TId, forceHover: boolean) => {
        if (rowId && this._iconBarRef.current) {
            const row = this._iconBarRef.current.querySelector(`[data-rowid="${rowId.toString()}"]`);
            row.classList[forceHover ? "add" : "remove"]("hover");
        }

        if (!!this.props.rowAction) {
            return;
        }

        if (groupId) {
            const rows = [...this._tableRef.current.querySelectorAll(`[data-groupid="${groupId.toString()}"]`)];

            for (const row of rows) {
                for (const child of [...row.children]) {
                    if (child.getAttribute('data-testid') !== TestIds.State) {
                        (child as HTMLElement).style.backgroundColor = forceHover ? this.props.theme.C_BG_row_field_hover : this.props.theme.C_BG_row_field;
                    }
                }
            }
        }
    };

    handleRowMouseEnter = (e: IRowMouseEvent) => {
        this.setHover(e.id, e.groupId, true);
    };

    handleRowMouseLeave = (e: IRowMouseEvent) => {
        this.setHover(e.id, e.groupId, false);
    };

    /** Move focus outline with the scrollbar, so that it is visually pleasing
     and doesn't end in the middle of the table when scrolled all the way to the right */
    shiftRowFocusElement = (focusedElement: HTMLElement, scrollLeft: number) => {
        // compensate for the hierarchy rows offset
        const offsetX = focusedElement.parentElement.clientWidth - focusedElement.clientWidth;
        const focusScrollLeft = Math.min(scrollLeft < offsetX ? 1 : scrollLeft - offsetX);

        focusedElement.style.setProperty("--left", `${focusScrollLeft}px`);
        focusedElement.style.setProperty("--width", `calc(100% + ${Math.min(scrollLeft, offsetX)}px)`);
    };

    handleRowFocus = (e: IRowFocusEvent) => {
        const focusedElement = e.originalEvent.target as HTMLElement;
        const scrollLeft = this._scrollRef.current.scrollLeft;

        this.shiftRowFocusElement(focusedElement, scrollLeft);
    };

    isRowSelected = (rowId: TId) => {
        return !this.props.isForPrint && isRowSelected(this.props.selectedRows, rowId);
    };

    getRowType = (row: IRow) => {
        return row.type ?? RowType.Value;
    };

    handleRowClick = (rowId: TId, props: IRowProps, modifiers?: IModifierKeys) => {
        this.props.onRowSelect?.(rowId, props, modifiers);
    };

    handleRowContextMenu = (rowId: TId, props: IRowProps, event: React.MouseEvent) => {
        if (this.props.onContextMenu) {
            // context menu is for whole table, we need to distinguish, which row was clicked, so we can set correct
            // context menu items for particular row (note, with multiple selection, more rows can be selected,
            // when the context menu is triggered)
            this.props.onRowContextMenuSelection?.(rowId, props, event);
            // shows context menu
            this.props.onContextMenu(event);
        }
    };

    handleGroupToggle = (id: TId) => {
        this._toggledGroupId = id;
        this.props.onGroupToggle(id);
    };

    renderRow = (row: IRow, i: number, nextRow: IRow, itemProps: IFocusableItemProps) => {
        if (!row) {
            // null stands for unloaded row so that handleLoadMoreRows can be triggered
            return null;
        }

        if (this.getRowType(row) === RowType.New) {
            return (
                <Row key={row.id.toString() || i} id={row.id}
                     type={RowType.New}
                     selected
                     isDisabled={!!this.props.rowAction}
                    // force row width, so that the cancel new row button is position correctly when scrolling
                     minWidth={this.props.rows?.length > 0 ? this.getTableMinWidth() : null}
                     action={<IconButton title={this.props.t("Common:General.Cancel")}
                                         onClick={this.props.onAddingRowCancel}
                                         isDecorative>
                         <CloseIcon width={IconSize.S} height={IconSize.S}/>
                     </IconButton>}
                     level={row.level}
                     offset={this.getTranslateOffset(this.getScrollTop())}>
                    <SimpleBodyCell>
                        {row.content}
                    </SimpleBodyCell>
                </Row>
            );
        }

        const isLocked = this.isRowLocked(row);
        const action = getAction(row, this.props.isForPrint);
        const isDisabled = row.isDisabled || (this.props.rowAction && this.props.rowAction?.actionType !== RowAction.Custom && isLocked);
        const tooltip = row.tooltip ?? (isDisabled && isLocked ? this.props.t("Components:Table.LockedRecord") : null);

        return (
            <Row key={row.id.toString() || i}
                 id={row.id}
                 dataId={row.dataId}
                 groupId={row.groupId}
                 open={row.open}
                 hasRows={!!this.getRowRowCount(row)}
                 type={this.getRowType(row)}
                 tooltip={tooltip}
                 isLocked={row.isLocked} // has to be present for check in renderInnerPart
                 isDisabled={isDisabled}
                 isHoverDisabled={this.props.isHoverDisabled}
                 isHighlighted={row.isHighlighted}
                 isDivider={row.isDivider}
                // ignore sticky functionality for now, we don't use it anyway
                // sticky={this.getRowsStickyValues(row.sticky, this._unfoldedRows, row.originalIndex)}
                 level={row.level}
                 offset={this.getTranslateOffset(this.getScrollTop())}
                 customData={row.customData}
                 passProps={itemProps}
                 hierarchy={this.props.hierarchy}
                 onMouseEnter={this.handleRowMouseEnter}
                 onMouseLeave={this.handleRowMouseLeave}
                 onFocus={this.handleRowFocus}
                 onGroupToggle={this.handleGroupToggle}
                 statusHighlight={row.statusHighlight}
                 statusHighlightTooltip={row.statusHighlightTooltip}
                 action={action}
                 nextRow={nextRow}
                 selected={this.isRowSelected(row.id)}
                 isList={this.props.isList}
                 addingRow={this.props.addingRow}
                 nextRowIsSelected={(nextRow && this.isRowSelected(nextRow.id))}
                 isBold={row.isBold}
                 hasGroups={this._hasGroups[row.level]}
                 customBackgroundColor={this.props.customColors?.rowBackgroundColor}
                 customRowBorderColor={this.props.customColors?.rowBorderColor}
                 onContextMenu={this.handleRowContextMenu}
                 onDragStart={this.props.onDragStart}
                 onClick={this.handleRowClick}>
                {this.getColumns().map((column, j, columns) => {
                    let cellValueObject: ICellValueObject = null;

                    const colValue = this.getRowColumnValue(row, column);

                    if (isRowValueCellValueObject(colValue)) {
                        cellValueObject = colValue as ICellValueObject;
                    }

                    return (
                        <BodyCell textAlign={column.textAlign} key={column.id}
                                  id={column.id}
                                  isLoading={row.isLoading}
                                  isRowHighlighted={row.isHighlighted}
                                  sticky={this.getColumnStickyValues(column.sticky, columns, j)}
                                  isColumnHighlighted={column.isHighlighted}
                                  border={column.border}
                                  width={j === 0 && !this.props.contentBefore ? this.getFirstColumnWidth(row.level) : columns[j].width}
                                  minWidth={columns[j].minWidth}
                                  first={j === 0 && !this.props.contentBefore}
                                  last={j === columns.length - 1}
                                  hasAction={this.state.hasDrilldownAction}
                                  keepSpaceForStatus={this.props.useStatusHighlight !== false}
                                  level={row.level}
                                  tooltip={this.getRowTooltip(row, column)}
                                  onlyShowTooltipWhenChildrenOverflowing={this.getRowTooltipVisibility(row, column)}
                                  customBackgroundColor={this.props.customColors?.rowBackgroundColor}
                                  afterContent={cellValueObject?.afterContent}
                                  afterContentMinWidth={column.afterContentMinWidth}
                                  stretchContent={column.stretchContent}
                                  hoverContent={cellValueObject?.hoverContent}
                                  isHierarchy={!!this._hasGroups[row.level]}
                                  isForPrint={this.props.isForPrint}
                                  onColumnResize={this.props.disableColumnResize ? null : this.handleColumnResize}
                                  columnIndex={j}
                                  allowOverflow={!!row.allowOverflow?.[column.id]}>
                            {this.getRowValue(row, column)}
                        </BodyCell>
                    );
                })}
            </Row>
        );
    };

    unfoldRows = (rows: IRow[], rowCount: number, level = 0, group: TId = null): IUnfoldRow[] => {
        const unfoldRows = [];
        let row: IUnfoldRow;

        for (let i = 0; i < rowCount; i++) {
            row = rows && rows[i];
            if (row) {
                row.level = level;
                row.group = group;
                row.groupIndex = i;

                if (row.type === RowType.Group || row.rows?.length) {
                    row.group = row.id;
                    row.groupIndex = -1;
                    this._hasGroups[level] = this._hasGroups[level] || level > 0 || (!!row.rows && row.rows.length > 0);
                }

                unfoldRows.push(row);

                if (row.open) {
                    unfoldRows.push(...this.unfoldRows(row.rows, this.getRowRowCount(row), level + 1, row.id));
                }
            } else {
                unfoldRows.push(null);
            }
        }

        return unfoldRows;
    };

    /** Cache unfolded rows so that we can have easy access to any row */
    prepareUnfoldedRowsMemoized = memoizeOne((rows: IRow[], rowCount: number, itemProps: IFocusableItemProps): void => {
        this._hasGroups = {};
        // caches unfolded and rendered rows
        this._unfoldedRows = this.unfoldRows(rows, rowCount);
    }, () => [this.props.columns, this.props.selectedRows, this.props.onRowSelect, this.props.addingRow, this.props.rowAction]);

    getRowRowCount = (row: Pick<IRow, "rowCount" | "rows">) => {
        return row.rowCount ?? row.rows?.length ?? 0;
    };

    shouldAddNewRowAsFirstRow = (): boolean => {
        return newRowTypes.includes(this.props.addingRow) && !this.props.addingRowParent;
    };

    /**
     * Returns rows if the table has rows only.
     * If table includes groups, it unfolds the nested rows into simple array.
     *
     */
    prepareRows = (itemProps: IFocusableItemProps) => {
        let rows = this.props.rows;
        let rowCount = this.getRowRowCount({ rowCount: this.props.rowCount, rows: this.props.rows });

        if (!this.props.isForPrint) {
            if (this.shouldAddNewRowAsFirstRow()) {
                let newRowContent: React.ReactNode;
                if (this.props.addingRow === TableAddingRowType.Custom && this.props.customNewRowLabel) {
                    newRowContent = this.props.customNewRowLabel;
                } else {
                    newRowContent = this.props.t(`Components:Table.${this.props.addingRow === TableAddingRowType.Copy ? "CopiedRow" : "NewRow"}`);
                }
                rows = [{
                    id: NEW_ROW_ID,
                    type: RowType.New,
                    level: 0,
                    content:
                        <NewRowContent>{newRowContent}</NewRowContent>,
                    values: {}
                }, ...rows];

                rowCount += 1;
            } else if (this.props.addingRow && this.props.addingRowParent) {
                rows = cloneDeep(rows);
                let parentRow = getRowFromArray(rows, this.props.addingRowParent);

                if (parentRow) {
                    const parentRows = parentRow?.rows ?? [];

                    parentRow.rows = [{
                        id: NEW_ROW_ID,
                        type: RowType.New,
                        content: <NewRowContent>{this.props.t("Components:Table.NewRow")}</NewRowContent>,
                        values: {}
                    }, ...parentRows];
                    parentRow.rowCount = parentRow.rowCount ? parentRow.rowCount + 1 : 1;

                    while (parentRow) {
                        rows = updateRowInArray(rows, parentRow.id, (row) => {
                            row.open = true;
                            parentRow = row.customData?.parent;

                            return row;
                        });
                    }


                }
            }
        }

        return this.prepareUnfoldedRowsMemoized(
            rows, rowCount, itemProps
        );
    };

    // renders ONLY visible rows
    getVisibleRows = (firstVisible: number, lastVisible: number, itemProps: IFocusableItemProps): React.ReactElement[] => {
        const visibleRows = [];

        lastVisible = Math.min(lastVisible, this.getTotalRowCount());

        // ignore sticky functionality for now, we don't use it anyway
        // const stickyRowsBefore = rows.reduce((rowsBefore, currentRow, i) => {
        //     const isSticky = currentRow && currentRow.props.sticky && i <= firstVisible + rowsBefore.length;
        //
        //     return isSticky ? [...rowsBefore, { ...currentRow, originalIndex: i }] : rowsBefore;
        // }, []);
        //
        //
        // const stickyRowsAfter = rows.reduce((rowsAfter, currentRow, i) => {
        //     const isSticky = currentRow && currentRow.props.sticky && i >= lastVisible + rowsAfter.length;
        //
        //     return isSticky ? [...rowsAfter, { ...currentRow, originalIndex: i }] : rowsAfter;
        // }, []);
        //
        // firstVisible += stickyRowsBefore.length;
        // lastVisible -= stickyRowsAfter.length;
        //
        // stickyRowsBefore.forEach(row => {
        //     visibleRows.push(row);
        // });

        for (let i = firstVisible; i <= lastVisible; i++) {
            const renderedRow = this.renderRow(this._unfoldedRows[i], i, this._unfoldedRows[i + 1], itemProps);

            // const row = { ...renderedRow, originalIndex: i };

            // it's possible that the row has not yet been created by SmartTable (as "loading" state)
            if (renderedRow) {
                visibleRows.push(renderedRow);
            }
        }

        // stickyRowsAfter.forEach(row => {
        //     visibleRows.push(row);
        // });

        return visibleRows;
    };

    getMaxOpenLevel = (rows: IUnfoldRow[] = [], level = 0) => {
        let maxLevel = level;

        rows.forEach(row => {
            if (!row || !row.open || !row.rows || row.rows.length === 0) {
                return;
            }

            maxLevel = Math.max(maxLevel, this.getMaxOpenLevel(row.rows, level + 1));
        });

        return maxLevel;
    };

    handleLoadMoreRows = (startIndex: number, stopIndex: number): null => {
        // TODO for props.addingRowParent the behavior is more complex,
        //  but maybe it doesn't matter, since we usually have all rows loaded when using table with hierarchy

        // if Table manually add RowType.New into this._unfoldedRows
        // we need to load new rows from startIndex - 1, because new row isn't part of this.props.rows
        if (this.shouldAddNewRowAsFirstRow()) {
            startIndex -= 1;
            stopIndex -= 1;
        }

        if (this._unfoldedRows[startIndex - 1] && this._unfoldedRows[startIndex - 1].group) {
            this.props.onLoadMoreItems({
                group: this._unfoldedRows[startIndex - 1].group,
                startIndex: this._unfoldedRows[startIndex - 1].groupIndex + 1,
                stopIndex: this._unfoldedRows[startIndex - 1].groupIndex + 1 + (stopIndex - startIndex)
            });
        } else {
            this.props.onLoadMoreItems({ startIndex, stopIndex });
        }

        return null;
    };

    getTableMinWidth = () => {
        let minWidth = 0;

        this.getColumns().forEach((column, i) => {
            if (i === 0) {
                minWidth += this.getFirstColumnWidth();
            } else {
                minWidth += column.width;
            }
        });

        return minWidth;
    };

    getTotalHeight = (): number => {
        return this.getTotalRowCount() * ROW_HEIGHT;
    };

    getTotalRowCount = (): number => {
        return (this._unfoldedRows && this._unfoldedRows.length) || 0;
    };

    getFirstColumnWidth = (level = 0, { isHeader = false, width = this.getColumns()?.[0]?.width } = {}) => {
        let firstColumnWidth = width;

        if (!firstColumnWidth) {
            return 0;
        }

        const maxOpenLevel = this.getMaxOpenLevel(this.props.rows);

        // in hierarchy tables, only first level have groupMargin, others have more narrow indent
        for (let i = level; i < maxOpenLevel; i++) {
            firstColumnWidth += TableSizes.GroupMargin;
        }

        if (this._hasGroups[level] && !isHeader && !this.props.hierarchy) {
            // compensate for the group toggle icon
            firstColumnWidth -= IconSize.asNumber("XS");
        }

        if (!isObjectEmpty(this._hasGroups)) {
            if (level === 0) {
                const diff = TableSizes.MaxColumnWidth - firstColumnWidth;
                this._maxWidthFirstColumn = diff > 0 ? width + diff : width - diff;
            } else if (level === maxOpenLevel) {
                const diff = firstColumnWidth - TableSizes.MinColumnWidth;
                this._minWidthFirstColumn = diff > 0 ? width - diff : width + diff;
            }
        }

        return firstColumnWidth;
    };

    getVisibleRowsRange = (height: number) => {
        let firstVisible = Math.floor(this.getScrollTop() / ROW_HEIGHT) - OVERHANG_ROW_COUNT;
        let lastVisible = firstVisible + Math.ceil(height / ROW_HEIGHT) + 2 * OVERHANG_ROW_COUNT;

        if (firstVisible < 0) {
            firstVisible = 0;
        }

        if (this.props.minimumRenderedRows && lastVisible - firstVisible < this.props.minimumRenderedRows) {
            lastVisible = firstVisible + this.props.minimumRenderedRows;
        }

        return { firstVisible, lastVisible };
    };

    getHeaderCellWidth = (i: number): number => {
        const columns = this.getColumns();

        if (i === 0 && !this.props.contentBefore) {
            return this.getFirstColumnWidth(0, { isHeader: true });
        } else {
            return columns[i].width;
        }
    };

    renderNoData = () => {
        return (
            <NoData noDataText={this.props.noDataText}
                    isSimplified={this.props.hasSimplifiedNoData}/>
        );
    };

    renderActionButton = (args: IActionRendererArgs) => {
        const rowAction = this.props.rowAction;
        const isNoneActionState = args.actionState === ActionState.None;

        args.isDisabled = args.isDisabled || args.actionState === ActionState.Disabled;

        const _renderCheckbox = () => {
            if (this.props.rowAction?.isSingleSelect) {
                return <ActionRadioBoxStyled checked={args.actionState === ActionState.Active}
                                             isDisabled={args.isDisabled}
                                             onChecked={args.onClick}/>;
            } else {
                return <ActionCheckBoxStyled checked={args.actionState === ActionState.Active}
                                             isDisabled={args.isDisabled}
                                             onChange={args.onClick}/>;
            }
        };

        let InnerIcon;
        let title;

        // todo would be nice if None actually meant no icon
        // and ActionState.Disabled could be add for disabled icons.
        // this would need refactoring in places where we currently use isRowWithoutAction to show disabled icons (e.g. CoA table)
        if (isNoneActionState) {
            return null;
        }

        switch (rowAction.actionType) {
            case RowAction.Lock:
                if (args.actionState === ActionState.Active) {
                    InnerIcon = LockFilledIcon;
                    title = !args.canUnlock && args.isLocked ? this.props.t("Components:Table.LockPermissionLack") : this.props.t("Components:Table.UnlockRecord");
                } else {
                    InnerIcon = LockIcon;
                    title = this.props.t("Components:Table.LockRecord");
                }
                break;
            case RowAction.Remove:
                if (args.actionState === ActionState.Active) {
                    InnerIcon = RefreshIcon;
                    title = this.props.t("Components:Table.RestoreRow");
                } else {
                    InnerIcon = BinIcon;
                    title = this.props.t("Components:Table.RemoveRow");
                }
                break;
            case RowAction.MassEdit:
            case RowAction.Check:
                return _renderCheckbox();
            case RowAction.Custom:
                const { render } = rowAction;
                return render ? render(args) : _renderCheckbox();
        }

        const isDisabled = (isNoneActionState || args.isDisabled) || (rowAction.actionType === RowAction.Lock && !args.canUnlock && args.isLocked);
        return (
            <>
                <IconButton title={rowAction?.tooltip ? rowAction.tooltip(args.rowId) ?? title : title}
                            onClick={args.onClick}
                            isDisabled={isDisabled}
                            isDecorative>
                    <InnerIcon width={IconSize.S} height={IconSize.S}/>
                </IconButton>
            </>
        );
    };

    handleToggleAllClick = () => {
        this.props.rowAction?.onToggleChange?.(this.props.rowAction?.toggleState === ToggleState.AllChecked ? ToggleState.AllUnchecked : ToggleState.AllChecked);
    };

    renderActionHeaderButton = () => {
        return (
            <HeaderActionWrapperPositioner>
                <HeaderActionWrapper data-testid={TestIds.TableMasterAction}>
                    {
                        this.renderActionButton({
                            actionState: this.props.rowAction?.toggleState === ToggleState.AllChecked ? ActionState.Active : ActionState.Inactive,
                            onClick: this.handleToggleAllClick,
                            isDisabled: this.props.rowAction?.toggleState === ToggleState.Disabled,
                            isMainToggle: true
                        })
                    }
                </HeaderActionWrapper>
            </HeaderActionWrapperPositioner>
        );
    };

    renderActionRowButton = (row: Row, actionState: ActionState, isDisabled: boolean, isLocked: boolean, canUnlock: boolean) => {
        return this.renderActionButton({
            actionState,
            onClick: () => this.props.rowAction.onClick(row.props.id, row.props as IRowProps),
            isDisabled, isMainToggle: false,
            rowId: row.props.id,
            isLocked,
            canUnlock
        });
    };

    isRowLocked = (row: IRow) => {
        return (!this.props.rowAction || this.props.rowAction.actionType !== RowAction.Lock) && (row).isLocked;
    };

    renderShadows = (visibleRows: React.ReactElement<IRowProps>[]) => {
        return visibleRows.map(row => {
            const isValueRow = row.props?.type === RowType.Value;
            const state = this.props.rowAction?.getActionState(row.props.id, row.props);

            return (
                <RowShadow key={row.props.id.toString()}
                           selected={row.props.selected}
                           isChecked={state === ActionState.Active}
                           aria-selected={row.props.selected}
                           isList={row.props.isList}
                           level={row.props.level}
                           isValueRow={isValueRow}
                           isForPrint={this.props.isForPrint}
                />
            );
        });

    };

    renderInnerPart = (height: number, itemProps: IFocusableItemProps) => {
        const { firstVisible, lastVisible } = this.getVisibleRowsRange(height);
        const visibleRows = this.getVisibleRows(firstVisible, lastVisible, itemProps);
        const fakeHeight = this.getTotalHeight();
        const secondHeight = Math.min(height, this.getTotalHeight());
        const isActive = !!this.props.rowAction;
        return (
            <InnerWrapper _height={secondHeight}>
                <IconBarScroll ref={this._iconBarScrollRef}
                               isActive={isActive}>
                    <IconBar ref={this._iconBarRef} _height={fakeHeight} isActive={isActive}
                             data-testid={TestIds.IconBar}>
                        {visibleRows.map((row) => {
                            const rowLocked = this.isRowLocked(row.props as IRow);
                            const canUnlock = row.props.customData?.canUnlock;
                            // locking should be disabled for dirty (disabled) row - DEV-2067
                            const isDisabled = (rowLocked && this.props.rowAction?.actionType !== RowAction.Custom)
                                || (this.props.rowAction?.actionType === RowAction.Lock && row.props.isDisabled);
                            const state = this.props.rowAction?.getActionState(row.props.id, row.props as IRowProps);
                            const showOnHover = getValue(this.props.rowAction?.showIconsOnHover, row.props.id, row) && state !== ActionState.Active;
                            const isNewRow = row.props.id === NEW_ROW_ID;
                            const CustomRowIcon = !isNewRow ? this.props.rowIcon?.(row.props.id, row.props as IRowProps, this.props.rowAction) ?? (rowLocked && LockFilledIcon) : null;
                            return (
                                <BeforeRowContentWrapper key={row.props.id.toString()}
                                                         title={rowLocked ? this.props.t("Components:Table.LockedRecord") : null}
                                                         isDivider={!rowLocked && row.props.isDivider}
                                                         aria-selected={row.props.selected}
                                                         data-rowid={row.props.id.toString()}
                                                         data-testid={TestIds.IconBarItem}>
                                    {CustomRowIcon && <RowIcon><CustomRowIcon width={IconSize.S}/></RowIcon>}
                                    <ActionIconWrapper isActive={isActive}
                                                       showOnHover={showOnHover}
                                                       data-testid={TestIds.ActionIconWrapper}>
                                        {this.props.rowAction && this.renderActionRowButton(row as unknown as Row, state, isDisabled, row.props.isLocked, canUnlock)}
                                    </ActionIconWrapper>
                                    {this.props.contentBefore?.(row.props.id, row.props as IRowProps)}
                                </BeforeRowContentWrapper>
                            );
                        })}
                    </IconBar>
                </IconBarScroll>
                <TableWrapper ref={this.props.tableWrapperRef}>
                    <RowShadowScroll ref={this._rowShadowScrollRef}>
                        <FakeHeight _height={fakeHeight}/>
                        <RowShadowWrapper ref={this._rowShadowRef}>
                            {this.renderShadows(visibleRows)}
                        </RowShadowWrapper>
                    </RowShadowScroll>
                    <InfiniteLoader
                        isItemLoaded={this.isRowLoaded}
                        itemCount={this.getTotalRowCount()}
                        loadMoreItems={this.handleLoadMoreRows}
                        minimumBatchSize={this.props.minimumBatchSize}>
                        {({
                              onItemsRendered,
                              ref
                          }: { onItemsRendered(props: IItemsRenderedEvent): void, ref: React.Ref<any> }) => {

                            return (
                                <ScrollBar
                                    scrollableNodeProps={{
                                        onScroll: this.handleScroll,
                                        ref: this._scrollRef
                                    }}
                                    style={{
                                        overflowX: "visible",
                                        width: "100%",
                                        height: secondHeight
                                    }}>
                                    <FakeHeight _height={fakeHeight}
                                                _width={this.props.rows?.length > 0 ? this.getTableMinWidth() : null}/>
                                    <Body
                                        isVirtualized
                                        ref={ref}
                                        passRef={this._bodyRef}
                                        // minWidth={this.getTableMinWidth()}
                                        visibleRows={{ firstVisible, lastVisible }}
                                        onItemsRendered={onItemsRendered}>
                                        {visibleRows}
                                    </Body>
                                </ScrollBar>
                            );
                        }}
                    </InfiniteLoader>
                </TableWrapper>
            </InnerWrapper>
        );
    };

    renderTable = (itemProps: IFocusableItemProps) => {
        return (
            <>
                <StyledHeader ref={this._headerRef} role="row"
                              data-testid={TestIds.TableHeader}>
                    {this.renderHeader(this.props.columns)}
                    <RowStretcher style={{
                        // so that header isn't less wide than body (problem with scrolling)
                        // size of the shadow padding + width of the action button
                        // only for virtualized table, otherwise table doesn't have shadows
                        minWidth: this.props.disableVirtualization ? "auto" : `${TableSizes.ShadowPadding + IconSize.asNumber("L")}px`,
                        minHeight: 1
                    }}/>
                </StyledHeader>
                {this.props.rowAction && !this.props.rowAction.isSingleSelect && this.renderActionHeaderButton()}
                {!this.shouldDisableVirtualization() && this.renderVirtualizedTable(itemProps)}
                {this.shouldDisableVirtualization() && this.renderWholeTable(itemProps)}
            </>
        );
    };

    handleHeaderClick = (sort: ISort) => {
        this.props.onSortChange([sort]);
    };

    renderHeader = (columns: TColumn[]) => {
        if (!this.props.rows || this.props.rows.length === 0) {
            return null;
        }

        // don't render header if there is no label
        if (isTableWithoutHeader(columns)) {
            return null;
        }

        return (
            columns.map(((column, i) => {
                const tooltip = Array.isArray(column.label) ? column.label.join("\n") : column.label;
                const labels = column.label?.split("\n") ?? [];
                const labelsRender = labels.map((label, index) => {
                    let labelRender = (
                        <Tooltip content={tooltip}
                                 onlyShowWhenChildrenOverflowing
                                 key={index}>
                            {(ref) => {
                                return (
                                    <Label key={index}
                                           textAlign={column.textAlign}
                                           ref={ref}>
                                        {label}
                                    </Label>
                                );
                            }}
                        </Tooltip>
                    );

                    if (index === labels.length - 1 && column.info) {
                        labelRender = (
                            <LabelTooltipWrapper textAlign={column.textAlign} key={index}>
                                {labelRender}
                                {isMetaColumn(column) &&
                                    <HeaderTooltipIcon offsetY={18}>
                                        {column.info}
                                    </HeaderTooltipIcon>
                                }
                                {/*prevent layout from breaking in case label is empty string*/}
                                {labels[index] === "" && <>&nbsp;</>}
                            </LabelTooltipWrapper>
                        );
                    }

                    return labelRender;
                });

                if (isMetaColumn(column)) {
                    return (
                        <MetaColumn key={i}
                                    border={column.border}
                                    isForPrint={this.props.isForPrint}
                                    data-testid={TestIds.TableHeaderMetaColumn}>
                            <MetaColumnLabel
                                isHighlighted={column.isHighlighted}
                                data-testid={TestIds.TableHeaderMetaColumnLabel}>
                                {labelsRender}
                            </MetaColumnLabel>
                            <MetaColumnContent data-testid={TestIds.TableHeaderMetaColumnContent}>
                                {this.renderHeader(column.columns)}
                            </MetaColumnContent>
                        </MetaColumn>
                    );
                } else {
                    const allColumns = this.getColumns();
                    const columnIndex = allColumns.findIndex(col => col.id === column.id);
                    let sort = null;

                    for (let i = 0; i < this.props.sort.length; i++) {
                        if (this.props.sort[i].id === column.id) {
                            sort = this.props.sort[i];
                        }
                    }

                    const isLast = columnIndex === allColumns.length - 1;

                    return (
                        <HeaderCell textAlign={column.textAlign}
                                    info={column.info}
                                    key={column.id} id={column.id}
                                    disableSort={column.disableSort || this.props.disableSort}
                                    onClick={this.handleHeaderClick}
                                    sort={sort?.sort}
                                    currentSort={this.props.sort?.[0]?.sort}
                                    isColumnHighlighted={column.isHighlighted}
                                    border={column.border}
                                    sticky={this.getColumnStickyValues(column.isSticky, allColumns, columnIndex)}
                                    isForPrint={this.props.isForPrint}
                                    columnIndex={columnIndex}
                                    tooltip={tooltip}
                                    width={this.getHeaderCellWidth(columnIndex)}
                                    first={columnIndex === 0 && !this.props.contentBefore}
                                    last={isLast}
                                    hasAction={isLast && this.state.hasDrilldownAction}
                        >
                            {labelsRender}
                        </HeaderCell>
                    );
                }
            }))
        );
    };

    shouldDisableVirtualization = () => {
        return this.props.disableVirtualization || this.props.isForPrint;
    };

    renderVirtualizedTable = (itemProps: IFocusableItemProps) => {
        return (
            <div style={{
                width: "100%",
                display: "flex",
                flexGrow: 1,
                flexShrink: 0
            }}>
                <AutoSizerWrapper>
                    <AutoSizer disableWidth={true}>
                        {
                            ({ height }: { height: number }) => {
                                return this.renderInnerPart(height, itemProps);
                            }}
                    </AutoSizer>
                </AutoSizerWrapper>
            </div>
        );
    };

    // doesn't support scrollToRow (don't use own vertical scrollbar)
    // TODO add same support for shadows and RightIconBar as in virtualized table, if needed
    renderWholeTable = (itemProps: IFocusableItemProps) => {
        const visibleRows = this.getVisibleRows(0, this.getTotalRowCount(), itemProps);

        return (
            // this is rendered inside flex together with StyledHeader, and ScrollBar has height: 100% =>
            // => there has to be one more wrapper to prevent scrollbar from being to tall
            <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
                <ScrollBar
                    scrollableNodeProps={{
                        onScroll: this.handleScroll,
                        ref: this._scrollRef
                    }}>
                    {!this.props.withoutShadows &&
                        <WholeTableShadowWrapper>
                            {this.renderShadows(visibleRows)}
                        </WholeTableShadowWrapper>
                    }
                    <Body isVirtualized={false}
                          passRef={this._bodyRef}
                        // minWidth={this.getTableMinWidth()}
                    >
                        {visibleRows}
                    </Body>
                </ScrollBar>
            </div>
        );
    };

    render = () => {
        // reset getRowColumnValue cache before rendering if props.rows or props.columns has changed.
        // Check like this should usually be in componentDidUpdate,
        // but that is too late. We need to clear the cache before the rendering, otherwise we would have to render twice (performance hit).
        if (this._prevRows !== this.props.rows || this._prevColumns !== this.props.columns) {
            this.getRowColumnValue.cache.clear();
        }

        this._prevRows = this.props.rows;
        this._prevColumns = this.props.columns;

        let table = (
            <FocusManager direction={FocusDirection.Vertical} disableLoop>
                {({ itemProps, wrapperProps }) => {
                    this.prepareRows(itemProps);

                    return (
                        <StyledTable {...wrapperProps}
                                     ref={composeRefHandlers(this._tableRef, this.props.passRef, wrapperProps.ref)}
                                     style={this.props.style}
                                     data-testid={TestIds.Table}
                                     isBusy={this.props.busy}
                                     {...{ [HOTSPOT_ID_ATTR]: this.props.tableId }}
                                     isVirtualized={!this.shouldDisableVirtualization()}
                                     isForPrint={this.props.isForPrint}
                                     role="table" aria-rowcount={this.getTotalRowCount()}>
                            {this._unfoldedRows?.length > 0 ? this.renderTable(itemProps) : this.props.busy ? null : this.renderNoData()}
                        </StyledTable>
                    );
                }}
            </FocusManager>
        );

        if (this.props.isForPrint) {
            // enforce default theme for printing
            table = (
                <ThemeProvider theme={themes["light"]}>
                    {table}
                </ThemeProvider>
            );
        }

        return table;
    };
}

interface IItemsRenderedEvent {
    visibleStartIndex: number;
    visibleStopIndex: number;
}

interface IBodyProps {
    minWidth?: number;
    isVirtualized?: boolean;
    passRef?: React.RefObject<HTMLDivElement>;
    onItemsRendered?: (props: IItemsRenderedEvent) => void;
    visibleRows?: { firstVisible: number, lastVisible: number };
}

class Body extends PureComponent<IBodyProps> {
    componentDidUpdate() {
        if (this.props.visibleRows && this.props.onItemsRendered) {
            this.props.onItemsRendered({
                visibleStartIndex: this.props.visibleRows.firstVisible,
                visibleStopIndex: this.props.visibleRows.lastVisible
            });
        }
    }

    render() {
        return (
            <StyledBody minWidth={this.props.minWidth} ref={this.props.passRef}
                        isVirtualized={this.props.isVirtualized}
                        role="rowgroup">
                {this.props.children}
            </StyledBody>
        );
    }
}

export default withBusyIndicator({ isOverComponent: true })(withTranslation(["Components", "Common"])(withContextMenu(withDomManipulator(withTheme(Table)))));
