import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import React from "react";

import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { FormViewForExtend } from "../../../views/formView/FormView";
import { FormAlertContainer } from "../../../views/formView/FormView.styles";
import { PureForm } from "../../../views/formView/PureForm";

class FixedAssetChangePriceFormView extends FormViewForExtend<IEntity> {

    handleChange(e: ISmartFieldChange): void {
        super.handleChange(e);

        if (e.bindingContext.getPath() === BindingContext.localContext("Type")) {
            const { storage } = this.props;
            const dateBc = storage.data.bindingContext.navigate("Date");
            storage.clearError(dateBc);
            storage.validateField(dateBc)
                .then(() => storage.refreshFields());
        }
    }

    renderForm(): React.ReactElement {
        const { alert } = this.props.storage.data;
        return (<>
            {alert && <FormAlertContainer status={alert.status}
                                          title={alert.title}
                                          subTitle={alert.subTitle}
                                          isFullWidth/>}
            <PureForm
                storage={this.props.storage}
                onGroupExpand={this.handleGroupExpand}
                onBlur={this.handleBlur}
                onCancel={this.handleCancel}
                onRemove={this.handleRemove}
                onLineItemChange={this.handleLineItemsChange}
                onLineItemAction={this.handleLineItemsAction}
                onGroupAction={this.handleGroupAction}
                onConfirm={this.handleConfirm}
                {...this.props.formProps}
                onChange={this.handleChange}
                onTemporalChange={this.handleTemporalChange}/>
        </>);
    }
}

export default withPermissionContext(FixedAssetChangePriceFormView);