export interface IFetchMiddleWareBeforeArgs {
    request: Request;
    stop: () => void;
}

export type TFetchMiddlewareBefore = (args: IFetchMiddleWareBeforeArgs) => Promise<Request | Response | void>;

export interface IFetchMiddleWareAfterArgs {
    originalRequest: Request;
    response: Response;
    stop: () => void;
}

export type TFetchMiddlewareAfter = (args: IFetchMiddleWareAfterArgs) => Promise<Response | void>;

class FetchWithMiddleware {
    private beforeMiddlewares: TFetchMiddlewareBefore[] = [];
    private afterMiddlewares: TFetchMiddlewareAfter[] = [];
    public originalFetch = window.fetch.bind(window);

    constructor() {
        window.fetch = this.fetch;
    }

    // called before fetch call
    public useBefore = (middleware: TFetchMiddlewareBefore): void => {
        this.beforeMiddlewares.push(middleware);
    };

    // called after fetch call
    public useAfter = (middleware: TFetchMiddlewareAfter): void => {
        this.afterMiddlewares.push(middleware);
    };

    public removeBefore = (middleware: TFetchMiddlewareBefore): void => {
        this.beforeMiddlewares = this.beforeMiddlewares.filter((m) => m !== middleware);
    };

    public removeAfter = (middleware: TFetchMiddlewareAfter): void => {
        this.afterMiddlewares = this.afterMiddlewares.filter((m) => m !== middleware);
    };

    public fetch = async (url: RequestInfo | URL, options?: RequestInit): Promise<Response> => {
        let request = new Request(url, options);
        let shouldStop = false;
        const stopFn = () => {
            shouldStop = true;
        };

        for (const middleware of this.beforeMiddlewares) {
            request = (await middleware({
                request, stop: stopFn
            }) || request) as Request;

            if (shouldStop) {
                return request as unknown as Response;
            }
        }
        let response = await this.originalFetch(request);

        for (const middleware of this.afterMiddlewares) {
            response = await middleware({
                originalRequest: request,
                response,
                stop: stopFn
            }) || response;

            // if middleware returns response, cancel the request and  return the response immediately
            if (shouldStop) {
                return response as Response;
            }
        }

        return response;
    };
}

// singleton instance
const fetchWithMiddleware = new FetchWithMiddleware();

export default fetchWithMiddleware;