import { IEntity } from "@odata/BindingContext";
import { getBoundValue } from "@odata/Data.utils";
import { getEnumName, getEnumSelectItems, isEnum, loadEnums } from "@odata/GeneratedEnums.utils";
import { formatValue } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { isNotDefined } from "@utils/general";
import { logger } from "@utils/log";
import { getOneFetch, isAbortException } from "@utils/oneFetch";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { ModelEvent } from "../../../model/Model";
import { IStorageModelData, StorageModel } from "../../../model/StorageModel";
import { TableStorage } from "../../../model/TableStorage";
import { IComplexFilter, isComplexFilterArr } from "../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { IMultiSelectionChangeArgs } from "../../inputs/select/MultiSelect";
import ValueHelper from "../../inputs/valueHelper";
import { ISmartMultiSelectProps } from "../smartSelect/SmartMultiSelect";
import {
    createSelectItem,
    fetchValueHelperData,
    getValueHelpFilterGroupByProps,
    parseCompositeFilterId
} from "./ValueHelper.utils";
import { IBaseSelectionChangeArgs, ISelectItem, TSelectItemId } from "@components/inputs/select/Select.types";
import { getItemsForRenderCallbackArgs } from "@components/inputs/select/SelectAPI";

export interface IValueHelperChangeArgs extends IBaseSelectionChangeArgs, Pick<IMultiSelectionChangeArgs, "selectedItems"> {
    value: IComplexFilter[] | TSelectItemId[];
}

interface ISmartTValueHelperCustomData {
    activeFilter?: string;
}

export interface IProps extends Omit<ISmartMultiSelectProps, "value" | "onChange">, WithOData, WithTranslation {
    label: string;
    value: IComplexFilter[] | TSelectItemId[];
    onChange: (args: IValueHelperChangeArgs) => void;
}

interface IState {
    items: ISelectItem[];
    isLoadingItems?: boolean;
}

class SmartValueHelper extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    oneFetch = getOneFetch();

    state: IState = {
        items: []
    };

    componentDidMount() {
        this.props.storage.emitter.on(ModelEvent.FilterChanged, this.handleFilterChange);
        this.setInitialItems();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.value?.length !== prevProps.value?.length) {
            this.setInitialItems();
        }
    }

    componentWillUnmount() {
        this.props.storage.emitter.off(ModelEvent.FilterChanged, this.handleFilterChange);
    }

    async fetchItems(): Promise<ISelectItem[]> {
        const { bindingContext, storage, fieldInfo } = this.props;
        if (fieldInfo.fieldSettings?.itemsFactory) {
            return (await fieldInfo.fieldSettings.itemsFactory({
                storage, bindingContext, info: fieldInfo, fetchFn: this.oneFetch
            })) ?? []; // itemsFactory should always return value, but in case it doesn't, fallback to empty array to prevent infinite loop
        }
        const isEnum = this.isEnum();
        const opts = {
            bindingContext, fieldInfo, isEnum,
            storage: storage as TableStorage
        };
        const data = await fetchValueHelperData(opts, this.oneFetch);
        return data.map(entity => createSelectItem(entity, opts));
    }

    isEnum = (): boolean => {
        return isEnum(this.props.fieldInfo);
    };

    isCurrencyEnum = (): boolean => {
        return this.isEnum() && this.getEnumName() === "Currency";
    };

    getEnumName = (): string => {
        return getEnumName(this.props.fieldInfo, this.props.oData);
    };

    getItems = () => {
        // used by reports
        const { bindingContext, fieldInfo } = this.props;
        if (bindingContext.isLocal() && fieldInfo?.fieldSettings?.items) {
            const { items, itemsForRender } = fieldInfo.fieldSettings;
            if (itemsForRender) {
                return itemsForRender(items, getItemsForRenderCallbackArgs(this.props.storage, fieldInfo, bindingContext, this.context));
            }
            return items;
        }

        return this.state.items?.length ? this.state.items : fieldInfo?.fieldSettings?.initialItems ?? [];
    };

    getStorage = () => {
        return this.props.storage as StorageModel<unknown, IStorageModelData<unknown, ISmartTValueHelperCustomData>, ISmartTValueHelperCustomData>;
    };

    handleFilterChange = async () => {
        if (this.props.bindingContext.isLocal()) {
            // for filters in reports
            return;
        }
        if (this.getStorage().getCustomData().activeFilter !== this.props.name) {
            this.setState({ items: [] });
        }
    };

    handleOpen = async () => {
        if (this.props.bindingContext.isLocal()) {
            // for filters in reports
            return;
        }

        let items: ISelectItem[] = [];
        try {
            this.setState({ isLoadingItems: true });
            items = await this.fetchItems();
        } catch (error) {
            // OneFetch -> raises AbortError exception -> ignore, do not set new state
            if (isAbortException(error)) {
                return;
            }
            // todo: how to handle possible error? May be some global message on page...
            // for now, fall silently - value helper will be empty
            logger.error("error in fetchData", error);
        }

        this.setState({ items, isLoadingItems: false });
    };

    setInitialItems = async () => {
        let initialItems: ISelectItem[];

        if (this.isEnum()) {
            // we need to load the enum name space even without initial item, to show correct translations in conditional dialog
            const enumName = this.getEnumName();

            if (!enumName) {
                return;
            }

            const { namespacesPromise, notLoadedNamespaces } = loadEnums(enumName);

            if (notLoadedNamespaces.length > 0) {
                await namespacesPromise;
                // because we load the name space manually,
                // we have to trigger manual rerender as well
                this.forceUpdate();
            }

            if (!this.props.value || this.props.fieldInfo.fieldSettings?.initialItems) {
                return;
            }

            const allItems = getEnumSelectItems(enumName);
            initialItems = allItems.filter((item: ISelectItem<string>) => (this.props.value as string[]).includes(item.id));

            if (!this.props.fieldInfo.fieldSettings) {
                this.props.fieldInfo.fieldSettings = {};
            }
        } else if (!isComplexFilterArr(this.props.value)) {
            if (isNotDefined(this.props.value)) {
                return;
            }

            initialItems = (this.props.value).map(val => {
                let entity: IEntity = {};
                let valForFormatter = val;

                if (getValueHelpFilterGroupByProps(this.props.bindingContext, this.props.fieldInfo)?.length > 0) {
                    // if groupByProps exists, value (id) is composed of multiple different values
                    // => we have to parse it and replace the "valForFormatter" with just the main value id
                    // ==> formatters called from formatValue then has correct data to work with
                    entity = parseCompositeFilterId(val as string);

                    if (entity) {
                        valForFormatter = getBoundValue({
                            bindingContext: this.props.bindingContext,
                            data: entity,
                            dataBindingContext: this.props.bindingContext.getRootParent()
                        });
                    }
                }

                return {
                    id: val,
                    label: formatValue(valForFormatter, this.props.fieldInfo, {
                        entity: entity || this.props.storage.data.entity,
                        readonly: true
                    })
                };
            });
        }
        this.props.fieldInfo.fieldSettings.initialItems = initialItems;

        this.props.storage.addActiveField(this.props.bindingContext);
        this.props.storage.refreshFields();
    };

    handleSelectChange = (args: IValueHelperChangeArgs) => {
        const { triggerAdditionalTasks, value } = args;
        this.getStorage().setCustomData({ activeFilter: this.props.name });
        if (triggerAdditionalTasks) {
            this.props.onChange({
                ...args,
                value,
                triggerAdditionalTasks
            });
        }
    };

    handleClick = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onClick?.(e, { storage, bindingContext });
    };
    handleIconActivate = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onIconActivate?.(e, { storage, bindingContext });
    };

    render = () => {
        const { bindingContext, value = [], ...restProps } = this.props;
        const items = this.getItems() ?? [];
        const initialItems = this.props.fieldInfo?.fieldSettings?.initialItems;
        const val = (isComplexFilterArr(value) || !value ? [] : value) as TSelectItemId[];
        // items, which are selected, but filtered out by other filters, are passed also as items, so they don't
        // disappear magically and user can remove them or can see that other filters are filtered by these values
        const filteredOutItems = initialItems?.filter(initialItem =>
            val.includes(initialItem.id) && !items.find(item => item.id === initialItem.id)) ?? [];

        const isTabular = !!this.isCurrencyEnum();
        const allItems = [...filteredOutItems, ...items];

        if (this.isCurrencyEnum()) {
            allItems.forEach(item => {
                item.tabularData = [`${item.id}`, item.label];
            });
        }

        return (
            <ValueHelper
                {...restProps}
                value={value}
                items={allItems}
                isTabular={isTabular}
                isLoadingItems={this.state.isLoadingItems}
                type={this.props.fieldInfo.valueType}
                onChange={this.handleSelectChange}
                onOpen={this.handleOpen}
                onClick={this.handleClick}
                onIconActivate={this.handleIconActivate}
            />
        );
    }
    ;
}

export default withTranslation()(withOData(SmartValueHelper));