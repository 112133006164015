import { isDefined } from "@utils/general";
import React from "react";
import { ToolbarItemType } from "../../enums";
import { getIcon } from "../icon";
import IconSelect from "../inputs/select/IconSelect";
import { StyledWriteLine, WriteLineLabel } from "./Toolbar.styles";
import { SubmittedNumericWriteLine, SubmittedWriteLine } from "../inputs/field/SubmittedInput";
import { SegmentedButton } from "../button/SegmentedButton";
import { Button, IProps as IButtonProps } from "../button";
import { TValue } from "../../global.types";
import TestIds from "../../testIds";
import ConfirmationButtons, { IProps as IConfirmationButtonProps } from "../../views/table/ConfirmationButtons";
import { ToolbarButton } from "@components/toolbar/ToolbarButton";
import { THistoryLocation } from "@components/drillDown/DrillDown.utils";
import { IToolbarItem, TToolbarItem } from "@components/toolbar/Toolbar.types";

export interface IToolbarButtonProps {
    item: IToolbarItem;
    onClick?: (item: IToolbarItem) => void;
    /** Use instead of onClick  */
    link?: THistoryLocation;
}

export const isToolbarItemDefinition = (item: TToolbarItem): item is IToolbarItem => {
    return isDefined((item as IToolbarItem)?.id);
};

export function createToolbarItem(item: IToolbarItem, onChange?: (id: string, value?: TValue) => void): React.ReactElement {
    const key = item.id;
    const props = item.itemProps || {};
    let element: React.ReactElement;

    switch (item.itemType) {
        case ToolbarItemType.Icon:
        case ToolbarItemType.SmallIcon:
            element = <ToolbarButton key={key}
                                     item={item}
                                     onClick={() => onChange?.(item.id)}
                                     link={(props as Partial<IToolbarButtonProps>).link}/>;
            break;
        case ToolbarItemType.IconSelect:
            const Icon = getIcon(item.iconName);
            element = <IconSelect
                key={key}
                icon={<Icon/>} title={item.label}
                headerText={item.itemProps.headerText}
                items={item.itemProps.items}
                hotspotId={item.id}
                onChange={(e) => onChange?.(e.value as string)}
                isDisabled={item.isDisabled}
                isActive={item.isActive}
                {...item.itemProps?.selectProps}
            />;
            break;
        case ToolbarItemType.WriteLine:
            element = (
                <StyledWriteLine key={key}
                                 maxWidth={props.maxWidth}
                                 isResponsive
                                 data-testid={TestIds.LabeledWriteLine}>
                    <SubmittedWriteLine {...props}
                                        value={props.value}
                                        isDisabled={item.isDisabled}
                                        onChange={
                                            (e) => onChange?.(item.id, e.value)
                                        }/>
                    {props.secondaryLabel &&
                        <WriteLineLabel data-testid={TestIds.WriteLineLabel}>{props.secondaryLabel}</WriteLineLabel>
                    }
                </StyledWriteLine>
            );
            break;
        case ToolbarItemType.NumericWriteLine:
            element = (
                <StyledWriteLine key={key} data-testid={TestIds.LabeledWriteLine}>
                    <SubmittedNumericWriteLine {...props}
                                               value={props.value}
                                               isDisabled={item.isDisabled}
                                               onChange={
                                                   (e) => onChange?.(item.id, e.value?.toString())
                                               }/>
                    {props.secondaryLabel &&
                        <WriteLineLabel data-testid={TestIds.WriteLineLabel}>{props.secondaryLabel}</WriteLineLabel>
                    }
                </StyledWriteLine>
            );
            break;
        case ToolbarItemType.SegmentedButton:
            element = <SegmentedButton key={key} {...props}
                                       hotspotId={item.id}
                                       def={props.def}
                                       isDisabled={item.isDisabled}
                                       onChange={(id) => onChange?.(item.id, id)}/>;
            break;
        case ToolbarItemType.Button:
            element = <Button key={key}
                              hotspotId={item.id}
                              isDisabled={item.isDisabled}
                              onClick={() => onChange?.(item.id)}
                              link={(props as Partial<IButtonProps>).link}
                              {...props}>{item.label}</Button>;
            break;
        case ToolbarItemType.ConfirmationButtons:
            const confButtonsProps = item.itemProps as IConfirmationButtonProps;

            element = <ConfirmationButtons key={key}
                                           {...confButtonsProps}/>;
            break;
        case ToolbarItemType.Custom:
            return item.render();
        default:
            break;
    }

    return element;
}

export const getConfirmationButtonsToolbarDefinition = (props: IConfirmationButtonProps, id = "confirmationButtons"): IToolbarItem => {
    return {
        id,
        itemType: ToolbarItemType.ConfirmationButtons,
        itemProps: props
    };
};