import { withOData } from "@odata/withOData";
import { ReactElement } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Page, { IPageParams, IProps } from "../../Page";
import { getDefinitions } from "./UsersDef";
import UsersTableView from "./UsersTableView";

export interface IUsersProps extends IProps<IPageParams> {
    customFooter?: ReactElement;
    customHeader?: ReactElement;
    hideBusyIndicator?: boolean;
    onTableActionChange?: (action: string) => void;
    onTableLoad?: () => void;
}

class Users extends Page<IUsersProps> {
    static defaultProps = {
        getDef: getDefinitions,
        tableView: UsersTableView
    };

    getMandatoryProps() {
        return {
            ...super.getMandatoryProps(),
            onTableActionChange: this.props.onTableActionChange,
            tableViewProps: {
                customFooter: this.props.customFooter,
                customHeader: this.props.customHeader,
                hideBusyIndicator: this.props.hideBusyIndicator,
                onTableLoad: this.props.onTableLoad
            }
        };
    }
}

export default withRouter<IUsersProps, any>(withTranslation(getDefinitions.translationFiles)(withOData(Users)));