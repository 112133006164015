import { IColumn, IRow, TId } from "@components/table";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import { createToolbarItem } from "@components/toolbar/Toolbar.utils";
import { IAccountingDepreciationPolicyItemEntity } from "@odata/GeneratedEntityTypes";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SimpleTable from "../../../components/simpleTable";
import { RowType, Status, TextAlign, ToolbarItemType } from "../../../enums";
import { formatCurrency } from "../../../types/Currency";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { TableButtonsAction } from "../../../views/table/TableToolbar.utils";
import { HeaderWrapper, ToggleWrapper } from "./AccountingDepreciationPolicyTable.styles";


interface IProps {
    header: React.ReactElement;
    data: IAccountingDepreciationPolicyItemEntity[];
    disableBeforeDate: Date;
    currency: string;
}

export const AccountingDepreciationPolicyTable: React.FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation(["FixedAsset", "Components"]);

    const columns: IColumn[] = [
        { id: "period", label: `${t("FixedAsset:DepreciationTable.Period")}:` },
        { id: "depreciation", label: `${t("FixedAsset:DepreciationTable.Depreciation")}:`, textAlign: TextAlign.Right },
        { id: "balance", label: `${t("FixedAsset:DepreciationTable.Balance")}:`, textAlign: TextAlign.Right },
        {
            id: "depreciated",
            label: `${t("FixedAsset:DepreciationTable.AccountingDepreciated")}:`,
            textAlign: TextAlign.Right
        },
    ];
    const { currency, disableBeforeDate, data } = props;

    const defaultOpened = [`${getUtcDayjs().year()}`];
    const [openedGroups, setOpenedGroup] = useState<string[]>(defaultOpened ?? []);

    const rows: IRow[] = useMemo(() => {
        const today = getUtcDayjs();
        const itemsByYears: Record<string, (IAccountingDepreciationPolicyItemEntity)[]> = {};
        let closest;
        const ret = [];

        for (const item of data) {
            const curr = getUtcDayjs(item.DateDepreciation);
            const year = curr.year();

            if (!itemsByYears[year]) {
                itemsByYears[year] = [];
            }

            itemsByYears[year].push(item);

            if (today.isSame(curr, "month")) {
                closest = item.Order;
            }
        }

        const disabledDate = getUtcDayjs(disableBeforeDate);

        for (const year of Object.keys(itemsByYears)) {
            const childRows: IRow[] = [];

            for (const item of itemsByYears[year]) {
                childRows.push({
                    id: item.Order,
                    values: {
                        period: DateType.format(item.DateDepreciation),
                        depreciation: formatCurrency(item.CalculatedExpense, currency),
                        balance: formatCurrency(item.EndValue, currency),
                        depreciated: formatCurrency(item.AccumulatedDepreciation, currency)
                    },
                    statusHighlight: item.Order === closest ? Status.Success : undefined,
                    isDisabled: disableBeforeDate && disabledDate.diff(item.DateDepreciation) >= 0
                });
            }

            ret.push({
                id: year,
                values: { period: year },
                type: RowType.Group,
                rows: childRows,
                open: openedGroups.includes(`${year}`)
            });
        }

        return ret;
    }, [data, disableBeforeDate, currency, openedGroups]);

    // Toggle All functionality
    const handleGroupToggle = useCallback((id: TId) => {
        const stringId = id.toString();
        if (openedGroups.includes(stringId)) {
            setOpenedGroup([...openedGroups.filter(item => item !== stringId)]);
        } else {
            setOpenedGroup([...openedGroups, stringId]);
        }
    }, [openedGroups, setOpenedGroup]);

    // todo: better solution?
    const filteredGroups = openedGroups.filter(group => rows.find(row => row.id === group));
    const isExpandable = filteredGroups.length !== rows.length;

    const handleToggleAll = useCallback(() => {
        if (isExpandable) {
            setOpenedGroup(rows.map(row => row.id.toString()));
        } else {
            // All rows are opened -> collapse all
            setOpenedGroup([]);
        }
    }, [isExpandable, setOpenedGroup, rows]);

    const expandCollapseAll: IToolbarItem = {
        id: TableButtonsAction.ExpandCollapseAll,
        itemType: ToolbarItemType.Icon,
        label: t(`Components:Table.${isExpandable ? "OpenAll" : "CloseAll"}`),
        iconName: isExpandable ? "ExpandAll" : "CollapseAll"
    };

    return (<>
        <HeaderWrapper>
            {props.header}
            {!!rows.length && <ToggleWrapper>{createToolbarItem(expandCollapseAll, handleToggleAll)}</ToggleWrapper>}
        </HeaderWrapper>
        <SimpleTable onGroupToggle={handleGroupToggle} rows={rows} columns={columns}/>
    </>);
};