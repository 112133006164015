import { clamp, doesElementContainsElement, isNotDefined } from "@utils/general";
import { debounce } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components/macro";

import { EVALA_WEBPAGE } from "../../constants";
import { AppContext, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { isEvalaProduction } from "../../global.utils";
import TestIds from "../../testIds";
import { themes } from "../../theme";
import memoizeOne from "../../utils/memoizeOne";
import { ExtendedShellOverlay } from "../../views/main/ExtendedShell.style";
import { ISegmentedButton } from "../button/SegmentedButton";
import { ModalBackground } from "../dialog/Dialog.styles";
import ModalBackdrop from "../dialog/ModalBackdrop";
import { HelpIcon, SupportIcon } from "../icon";
import { TImage } from "../imageUploader";
import { FieldMouseCapture } from "../inputs/field/Field.styles";
import { StyledTooltip } from "../tooltip/Tooltip.style";
import { TooltipArrow } from "../tooltipIcon/TooltipIcon.styles";
import Hotspot, { IHotspotProps } from "./Hotspot";
import {
    HotspotsFooter,
    HotspotsFooterButton,
    HotspotsFooterButtons,
    HotspotsFooterFileUploadButton,
    HotspotsFooterIconButton,
    HotspotsFooterSegmentedButton,
    PossibleHotspot,
    StyledHotspot,
    StyledHotspots
} from "./Hotspots.styles";
import {
    addIndexToDuplicateHotspotID,
    buildHotspotId,
    GENERAL_HOTSPOT_VIEW,
    getCleanHotspotId,
    getClosestViewId,
    HOTSPOT_ID_ATTR,
    isSameHotspotDefId,
    splitHotspotId
} from "./Hotspots.utils";
import HotspotTooltipContent, { IHotspotTooltipContentProps } from "./HotspotTooltipContent";
import NewHotspotTooltipContent from "./NewHotspotTooltipContent";

// hotspots (center of the circle) has to be rendered at least "hotspots_page_padding" from the edge of the window
const hotspots_window_padding = 0;

export enum HotspotsMode {
    View = "View",
    Edit = "Edit"
}

export type THotspotTooltipDef = Omit<IHotspotTooltipContentProps, "id" | "onDislike" | "onLike" | "onClose">;

export interface IHotspotDef {
    id: string;
    tooltipDef?: THotspotTooltipDef;
}

type THotspotState = Omit<IHotspotProps, "content" | "onClick"> &
    {
        isObscured?: boolean;
        width?: number;
        height?: number;
        // used to correctly order hotspots when rendering,
        // to provide better experience when using Tab keyboard navigation
        domIndex?: number;
        // reference to the definition from this.props.hotspots, if exists
        def?: IHotspotDef;
        // set to true, if only GENERAL_HOTSPOT_VIEW is available for this hotspot
        isOnlyGlobal?: boolean;
        groupId?: number;
    };

interface IHotspotGroupState {
    id: number;
    top: number;
    left: number;
    height: number;
    width: number;
    hotspots: Set<string>;
}

export interface IProps {
    hotspots: IHotspotDef[];
    mode?: HotspotsMode;

    onClose?: () => void;
    onModeChange?: (mode: HotspotsMode) => void;
    onExportClick?: () => void;
    onFileLoad?: (files: File[]) => void;

    onLike?: (hotspotId: string, value: boolean) => void;
    onDislike?: (hotspotId: string, value: boolean) => void;
    onSendCommentClick?: (id: string, comment: string) => void;
    onCommentTextChange?: (id: string, comment: string) => void;
    onImageInsert?: (id: string, images: File[]) => void;
    onImageClick?: (id: string, image: TImage) => void;
    onHotspotsChange?: (hotspots: IHotspotDef[]) => void;
    avatarSrc?: string;
    onAlertFadeEnd?: (id: string) => void;

    style?: React.CSSProperties;
    className?: string;
}

// if those elements cover some element, it doesn't mean it is actually obscured
const ALLOWED_COVER_ELEMENTS = [
    StyledHotspots.styledComponentId, StyledHotspot.styledComponentId, PossibleHotspot.styledComponentId, ModalBackground.styledComponentId,
    ExtendedShellOverlay.styledComponentId, StyledTooltip.styledComponentId, FieldMouseCapture.styledComponentId,
    TooltipArrow.styledComponentId,
    // @ts-ignore
    HotspotsFooterButtons.styledComponentId, HotspotsFooterButton.styledComponentId, HotspotsFooterIconButton.styledComponentId, HotspotsFooterSegmentedButton.styledComponentId, HotspotsFooterFileUploadButton.styledComponentId
];

// todo probably move isActive state of hotspots to props as well
interface IState {
    hotspotsState: Record<string, THotspotState>;
    // In HotspotsMode.Edit all changes are first stored here
    // and only after pressing confirm button, props.onHotspotsChange will be called.
    // This means that all unconfirmed changes will be lost on mode change/hotspots close.
    unconfirmedChanges: Record<string, IHotspotDef>;
    // for multiple close hotspots collapsed into one group
    hotspotsGroups: Record<string, IHotspotGroupState>;
    isSupportButtonOpen: boolean;
}

class Hotspots extends React.PureComponent<IProps & WithTranslation, IState> {
    static contextType = AppContext;
    static defaultProps: Partial<IProps> = {
        mode: HotspotsMode.View
    };

    state: IState = {
        hotspotsState: {},
        unconfirmedChanges: {},
        hotspotsGroups: {},
        isSupportButtonOpen: false
    };

    _hotspotsRef = React.createRef<HTMLDivElement>();
    ignoreObscuringChanges = false;

    get hotspots() {
        return this.props.hotspots;
    }

    componentDidMount() {
        this.updatePositions();
        window.addEventListener("resize", this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
    }

    componentDidUpdate(prevProps: IProps & WithTranslation, prevState: IState) {
        if (this.hasHotspotsChanged(prevProps.hotspots, this.props.hotspots) || (this.props.mode !== prevProps.mode)) {
            this.updatePositions();
        }

        if (prevProps.mode === HotspotsMode.Edit && this.props.mode !== HotspotsMode.Edit) {
            this.resetUnconfirmedChanges();
        }
    }

    hasHotspotsChanged = (oldHotspots: IHotspotDef[], newHotspots: IHotspotDef[]) => {
        return oldHotspots.length !== newHotspots.length || newHotspots.find(hotspot => !oldHotspots.find(h => h.id === hotspot.id));
    };

    resetUnconfirmedChanges = () => {
        this.setState({
            unconfirmedChanges: {}
        });

        // remove empty (unconfirmed) hotspots
        this.props.onHotspotsChange(this.hotspots.filter(hotspot => hotspot.tooltipDef?.header || hotspot.tooltipDef?.text));
    };

    getSegmentedButtonDef = memoizeOne((): ISegmentedButton[] => {
        return [
            { id: HotspotsMode.View, iconName: "Visible", title: this.props.t("Components:Hotspots.ViewMode") },
            { id: HotspotsMode.Edit, iconName: "Edit", title: this.props.t("Components:Hotspots.EditMode") }
        ];
    }, () => [this.props.tReady]);

    getHotspotState = (id: string): THotspotState => {
        return this.state.hotspotsState?.[id];
    };

    /** There can be multiple hotspots for one definition (with same element and view id)
     * find and return all of them */
    getAllHotspotsStates = (hotspotDefId: string): THotspotState[] => {
        return Object.values(this.state.hotspotsState).filter(state => isSameHotspotDefId(state.id, hotspotDefId));
    };

    // is this enough or should we use popper for even hotspot positioning?
    updatePositions = () => {
        const context = this.context as IAppContext;
        // todo what about when we have same generic button, in multiple places in view at once
        // should we show the same hotspot on both of them at the same time? if so, how should the liking/commenting work for them?
        // for now, we will only show hotspot for the first matched element, to prevent those problems
        const hotspotsState: Record<string, THotspotState> = {};
        const wrapperRect = this._hotspotsRef.current.getBoundingClientRect();
        const modalRoot = document.getElementById("modal-root");
        // DEV-15717 - if any popup is opened, only show hotspots in that that popup
        // modal-root can contain something other then dialogs (e.g. BackgroundJobs)
        // check isModalOpened as well
        const openedModals = Array.from(modalRoot.children ?? []).filter(c => c.getAttribute("data-testid") !== TestIds.BackgroundJobs);
        const hotspotsRoot = context.isModalOpened() && openedModals.length > 0 ? modalRoot : document;
        const elementsWithHotspotId = hotspotsRoot.querySelectorAll<HTMLElement>(`[${HOTSPOT_ID_ATTR}]`);
        const sameElementsCount: Record<string, number> = {};

        for (let i = 0; i < elementsWithHotspotId.length; i++) {
            const element = elementsWithHotspotId[i];
            const elementId = element.getAttribute(HOTSPOT_ID_ATTR);
            const viewId = getClosestViewId(element);
            // try to find more unique definition first, then get the General
            const hotspotDef = this.hotspots.find(hotspot => hotspot.id === buildHotspotId(viewId, elementId)) ?? this.hotspots.find(hotspot => hotspot.id === buildHotspotId(GENERAL_HOTSPOT_VIEW, elementId));

            if (!hotspotDef && this.props.mode === HotspotsMode.View) {
                continue;
            }

            // when in creating mode, we need to show ALL possible hotspots elements,
            // not just the ones that actually have hotspots
            let id: string = hotspotDef?.id ?? elementId;

            // always add duplicate index to elements with same id, even if they use different view
            // to prevent errors when changing between IsOnlyForThisPage states
            if (isNotDefined(sameElementsCount[elementId])) {
                sameElementsCount[elementId] = 0;
            } else {
                sameElementsCount[elementId] += 1;
                id = addIndexToDuplicateHotspotID(id, sameElementsCount[elementId]);
            }

            const elementRect = element.getBoundingClientRect();
            const currentHotspotState = this.state.hotspotsState?.[id];


            // todo how to properly check for full visibility
            let isElementObscured = false;

            if (!currentHotspotState?.isActive) {
                // to make isElementObscured more reliable, check for overlaps at three different points
                // - in the middle, left side, right side
                const positions = [
                    [elementRect.right - (elementRect.width / 2), elementRect.top + (elementRect.height / 2)],
                    [elementRect.right - 3, elementRect.top + (elementRect.height / 2)],
                    [elementRect.right - elementRect.width + 3, elementRect.top + (elementRect.height / 2)]
                ];

                const fnCheckIfObscured = (elements: Element[]): boolean => {
                    // ignore overlays like StyledHotspots and ExtendedShellOverlay
                    const elementFromPoint = elements.find(elem => ALLOWED_COVER_ELEMENTS.every(className => typeof elem.className !== "string" || !elem.className?.includes(className)));
                    // check if other elements hides the current element
                    return elementFromPoint !== element && !doesElementContainsElement(element, elementFromPoint);
                };

                for (const [x, y] of positions) {
                    const elementsFromPoint = document.elementsFromPoint(x, y);

                    isElementObscured = fnCheckIfObscured(elementsFromPoint);

                    if (isElementObscured) {
                        break;
                    }
                }
            }

            const top = clamp(elementRect.top - wrapperRect.top, hotspots_window_padding, wrapperRect.height - elementRect.height - hotspots_window_padding);
            const left = clamp(elementRect.left - wrapperRect.left, hotspots_window_padding, wrapperRect.width - elementRect.width - hotspots_window_padding);

            hotspotsState[id] = {
                id: id,
                top: top,
                left: left,
                width: elementRect.width,
                height: elementRect.height,
                isActive: currentHotspotState?.isActive ?? false,
                isObscured: this.ignoreObscuringChanges ? currentHotspotState?.isObscured : isElementObscured,
                domIndex: i,
                isOnlyGlobal: viewId === GENERAL_HOTSPOT_VIEW,
                def: hotspotDef
            };
        }

        this.ignoreObscuringChanges = false;

        // TODO uncomment after updated design specs is finished
        // find hotspots that are too close together
        // const hotspotsGroups: Record<string, IHotspotGroupState> = {};
        // const hotspotKeys = Object.keys(hotspotsState);
        // const MinDistance = 22;
        //
        // for (let i = 0; i < hotspotKeys.length; i++) {
        //     const hotspot1 = hotspotsState[hotspotKeys[i]];
        //
        //     if (!hotspot1.def || hotspot1.isObscured) {
        //         continue;
        //     }
        //
        //     for (let j = i + 1; j < hotspotKeys.length; j++) {
        //         const hotspot2 = hotspotsState[hotspotKeys[j]];
        //
        //         if (!hotspot2.def || hotspot2.isObscured) {
        //             continue;
        //         }
        //
        //         const distance = Math.sqrt(Math.pow(hotspot1.top - hotspot2.top, 2) + Math.pow((hotspot1.left + hotspot1.width) - (hotspot2.left + hotspot2.width), 2));
        //
        //         if (distance < MinDistance) {
        //             this.addHotspotsIntoGroup(hotspotsGroups, hotspot1, hotspot2);
        //         }
        //     }
        // }

        this.setState({
            hotspotsState
            // hotspotsGroups
        });
    };

    addHotspotsIntoGroup = (hotspotsGroups: Record<string, IHotspotGroupState>, hotspot1: THotspotState, hotspot2: THotspotState): void => {
        let group: IHotspotGroupState;

        if (hotspot1.groupId) {
            group = hotspotsGroups[hotspot1.groupId];
        } else if (hotspot2.groupId) {
            group = hotspotsGroups[hotspot2.groupId];
        } else {
            hotspotsGroups[hotspot1.domIndex] = {
                id: hotspot1.domIndex,
                top: hotspot1.top,
                left: hotspot1.left + hotspot1.width,
                height: hotspot1.top,
                width: hotspot1.left + hotspot1.width,
                hotspots: new Set<string>()
            };

            group = hotspotsGroups[hotspot1.domIndex];
        }

        this.addHotspotIntoGroup(group, hotspot1);
        this.addHotspotIntoGroup(group, hotspot2);
    };

    addHotspotIntoGroup = (group: IHotspotGroupState, hotspot: THotspotState): void => {
        group.hotspots.add(hotspot.id);
        hotspot.groupId = group.id;

        group.top = Math.min(group.top, hotspot.top);
        group.left = Math.min(group.left, hotspot.left + hotspot.width);
        group.height = Math.max(group.height, hotspot.top);
        group.width = Math.max(group.width, hotspot.left + hotspot.width);
    };

    // => tooltip is not moved to the new position of hotspot, we need to somehow force update tooltip position manually
    handleWindowResize = debounce(() => {
        this.updatePositions();
        (this.context as IAppContext).eventEmitter.emit(ContextEvents.UpdatePoppers);
    }, 50);

    handleHotspotClick = (id: string, forcedState?: boolean) => {
        const hotspotState = this.getHotspotState(id);

        this.setState({
            hotspotsState: {
                ...this.state.hotspotsState,
                [id]: {
                    ...hotspotState,
                    id,
                    isActive: forcedState ?? !hotspotState.isActive,
                    isObscured: false
                }
            }
        });
    };

    handleHotspotClose = (id: string) => {
        this.handleHotspotClick(id, false);
    };

    handleLike = (hotspotId: string, value: boolean) => {
        this.props.onLike?.(hotspotId, value);

        // automatically close hotspot (if still opened) on like click
        setTimeout(() => {
            if (this.state.hotspotsState[hotspotId].isActive) {
                this.handleHotspotClose(hotspotId);
            }
        }, 500);
    };

    handlePossibleHotspotClick = (id: string) => {
        const cleanId = getCleanHotspotId(id);
        const isNew = !this.hotspots.find(hotspot => hotspot.id === cleanId);

        if (!isNew) {
            this.handleHotspotClick(id);
        } else {
            const allSameHotspots = Object.values(this.state.hotspotsState).filter(state => {
                return getCleanHotspotId(state.id) === cleanId;
            });
            const newHotspotsState = { ...this.state.hotspotsState };


            for (let i = 0; i < allSameHotspots.length; i++) {
                const hotspotState = allSameHotspots[i];
                const newFullId = buildHotspotId(GENERAL_HOTSPOT_VIEW, hotspotState.id);

                newHotspotsState[newFullId] = {
                    ...hotspotState,
                    id: newFullId,
                    isActive: hotspotState.id === id
                };

                delete newHotspotsState[hotspotState.id];
            }

            // by setting isActive to true, Tooltip will be immediately opened
            // that will possibly obscure some hotspots
            // => ignore that
            this.ignoreObscuringChanges = true;

            const newId = buildHotspotId(GENERAL_HOTSPOT_VIEW, cleanId);
            const newHotspotDef: IHotspotDef = { id: newId, tooltipDef: {} };
            const updatedHotspots = [
                ...this.hotspots,
                newHotspotDef
            ];

            this.props.onHotspotsChange(updatedHotspots);

            this.setState({
                hotspotsState: newHotspotsState,
                unconfirmedChanges: { ...this.state.unconfirmedChanges, [newId]: newHotspotDef }
            });
        }
    };

    handleHeaderChange = (hotspotId: string, header: string) => {
        const hotspotDef = this.hotspots.find(hotspot => hotspot.id === hotspotId);

        this.setState({
            unconfirmedChanges: {
                ...this.state.unconfirmedChanges,
                [hotspotId]: {
                    ...hotspotDef,
                    ...this.state.unconfirmedChanges?.[hotspotId],
                    tooltipDef: {
                        ...hotspotDef.tooltipDef,
                        ...this.state.unconfirmedChanges?.[hotspotId]?.tooltipDef,
                        header
                    }
                }
            }
        });
    };

    handleTextChange = (hotspotId: string, text: string) => {
        const hotspotDef = this.hotspots.find(hotspot => hotspot.id === hotspotId);

        this.setState({
            unconfirmedChanges: {
                ...this.state.unconfirmedChanges,
                [hotspotId]: {
                    ...hotspotDef,
                    ...this.state.unconfirmedChanges?.[hotspotId],
                    tooltipDef: {
                        ...hotspotDef.tooltipDef,
                        ...this.state.unconfirmedChanges?.[hotspotId]?.tooltipDef,
                        text
                    }
                }
            }
        });
    };

    handleIsOnlyForThisPageChange = (sameElementIdIndex: number, hotspotId: string, isOnlyForThisPageChange: boolean) => {
        const newHotspotsState = { ...this.state.hotspotsState };
        const hotspotDef = this.hotspots.find(hotspot => hotspot.id === hotspotId);
        const { elementId } = splitHotspotId(hotspotId);
        let viewId;

        if (isOnlyForThisPageChange) {
            const element = document.querySelectorAll<HTMLElement>(`[${HOTSPOT_ID_ATTR}="${elementId}"]`)[sameElementIdIndex];

            viewId = getClosestViewId(element);
        } else {
            viewId = GENERAL_HOTSPOT_VIEW;
        }

        const newId = buildHotspotId(viewId, elementId);

        // by setting isActive to true, Tooltip will be immediately re-opened
        // that will possibly obscure some hotspots
        // => ignore that
        this.ignoreObscuringChanges = true;

        // only filter out old hotspotId if there is just one instance of this hotspot
        const sameHotspotsCount = Object.values(this.state.hotspotsState).filter(h => getCleanHotspotId(h.id) === hotspotId).length;

        const updatedHotspots = [
            ...this.hotspots.filter(h => h.id !== newId && (sameHotspotsCount > 1 || h.id !== hotspotId)),
            {
                ...hotspotDef,
                id: newId
            }
        ];

        // transfer unconfirmed changes to new hotspotId
        if (this.state.unconfirmedChanges[hotspotId]) {
            const newUnconfirmedChanges = {
                ...this.state.unconfirmedChanges,
                [newId]: {
                    ...this.state.unconfirmedChanges[hotspotId],
                    id: newId
                }
            };

            delete newUnconfirmedChanges[hotspotId];

            this.setState({
                unconfirmedChanges: newUnconfirmedChanges
            });
        }

        this.props.onHotspotsChange(updatedHotspots);

        const oldStateId = addIndexToDuplicateHotspotID(hotspotDef.id, sameElementIdIndex);
        const newStateId = addIndexToDuplicateHotspotID(newId, sameElementIdIndex);

        newHotspotsState[newStateId] = {
            ...this.state.hotspotsState[oldStateId],
            id: newStateId,
            isActive: true,
            isObscured: false
        };

        delete newHotspotsState[oldStateId];

        this.setState({
            hotspotsState: newHotspotsState
        });
    };

    handleHotspotRemove = (hotspotId: string) => {
        const newHotspotsState = { ...this.state.hotspotsState };

        delete newHotspotsState[hotspotId];
        this.ignoreObscuringChanges = true;

        this.props.onHotspotsChange(this.hotspots.filter(hotspot => hotspot.id !== hotspotId));

        this.setState({
            hotspotsState: newHotspotsState
        });
    };

    handleHotspotConfirm = (hotspotId: string, confirmId: string) => {
        const updatedHotspots = this.hotspots.map(hotspot => {
            if (hotspotId !== hotspot.id) {
                return hotspot;
            }

            return this.state.unconfirmedChanges[hotspotId] ?? hotspot;
        });

        const unconfirmedChanges = { ...this.state.unconfirmedChanges };

        delete unconfirmedChanges[hotspotId];

        this.setState({
            unconfirmedChanges
        });
        this.handleHotspotClose(confirmId);
        this.props.onHotspotsChange(updatedHotspots);
    };

    handleSupportButtonClick = () => {
        this.setState({
            isSupportButtonOpen: !this.state.isSupportButtonOpen
        });
    };

    handleSupportClose = () => {
        this.setState({
            isSupportButtonOpen: false
        });
    };

    handleModeChange = (buttonId: string) => {
        this.props.onModeChange?.(buttonId as HotspotsMode);
    };

    renderHotspotContent = (hotspotDef: IHotspotDef, hotspotState: THotspotState, confirmId: string, sameElementIdIndex: number) => {
        const { id, tooltipDef } = hotspotDef;

        if (this.props.mode === HotspotsMode.View) {
            return (
                <HotspotTooltipContent
                    id={id}
                    {...tooltipDef}
                    onClose={this.handleHotspotClose}
                    showLikeButtons
                    onLike={this.handleLike}
                    onDislike={this.props.onDislike}
                    onCommentTextChange={this.props.onCommentTextChange}
                    onSendCommentClick={this.props.onSendCommentClick}
                    isLikeActive={tooltipDef?.isLikeActive}
                    isDislikeActive={tooltipDef?.isDislikeActive}
                    onImageInsert={this.props.onImageInsert}
                    onImageClick={this.props.onImageClick}
                    avatarSrc={this.props.avatarSrc}
                    onAlertFadeEnd={this.props.onAlertFadeEnd}
                    emojiPickerDomPortalParent={this._hotspotsRef}
                />
            );
        } else {
            return (
                <NewHotspotTooltipContent
                    id={id}
                    header={hotspotDef.tooltipDef?.header}
                    text={hotspotDef.tooltipDef?.text}
                    isOnlyForThisPage={splitHotspotId(hotspotDef.id).viewId !== GENERAL_HOTSPOT_VIEW}
                    isOnlyForThisPageDisabled={hotspotState.isOnlyGlobal}
                    onHeaderChange={this.handleHeaderChange}
                    onTextChange={this.handleTextChange}
                    onIsOnlyForThisPageChange={this.handleIsOnlyForThisPageChange.bind(this, sameElementIdIndex)}
                    // we use hotspotDef.id for text changes, because we share one definition for all hotspots with same id
                    // but we need to use unique confirmId for closing, because every hotspot have different active state
                    onConfirm={() => {
                        this.handleHotspotConfirm(id, confirmId);
                    }}
                    onRemove={this.handleHotspotRemove}
                />
            );
        }
    };

    renderHotspot = (hotspotDef: IHotspotDef, renderedHotspots: React.ReactElement[]) => {
        const hotspotStates = this.getAllHotspotsStates(hotspotDef.id);

        if (hotspotStates.length === 0) {
            return;
        }

        // find index of the first hotspotState
        // we need to include all hotspots with same element id (even if viewid is different)
        // so that we can use that information in handleIsOnlyForThisPageChange
        let sameElementIdIndex = 0;
        const allHotspotsKeys = Object.keys(this.state.hotspotsState);

        for (let i = 0; i < allHotspotsKeys.length; i++) {
            const hotspotKey = allHotspotsKeys[i];

            if (hotspotKey === hotspotStates[0].id) {
                break;
            }

            const { elementId } = splitHotspotId(hotspotKey);
            const { elementId: hotspotDefElementId } = splitHotspotId(hotspotDef.id);

            if (getCleanHotspotId(elementId ?? hotspotKey) === getCleanHotspotId(hotspotDefElementId ?? hotspotDef.id)) {
                sameElementIdIndex += 1;
            }
        }

        for (let i = 0; i < hotspotStates.length; i++) {
            const hotspotState = hotspotStates[i];

            const id = hotspotState.id;

            if (!hotspotState || hotspotState.isObscured || hotspotState.groupId) {
                continue;
            }

            renderedHotspots[hotspotState.domIndex] = (
                <Hotspot {...hotspotState}
                         key={id}
                         id={id}
                         left={hotspotState.left + hotspotState.width}
                         isEditing={this.props.mode === HotspotsMode.Edit}
                         onClick={this.handleHotspotClick}
                         onClose={this.handleHotspotClose}
                         content={this.renderHotspotContent(hotspotDef, hotspotState, id, sameElementIdIndex + i)}
                />
            );
        }
    };

    renderHotspots = () => {
        // try to render hotspots in order of DOM elements they represent (handled by "domIndex"),
        // this way, when using Tab to navigate between hotspots, focus will move somewhat more reasonably
        const renderedHotspots: React.ReactElement[] = [];

        for (const hotspot of this.hotspots) {
            const updatedHotspotDef = { ...hotspot, ...this.state.unconfirmedChanges[hotspot.id] };

            this.renderHotspot(updatedHotspotDef, renderedHotspots);
        }

        this.renderGroups(renderedHotspots);

        return renderedHotspots.filter(h => h);
    };

    renderGroups = (renderedHotspots: React.ReactElement[]) => {
        for (const group of Object.values(this.state.hotspotsGroups)) {
            renderedHotspots[group.id] = this.renderGroup(group);
        }
    };

    renderGroup = (group: IHotspotGroupState) => {
        const top = (group.top + group.height) / 2;
        const left = (group.left + group.width) / 2;


        return (
            <Hotspot top={top}
                     left={left}
                     key={group.id}
                     id={group.id.toString()}
                     isGrouped
                     groupCount={group.hotspots.size}
                     content={(<></>)}
            />
        );
    };

    renderPossibleHotspots = () => {
        if (this.props.mode !== HotspotsMode.Edit) {
            return null;
        }

        return Object.values(this.state.hotspotsState).map((possibleHotspotDef) => {
            if (possibleHotspotDef.isObscured) {
                return null;
            }

            return <PossibleHotspot key={possibleHotspotDef.id}
                                    $width={possibleHotspotDef.width}
                                    $height={possibleHotspotDef.height}
                                    $top={possibleHotspotDef.top}
                                    $left={possibleHotspotDef.left}
                                    $isActive={possibleHotspotDef.isActive}
                                    onClick={this.handlePossibleHotspotClick.bind(this, possibleHotspotDef.id)}/>;
        });
    };

    renderCustomLoadButton = (onClick: () => void, isDisabled: boolean) => {
        return (
            <HotspotsFooterButton onClick={onClick}
                                  isDisabled={isDisabled}
                                  isTransparent
                                  isLight>
                {this.props.t("Components:Hotspots.Load")}
            </HotspotsFooterButton>
        );
    };

    renderFooter = () => {
        return (
            <ThemeProvider theme={themes.light}>
                <HotspotsFooter>
                    <HotspotsFooterButtons>
                        {this.props.mode === HotspotsMode.View &&
                            <>
                                <HotspotsFooterIconButton
                                    title={this.props.t("Components:Hotspots.Help")}
                                    link={`${EVALA_WEBPAGE}/support`}
                                    isLight
                                    isTransparent>
                                    <HelpIcon/>
                                </HotspotsFooterIconButton>

                                <HotspotsFooterIconButton
                                    title={this.props.t("Components:Hotspots.SupportTitle")}
                                    link={`${EVALA_WEBPAGE}/info/contact`}
                                    isActive={this.state.isSupportButtonOpen}
                                    isLight
                                    isTransparent>
                                    <SupportIcon/>
                                </HotspotsFooterIconButton>
                                {/*<PopperTooltip*/}
                                {/*    isHidden={!this.state.isSupportButtonOpen}*/}
                                {/*    offsetY={BUTTON_POPPER_TOOLTIP_OFFSET_Y}*/}
                                {/*    style={{*/}
                                {/*        zIndex: LayoutStackOrder.Hotspots + 1*/}
                                {/*    }}*/}
                                {/*    content={(*/}
                                {/*        <HotspotTooltipContent*/}
                                {/*            id="support"*/}
                                {/*            width={"fit-content"}*/}
                                {/*            header={this.props.t("Components:Hotspots.SupportTitle")}*/}
                                {/*            headerTextAlign={TextAlign.Left}*/}
                                {/*            text={"Email: support@evala.cz\nTelefon: +420 111 222 333\n\n(po-pa, 7:00 - 18:00)"}*/}
                                {/*            textAlign={TextAlign.Left}*/}
                                {/*            onClose={this.handleSupportClose}*/}
                                {/*        />*/}
                                {/*    )}>*/}
                                {/*    {(ref) => (*/}
                                {/*        <HotspotsFooterIconButton*/}
                                {/*            title={this.props.t("Components:Hotspots.SupportTitle")}*/}
                                {/*            onClick={this.handleSupportButtonClick}*/}
                                {/*            isActive={this.state.isSupportButtonOpen}*/}
                                {/*            isLight*/}
                                {/*            isTransparent*/}
                                {/*            passRef={ref}*/}
                                {/*            // style={{ backdropFilter: "brightness(0.8)" }}*/}
                                {/*        >*/}
                                {/*            <SupportIcon/>*/}
                                {/*        </HotspotsFooterIconButton>*/}
                                {/*    )}*/}
                                {/*</PopperTooltip>*/}
                            </>
                        }
                        {this.props.mode === HotspotsMode.Edit &&
                            <>
                                <HotspotsFooterButton onClick={this.props.onExportClick}
                                                      isTransparent
                                                      isLight>
                                    {this.props.t("Components:Hotspots.Export")}
                                </HotspotsFooterButton>
                                <HotspotsFooterFileUploadButton onFileChange={this.props.onFileLoad}
                                                                accept={"application/JSON"}
                                                                renderCustomButton={this.renderCustomLoadButton}
                                                                hotspotId={"HotspotsFooterUploadButton"}/>
                            </>
                        }
                        {isEvalaProduction() ? null :
                            <HotspotsFooterSegmentedButton def={this.getSegmentedButtonDef()}
                                                           selectedButtonId={this.props.mode}
                                                           onChange={this.handleModeChange}
                                                           isLight/>
                        }

                        <HotspotsFooterButton onClick={this.props.onClose}
                                              isLight>
                            {this.props.t("Components:Hotspots.CloseHotspots")}
                        </HotspotsFooterButton>
                    </HotspotsFooterButtons>
                </HotspotsFooter>
            </ThemeProvider>
        );
    };

    render() {
        return (
            <StyledHotspots ref={this._hotspotsRef}
                            style={this.props.style}
                            data-testid={TestIds.Hotspots}
                            className={this.props.className}>
                <ModalBackdrop customRootId={"hotspots-root"}/>
                {this.renderPossibleHotspots()}
                {this.renderHotspots()}
                {this.renderFooter()}
            </StyledHotspots>
        );
    }
}

export default withTranslation(["Components"])(Hotspots);