export enum AccountCategoryCode {
    BalanceSheet = "BS",
    IncomeStatement = "IS",
    FinancialStatement = "FS",
    Internal = "IN",
    Extension = "EX",
}

export enum AccountingCode {
    AccountingForBusiness = "CzAccBiz",
    CashBasisAccounting = "CzCashBas",
}

export enum AccountRoleCode {
    AccountsPayable = "AP",
    AccountsReceivable = "AR",
    Bank = "BANK",
    Cash = "CASH",
    ValueAddedTax = "VAT",
    ValueAddedTaxBalance = "VATBALANCE",
    Asset = "ASSET",
    Other = "OTHER",
}

export enum AccountTypeCode {
    Assets = "A",
    Liabilities = "L",
    Income = "I",
    Expense = "E",
    None = "N",
    Opening = "O",
    Closing = "C",
    Balancing = "B",
}

export enum AccruedProcessingTypeCode {
    AccruedExpense = "AccruedExpense",
    AccruedRevenue = "AccruedRevenue",
    EstimatedAccruedExpense = "EstimatedAccruedExpense",
    EstimatedAccruedRevenue = "EstimatedAccruedRevenue",
}

export enum ActionTypeCode {
    ExcelExport = "XLS",
    CsvExport = "CSV",
    PDFExport = "PDF",
    XMLExport = "XML",
}

export enum AddressTypeCode {
    BusinessPartnerBilling = "BusinessPartnerBill",
    BusinessPartnerCorrespondence = "BusinessPartnerCorr",
    CompanyLegal = "CompanyLegal",
    CompanyCommunication = "CompanyCommunication",
    EmployeeLocal = "EmployeeLocal",
    EmployeeForeign = "EmployeeForeign",
    EmployeeContact = "EmployeeContact",
}

export enum ApprovalStatusTypeCode {
    Approved = "Approved",
    NotNeeded = "NotNeeded",
    Pending = "Pending",
    Rejected = "Rejected",
    Canceled = "Canceled",
}

export enum AssetDisposalTypeCode {
    Damaged = "DAMAGE",
    Disposed = "DISPOSAL",
    TransferredFreeOfCharge = "GIFT",
    Sold = "SALE",
    TransferredToPersonalUse = "TRANSFER",
}

export enum AssetItemTypeCode {
    Acquisition = "ACQ",
    Disposal = "DIS",
    PutInUse = "USE",
    TechnicalImprovement = "IMP",
    IncreaseOfPrice = "INC",
    ReductionOfPrice = "RED",
    AccountingDepreciation = "MAD",
    TaxDepreciation = "TAX",
    DepreciationOfTheResidualValue = "DRV",
    ImportOfOldAsset = "OLD",
}

export enum AssetStatusCode {
    Created = "CRE",
    InUse = "USE",
    Disposed = "DIS",
}

export enum AssetTypeCode {
    Intangible = "Intan",
    Tangible = "Tan",
}

export enum AssetValueCode {
    MinimalFixedAssetValue = "CZ_AV0",
    MinimalFixedAssetValueFrom2021 = "CZ_AV21",
}

export enum BackgroundJobStatusCode {
    NotStarted = "NotStarted",
    Running = "Running",
    PendingUserInput = "PendingUserInput",
    Finished = "Finished",
    Error = "Error",
    Canceled = "Canceled",
    Cancelling = "Cancelling",
    Finishing = "Finishing",
    Expired = "Expired",
    FinishedWithWarning = "FinishedWithWarning",
}

export enum BackgroundJobTypeCode {
    BankStatementImport = "BankImport",
    PostingRules = "PostingRules",
    FileStorageCleanup = "AzureStorageCleanup",
    VerificationCleanup = "VerificationCleanup",
    UpdateSentDataBoxMessages = "UpdateSentDBM",
    ReceivingNewDataBoxMessages = "ReceiveDBM",
    UpdateDataBoxCredentials = "UpdateDBCredentials",
    GoPayPaymentCreate = "GoPayPaymentCreate",
    GoPayPaymentCheckStatus = "GoPayPaymentStatus",
    DeferredDocumentPlanProcessing = "DeferredPlanProc",
    AssetsDepreciationUpdate = "DepreciationUpdate",
    GetS5InvoicePDF = "GetS5InvoicePdf",
    RecurringTaskProcessing = "RecurringTaskProc",
    LoadExchangeRates = "LoadExchangeRates",
    UserSettingsSynchronization = "SyncUserSettings",
    BankAPIStatementImport = "BankApiImport",
    BusinessPartnerUpdate = "BusinessPartnerUpdt",
    SessionCleanup = "SessionCleanup",
    InboxNotification = "InboxNotification",
    NotificationCleanup = "NotificationCleanup",
    CompanyDelete = "CompanyDelete",
    RetryDeleteCompaniesAtNight = "RetryCompanyDelete",
    CleanupLoginLogs = "CleanupLoginLogs",
    TenantDelete = "TenantDelete",
    DataImportCleanup = "DataImportCleanup",
    DocumentForApprovalNotification = "ApprovalNotification",
    ConfirmationOfBankTransactions = "BankTransactionsConfirmer",
    MultiplePostOfBankTransactions = "BankTransactionsPostMultiple",
    AttendanceCreation = "PrAttendanceCreation",
    MultipleMatchOfBankTransactions = "BankTransactionsMatchMultiple",
    RossumDataMining = "RossumDataMining",
    RossumDataMiningSystem = "RossumDataMiningSystem",
    FailedLoginAttemptsCleanup = "LoginFailedAttemptsCleanup",
    CleanupInactiveUsers = "CleanupInactiveUsers",
    CleanupTenantSelectionLogs = "CleanupTenantSelectionLogs",
    IsdocImport = "IsdocImport",
    MoneyS3Import = "MoneyS3Import",
    PohodaImport = "PohodaImport",
    CalculateTenantScore = "CalculateTenantScore",
    SynchronizeToDaktela = "SynchronizeToDaktela",
    SynchronizeToCrmScheduled = "SynchronizeToCrmScheduled",
    SynchronizeToCrm = "SynchronizeToCrm",
    SetFixedExchangeRates = "FixedExchangeRateSetting",
    TemporalSalesReport = "TemporalSalesReportJob",
    CreatingDemoCompany = "CreateDemoTenantJob",
    PostMultipleDrafts = "DraftsPostMultiple",
    BillingProcessing = "BillingProcessing",
    ImportsDocumentsFromInvoiceServices = "InvoiceServiceIntegrationJob",
    ImportsDocumentsFromInvoiceService = "InvoiceServiceIntegrationBkg",
    Rel2024_12VatStatementGeneration = "Rel2024_12_VatStatementGen",
}

export enum BankAccountNumberFormatTypeCode {
    Inner = "I",
    Edit = "E",
}

export enum BankAccountTypeCode {
    BusinessPartner = "BusinessPartner",
    CompanyBankAccount = "Company",
    InsuranceCompany = "InsuranceCompany",
}

export enum BankApiStatementImportFrequencyCode {
    Daily = "D",
    Monthly = "M",
    Weekly = "W",
}

export enum BankApiStatementImportRangeCode {
    CurrentActiveFiscalYear = "FiscalYear",
    FromDate = "Custom",
}

export enum BankCode {
    KomerčníBanka_A_s_ = "0100",
    ČeskoslovenskáObchodníBanka_A_S_ = "0300",
    MONETAMoneyBank_A_s_ = "0600",
    ČESKÁNÁRODNÍBANKA = "0710",
    ČeskáSpořitelna_A_s_ = "0800",
    FioBanka_A_s_ = "2010",
    MUFGBankEuropeN_V_PragueBranch = "2020",
    Citfin_SpořitelníDružstvo = "2060",
    TRINITYBANKA_s_ = "2070",
    HypotečníBanka_A_s_ = "2100",
    PeněžníDům_SpořitelníDružstvo = "2200",
    Artesa_SpořitelníDružstvo = "2220",
    BankaCREDITASA_s_ = "2250",
    NEYSpořitelníDružstvo = "2260",
    PodnikatelskáDružstevníZáložna = "2275",
    CitibankEuropePlc_OrganizačníSložka = "2600",
    UniCreditBankCzechRepublicAndSlovakia_A_s_ = "2700",
    AirBankA_s_ = "3030",
    BNPParibasPersonalFinanceSA_OdštěpnýZávod = "3050",
    PKOBPS_A__CzechBranch = "3060",
    INGBankN_V_ = "3500",
    ExpobankCZA_s_ = "4000",
    NárodníRozvojováBanka_A_s_ = "4300",
    RaiffeisenbankA_s_ = "5500",
    J_AND_TBANKA_A_s_ = "5800",
    PPFBankaA_s_ = "6000",
    RaiffeisenbankA_s_Do31_12_2021EquaBankA_s_ = "6100",
    COMMERZBANKAktiengesellschaft_PobočkaPraha = "6200",
    MBankS_A__OrganizačníSložka = "6210",
    BNPParibasS_A__PobočkaČeskáRepublika = "6300",
    PartnersBanka_A_s_ = "6363",
    VšeobecnáÚverováBankaA_s__PobočkaPraha = "6700",
    SberbankCZ_A_s_ = "6800",
    DeutscheBankAktiengesellschaftFilialePrag_OrganizačníSložka = "7910",
    RaiffeisenStavebníSpořitelnaA_s_ = "7950",
    ČSOBStavebníSpořitelna_A_s_ = "7960",
    MONETAStavebníSpořitelna_A_s_ = "7970",
    ModráPyramidaStavebníSpořitelna_A_s_ = "7990",
    VolksbankRaiffeisenbankNordoberpfalzEGPobočkaCheb = "8030",
    OberbankAGPobočkaČeskáRepublika = "8040",
    StavebníSpořitelnaČeskéSpořitelny_A_s_ = "8060",
    ČeskáExportníBanka_A_s_ = "8090",
    HSBCContinentalEurope_CzechRepublic = "8150",
    SparkasseOberlausitzNiederschlesien = "8190",
    FASFinanceCompanyS_r_o_ = "8198",
    MoneyPoloEuropeS_r_o_ = "8199",
    PRIVATBANKDerRaiffeisenlandesbankOberösterreichAktiengesellschaft_PobočkaČeskáRepublika = "8200",
    PaymentExecutionS_r_o_ = "8220",
    ABAPAYS_r_o_ = "8230",
    DružstevníZáložnaKredit = "8240",
    BankOfChinaCEELtd_PragueBranch = "8250",
    BankOfCommunicationsCo__Ltd__PragueBranchOdštěpnýZávod = "8255",
    IndustrialAndCommercialBankOfChinaLimited_PragueBranch_OdštěpnýZávod = "8265",
    FairplayPayS_r_o_ = "8270",
    VIVAPAYMENTSERVICESS_A_OdštěpnýZávod = "8272",
    BEfektA_s_ = "8280",
    MercuriusPartnersS_r_o_ = "8293",
    BESTPAYS_r_o_ = "8299",
    FerratumBankPlc = "8500",
}

export enum BankExchangeRateFreshnessCode {
    Current = "Current",
    PreviousDay = "PrevDay",
}

export enum BankPaymentOrderStateCode {
    Exported = "Exported",
    Sent = "Sent",
    CustomerPortal = "CustomerPortal",
}

export enum BankTransactionDocumentNumberLocationCode {
    RemittanceInformation = "RI",
    SpecificSymbol = "SS",
    VariableSymbol = "VS",
    PaymentInformation = "PI",
}

export enum BankTransactionTypeCode {
    IncomingPayment = "In",
    OutgoingPayment = "Out",
    Other = "Oth",
}

export enum BillingItemTypeCode {
    Company = "Company",
    Module = "Module",
    User = "User",
    AcceptableUserPolicy = "AUP",
    ArchivedCompany = "ArchivComp",
    Discount = "Discount",
}

export enum BillingModuleTypeCode {
    Archived = "Archived",
    CashBased = "CashBased",
    DoubleEntry = "DoubleEntry",
    ExtraDocument = "ExtraDocument",
    IndividualCashBased = "IndividualCashBased",
    IndividualDoubleEntry = "IndividualDoubleEntry",
    Rossum = "Rossum",
    Storage = "Storage",
    IndividualDiscount = "IndividualDiscount",
    TrialDiscount = "TrialDiscount",
    RossumFreeDocuments = "RossumDiscount",
}

export enum BillingPricingCode {
    CZArchived = "CzArchived",
    CZCashBased = "CzCashBased",
    CZDoubleEntry = "CzDoubleEntry",
    CZExtraDocument2024_5 = "CzExtraDocument2024A",
    CZExtraDocument2024_6 = "CzExtraDocument2024B",
    CZIndividualCashBased = "CzIndividualCashBased",
    CZIndividualDoubleEntry = "CzIndividualDoubleEntry",
    CZRossum = "CzRossum",
    CZStorage = "CzStorage",
}

export enum BookkeepingCode {
    DoubleentryBookkeeping = "DE",
    SingleentryBookkeeping = "SE",
}

export enum BusinessPartnerDocumentDefaultTypeCode {
    Issued = "Issued",
    Received = "Received",
}

export enum CbaAssetDisposalTypeCode {
    AssetDamageNaturalDisaster_UnknownPerpetrator = "Damage",
    GiftOrTransferToPersonalUse = "GiftTransfer",
    OtherDamage = "OtherDamage",
    PhysicalDisposal = "PhysicalDisposal",
    Sale = "Sale",
}

export enum CbaCategoryTaxImpactCode {
    Nontax = "NonTax",
    Partial = "Partial",
    Tax = "Tax",
    VATCorrection = "VatCorr",
}

export enum CbaEntryLedgerDocumentTypeCode {
    Bank = "Bank",
    CashBox = "CashBox",
    InternalDocument = "InternalDocument",
    ProformaInvoice = "ProformaDocument",
}

export enum CbaEntrySpecialVatCalculationCode {
    AssetInAutomatedVATInclusionMode = "AAvi",
    AssetInReverseCharge = "ARCh",
    ReverseCharge = "RCh",
}

export enum CbaEntryTypeCode {
    Expense = "Expense",
    Income = "Income",
}

export enum ClearedStatusCode {
    Cleared = "C",
    PartiallyCleared = "P",
    NotCleared = "N",
    N_A = "_",
}

export enum ColourThemeCode {
    Light = "Light",
    Dark = "Dark",
}

export enum CommunicationEntryChannelCode {
    Email = "EMAIL",
    DataMessage = "DATABOX",
    BankAPI = "BANKAPI",
}

export enum CommunicationEntryStateCode {
    Sent = "SENT",
    Delivered = "DELIVERED",
    Received = "RECEIVED",
    Undeliverable = "UNDELIVERABLE",
    Deleted = "DELETED",
}

export enum CommunicationEntryTypeCode {
    Received = "IN",
    Sent = "OUT",
}

export enum CommunicationTypeCode {
    User = "User",
    System = "System",
}

export enum CompanyBankAccountTypeCode {
    Bank = "BANK",
    PaymentService = "SERVICE",
}

export enum CompanyPermissionCode {
    Asset = "Asset",
    Audit = "Audit",
    Bank = "Bank",
    CanImport = "CanImport",
    CashBox = "CashBox",
    CustomerPortal = "CustomerPortal",
    DataBox = "DataBox",
    DocumentPosting = "DocumentPosting",
    FiscalYearClose = "FiscalYearClose",
    SortingFilesFromAPhotoFile = "InBox",
    InternalAndCorrectiveDocuments = "InternalDocuments",
    InvoicesIssued = "InvoicesIssued",
    InvoicesReceived = "InvoicesReceived",
    CustomerSettings = "MasterData",
    Payroll = "PrPayroll",
    PayrollSettings = "PrPayrollSetting",
    ReceivableAdjustments = "ReceivableAdjustments",
    RecurringTask = "RecurringTask",
    Reports = "Reports",
    VatStatement = "VatStatement",
}

export enum CompanyRoleTypeCode {
    UserRole = "User",
    CustomerRole = "Customer",
}

export enum CompanyStateCode {
    New = "New",
    Initialized = "Initialized",
    Archived = "Archived",
    DeleteFailed = "DeleteFailed",
    DeleteRetryFailed = "DeleteRetryFailed",
    WaitingForDelete = "WaitingForDelete",
}

export enum ContactTypeCode {
    BusinessPartner = "BusinessPartner",
    CompanyCommunication = "CompanyCommunication",
}

export enum CountryCode {
    Andorra = "AD",
    UnitedArabEmirates = "AE",
    Afghanistan = "AF",
    AntiguaAndBarbuda = "AG",
    Anguilla = "AI",
    Albania = "AL",
    Armenia = "AM",
    Angola = "AO",
    Antarctica = "AQ",
    Argentina = "AR",
    AmericanSamoa = "AS",
    Austria = "AT",
    Australia = "AU",
    Aruba = "AW",
    ÅlandIslands = "AX",
    Azerbaijan = "AZ",
    BosniaAndHerzegovina = "BA",
    Barbados = "BB",
    Bangladesh = "BD",
    Belgium = "BE",
    BurkinaFaso = "BF",
    Bulgaria = "BG",
    Bahrain = "BH",
    Burundi = "BI",
    Benin = "BJ",
    SaintBarthélemy = "BL",
    Bermuda = "BM",
    Brunei = "BN",
    Bolivia = "BO",
    Bonaire_SintEustasiusAndSaba = "BQ",
    Brazil = "BR",
    Bahamas = "BS",
    Bhutan = "BT",
    BouvetIsland = "BV",
    Botswana = "BW",
    Belarus = "BY",
    Belize = "BZ",
    Canada = "CA",
    CocosKeelingIslands = "CC",
    CongoDRC = "CD",
    CentralAfricanRepublic = "CF",
    Congo = "CG",
    CôteDIvoire = "CI",
    CookIslands = "CK",
    Chile = "CL",
    Cameroon = "CM",
    China = "CN",
    Colombia = "CO",
    CostaRica = "CR",
    Cuba = "CU",
    CaboVerde = "CV",
    Curaçao = "CW",
    ChristmasIsland = "CX",
    Cyprus = "CY",
    CzechRepublic = "CZ",
    Germany = "DE",
    Djibouti = "DJ",
    Denmark = "DK",
    Dominica = "DM",
    DominicanRepublic = "DO",
    Algeria = "DZ",
    Ecuador = "EC",
    Estonia = "EE",
    Egypt = "EG",
    WesternSahara = "EH",
    Eritrea = "ER",
    Spain = "ES",
    Ethiopia = "ET",
    Finland = "FI",
    Fiji = "FJ",
    FalklandIslands = "FK",
    Micronesia = "FM",
    FaroeIslands = "FO",
    France = "FR",
    Gabon = "GA",
    UnitedKingdom = "GB",
    Grenada = "GD",
    Georgia = "GE",
    FrenchGuiana = "GF",
    Guernsey = "GG",
    Ghana = "GH",
    Gibraltar = "GI",
    Greenland = "GL",
    Gambia = "GM",
    Guinea = "GN",
    Guadeloupe = "GP",
    EquatorialGuinea = "GQ",
    Greece = "GR",
    SouthGeorgiaAndTheSouthSandwichIslands = "GS",
    Guatemala = "GT",
    Guam = "GU",
    GuineaBissau = "GW",
    Guyana = "GY",
    HongKong = "HK",
    HeardIslandAndMcDonaldIslands = "HM",
    Honduras = "HN",
    Croatia = "HR",
    Haiti = "HT",
    Hungary = "HU",
    Switzerland = "CH",
    Indonesia = "ID",
    Ireland = "IE",
    Israel = "IL",
    IsleOfMan = "IM",
    India = "IN",
    BritishIndianOceanTerritory = "IO",
    Iraq = "IQ",
    Iran = "IR",
    Iceland = "IS",
    Italy = "IT",
    Jersey = "JE",
    Jamaica = "JM",
    Jordan = "JO",
    Japan = "JP",
    Kenya = "KE",
    Kyrgyzstan = "KG",
    Cambodia = "KH",
    Kiribati = "KI",
    Comoros = "KM",
    SaintKittsAndNevis = "KN",
    NorthKorea = "KP",
    SouthKorea = "KR",
    Kuwait = "KW",
    CaymanIslands = "KY",
    Kazakhstan = "KZ",
    Laos = "LA",
    Lebanon = "LB",
    SaintLucia = "LC",
    Liechtenstein = "LI",
    SriLanka = "LK",
    Liberia = "LR",
    Lesotho = "LS",
    Lithuania = "LT",
    Luxembourg = "LU",
    Latvia = "LV",
    Libya = "LY",
    Morocco = "MA",
    Monaco = "MC",
    Moldova = "MD",
    Montenegro = "ME",
    SaintMartin = "MF",
    Madagascar = "MG",
    MarshallIslands = "MH",
    NorthMacedonia = "MK",
    Mali = "ML",
    Myanmar = "MM",
    Mongolia = "MN",
    Macao = "MO",
    NorthernMarianaIslands = "MP",
    Martinique = "MQ",
    Mauritania = "MR",
    Montserrat = "MS",
    Malta = "MT",
    Mauritius = "MU",
    Maldives = "MV",
    Malawi = "MW",
    Mexico = "MX",
    Malaysia = "MY",
    Mozambique = "MZ",
    Namibia = "NA",
    NewCaledonia = "NC",
    Niger = "NE",
    NorfolkIsland = "NF",
    Nigeria = "NG",
    Nicaragua = "NI",
    Netherlands = "NL",
    Norway = "NO",
    Nepal = "NP",
    Nauru = "NR",
    Niue = "NU",
    NewZealand = "NZ",
    Oman = "OM",
    Panama = "PA",
    Peru = "PE",
    FrenchPolynesia = "PF",
    PapuaNewGuinea = "PG",
    Philippines = "PH",
    Pakistan = "PK",
    Poland = "PL",
    SaintPierreAndMiquelon = "PM",
    PitcairnIslands = "PN",
    PuertoRico = "PR",
    Palestine = "PS",
    Portugal = "PT",
    Palau = "PW",
    Paraguay = "PY",
    Qatar = "QA",
    Réunion = "RE",
    Romania = "RO",
    Serbia = "RS",
    Russia = "RU",
    Rwanda = "RW",
    SaudiArabia = "SA",
    SolomonIslands = "SB",
    Seychelles = "SC",
    Sudan = "SD",
    Sweden = "SE",
    Singapore = "SG",
    SaintHelena = "SH",
    Slovenia = "SI",
    Svalbard = "SJ",
    Slovakia = "SK",
    SierraLeone = "SL",
    SanMarino = "SM",
    Senegal = "SN",
    Somalia = "SO",
    Suriname = "SR",
    SouthSudan = "SS",
    SãoToméAndPríncipe = "ST",
    ElSalvador = "SV",
    SintMaarten = "SX",
    Syria = "SY",
    Eswatini = "SZ",
    TurksAndCaicosIslands = "TC",
    Chad = "TD",
    FrenchSouthernTerritories = "TF",
    Togo = "TG",
    Thailand = "TH",
    Tajikistan = "TJ",
    Tokelau = "TK",
    TimorLeste = "TL",
    Turkmenistan = "TM",
    Tunisia = "TN",
    Tonga = "TO",
    Turkey = "TR",
    TrinidadAndTobago = "TT",
    Tuvalu = "TV",
    Taiwan = "TW",
    Tanzania = "TZ",
    Ukraine = "UA",
    Uganda = "UG",
    U_S_MinorOutlyingIslands = "UM",
    UnitedStatesOfAmerica = "US",
    Uruguay = "UY",
    Uzbekistan = "UZ",
    VaticanCity = "VA",
    SaintVincentAndTheGrenadines = "VC",
    Venezuela = "VE",
    BritishVirginInslands = "VG",
    U_S_VirginIslands = "VI",
    Vietnam = "VN",
    Vanuatu = "VU",
    WallisAndFutuna = "WF",
    Samoa = "WS",
    Kosovo = "XK",
    Yemen = "YE",
    Mayotte = "YT",
    SouthAfrica = "ZA",
    Zambia = "ZM",
    Zimbabwe = "ZW",
    EuropeanUnion = "XX",
    InternationalMonetaryFund = "YY",
}

export enum CountryClassificationCode {
    Domestic = "Domestic",
    EuropeanUnion = "EEA",
    OutsideTheEuropeanUnion = "NonEEA",
}

export enum CountryOrUnionCode {
    Andorra = "AD",
    UnitedArabEmirates = "AE",
    Afghanistan = "AF",
    AntiguaAndBarbuda = "AG",
    Anguilla = "AI",
    Albania = "AL",
    Armenia = "AM",
    Angola = "AO",
    Antarctica = "AQ",
    Argentina = "AR",
    AmericanSamoa = "AS",
    Austria = "AT",
    Australia = "AU",
    Aruba = "AW",
    ÅlandIslands = "AX",
    Azerbaijan = "AZ",
    BosniaAndHerzegovina = "BA",
    Barbados = "BB",
    Bangladesh = "BD",
    Belgium = "BE",
    BurkinaFaso = "BF",
    Bulgaria = "BG",
    Bahrain = "BH",
    Burundi = "BI",
    Benin = "BJ",
    SaintBarthélemy = "BL",
    Bermuda = "BM",
    Brunei = "BN",
    Bolivia = "BO",
    Bonaire_SintEustasiusAndSaba = "BQ",
    Brazil = "BR",
    Bahamas = "BS",
    Bhutan = "BT",
    BouvetIsland = "BV",
    Botswana = "BW",
    Belarus = "BY",
    Belize = "BZ",
    Canada = "CA",
    CocosKeelingIslands = "CC",
    CongoDRC = "CD",
    CentralAfricanRepublic = "CF",
    Congo = "CG",
    CôteDIvoire = "CI",
    CookIslands = "CK",
    Chile = "CL",
    Cameroon = "CM",
    China = "CN",
    Colombia = "CO",
    CostaRica = "CR",
    Cuba = "CU",
    CaboVerde = "CV",
    Curaçao = "CW",
    ChristmasIsland = "CX",
    Cyprus = "CY",
    CzechRepublic = "CZ",
    Germany = "DE",
    Djibouti = "DJ",
    Denmark = "DK",
    Dominica = "DM",
    DominicanRepublic = "DO",
    Algeria = "DZ",
    Ecuador = "EC",
    Estonia = "EE",
    Egypt = "EG",
    WesternSahara = "EH",
    Eritrea = "ER",
    Spain = "ES",
    Ethiopia = "ET",
    Finland = "FI",
    Fiji = "FJ",
    FalklandIslands = "FK",
    Micronesia = "FM",
    FaroeIslands = "FO",
    France = "FR",
    Gabon = "GA",
    UnitedKingdom = "GB",
    Grenada = "GD",
    Georgia = "GE",
    FrenchGuiana = "GF",
    Guernsey = "GG",
    Ghana = "GH",
    Gibraltar = "GI",
    Greenland = "GL",
    Gambia = "GM",
    Guinea = "GN",
    Guadeloupe = "GP",
    EquatorialGuinea = "GQ",
    Greece = "GR",
    SouthGeorgiaAndTheSouthSandwichIslands = "GS",
    Guatemala = "GT",
    Guam = "GU",
    GuineaBissau = "GW",
    Guyana = "GY",
    HongKong = "HK",
    HeardIslandAndMcDonaldIslands = "HM",
    Honduras = "HN",
    Croatia = "HR",
    Haiti = "HT",
    Hungary = "HU",
    Switzerland = "CH",
    Indonesia = "ID",
    Ireland = "IE",
    Israel = "IL",
    IsleOfMan = "IM",
    India = "IN",
    BritishIndianOceanTerritory = "IO",
    Iraq = "IQ",
    Iran = "IR",
    Iceland = "IS",
    Italy = "IT",
    Jersey = "JE",
    Jamaica = "JM",
    Jordan = "JO",
    Japan = "JP",
    Kenya = "KE",
    Kyrgyzstan = "KG",
    Cambodia = "KH",
    Kiribati = "KI",
    Comoros = "KM",
    SaintKittsAndNevis = "KN",
    NorthKorea = "KP",
    SouthKorea = "KR",
    Kuwait = "KW",
    CaymanIslands = "KY",
    Kazakhstan = "KZ",
    Laos = "LA",
    Lebanon = "LB",
    SaintLucia = "LC",
    Liechtenstein = "LI",
    SriLanka = "LK",
    Liberia = "LR",
    Lesotho = "LS",
    Lithuania = "LT",
    Luxembourg = "LU",
    Latvia = "LV",
    Libya = "LY",
    Morocco = "MA",
    Monaco = "MC",
    Moldova = "MD",
    Montenegro = "ME",
    SaintMartin = "MF",
    Madagascar = "MG",
    MarshallIslands = "MH",
    NorthMacedonia = "MK",
    Mali = "ML",
    Myanmar = "MM",
    Mongolia = "MN",
    Macao = "MO",
    NorthernMarianaIslands = "MP",
    Martinique = "MQ",
    Mauritania = "MR",
    Montserrat = "MS",
    Malta = "MT",
    Mauritius = "MU",
    Maldives = "MV",
    Malawi = "MW",
    Mexico = "MX",
    Malaysia = "MY",
    Mozambique = "MZ",
    Namibia = "NA",
    NewCaledonia = "NC",
    Niger = "NE",
    NorfolkIsland = "NF",
    Nigeria = "NG",
    Nicaragua = "NI",
    Netherlands = "NL",
    Norway = "NO",
    Nepal = "NP",
    Nauru = "NR",
    Niue = "NU",
    NewZealand = "NZ",
    Oman = "OM",
    Panama = "PA",
    Peru = "PE",
    FrenchPolynesia = "PF",
    PapuaNewGuinea = "PG",
    Philippines = "PH",
    Pakistan = "PK",
    Poland = "PL",
    SaintPierreAndMiquelon = "PM",
    PitcairnIslands = "PN",
    PuertoRico = "PR",
    Palestine = "PS",
    Portugal = "PT",
    Palau = "PW",
    Paraguay = "PY",
    Qatar = "QA",
    Réunion = "RE",
    Romania = "RO",
    Serbia = "RS",
    Russia = "RU",
    Rwanda = "RW",
    SaudiArabia = "SA",
    SolomonIslands = "SB",
    Seychelles = "SC",
    Sudan = "SD",
    Sweden = "SE",
    Singapore = "SG",
    SaintHelena = "SH",
    Slovenia = "SI",
    Svalbard = "SJ",
    Slovakia = "SK",
    SierraLeone = "SL",
    SanMarino = "SM",
    Senegal = "SN",
    Somalia = "SO",
    Suriname = "SR",
    SouthSudan = "SS",
    SãoToméAndPríncipe = "ST",
    ElSalvador = "SV",
    SintMaarten = "SX",
    Syria = "SY",
    Eswatini = "SZ",
    TurksAndCaicosIslands = "TC",
    Chad = "TD",
    FrenchSouthernTerritories = "TF",
    Togo = "TG",
    Thailand = "TH",
    Tajikistan = "TJ",
    Tokelau = "TK",
    TimorLeste = "TL",
    Turkmenistan = "TM",
    Tunisia = "TN",
    Tonga = "TO",
    Turkey = "TR",
    TrinidadAndTobago = "TT",
    Tuvalu = "TV",
    Taiwan = "TW",
    Tanzania = "TZ",
    Ukraine = "UA",
    Uganda = "UG",
    U_S_MinorOutlyingIslands = "UM",
    UnitedStatesOfAmerica = "US",
    Uruguay = "UY",
    Uzbekistan = "UZ",
    VaticanCity = "VA",
    SaintVincentAndTheGrenadines = "VC",
    Venezuela = "VE",
    BritishVirginInslands = "VG",
    U_S_VirginIslands = "VI",
    Vietnam = "VN",
    Vanuatu = "VU",
    WallisAndFutuna = "WF",
    Samoa = "WS",
    Kosovo = "XK",
    Yemen = "YE",
    Mayotte = "YT",
    SouthAfrica = "ZA",
    Zambia = "ZM",
    Zimbabwe = "ZW",
    EuropeanUnion = "XX",
    InternationalMonetaryFund = "YY",
}

export enum CurrencyCode {
    UAEDirham = "AED",
    AfghanAfghani = "AFN",
    Lek = "ALL",
    ArmenianDram = "AMD",
    NetherlandsAntilleanGuilder = "ANG",
    Kwanza = "AOA",
    ArgentinePeso = "ARS",
    AustralianDollar = "AUD",
    ArubanFlorin = "AWG",
    AzerbaijanManat = "AZN",
    ConvertibleMark = "BAM",
    BarbadosDollar = "BBD",
    Taka = "BDT",
    BulgarianLev = "BGN",
    BahrainiDinar = "BHD",
    BurundiFranc = "BIF",
    BermudianDollar = "BMD",
    BruneiDollar = "BND",
    Boliviano = "BOB",
    Mvdol = "BOV",
    BrazilianReal = "BRL",
    BahamianDollar = "BSD",
    Ngultrum = "BTN",
    Pula = "BWP",
    BelarusianRuble = "BYN",
    BelizeDollar = "BZD",
    CanadianDollar = "CAD",
    CongoleseFranc = "CDF",
    UnidadDeFomento = "CLF",
    ChileanPeso = "CLP",
    YuanRenminbi = "CNY",
    ColombianPeso = "COP",
    UnidadDeValorReal = "COU",
    CostaRicanColon = "CRC",
    PesoConvertible = "CUC",
    CubanPeso = "CUP",
    CaboVerdeEscudo = "CVE",
    CzechKoruna = "CZK",
    DjiboutiFranc = "DJF",
    DanishKrone = "DKK",
    DominicanPeso = "DOP",
    AlgerianDinar = "DZD",
    EgyptianPound = "EGP",
    Nakfa = "ERN",
    EthiopianBirr = "ETB",
    Euro = "EUR",
    FijiDollar = "FJD",
    FalklandIslandsPound = "FKP",
    PoundSterling = "GBP",
    Lari = "GEL",
    GhanaCedi = "GHS",
    GibraltarPound = "GIP",
    Dalasi = "GMD",
    GuineanFranc = "GNF",
    Quetzal = "GTQ",
    GuyanaDollar = "GYD",
    HongKongDollar = "HKD",
    Lempira = "HNL",
    Kuna = "HRK",
    Gourde = "HTG",
    Forint = "HUF",
    WIREuro = "CHE",
    SwissFranc = "CHF",
    WIRFranc = "CHW",
    Rupiah = "IDR",
    NewIsraeliSheqel = "ILS",
    IndianRupee = "INR",
    IraqiDinar = "IQD",
    IranianRial = "IRR",
    IcelandKrona = "ISK",
    JamaicanDollar = "JMD",
    JordanianDinar = "JOD",
    Yen = "JPY",
    KenyanShilling = "KES",
    Som = "KGS",
    Riel = "KHR",
    ComorianFranc = "KMF",
    NorthKoreanWon = "KPW",
    Won = "KRW",
    KuwaitiDinar = "KWD",
    CaymanIslandsDollar = "KYD",
    Tenge = "KZT",
    LaoKip = "LAK",
    LebanesePound = "LBP",
    SriLankaRupee = "LKR",
    LiberianDollar = "LRD",
    Loti = "LSL",
    LibyanDinar = "LYD",
    MoroccanDirham = "MAD",
    MoldovanLeu = "MDL",
    MalagasyAriary = "MGA",
    Denar = "MKD",
    Kyat = "MMK",
    Tugrik = "MNT",
    Pataca = "MOP",
    Ouguiya = "MRU",
    MauritiusRupee = "MUR",
    Rufiyaa = "MVR",
    MalawiKwacha = "MWK",
    MexicanPeso = "MXN",
    MexicanUnidadDeInversionUDI = "MXV",
    MalaysianRinggit = "MYR",
    MozambiqueMetical = "MZN",
    NamibiaDollar = "NAD",
    Naira = "NGN",
    CordobaOro = "NIO",
    NorwegianKrone = "NOK",
    NepaleseRupee = "NPR",
    NewZealandDollar = "NZD",
    RialOmani = "OMR",
    Balboa = "PAB",
    Sol = "PEN",
    Kina = "PGK",
    PhilippinePeso = "PHP",
    PakistanRupee = "PKR",
    Zloty = "PLN",
    Guarani = "PYG",
    QatariRial = "QAR",
    RomanianLeu = "RON",
    SerbianDinar = "RSD",
    RussianRuble = "RUB",
    RwandaFranc = "RWF",
    SaudiRiyal = "SAR",
    SolomonIslandsDollar = "SBD",
    SeychellesRupee = "SCR",
    SudanesePound = "SDG",
    SwedishKrona = "SEK",
    SingaporeDollar = "SGD",
    SaintHelenaPound = "SHP",
    Leone694 = "SLL",
    Leone = "SLE",
    SomaliShilling = "SOS",
    SurinamDollar = "SRD",
    SouthSudanesePound = "SSP",
    Dobra = "STN",
    ElSalvadorColon = "SVC",
    SyrianPound = "SYP",
    Lilangeni = "SZL",
    Baht = "THB",
    Somoni = "TJS",
    TurkmenistanNewManat = "TMT",
    TunisianDinar = "TND",
    Paanga = "TOP",
    TurkishLira = "TRY",
    TrinidadAndTobagoDollar = "TTD",
    NewTaiwanDollar = "TWD",
    TanzanianShilling = "TZS",
    Hryvnia = "UAH",
    UgandaShilling = "UGX",
    USDollar = "USD",
    USDollarNextDay = "USN",
    UruguayPesoEnUnidadesIndexadasUI = "UYI",
    PesoUruguayo = "UYU",
    UnidadPrevisional = "UYW",
    UzbekistanSum = "UZS",
    BolívarSoberano = "VES",
    VenezuelanDigitalBolívar = "VED",
    VenezuelanBolívar = "VEF",
    Dong = "VND",
    Vatu = "VUV",
    Tala = "WST",
    CFAFrancBEAC = "XAF",
    EastCaribbeanDollar = "XCD",
    SDRSpecialDrawingRight = "XDR",
    CFAFrancBCEAO = "XOF",
    CFPFranc = "XPF",
    Sucre = "XSU",
    ADBUnitOfAccount = "XUA",
    YemeniRial = "YER",
    Rand = "ZAR",
    ZambianKwacha = "ZMW",
    ZimbabweanDollar = "ZWL",
}

export enum DataLimitTypeCode {
    BankTransactionLimit = "BankTransactionLimit",
    DocumentLimit = "DocumentLimit",
    DraftDocumentLimit = "DraftDocumentLimit",
    FileLimit = "FileLimit",
    JournalEntryLimit = "JournalEntryLimit",
}

export enum DateFormatCode {
    DaysMonthsYearsDots = "DaysMonthsYearsDots",
    DaysMonthsYearsSlashes = "DaysMonthsYearsSlashes",
    DaysMonthsYearsHyphens = "DaysMonthsYearsHyphens",
    MonthsDaysYearsDots = "MonthsDaysYearsDots",
    MonthsDaysYearsSlashes = "MonthsDaysYearsSlashes",
    MonthsDaysYearsHyphens = "MonthsDaysYearsHyphens",
    YearsMonthsDaysDots = "YearsMonthsDaysDots",
    YearsMonthsDaysSlashes = "YearsMonthsDaysSlashes",
    YearsMonthsDaysHyphens = "YearsMonthsDaysHyphens",
}

export enum DayOfWeekCode {
    Friday = "FRI",
    Monday = "MON",
    Saturday = "SAT",
    Sunday = "SUN",
    Thursday = "THU",
    Tuesday = "TUE",
    Wednesday = "WED",
}

export enum DeferredPlanPostingTypeCode {
    Monthly = "Monthly",
    Yearly = "Yearly",
}

export enum DepreciationPolicyTypeCode {
    TaxDepreciation = "TAX",
    AccountingDepreciation = "ACCOUNTING",
}

export enum DepreciationSettingTypeCode {
    Interrupted = "INTERRUPTED",
    Partial = "PARTIAL",
}

export enum DepreciationTypeCode {
    Linear = "LINEAR",
    Accelerated = "ACCELERATED",
    TimeDependent = "TIMED",
}

export enum DocumentImportTypeCode {
    Isdoc = "Isdoc",
    MoneyS3 = "MoneyS3",
    Pohoda = "Pohoda",
}

export enum DocumentItemTypeCode {
    Platba = "PaymentDocumentItem",
    ObecnáPoložka = "RegularDocumentItem",
    PoložkaInterníhoDokladu = "InternalDocumentItem",
}

export enum DocumentLinkClearingTypeCode {
    Both = "All",
    None = "None",
    SourceDocument = "SourceDocument",
    TargetDocument = "TargetDocument",
}

export enum DocumentLinkTypeCode {
    Default = "DF",
    CorrectiveWithClearing = "CRC",
    CorrectiveWithoutClearing = "CRN",
    Payment = "PM",
    InternalDocumentClearing = "IDCL",
    ExchangeRateGain = "EXRG",
    ExchangeRateLoss = "EXRL",
    ReceivableAdjustment = "RA",
    ReceivableAdjustmentSmall = "RAS",
    ReceivableAdjustmentCancellation = "RAC",
    ReceivableAdjustmentCancellationClearing = "RACC",
    ReceivableAdjustmentExchangeRateDifference = "RAEX",
    DebtWriteOffTaxDeductible = "WOT",
    DebtWriteOffNotTaxDeductible = "WON",
    DeferredExpense = "DE",
    AccruedExpense = "AE",
    AccruedExpenseSettlement = "AES",
    DeferredRevenue = "DR",
    AccruedRevenue = "AR",
    AccruedRevenueSettlement = "ARS",
    EstimatedAccruedRevenue = "EDR",
    EstimatedAccruedRevenueSettlement = "EDRS",
    EstimatedAccruedExpense = "EAE",
    EstimatedAccruedExpenseSettlement = "EAES",
    AutomatedVATInclusionInputVAT = "AVII",
    AutomatedVATInclusionOutputVAT = "AVIO",
    AssetToExpenseReassignment = "ATE",
    ProformaInvoiceDeduction = "PII",
    AssetPutInUseDocument = "APIU",
}

export enum DocumentSourceTypeCode {
    Fakturoid = "Fakturoid",
    ISDOC = "ISDOC",
    Manual = "Manual",
    Money = "Money",
    Pohoda = "Pohoda",
    Rossum = "Rossum",
    IDoklad = "iDoklad",
}

export enum DocumentStatusCode {
    Posted = "P:_",
    NotCleared = "P:N",
    PartiallyCleared = "P:P",
    Cleared = "P:C",
    NotPosted = "N:_",
    NotPaid = "_:N",
    PartiallyPaid = "_:P",
    Paid = "_:C",
    Created = "_:_",
    NotPostedNotPaid = "N:N",
    NotPostedPartiallyPaid = "N:P",
    NotPostedPaid = "N:C",
}

export enum DocumentTypeCode {
    BankTransaction = "BankTransaction",
    CorrectiveInvoiceIssued = "CorrectiveInvoiceIssued",
    CorrectiveInvoiceReceived = "CorrectiveInvoiceReceived",
    InternalDocument = "InternalDocument",
    InvoiceIssued = "InvoiceIssued",
    InvoiceReceived = "InvoiceReceived",
    OtherLiability = "OtherLiability",
    OtherReceivable = "OtherReceivable",
    ProformaInvoiceIssued = "ProformaInvoiceIssued",
    ProformaInvoiceReceived = "ProformaInvoiceReceived",
    CashReceiptIssued = "CashReceiptIssued",
    CashReceiptReceived = "CashReceiptReceived",
}

export enum ElectronicSubmissionSubmissionTypeCode {
    Amended = "Amended",
    Regular = "Regular",
}

export enum ElectronicSubmissionTypeCode {
    VatControlStatement = "VatControlSt",
    VatStatement = "VatSt",
    VatVIESStatement = "VatViesSt",
}

export enum EntityPermissionLevelCode {
    Read = "R",
    Write_Create = "W",
    Delete = "D",
}

export enum ExchangeRateTypeCode {
    ByBank = "ByBank",
    Fixed = "Fixed",
}

export enum ExpenseTaxRateCode {
    ZemědělskáALesníVýroba = "CzeAgriculture2010",
    ŘemeslnéŽivnosti = "CzeCrafts2010",
    Živnosti = "CzeTrade2010",
    JináPodnikáníPráva_Autoři____ = "CzeOtherProfessions2010",
    Pronájem = "CzeRent2010",
}

export enum ExportRangeCode {
    All = "All",
    CurrentFiscalYear = "CurrentFiscalYear",
    LastClosedFiscalYear = "LastClosedFiscalYear",
}

export enum ExportResultTypeCode {
    SeparatelyForEachRecord = "MultipleFiles",
    SingleFile = "SingleFile",
}

export enum ExportTypeCode {
    All = "All",
    Documents = "Documents",
    BankTransactions = "BankTransactions",
    CashReceipts = "CashReceipts",
}

export enum ExternalCorrectedDocumentTypeCode {
    Clear = "Clear",
    DontClear = "DontClear",
}

export enum FeatureCode {
    MdbImport = "MdbImport",
    Payroll = "Payroll",
}

export enum FinancialReportLayoutCode {
    RozvahaPlatnáOd1_1_2018 = "CZ_BS2018",
    VýsledkovkaDruhovéČleněníPlatnáOd1_1_2019 = "CZ_IS_N_2019",
    VýsledkovkaÚčelovéČleněníPlatnáOd1_1_2019 = "CZ_IS_F_2019",
}

export enum FiscalYearCloseSectionTypeCode {
    Accrued = "Accrued",
    Asset = "Asset",
    Bank = "Bank",
    CashBox = "CashBox",
    DeferredPlan = "DeferredPlan",
    ExchangeRates = "ExchangeRates",
    FinalClosure = "FinalClosure",
    ForeignCurrencies = "ForeignCurrencies",
    Inventory = "Inventory",
    PayableReceivable = "PayableReceivable",
    ReceivableAdjustments = "ReceivableAdjustments",
    TaxStatement = "TaxStatement",
}

export enum FiscalYearStatusCode {
    Closed = "CD",
    Active = "AC",
    NotUsed = "NU",
}

export enum FulfilmentTypeCode {
    ReceivedTaxableFulfilments = "R",
    ExecutedTaxableFulfilments = "X",
}

export enum GeneralPermissionCode {
    CanExportDataFromTheSystem = "CanExport",
    OrganizationManagement = "OrganizationManagement",
    CompanyManagement = "CompanyManagement",
    CommonSettingsManagement = "TemplateManagement",
    Unlocking = "Unlocking",
    AuditTrail = "AuditTrail",
}

export enum GovernmentInstitutionCode {
    CZ_CSSA_110 = "CZ_CSSA_110",
    CZ_CSSA_111 = "CZ_CSSA_111",
    CZ_CSSA_112 = "CZ_CSSA_112",
    CZ_CSSA_113 = "CZ_CSSA_113",
    CZ_CSSA_114 = "CZ_CSSA_114",
    CZ_CSSA_115 = "CZ_CSSA_115",
    CZ_CSSA_116 = "CZ_CSSA_116",
    CZ_CSSA_117 = "CZ_CSSA_117",
    CZ_CSSA_118 = "CZ_CSSA_118",
    CZ_CSSA_119 = "CZ_CSSA_119",
    CZ_CSSA_121 = "CZ_CSSA_121",
    CZ_CSSA_122 = "CZ_CSSA_122",
    CZ_CSSA_123 = "CZ_CSSA_123",
    CZ_CSSA_220 = "CZ_CSSA_220",
    CZ_CSSA_221 = "CZ_CSSA_221",
    CZ_CSSA_222 = "CZ_CSSA_222",
    CZ_CSSA_223 = "CZ_CSSA_223",
    CZ_CSSA_224 = "CZ_CSSA_224",
    CZ_CSSA_225 = "CZ_CSSA_225",
    CZ_CSSA_226 = "CZ_CSSA_226",
    CZ_CSSA_227 = "CZ_CSSA_227",
    CZ_CSSA_228 = "CZ_CSSA_228",
    CZ_CSSA_229 = "CZ_CSSA_229",
    CZ_CSSA_230 = "CZ_CSSA_230",
    CZ_CSSA_231 = "CZ_CSSA_231",
    CZ_CSSA_332 = "CZ_CSSA_332",
    CZ_CSSA_333 = "CZ_CSSA_333",
    CZ_CSSA_334 = "CZ_CSSA_334",
    CZ_CSSA_335 = "CZ_CSSA_335",
    CZ_CSSA_336 = "CZ_CSSA_336",
    CZ_CSSA_337 = "CZ_CSSA_337",
    CZ_CSSA_338 = "CZ_CSSA_338",
    CZ_CSSA_339 = "CZ_CSSA_339",
    CZ_CSSA_440 = "CZ_CSSA_440",
    CZ_CSSA_441 = "CZ_CSSA_441",
    CZ_CSSA_442 = "CZ_CSSA_442",
    CZ_CSSA_443 = "CZ_CSSA_443",
    CZ_CSSA_444 = "CZ_CSSA_444",
    CZ_CSSA_445 = "CZ_CSSA_445",
    CZ_CSSA_446 = "CZ_CSSA_446",
    CZ_CSSA_447 = "CZ_CSSA_447",
    CZ_CSSA_448 = "CZ_CSSA_448",
    CZ_CSSA_449 = "CZ_CSSA_449",
    CZ_CSSA_550 = "CZ_CSSA_550",
    CZ_CSSA_551 = "CZ_CSSA_551",
    CZ_CSSA_552 = "CZ_CSSA_552",
    CZ_CSSA_553 = "CZ_CSSA_553",
    CZ_CSSA_554 = "CZ_CSSA_554",
    CZ_CSSA_555 = "CZ_CSSA_555",
    CZ_CSSA_556 = "CZ_CSSA_556",
    CZ_CSSA_557 = "CZ_CSSA_557",
    CZ_CSSA_558 = "CZ_CSSA_558",
    CZ_CSSA_559 = "CZ_CSSA_559",
    CZ_CSSA_660 = "CZ_CSSA_660",
    CZ_CSSA_661 = "CZ_CSSA_661",
    CZ_CSSA_662 = "CZ_CSSA_662",
    CZ_CSSA_663 = "CZ_CSSA_663",
    CZ_CSSA_664 = "CZ_CSSA_664",
    CZ_CSSA_665 = "CZ_CSSA_665",
    CZ_CSSA_666 = "CZ_CSSA_666",
    CZ_CSSA_667 = "CZ_CSSA_667",
    CZ_CSSA_668 = "CZ_CSSA_668",
    CZ_CSSA_669 = "CZ_CSSA_669",
    CZ_CSSA_670 = "CZ_CSSA_670",
    CZ_CSSA_771 = "CZ_CSSA_771",
    CZ_CSSA_772 = "CZ_CSSA_772",
    CZ_CSSA_773 = "CZ_CSSA_773",
    CZ_CSSA_774 = "CZ_CSSA_774",
    CZ_CSSA_775 = "CZ_CSSA_775",
    CZ_CSSA_776 = "CZ_CSSA_776",
    CZ_CSSA_777 = "CZ_CSSA_777",
    CZ_CSSA_778 = "CZ_CSSA_778",
    CZ_CSSA_779 = "CZ_CSSA_779",
    CZ_CSSA_780 = "CZ_CSSA_780",
    CZ_CSSA_781 = "CZ_CSSA_781",
    CZ_CSSA_782 = "CZ_CSSA_782",
    CZ_CSSA_783 = "CZ_CSSA_783",
    CZ_CSSA_784 = "CZ_CSSA_784",
    CZ_CSSA_884 = "CZ_CSSA_884",
    CZ_CSSA_885 = "CZ_CSSA_885",
    CZ_CSSA_886 = "CZ_CSSA_886",
    CZ_CSSA_887 = "CZ_CSSA_887",
    CZ_CSSA_888 = "CZ_CSSA_888",
    CZ_CSSA_889 = "CZ_CSSA_889",
    CZ_CSSA_890 = "CZ_CSSA_890",
    CZ_CSSA_891 = "CZ_CSSA_891",
    CZ_CSSA_892 = "CZ_CSSA_892",
    CZ_CSSA_893 = "CZ_CSSA_893",
    CZ_CSSA_894 = "CZ_CSSA_894",
    CZ_LO_krp_2_049968E32 = "CZ_LO_krp_2_049968E32",
    CZ_LO_ABC_A902AF19A = "CZ_LO_ABC_A902AF19A",
    CZ_LO_ABE_6369B6B7E = "CZ_LO_ABE_6369B6B7E",
    CZ_LO_ABF_E9333A499 = "CZ_LO_ABF_E9333A499",
    CZ_LO_ABG_B994AE224 = "CZ_LO_ABG_B994AE224",
    CZ_LO_ABI_860538615 = "CZ_LO_ABI_860538615",
    CZ_LO_ABJ_44738C95A = "CZ_LO_ABJ_44738C95A",
    CZ_LO_ABK_87C207A69 = "CZ_LO_ABK_87C207A69",
    CZ_LO_ABF_4CC55AE86 = "CZ_LO_ABF_4CC55AE86",
    CZ_LO_ABF_82487F7FE = "CZ_LO_ABF_82487F7FE",
    CZ_LO_krp_3_E38A51A63 = "CZ_LO_krp_3_E38A51A63",
    CZ_LO_BNA_FC387E210 = "CZ_LO_BNA_FC387E210",
    CZ_LO_BEA_14137BCE5 = "CZ_LO_BEA_14137BCE5",
    CZ_LO_PYA_0C2D753DC = "CZ_LO_PYA_0C2D753DC",
    CZ_LO_KHD_C336E2F1F = "CZ_LO_KHD_C336E2F1F",
    CZ_LO_KOB_F7EE29581 = "CZ_LO_KOB_F7EE29581",
    CZ_LO_PBC_97F89B422 = "CZ_LO_PBC_97F89B422",
    CZ_LO_BEB_E33EF1720 = "CZ_LO_BEB_E33EF1720",
    CZ_LO_KLA_6640D167C = "CZ_LO_KLA_6640D167C",
    CZ_LO_KOA_72D8674A1 = "CZ_LO_KOA_72D8674A1",
    CZ_LO_MEB_D1F3919B0 = "CZ_LO_MEB_D1F3919B0",
    CZ_LO_KHA_42D12F28E = "CZ_LO_KHA_42D12F28E",
    CZ_LO_NBA_13E388641 = "CZ_LO_NBA_13E388641",
    CZ_LO_MEA_E5A979D34 = "CZ_LO_MEA_E5A979D34",
    CZ_LO_NBC_C7D78FA51 = "CZ_LO_NBC_C7D78FA51",
    CZ_LO_NBB_1F04306A2 = "CZ_LO_NBB_1F04306A2",
    CZ_LO_MBA_D6DE3D8CF = "CZ_LO_MBA_D6DE3D8CF",
    CZ_LO_MBB_D96C0E408 = "CZ_LO_MBB_D96C0E408",
    CZ_LO_MEC_9C07D9E86 = "CZ_LO_MEC_9C07D9E86",
    CZ_LO_NBA_90FA1B402 = "CZ_LO_NBA_90FA1B402",
    CZ_LO_NBA_002CA99E5 = "CZ_LO_NBA_002CA99E5",
    CZ_LO_PYA_3BE74F42C = "CZ_LO_PYA_3BE74F42C",
    CZ_LO_PZA_FCDAE47E2 = "CZ_LO_PZA_FCDAE47E2",
    CZ_LO_PBA_E16F20AF3 = "CZ_LO_PBA_E16F20AF3",
    CZ_LO_RAA_4E53C77F5 = "CZ_LO_RAA_4E53C77F5",
    CZ_LO_PYA_7DA52ABF3 = "CZ_LO_PYA_7DA52ABF3",
    CZ_LO_PBB_F76B20967 = "CZ_LO_PBB_F76B20967",
    CZ_LO_KLB_3C0AA7D87 = "CZ_LO_KLB_3C0AA7D87",
    CZ_LO_KLA_4E8E9F125 = "CZ_LO_KLA_4E8E9F125",
    CZ_LO_KHB_93C902722 = "CZ_LO_KHB_93C902722",
    CZ_LO_BNB_935398BAF = "CZ_LO_BNB_935398BAF",
    CZ_LO_BNC_DFC73A7B0 = "CZ_LO_BNC_DFC73A7B0",
    CZ_LO_KHC_B7F0770C8 = "CZ_LO_KHC_B7F0770C8",
    CZ_LO_krp_4_602B725DB = "CZ_LO_krp_4_602B725DB",
    CZ_LO_TAA_37CB9148A = "CZ_LO_TAA_37CB9148A",
    CZ_LO_STB_3703589E2 = "CZ_LO_STB_3703589E2",
    CZ_LO_CBA_FA14C5EC5 = "CZ_LO_CBA_FA14C5EC5",
    CZ_LO_CBA_4A14A2BFC = "CZ_LO_CBA_4A14A2BFC",
    CZ_LO_CKA_B217B2392 = "CZ_LO_CKA_B217B2392",
    CZ_LO_JHB_156533919 = "CZ_LO_JHB_156533919",
    CZ_LO_CBA_FCC0DEE06 = "CZ_LO_CBA_FCC0DEE06",
    CZ_LO_CKA_EF929E34F = "CZ_LO_CKA_EF929E34F",
    CZ_LO_JHA_F980EBDBC = "CZ_LO_JHA_F980EBDBC",
    CZ_LO_CKB_D4756FC6B = "CZ_LO_CKB_D4756FC6B",
    CZ_LO_CBA_A9D576F4B = "CZ_LO_CBA_A9D576F4B",
    CZ_LO_CBA_C56431DFB = "CZ_LO_CBA_C56431DFB",
    CZ_LO_PIC_AF24E5176 = "CZ_LO_PIC_AF24E5176",
    CZ_LO_PIA_3DEEF76CE = "CZ_LO_PIA_3DEEF76CE",
    CZ_LO_PIB_3F41CF207 = "CZ_LO_PIB_3F41CF207",
    CZ_LO_TAA_2E7022573 = "CZ_LO_TAA_2E7022573",
    CZ_LO_PTA_5B066C49F = "CZ_LO_PTA_5B066C49F",
    CZ_LO_CBB_EA2A057F2 = "CZ_LO_CBB_EA2A057F2",
    CZ_LO_PIA_B97D6F3E1 = "CZ_LO_PIA_B97D6F3E1",
    CZ_LO_PTA_BE624C4C3 = "CZ_LO_PTA_BE624C4C3",
    CZ_LO_PIA_280D1CB22 = "CZ_LO_PIA_280D1CB22",
    CZ_LO_TAB_B25176745 = "CZ_LO_TAB_B25176745",
    CZ_LO_STA_4703A6955 = "CZ_LO_STA_4703A6955",
    CZ_LO_TAA_94CA65FA1 = "CZ_LO_TAA_94CA65FA1",
    CZ_LO_CBC_3DF746B5E = "CZ_LO_CBC_3DF746B5E",
    CZ_LO_JHC_91D58F03A = "CZ_LO_JHC_91D58F03A",
    CZ_LO_CBD_2908CF136 = "CZ_LO_CBD_2908CF136",
    CZ_LO_TAA_BD7C1DE58 = "CZ_LO_TAA_BD7C1DE58",
    CZ_LO_PTB_B63D26726 = "CZ_LO_PTB_B63D26726",
    CZ_LO_STC_6FC5C88F1 = "CZ_LO_STC_6FC5C88F1",
    CZ_LO_PTC_4987D2B5E = "CZ_LO_PTC_4987D2B5E",
    CZ_LO_STA_D5048CE26 = "CZ_LO_STA_D5048CE26",
    CZ_LO_CKA_2D2C2757A = "CZ_LO_CKA_2D2C2757A",
    CZ_LO_CBA_916C58EA0 = "CZ_LO_CBA_916C58EA0",
    CZ_LO_krp_12_239F5633F = "CZ_LO_krp_12_239F5633F",
    CZ_LO_BKA_F6E8D95C6 = "CZ_LO_BKA_F6E8D95C6",
    CZ_LO_BKB_348062832 = "CZ_LO_BKB_348062832",
    CZ_LO_BMA_FA2B1D04D = "CZ_LO_BMA_FA2B1D04D",
    CZ_LO_BVA_349879A27 = "CZ_LO_BVA_349879A27",
    CZ_LO_VYB_6B26277A4 = "CZ_LO_VYB_6B26277A4",
    CZ_LO_HOB_CCA9DDB92 = "CZ_LO_HOB_CCA9DDB92",
    CZ_LO_HOA_153CACEC9 = "CZ_LO_HOA_153CACEC9",
    CZ_LO_ZNC_9B9A7861E = "CZ_LO_ZNC_9B9A7861E",
    CZ_LO_BVB_759000374 = "CZ_LO_BVB_759000374",
    CZ_LO_BOB_5975F898B = "CZ_LO_BOB_5975F898B",
    CZ_LO_BVB_7B16864B5 = "CZ_LO_BVB_7B16864B5",
    CZ_LO_BOA_D9EE400CF = "CZ_LO_BOA_D9EE400CF",
    CZ_LO_HOB_40A27E3B5 = "CZ_LO_HOB_40A27E3B5",
    CZ_LO_BKC_F32C4B90A = "CZ_LO_BKC_F32C4B90A",
    CZ_LO_BVC_78D12E005 = "CZ_LO_BVC_78D12E005",
    CZ_LO_ZNB_A97726442 = "CZ_LO_ZNB_A97726442",
    CZ_LO_ZNB_6ACBD8955 = "CZ_LO_ZNB_6ACBD8955",
    CZ_LO_BOG_5D407392D = "CZ_LO_BOG_5D407392D",
    CZ_LO_BOD_D38E846B3 = "CZ_LO_BOD_D38E846B3",
    CZ_LO_VYC_6AD1A7B07 = "CZ_LO_VYC_6AD1A7B07",
    CZ_LO_BOE_CA487B098 = "CZ_LO_BOE_CA487B098",
    CZ_LO_HOC_F0644E7DF = "CZ_LO_HOC_F0644E7DF",
    CZ_LO_ZND_378E2B0F3 = "CZ_LO_ZND_378E2B0F3",
    CZ_LO_VYA_C8238791D = "CZ_LO_VYA_C8238791D",
    CZ_LO_ZNA_38BBA1914 = "CZ_LO_ZNA_38BBA1914",
    CZ_LO_HOB_34F2A66F1 = "CZ_LO_HOB_34F2A66F1",
    CZ_LO_BOF_C59B07AD2 = "CZ_LO_BOF_C59B07AD2",
    CZ_LO_krp_6_68C8C92DF = "CZ_LO_krp_6_68C8C92DF",
    CZ_LO_CHB_532610D8C = "CZ_LO_CHB_532610D8C",
    CZ_LO_SOB_679FFFCAD = "CZ_LO_SOB_679FFFCAD",
    CZ_LO_CHA_ACAEABEF6 = "CZ_LO_CHA_ACAEABEF6",
    CZ_LO_SOD_433AC839F = "CZ_LO_SOD_433AC839F",
    CZ_LO_KVA_5EA8547B5 = "CZ_LO_KVA_5EA8547B5",
    CZ_LO_SOC_434BAEA96 = "CZ_LO_SOC_434BAEA96",
    CZ_LO_CHC_C0C1CFD92 = "CZ_LO_CHC_C0C1CFD92",
    CZ_LO_KVF_73BCF968E = "CZ_LO_KVF_73BCF968E",
    CZ_LO_KVE_E6C28F76C = "CZ_LO_KVE_E6C28F76C",
    CZ_LO_SOA_CB66FD787 = "CZ_LO_SOA_CB66FD787",
    CZ_LO_KVB_AB2F53E85 = "CZ_LO_KVB_AB2F53E85",
    CZ_LO_krp_11_B090580DF = "CZ_LO_krp_11_B090580DF",
    CZ_LO_ZRB_02FDCE2B6 = "CZ_LO_ZRB_02FDCE2B6",
    CZ_LO_HBA_B03C4E0B2 = "CZ_LO_HBA_B03C4E0B2",
    CZ_LO_HBA_29F2A8512 = "CZ_LO_HBA_29F2A8512",
    CZ_LO_TRA_21C7A73F9 = "CZ_LO_TRA_21C7A73F9",
    CZ_LO_PEB_7C87E7476 = "CZ_LO_PEB_7C87E7476",
    CZ_LO_HBB_D92D2C07E = "CZ_LO_HBB_D92D2C07E",
    CZ_LO_TRA_6C331C13A = "CZ_LO_TRA_6C331C13A",
    CZ_LO_TRB_34500F1EC = "CZ_LO_TRB_34500F1EC",
    CZ_LO_JIA_7767C7CB0 = "CZ_LO_JIA_7767C7CB0",
    CZ_LO_PEA_E8209D5E9 = "CZ_LO_PEA_E8209D5E9",
    CZ_LO_HBC_F7F7FE2F5 = "CZ_LO_HBC_F7F7FE2F5",
    CZ_LO_TRC_5D9A85B0D = "CZ_LO_TRC_5D9A85B0D",
    CZ_LO_TRD_B9528B655 = "CZ_LO_TRD_B9528B655",
    CZ_LO_ZRC_074B2473A = "CZ_LO_ZRC_074B2473A",
    CZ_LO_PEA_AEFADFDB5 = "CZ_LO_PEA_AEFADFDB5",
    CZ_LO_PEA_D8A83688D = "CZ_LO_PEA_D8A83688D",
    CZ_LO_JIB_AF524304C = "CZ_LO_JIB_AF524304C",
    CZ_LO_HBA_A57EC05EB = "CZ_LO_HBA_A57EC05EB",
    CZ_LO_HBF_381A5630D = "CZ_LO_HBF_381A5630D",
    CZ_LO_JIC_E45A00927 = "CZ_LO_JIC_E45A00927",
    CZ_LO_TRA_03A351944 = "CZ_LO_TRA_03A351944",
    CZ_LO_JID_09F667B4F = "CZ_LO_JID_09F667B4F",
    CZ_LO_ZRE_EF3F891E6 = "CZ_LO_ZRE_EF3F891E6",
    CZ_LO_ZRD_F9FC07A0C = "CZ_LO_ZRD_F9FC07A0C",
    CZ_LO_ZRA_63E75399D = "CZ_LO_ZRA_63E75399D",
    CZ_LO_HBB_88552A06A = "CZ_LO_HBB_88552A06A",
    CZ_LO_krp_9_6598764ED = "CZ_LO_krp_9_6598764ED",
    CZ_LO_NAB_D39F07060 = "CZ_LO_NAB_D39F07060",
    CZ_LO_RKB_F950BDD49 = "CZ_LO_RKB_F950BDD49",
    CZ_LO_TUC_0457213C8 = "CZ_LO_TUC_0457213C8",
    CZ_LO_JCB_AC97E5129 = "CZ_LO_JCB_AC97E5129",
    CZ_LO_HKA_94C2F7528 = "CZ_LO_HKA_94C2F7528",
    CZ_LO_HKB_D6C2E4623 = "CZ_LO_HKB_D6C2E4623",
    CZ_LO_NAC_A9AB56BC1 = "CZ_LO_NAC_A9AB56BC1",
    CZ_LO_JCA_99FC17F70 = "CZ_LO_JCA_99FC17F70",
    CZ_LO_RKC_C3739467C = "CZ_LO_RKC_C3739467C",
    CZ_LO_NAA_05AB6B829 = "CZ_LO_NAA_05AB6B829",
    CZ_LO_JCC_583741992 = "CZ_LO_JCC_583741992",
    CZ_LO_NAD_1F35B8236 = "CZ_LO_NAD_1F35B8236",
    CZ_LO_HKC_707E00A9C = "CZ_LO_HKC_707E00A9C",
    CZ_LO_RKA_B922957F2 = "CZ_LO_RKA_B922957F2",
    CZ_LO_TUA_476CF34F1 = "CZ_LO_TUA_476CF34F1",
    CZ_LO_TUA_B38AF0717 = "CZ_LO_TUA_B38AF0717",
    CZ_LO_TUB_4C505353D = "CZ_LO_TUB_4C505353D",
    CZ_LO_krp_8_91106F528 = "CZ_LO_krp_8_91106F528",
    CZ_LO_CLB_34CE8A7AF = "CZ_LO_CLB_34CE8A7AF",
    CZ_LO_CLA_D272482CA = "CZ_LO_CLA_D272482CA",
    CZ_LO_LBF_F48D46A44 = "CZ_LO_LBF_F48D46A44",
    CZ_LO_CLD_99E06EFF9 = "CZ_LO_CLD_99E06EFF9",
    CZ_LO_LBB_E6FE7B0D1 = "CZ_LO_LBB_E6FE7B0D1",
    CZ_LO_LBC_119C9FCB0 = "CZ_LO_LBC_119C9FCB0",
    CZ_LO_LBA_F495C3917 = "CZ_LO_LBA_F495C3917",
    CZ_LO_JNA_77DE9C1DD = "CZ_LO_JNA_77DE9C1DD",
    CZ_LO_LBA_E4850E123 = "CZ_LO_LBA_E4850E123",
    CZ_LO_SMB_7E7BD0A76 = "CZ_LO_SMB_7E7BD0A76",
    CZ_LO_LBA_51929DC7D = "CZ_LO_LBA_51929DC7D",
    CZ_LO_SMA_D05C570E3 = "CZ_LO_SMA_D05C570E3",
    CZ_LO_CLD_32440500A = "CZ_LO_CLD_32440500A",
    CZ_LO_LBD_0C9B981C9 = "CZ_LO_LBD_0C9B981C9",
    CZ_LO_CLB_A7171E04F = "CZ_LO_CLB_A7171E04F",
    CZ_LO_SMA_CDE49713A = "CZ_LO_SMA_CDE49713A",
    CZ_LO_JNB_1BCAA01F5 = "CZ_LO_JNB_1BCAA01F5",
    CZ_LO_SMC_173777136 = "CZ_LO_SMC_173777136",
    CZ_LO_JNC_65AC430B5 = "CZ_LO_JNC_65AC430B5",
    CZ_LO_krp_14_C23096D2D = "CZ_LO_krp_14_C23096D2D",
    CZ_LO_NJD_1F4D465FB = "CZ_LO_NJD_1F4D465FB",
    CZ_LO_KAB_B26248ADE = "CZ_LO_KAB_B26248ADE",
    CZ_LO_BRA_B149A98EC = "CZ_LO_BRA_B149A98EC",
    CZ_LO_KAE_D5CB9AA4F = "CZ_LO_KAE_D5CB9AA4F",
    CZ_LO_NJE_B7AFAEAB5 = "CZ_LO_NJE_B7AFAEAB5",
    CZ_LO_FMA_820BA1BD6 = "CZ_LO_FMA_820BA1BD6",
    CZ_LO_FMC_A094C5B23 = "CZ_LO_FMC_A094C5B23",
    CZ_LO_KAC_29403568B = "CZ_LO_KAC_29403568B",
    CZ_LO_OPB_F5D5ADB23 = "CZ_LO_OPB_F5D5ADB23",
    CZ_LO_BRA_3BF6ACB18 = "CZ_LO_BRA_3BF6ACB18",
    CZ_LO_FMD_2D5DDE140 = "CZ_LO_FMD_2D5DDE140",
    CZ_LO_KAA_2EA3779BA = "CZ_LO_KAA_2EA3779BA",
    CZ_LO_NJB_E65ADDFAC = "CZ_LO_NJB_E65ADDFAC",
    CZ_LO_OPD_C139A4B28 = "CZ_LO_OPD_C139A4B28",
    CZ_LO_BRB_D8908EC79 = "CZ_LO_BRB_D8908EC79",
    CZ_LO_BRF_C68C9D925 = "CZ_LO_BRF_C68C9D925",
    CZ_LO_NJA_2B19F76C8 = "CZ_LO_NJA_2B19F76C8",
    CZ_LO_NJC_6CE4DB123 = "CZ_LO_NJC_6CE4DB123",
    CZ_LO_OPA_36300EA7A = "CZ_LO_OPA_36300EA7A",
    CZ_LO_KAD_5969719FA = "CZ_LO_KAD_5969719FA",
    CZ_LO_BRC_810D8F241 = "CZ_LO_BRC_810D8F241",
    CZ_LO_OTA_CE6613F36 = "CZ_LO_OTA_CE6613F36",
    CZ_LO_OTA_B1CBA8C8E = "CZ_LO_OTA_B1CBA8C8E",
    CZ_LO_OTA_BA3027E11 = "CZ_LO_OTA_BA3027E11",
    CZ_LO_BRD_52CF98A34 = "CZ_LO_BRD_52CF98A34",
    CZ_LO_FMB_4C1CFFDB1 = "CZ_LO_FMB_4C1CFFDB1",
    CZ_LO_OPC_003640ABF = "CZ_LO_OPC_003640ABF",
    CZ_LO_BRH_4B8C93926 = "CZ_LO_BRH_4B8C93926",
    CZ_LO_krp_13_8611BA035 = "CZ_LO_krp_13_8611BA035",
    CZ_LO_SUD_8F2C2FB83 = "CZ_LO_SUD_8F2C2FB83",
    CZ_LO_PRB_FC8643848 = "CZ_LO_PRB_FC8643848",
    CZ_LO_JEB_4D3CDF2DC = "CZ_LO_JEB_4D3CDF2DC",
    CZ_LO_JEA_1AFD54AAA = "CZ_LO_JEA_1AFD54AAA",
    CZ_LO_PRD_F01285EF3 = "CZ_LO_PRD_F01285EF3",
    CZ_LO_PVB_BB7FE05F8 = "CZ_LO_PVB_BB7FE05F8",
    CZ_LO_PRC_19AF6F66B = "CZ_LO_PRC_19AF6F66B",
    CZ_LO_OLB_00520C634 = "CZ_LO_OLB_00520C634",
    CZ_LO_SUC_ADC4B9658 = "CZ_LO_SUC_ADC4B9658",
    CZ_LO_OLA_6D051C0BE = "CZ_LO_OLA_6D051C0BE",
    CZ_LO_PVA_BEC503FD4 = "CZ_LO_PVA_BEC503FD4",
    CZ_LO_PRA_B0DCBBE58 = "CZ_LO_PRA_B0DCBBE58",
    CZ_LO_OLC_038D0DF41 = "CZ_LO_OLC_038D0DF41",
    CZ_LO_SUA_28AA5231C = "CZ_LO_SUA_28AA5231C",
    CZ_LO_OLD_07297FA7B = "CZ_LO_OLD_07297FA7B",
    CZ_LO_SUB_5EF255F85 = "CZ_LO_SUB_5EF255F85",
    CZ_LO_JED_5326E5082 = "CZ_LO_JED_5326E5082",
    CZ_LO_krp_10_2BEF22BB6 = "CZ_LO_krp_10_2BEF22BB6",
    CZ_LO_UOE_DEBCBF07D = "CZ_LO_UOE_DEBCBF07D",
    CZ_LO_CRB_46C53B740 = "CZ_LO_CRB_46C53B740",
    CZ_LO_PAB_8FACD1B6B = "CZ_LO_PAB_8FACD1B6B",
    CZ_LO_UOD_C8C58B6D3 = "CZ_LO_UOD_C8C58B6D3",
    CZ_LO_CRA_79A814AB7 = "CZ_LO_CRA_79A814AB7",
    CZ_LO_UOF_703F6F4ED = "CZ_LO_UOF_703F6F4ED",
    CZ_LO_SYC_2665653C7 = "CZ_LO_SYC_2665653C7",
    CZ_LO_UOC_B01DF3245 = "CZ_LO_UOC_B01DF3245",
    CZ_LO_UOB_156A05CC2 = "CZ_LO_UOB_156A05CC2",
    CZ_LO_SYB_FC45DAA19 = "CZ_LO_SYB_FC45DAA19",
    CZ_LO_SYC_D8412735D = "CZ_LO_SYC_D8412735D",
    CZ_LO_CRA_F0A91F696 = "CZ_LO_CRA_F0A91F696",
    CZ_LO_PAA_64E3F5F06 = "CZ_LO_PAA_64E3F5F06",
    CZ_LO_SYD_897CD86AB = "CZ_LO_SYD_897CD86AB",
    CZ_LO_PAC_00CBAACBA = "CZ_LO_PAC_00CBAACBA",
    CZ_LO_CRD_AD056F390 = "CZ_LO_CRD_AD056F390",
    CZ_LO_SYA_57B61A836 = "CZ_LO_SYA_57B61A836",
    CZ_LO_CRC_3382B7F21 = "CZ_LO_CRC_3382B7F21",
    CZ_LO_UOA_CE332AA0C = "CZ_LO_UOA_CE332AA0C",
    CZ_LO_UOD_0CDE13CA0 = "CZ_LO_UOD_0CDE13CA0",
    CZ_LO_UOF_2B9C48C1E = "CZ_LO_UOF_2B9C48C1E",
    CZ_LO_krp_5_77494A9E6 = "CZ_LO_krp_5_77494A9E6",
    CZ_LO_PJA_7945089FD = "CZ_LO_PJA_7945089FD",
    CZ_LO_TCA_8FD35ADA4 = "CZ_LO_TCA_8FD35ADA4",
    CZ_LO_DOA_847241E32 = "CZ_LO_DOA_847241E32",
    CZ_LO_KTC_73F2B0A9C = "CZ_LO_KTC_73F2B0A9C",
    CZ_LO_DOA_CC7812494 = "CZ_LO_DOA_CC7812494",
    CZ_LO_KTA_329929FF3 = "CZ_LO_KTA_329929FF3",
    CZ_LO_PSC_449796A94 = "CZ_LO_PSC_449796A94",
    CZ_LO_PSE_A13C28249 = "CZ_LO_PSE_A13C28249",
    CZ_LO_PJB_FC778C646 = "CZ_LO_PJB_FC778C646",
    CZ_LO_KTA_6772C7BA3 = "CZ_LO_KTA_6772C7BA3",
    CZ_LO_PSB_51F4D3A50 = "CZ_LO_PSB_51F4D3A50",
    CZ_LO_KTA_10659140F = "CZ_LO_KTA_10659140F",
    CZ_LO_PSA_133A19182 = "CZ_LO_PSA_133A19182",
    CZ_LO_PMA_1A5D8CF5F = "CZ_LO_PMA_1A5D8CF5F",
    CZ_LO_PJC_796150684 = "CZ_LO_PJC_796150684",
    CZ_LO_ROA_6AFEE5EFA = "CZ_LO_ROA_6AFEE5EFA",
    CZ_LO_PJA_E417E0DB0 = "CZ_LO_PJA_E417E0DB0",
    CZ_LO_TCB_22D0BD99E = "CZ_LO_TCB_22D0BD99E",
    CZ_LO_KTB_042B7C4DA = "CZ_LO_KTB_042B7C4DA",
    CZ_LO_TCA_70A37137E = "CZ_LO_TCA_70A37137E",
    CZ_LO_krp_15_A9027380C = "CZ_LO_krp_15_A9027380C",
    CZ_LO_UHB_C876B1431 = "CZ_LO_UHB_C876B1431",
    CZ_LO_KMB_8F893CF66 = "CZ_LO_KMB_8F893CF66",
    CZ_LO_KMC_D2DB08B40 = "CZ_LO_KMC_D2DB08B40",
    CZ_LO_VSD_4911F6413 = "CZ_LO_VSD_4911F6413",
    CZ_LO_KMA_05D798887 = "CZ_LO_KMA_05D798887",
    CZ_LO_KMA_B225E132E = "CZ_LO_KMA_B225E132E",
    CZ_LO_ZLA_9BA811B11 = "CZ_LO_ZLA_9BA811B11",
    CZ_LO_ZLD_E22CD9A4B = "CZ_LO_ZLD_E22CD9A4B",
    CZ_LO_VSB_E480C2D3A = "CZ_LO_VSB_E480C2D3A",
    CZ_LO_ZLB_AFB81AEF0 = "CZ_LO_ZLB_AFB81AEF0",
    CZ_LO_UHA_C54C4EF07 = "CZ_LO_UHA_C54C4EF07",
    CZ_LO_UHB_F230AD714 = "CZ_LO_UHB_F230AD714",
    CZ_LO_ZLC_9C2482262 = "CZ_LO_ZLC_9C2482262",
    CZ_LO_VSC_D7A2B2192 = "CZ_LO_VSC_D7A2B2192",
    CZ_LO_VSA_187EAA8D1 = "CZ_LO_VSA_187EAA8D1",
    CZ_LO_ZLA_F942C129A = "CZ_LO_ZLA_F942C129A",
    CZ_LO_krp_7_22CA62F39 = "CZ_LO_krp_7_22CA62F39",
    CZ_LO_TPA_21AD60F24 = "CZ_LO_TPA_21AD60F24",
    CZ_LO_DCA_512A054A6 = "CZ_LO_DCA_512A054A6",
    CZ_LO_DCA_3AF8DD938 = "CZ_LO_DCA_3AF8DD938",
    CZ_LO_TPA_4B3800936 = "CZ_LO_TPA_4B3800936",
    CZ_LO_CVB_3EBF5A554 = "CZ_LO_CVB_3EBF5A554",
    CZ_LO_CVC_A45AA8F6F = "CZ_LO_CVC_A45AA8F6F",
    CZ_LO_CVD_DDE169D06 = "CZ_LO_CVD_DDE169D06",
    CZ_LO_LTD_FA6440DFC = "CZ_LO_LTD_FA6440DFC",
    CZ_LO_MOB_3F45D3E49 = "CZ_LO_MOB_3F45D3E49",
    CZ_LO_LTE_6E6B6F54F = "CZ_LO_LTE_6E6B6F54F",
    CZ_LO_LNB_3189C2778 = "CZ_LO_LNB_3189C2778",
    CZ_LO_LTB_352F65940 = "CZ_LO_LTB_352F65940",
    CZ_LO_DCB_0E6AC6B18 = "CZ_LO_DCB_0E6AC6B18",
    CZ_LO_DCB_8B03AD1B7 = "CZ_LO_DCB_8B03AD1B7",
    CZ_LO_LTF_C7A4D90ED = "CZ_LO_LTF_C7A4D90ED",
    CZ_LO_LTC_4161A6763 = "CZ_LO_LTC_4161A6763",
    CZ_LO_DCC_107C9AE18 = "CZ_LO_DCC_107C9AE18",
    CZ_LO_CVE_487E7150C = "CZ_LO_CVE_487E7150C",
    CZ_LO_LNC_64DA904E3 = "CZ_LO_LNC_64DA904E3",
    CZ_SIC_KOOP = "CZ_SIC_KOOP",
    CZ_FA_2103 = "CZ_FA_2103",
    CZ_FA_3004 = "CZ_FA_3004",
    CZ_FA_2503 = "CZ_FA_2503",
    CZ_FA_3102 = "CZ_FA_3102",
    CZ_FA_2901 = "CZ_FA_2901",
    CZ_FA_3213 = "CZ_FA_3213",
    CZ_FA_2509 = "CZ_FA_2509",
    CZ_FA_3216 = "CZ_FA_3216",
    CZ_FA_2301 = "CZ_FA_2301",
    CZ_FA_2003 = "CZ_FA_2003",
    CZ_FA_2012 = "CZ_FA_2012",
    CZ_FA_2512 = "CZ_FA_2512",
    CZ_FA_2211 = "CZ_FA_2211",
    CZ_FA_2912 = "CZ_FA_2912",
    CZ_FA_2913 = "CZ_FA_2913",
    CZ_FA_3020 = "CZ_FA_3020",
    CZ_FA_2104 = "CZ_FA_2104",
    CZ_FA_3005 = "CZ_FA_3005",
    CZ_FA_2305 = "CZ_FA_2305",
    CZ_FA_3011 = "CZ_FA_3011",
    CZ_FA_2205 = "CZ_FA_2205",
    CZ_FA_2112 = "CZ_FA_2112",
    CZ_FA_2114 = "CZ_FA_2114",
    CZ_FA_3202 = "CZ_FA_3202",
    CZ_FA_2303 = "CZ_FA_2303",
    CZ_FA_2004 = "CZ_FA_2004",
    CZ_FA_2101 = "CZ_FA_2101",
    CZ_FA_3307 = "CZ_FA_3307",
    CZ_FA_2808 = "CZ_FA_2808",
    CZ_FA_3218 = "CZ_FA_3218",
    CZ_FA_3018 = "CZ_FA_3018",
    CZ_FA_2811 = "CZ_FA_2811",
    CZ_FA_3006 = "CZ_FA_3006",
    CZ_FA_3205 = "CZ_FA_3205",
    CZ_FA_3207 = "CZ_FA_3207",
    CZ_FA_2403 = "CZ_FA_2403",
    CZ_FA_2505 = "CZ_FA_2505",
    CZ_FA_3214 = "CZ_FA_3214",
    CZ_FA_2115 = "CZ_FA_2115",
    CZ_FA_3203 = "CZ_FA_3203",
    CZ_FA_2302 = "CZ_FA_2302",
    CZ_FA_2005 = "CZ_FA_2005",
    CZ_FA_2209 = "CZ_FA_2209",
    CZ_FA_2513 = "CZ_FA_2513",
    CZ_FA_3108 = "CZ_FA_3108",
    CZ_FA_2609 = "CZ_FA_2609",
    CZ_FA_2125 = "CZ_FA_2125",
    CZ_FA_2515 = "CZ_FA_2515",
    CZ_FA_3007 = "CZ_FA_3007",
    CZ_FA_3008 = "CZ_FA_3008",
    CZ_FA_3210 = "CZ_FA_3210",
    CZ_FA_2504 = "CZ_FA_2504",
    CZ_FA_2401 = "CZ_FA_2401",
    CZ_FA_3304 = "CZ_FA_3304",
    CZ_FA_2510 = "CZ_FA_2510",
    CZ_FA_3201 = "CZ_FA_3201",
    CZ_FA_2119 = "CZ_FA_2119",
    CZ_FA_2006 = "CZ_FA_2006",
    CZ_FA_3106 = "CZ_FA_3106",
    CZ_FA_2712 = "CZ_FA_2712",
    CZ_FA_3109 = "CZ_FA_3109",
    CZ_FA_3309 = "CZ_FA_3309",
    CZ_FA_3312 = "CZ_FA_3312",
    CZ_FA_2914 = "CZ_FA_2914",
    CZ_FA_2105 = "CZ_FA_2105",
    CZ_FA_2106 = "CZ_FA_2106",
    CZ_FA_2903 = "CZ_FA_2903",
    CZ_FA_2804 = "CZ_FA_2804",
    CZ_FA_3212 = "CZ_FA_3212",
    CZ_FA_2113 = "CZ_FA_2113",
    CZ_FA_2709 = "CZ_FA_2709",
    CZ_FA_3306 = "CZ_FA_3306",
    CZ_FA_2102 = "CZ_FA_2102",
    CZ_FA_2007 = "CZ_FA_2007",
    CZ_FA_3107 = "CZ_FA_3107",
    CZ_FA_2122 = "CZ_FA_2122",
    CZ_FA_2212 = "CZ_FA_2212",
    CZ_FA_3308 = "CZ_FA_3308",
    CZ_FA_2810 = "CZ_FA_2810",
    CZ_FA_3001 = "CZ_FA_3001",
    CZ_FA_2602 = "CZ_FA_2602",
    CZ_FA_3010 = "CZ_FA_3010",
    CZ_FA_2604 = "CZ_FA_2604",
    CZ_FA_2110 = "CZ_FA_2110",
    CZ_FA_3013 = "CZ_FA_3013",
    CZ_FA_3215 = "CZ_FA_3215",
    CZ_FA_2801 = "CZ_FA_2801",
    CZ_FA_2001 = "CZ_FA_2001",
    CZ_FA_2008 = "CZ_FA_2008",
    CZ_FA_2120 = "CZ_FA_2120",
    CZ_FA_2607 = "CZ_FA_2607",
    CZ_FA_2313 = "CZ_FA_2313",
    CZ_FA_2501 = "CZ_FA_2501",
    CZ_FA_3019 = "CZ_FA_3019",
    CZ_FA_3002 = "CZ_FA_3002",
    CZ_FA_2201 = "CZ_FA_2201",
    CZ_FA_2109 = "CZ_FA_2109",
    CZ_FA_3103 = "CZ_FA_3103",
    CZ_FA_2308 = "CZ_FA_2308",
    CZ_FA_2601 = "CZ_FA_2601",
    CZ_FA_2118 = "CZ_FA_2118",
    CZ_FA_2910 = "CZ_FA_2910",
    CZ_FA_2010 = "CZ_FA_2010",
    CZ_FA_2009 = "CZ_FA_2009",
    CZ_FA_2121 = "CZ_FA_2121",
    CZ_FA_2124 = "CZ_FA_2124",
    CZ_FA_2514 = "CZ_FA_2514",
    CZ_FA_2809 = "CZ_FA_2809",
    CZ_FA_3110 = "CZ_FA_3110",
    CZ_FA_3003 = "CZ_FA_3003",
    CZ_FA_2203 = "CZ_FA_2203",
    CZ_FA_2701 = "CZ_FA_2701",
    CZ_FA_2707 = "CZ_FA_2707",
    CZ_FA_2111 = "CZ_FA_2111",
    CZ_FA_2507 = "CZ_FA_2507",
    CZ_FA_3101 = "CZ_FA_3101",
    CZ_FA_2208 = "CZ_FA_2208",
    CZ_FA_2002 = "CZ_FA_2002",
    CZ_FA_2011 = "CZ_FA_2011",
    CZ_FA_2312 = "CZ_FA_2312",
    CZ_FA_2407 = "CZ_FA_2407",
    CZ_FA_2713 = "CZ_FA_2713",
    CZ_FA_3310 = "CZ_FA_3310",
    CZ_FA_3301 = "CZ_FA_3301",
}

export enum GovernmentInstitutionTypeCode {
    CzechSocialSecurityAdministration = "CSSA",
    FinancialAdministration = "FA",
    LabourOffice = "LO",
    StatutoryInsuranceCompany = "SIC",
}

export enum InboxEntityTypeCode {
    CashReceiptsIssued = "CashReceiptIssued",
    CashReceiptsReceived = "CashReceiptReceived",
    InvoicesIssued = "InvoiceIssued",
    InvoicesReceived = "InvoiceReceived",
    Other = "Other",
    OtherLiabilities = "OtherLiability",
    OtherReceivables = "OtherReceivable",
    ProformaInvoicesIssued = "ProformaInvoiceIssued",
    ProformaInvoicesReceived = "ProformaInvoiceReceived",
    TaxDocumentsIssued = "TaxDocumentIssued",
    TaxDocumentsReceived = "TaxDocumentReceived",
}

export enum InboxFileSourceCode {
    Email = "Email",
    CustomerPortal = "CustomerPortal",
    UploadedByAccountant = "UploadedByAccountant",
}

export enum InboxFileStatusCode {
    Processing = "Processing",
    Completed = "Completed",
    Deleted = "Deleted",
}

export enum InboxItemLogStateCode {
    Accepted = "Accepted",
    ApprovedByUser = "ApprovedByUser",
    IncludedByUser = "IncludedByUser",
    IncludedIn = "IncludedIn",
    ApprovedAutomaticallyDeliveredFromTrustedAddress = "ApprovedTrustedSender",
    ApprovedAutomaticallyDeliveredFromCustomerPortal = "ApprovedCustomerPortal",
    ApprovedAutomaticallyNoApproverNeeded = "ApprovedAutomatically",
    ApprovementCanceledByUser = "CanceledByUser",
    ApprovementResumedByUser = "ResumedByUser",
    RejectedByUser = "RejectedByUser",
    DeletedByUser = "DeletedByUser",
}

export enum IntegrationApiImportedDocumentsCode {
    NewOnly = "New",
    NewAndChanged = "NewAndChanged",
}

export enum IntegrationApiImportFrequencyCode {
    Daily = "Daily",
    LastDayOfTheMonth = "LastDayOfMonth",
    DoNotImportAutomatically = "Never",
    LastDayOfTheWeek = "LastDayOfWeek",
}

export enum IntegrationApiImportFromWhenCode {
    FromDate = "CustomDate",
    ForThisFiscalYear = "FiscalYear",
}

export enum IntegrationApiImportRangeCode {
    FromDate = "Custom",
    InTheCurrentFiscalYear = "FiscalYear",
    DoNotImport = "None",
}

export enum InteractionTypeCode {
    DeletedBankAccount = "DeletedBankAccount",
    DeletedAdvanceTaxPayment = "DeletedAdvanceTaxPayment",
    DeletedSalaryAdvance = "DeletedSalaryAdvance",
    DeletedBalanceSheetClose = "DeletedBalanceSheetClose",
    DeletedDeferredPlan = "DeletedDeferredPlan",
    DeletedExchangeRates = "DeletedExchangeRates",
    DeletedIncomeExpenseTransfer = "DeletedIncomeExpenseTransfer",
    DeletedIncomeTaxDocument = "DeletedIncomeTax",
    DeletedInitialAccountBalances = "DeletedInitialAccountBalances",
    DeletedInventoryCheckDocument = "DeletedInventoryCheck",
    DeletedInventoryClearingDocument = "DeletedInventoryClearing",
    DeletedLinksAndSettlement = "DeletedLinksAndSettlement",
    DeletedNextYearBalanceSheetOpen = "DeletedNextYearBalance",
    DeletedNextYearProfitOrLossTransfer = "DeletedNextYearProfitOrLoss",
    DeletedProfitOrLoss = "DeletedProfitOrLoss",
    DisposedAsset = "DisposedAsset",
    ReceivableAdjustments = "ReceivableAdjustments",
    UserInteraction = "UserInteraction",
    VATElectronicSubmissionUnlocked = "ElectronicSubmissionUnlocked",
}

export enum InventoryTypeCode {
    MethodA = "CzInvA",
    MethodB = "CzInvB",
}

export enum InvoiceServiceIntegrationTypeCode {
    Fakturoid = "Fakturoid",
    IDoklad = "IDoklad",
}

export enum JournalEntryTypeCode {
    Debit = "Dr",
    Credit = "Cr",
}

export enum LanguageCode {
    English = "en",
    Czech = "cs-CZ",
}

export enum LegalEntityCategoryCode {
    Large = "Large",
    Medium = "Medium",
    Micro = "Micro",
    Small = "Small",
}

export enum LegislativeFixedExchangeRateCode {
    CZ2020_CZK_AUD = "CZ2020_CZK_AUD",
    CZ2020_CZK_BRL = "CZ2020_CZK_BRL",
    CZ2020_CZK_BGN = "CZ2020_CZK_BGN",
    CZ2020_CZK_CNY = "CZ2020_CZK_CNY",
    CZ2020_CZK_DKK = "CZ2020_CZK_DKK",
    CZ2020_CZK_EUR = "CZ2020_CZK_EUR",
    CZ2020_CZK_PHP = "CZ2020_CZK_PHP",
    CZ2020_CZK_HKD = "CZ2020_CZK_HKD",
    CZ2020_CZK_HRK = "CZ2020_CZK_HRK",
    CZ2020_CZK_INR = "CZ2020_CZK_INR",
    CZ2020_CZK_IDR = "CZ2020_CZK_IDR",
    CZ2020_CZK_ISK = "CZ2020_CZK_ISK",
    CZ2020_CZK_ILS = "CZ2020_CZK_ILS",
    CZ2020_CZK_JPY = "CZ2020_CZK_JPY",
    CZ2020_CZK_ZAR = "CZ2020_CZK_ZAR",
    CZ2020_CZK_CAD = "CZ2020_CZK_CAD",
    CZ2020_CZK_KRW = "CZ2020_CZK_KRW",
    CZ2020_CZK_HUF = "CZ2020_CZK_HUF",
    CZ2020_CZK_MYR = "CZ2020_CZK_MYR",
    CZ2020_CZK_MXN = "CZ2020_CZK_MXN",
    CZ2020_CZK_XDR = "CZ2020_CZK_XDR",
    CZ2020_CZK_NOK = "CZ2020_CZK_NOK",
    CZ2020_CZK_NZD = "CZ2020_CZK_NZD",
    CZ2020_CZK_PLN = "CZ2020_CZK_PLN",
    CZ2020_CZK_RON = "CZ2020_CZK_RON",
    CZ2020_CZK_RUB = "CZ2020_CZK_RUB",
    CZ2020_CZK_SGD = "CZ2020_CZK_SGD",
    CZ2020_CZK_SEK = "CZ2020_CZK_SEK",
    CZ2020_CZK_CHF = "CZ2020_CZK_CHF",
    CZ2020_CZK_THB = "CZ2020_CZK_THB",
    CZ2020_CZK_TRY = "CZ2020_CZK_TRY",
    CZ2020_CZK_USD = "CZ2020_CZK_USD",
    CZ2020_CZK_GBP = "CZ2020_CZK_GBP",
    CZ2021_CZK_AUD = "CZ2021_CZK_AUD",
    CZ2021_CZK_BRL = "CZ2021_CZK_BRL",
    CZ2021_CZK_BGN = "CZ2021_CZK_BGN",
    CZ2021_CZK_CNY = "CZ2021_CZK_CNY",
    CZ2021_CZK_DKK = "CZ2021_CZK_DKK",
    CZ2021_CZK_EUR = "CZ2021_CZK_EUR",
    CZ2021_CZK_PHP = "CZ2021_CZK_PHP",
    CZ2021_CZK_HKD = "CZ2021_CZK_HKD",
    CZ2021_CZK_HRK = "CZ2021_CZK_HRK",
    CZ2021_CZK_INR = "CZ2021_CZK_INR",
    CZ2021_CZK_IDR = "CZ2021_CZK_IDR",
    CZ2021_CZK_ISK = "CZ2021_CZK_ISK",
    CZ2021_CZK_ILS = "CZ2021_CZK_ILS",
    CZ2021_CZK_JPY = "CZ2021_CZK_JPY",
    CZ2021_CZK_ZAR = "CZ2021_CZK_ZAR",
    CZ2021_CZK_CAD = "CZ2021_CZK_CAD",
    CZ2021_CZK_KRW = "CZ2021_CZK_KRW",
    CZ2021_CZK_HUF = "CZ2021_CZK_HUF",
    CZ2021_CZK_MYR = "CZ2021_CZK_MYR",
    CZ2021_CZK_MXN = "CZ2021_CZK_MXN",
    CZ2021_CZK_XDR = "CZ2021_CZK_XDR",
    CZ2021_CZK_NOK = "CZ2021_CZK_NOK",
    CZ2021_CZK_NZD = "CZ2021_CZK_NZD",
    CZ2021_CZK_PLN = "CZ2021_CZK_PLN",
    CZ2021_CZK_RON = "CZ2021_CZK_RON",
    CZ2021_CZK_RUB = "CZ2021_CZK_RUB",
    CZ2021_CZK_SGD = "CZ2021_CZK_SGD",
    CZ2021_CZK_SEK = "CZ2021_CZK_SEK",
    CZ2021_CZK_CHF = "CZ2021_CZK_CHF",
    CZ2021_CZK_THB = "CZ2021_CZK_THB",
    CZ2021_CZK_TRY = "CZ2021_CZK_TRY",
    CZ2021_CZK_USD = "CZ2021_CZK_USD",
    CZ2021_CZK_GBP = "CZ2021_CZK_GBP",
    CZ2022_CZK_AUD = "CZ2022_CZK_AUD",
    CZ2022_CZK_BRL = "CZ2022_CZK_BRL",
    CZ2022_CZK_BGN = "CZ2022_CZK_BGN",
    CZ2022_CZK_CNY = "CZ2022_CZK_CNY",
    CZ2022_CZK_DKK = "CZ2022_CZK_DKK",
    CZ2022_CZK_EUR = "CZ2022_CZK_EUR",
    CZ2022_CZK_PHP = "CZ2022_CZK_PHP",
    CZ2022_CZK_HKD = "CZ2022_CZK_HKD",
    CZ2022_CZK_HRK = "CZ2022_CZK_HRK",
    CZ2022_CZK_INR = "CZ2022_CZK_INR",
    CZ2022_CZK_IDR = "CZ2022_CZK_IDR",
    CZ2022_CZK_ISK = "CZ2022_CZK_ISK",
    CZ2022_CZK_ILS = "CZ2022_CZK_ILS",
    CZ2022_CZK_JPY = "CZ2022_CZK_JPY",
    CZ2022_CZK_ZAR = "CZ2022_CZK_ZAR",
    CZ2022_CZK_CAD = "CZ2022_CZK_CAD",
    CZ2022_CZK_KRW = "CZ2022_CZK_KRW",
    CZ2022_CZK_HUF = "CZ2022_CZK_HUF",
    CZ2022_CZK_MYR = "CZ2022_CZK_MYR",
    CZ2022_CZK_MXN = "CZ2022_CZK_MXN",
    CZ2022_CZK_XDR = "CZ2022_CZK_XDR",
    CZ2022_CZK_NOK = "CZ2022_CZK_NOK",
    CZ2022_CZK_NZD = "CZ2022_CZK_NZD",
    CZ2022_CZK_PLN = "CZ2022_CZK_PLN",
    CZ2022_CZK_RON = "CZ2022_CZK_RON",
    CZ2022_CZK_SGD = "CZ2022_CZK_SGD",
    CZ2022_CZK_SEK = "CZ2022_CZK_SEK",
    CZ2022_CZK_CHF = "CZ2022_CZK_CHF",
    CZ2022_CZK_THB = "CZ2022_CZK_THB",
    CZ2022_CZK_TRY = "CZ2022_CZK_TRY",
    CZ2022_CZK_USD = "CZ2022_CZK_USD",
    CZ2022_CZK_GBP = "CZ2022_CZK_GBP",
    CZ2023_CZK_AUD = "CZ2023_CZK_AUD",
    CZ2023_CZK_BRL = "CZ2023_CZK_BRL",
    CZ2023_CZK_BGN = "CZ2023_CZK_BGN",
    CZ2023_CZK_CNY = "CZ2023_CZK_CNY",
    CZ2023_CZK_DKK = "CZ2023_CZK_DKK",
    CZ2023_CZK_EUR = "CZ2023_CZK_EUR",
    CZ2023_CZK_PHP = "CZ2023_CZK_PHP",
    CZ2023_CZK_HKD = "CZ2023_CZK_HKD",
    CZ2023_CZK_INR = "CZ2023_CZK_INR",
    CZ2023_CZK_IDR = "CZ2023_CZK_IDR",
    CZ2023_CZK_ISK = "CZ2023_CZK_ISK",
    CZ2023_CZK_ILS = "CZ2023_CZK_ILS",
    CZ2023_CZK_JPY = "CZ2023_CZK_JPY",
    CZ2023_CZK_ZAR = "CZ2023_CZK_ZAR",
    CZ2023_CZK_CAD = "CZ2023_CZK_CAD",
    CZ2023_CZK_KRW = "CZ2023_CZK_KRW",
    CZ2023_CZK_HUF = "CZ2023_CZK_HUF",
    CZ2023_CZK_MYR = "CZ2023_CZK_MYR",
    CZ2023_CZK_MXN = "CZ2023_CZK_MXN",
    CZ2023_CZK_XDR = "CZ2023_CZK_XDR",
    CZ2023_CZK_NOK = "CZ2023_CZK_NOK",
    CZ2023_CZK_NZD = "CZ2023_CZK_NZD",
    CZ2023_CZK_PLN = "CZ2023_CZK_PLN",
    CZ2023_CZK_RON = "CZ2023_CZK_RON",
    CZ2023_CZK_SGD = "CZ2023_CZK_SGD",
    CZ2023_CZK_SEK = "CZ2023_CZK_SEK",
    CZ2023_CZK_CHF = "CZ2023_CZK_CHF",
    CZ2023_CZK_THB = "CZ2023_CZK_THB",
    CZ2023_CZK_TRY = "CZ2023_CZK_TRY",
    CZ2023_CZK_USD = "CZ2023_CZK_USD",
    CZ2023_CZK_GBP = "CZ2023_CZK_GBP",
}

export enum LockTypeCode {
    User = "User",
}

export enum MandatoryAccountCode {
    _321AccountsPayable = "CzAccBizAp",
    _311AccountsReceivable = "CzAccBizAr",
    _221Bank = "CzAccBizBank",
    _211Cash = "CzAccBizCash",
    _315BusinessOtherReceivable = "CzAccBizBusinessOtherAr",
    _325BusinessOtherPayable = "CzAccBizBusinessOtherAp",
    _343ValueAddedTax = "CzAccBizVat",
    _341IncomeTax = "CzAccBizIncomeTax",
    _342OtherDirectTaxes = "CzAccBizOtherDirTax",
    _345OtherTaxesAndFees = "CzAccBizOtherTaxes",
    _349BalancingAccountForVAT = "CzAccBizConAccount",
    _378OtherReceivables = "CzAccBizOtherAr",
    _379OtherPayables = "CzAccBizOtherAp",
    _391AdjustmentsToDoubtfulReceivables = "CzAccBizRecAdj",
    _395InternalClearing = "CzAccBizIntClrng",
    _431ProfitOrLossToBeApproved = "CzAccBizProfLossApr",
    _558AdditionsToTaxdeducibleAdjustments = "CzAccBizAddAdj",
    _042AcquisitionOfTangibleAssets = "CzAccBizTan",
    _041AcquisitionOfIntangibleAssets = "CzAccBizIntan",
    _551DepreciationsOfAssets = "CzAccBizAssDep",
    _381DeferredExpenses = "CzAccBizDefExp",
    _384DeferredRevenues = "CzAccBizDefRev",
    _383AccruedExpenses = "CzAccBizAccExp",
    _385AccruedRevenues = "CzAccBizAccRev",
    _388EstimatedAccruedRevenues = "CzAccBizEstAccRev",
    _389EstimatedAccruedExpenses = "CzAccBizEstAccExp",
    _546ReceivablesWriteoff = "CzAccBizWOff",
    _546001ReceivablesWriteoffTax = "CzAccBizWOffT",
    _546009ReceivablesWriteoffNontax = "CzAccBizWOffN",
    _700InitialBalanceSheetAccount = "CzAccBizInit",
    _701OpeningBalanceSheetAccount = "CzAccBizOpen",
    _702ClosingBalanceSheetAccount = "CzAccBizClose",
    _710ProfitAndLossAccount = "CzAccBizProfitLoss",
    _314AdvanceOperatingPaymentsGiven = "CzAccBizAdvPayGiven",
    _324AdvancePaymentsReceived = "CzAccBizAdvPayRecvd",
    _563ForeignExchangeLosses = "CzAccBizExchLoss",
    _663ForeignExchangeGains = "CzAccBizExchGain",
    _591IncomeTaxOnOrdinaryIncomeDue = "CzAccBizIncTaxExp",
    _112MaterialInStock = "CzAccBizInvMaterial",
    _132GoodsInHand = "CzAccBizInvGoods",
    _111MaterialProcurement = "CzAccBizInvMatProc",
    _131GoodsProcurement = "CzAccBizInvGoodsProc",
    _012Research = "CzAccBizResearch",
    _013Software = "CzAccBizSoftware",
    _021Buildings = "CzAccBizBuildings",
    _022Movables = "CzAccBizMovables",
    _031Holdings = "CzAccBizHoldings",
    _072AccumulatedDepreciationForResearch = "CzAccBizResearchAD",
    _073AccumulatedDepreciationForSoftware = "CzAccBizSoftwareAD",
    _081AccumulatedDepreciationForBuildings = "CzAccBizBuildingsAD",
    _082AccumulatedDepreciationForMovables = "CzAccBizHoldingsAD",
    _261CashInTransit = "CzAccBizCashTransfer",
    _491PersonalConsumptionOfTheEntrepreneur = "CzAccBizPersConsumption",
    _562InterestExpenses = "CzAccBizInterestExp",
    _662InterestRevenue = "CzAccBizInterestRev",
    _331PayrollPayable = "CzAccBizPayrollPayable",
    _335ReceivablesFromEmployees = "CzAccBizRecFromEmployee",
}

export enum MandatoryChartOfAccountsCode {
    MandatoryChartOfAccountsForBusinesses = "CzAccBiz",
}

export enum MinorAssetStatusCode {
    Active = "ACTIVE",
    WithoutAssets = "EMPTY",
}

export enum ModuleCode {
    ExtendedStorage = "Storage",
    Rossum = "Rossum",
}

export enum MonthOfYearCode {
    April = "APR",
    August = "AUG",
    December = "DEC",
    February = "FEB",
    January = "JAN",
    July = "JUL",
    June = "JUN",
    March = "MAR",
    May = "MAY",
    November = "NOV",
    October = "OCT",
    September = "SEP",
}

export enum MultiplePostingDateAccountingTypeCode {
    ExplicitDate = "ExplicitDate",
    IssuedDate = "DateIssued",
}

export enum NotificationTypeCode {
    DataBoxMessageReceivment = "DataboxMessage",
    InboxDocumentReceivment = "InboxDocument",
    InboxDocumentApprovalCanceled = "InboxDocumentApprovalCanceled",
    InboxDocumentApprovalResumed = "InboxDocumentApprovalResumed",
    SatisfactionSurvey = "SurveySatisfaction",
    SystemUpdate = "SystemUpdate",
    DocumentForApprovalReceivement = "DocumentForApproval",
    NewTicketCreated = "NewTicket",
    NewTicketFromCustomerPortalCreated = "NewTicketFromCustomerPortal",
    NewTicketMessageFromAnAccountantReceivement = "NewTicketMessage",
    NewTicketMessageFromACustomerReceivement = "NewTicketMessageFromCustomerPortal",
    BankStatementImportError = "BankStatementImportError",
    BankTransactionsMatchMultipleError = "BankTransactionsMatchMultipleError",
    InvoiceServiceIntegrationCredentialsExpiredReminder = "ISIntegrationCredentialsExpired",
}

export enum NotificationTypeGroupCode {
    DataBox = "Databox",
    Inbox = "Inbox",
    CustomerPortal = "CustomerPortal",
    Bank = "Bank",
    Integration = "ISIntegration",
}

export enum NumberRangeTypeCode {
    BankStatement = "BankStatement",
    MinorAsset = "MinorAsset",
    Asset = "Asset",
    CorrectiveInvoiceIssued = "CorrectiveInvoiceIssued",
    CorrectiveInvoiceReceived = "CorrectiveInvoiceReceived",
    InternalDocument = "InternalDocument",
    InvoiceIssued = "InvoiceIssued",
    InvoiceReceived = "InvoiceReceived",
    OtherLiability = "OtherLiability",
    OtherReceivable = "OtherReceivable",
    ProformaInvoiceIssued = "ProformaInvoiceIssued",
    ProformaInvoiceReceived = "ProformaInvoiceReceived",
    CashReceiptIssued = "CashReceiptIssued",
    CashReceiptReceived = "CashReceiptReceived",
    Employment = "PrEmployment",
    EmploymentTemplate = "PrEmploymentTemplate",
    SalaryAdvance = "PrSalaryAdvance",
    Employee = "PrEmployee",
    IndividualDeduction = "PrIndividualDeduction",
    GroupDeduction = "PrGroupDeduction",
}

export enum NumberRangeWildcardCode {
    DocumentsFiscalYear4Digits_UpTo2CharactersSuffix = "%FFFF%",
    DocumentsFiscalYear2Digits_UpTo2CharactersSuffix = "%FF%",
    DocumentsFiscalYear1Digit_UpTo2CharactersSuffix = "%F%",
    DocumentsFiscalPeriod3Digits = "%PPP%",
    CalendarYear4Digits = "%YYYY%",
    CalendarYear2Digits = "%YY%",
    CalendarYear1Digit = "%Y%",
    CalendarMonth2Digit = "%MM%",
}

export enum PayableReceivableTypeCode {
    Payable = "AP",
    Receivable = "AR",
    InternalDocument = "NO",
}

export enum PaymentDocumentItemTypeCode {
    ExchangeGain = "ExchangeGain",
    ExchangeLoss = "ExchangeLoss",
    Other = "Other",
    Payment = "Payment",
}

export enum PaymentItemCode {
    InitPaymentCZK = "InitPaymentCZK",
    InitPaymentEUR = "InitPaymentEUR",
}

export enum PaymentMethodCode {
    WireTransfer = "Wire",
    Cash = "Cash",
    Card = "Card",
    PaymentService = "Srvc",
    Netting = "Nett",
    CashOnDelivery = "Deli",
}

export enum PaymentOrderFormatCode {
    CzechPayment = "Czech",
    NonSEPAPayment = "NonSepa",
    SepaPayment = "Sepa",
}

export enum PaymentOrderOutputCode {
    BankAPIDefault = "BankApi",
    BankFile = "BankFile",
    CustomerPortal = "CustomerPortal",
    MicrosoftExcel = "Excel",
}

export enum PaymentStatusCode {
    WaitsForProcessing = "WP",
    NeedsApproval = "NA",
    PartiallyProcessed = "PP",
    Processed = "PR",
}

export enum PrAbsenceCategoryLegislativeTypeCode {
    Nemocenská = "CzIllness",
    OČR = "CzFamilyMemberCare",
    PlacenéVolno = "CzOtherPaidTimeOff",
    PracovníVolnoDarováníKrve = "CzPaidTimeOffBloodDonation",
    PracovníVolnoVolebníKomise = "CzPaidTimeOffElections",
    PracovníVolnoPohřeb = "CzPaidTimeOffFuneral",
    PracovníVolnoČinnostVedoucíchTáborů = "CzPaidTimeOffSummerCamp",
    PracovníVolnoSvatba = "CzPaidTimeOffWedding",
    OtcovskáDovolená = "CzParentalLeave",
    NeplacenéVolno = "CzUnpaidTimeOff",
    Dovolená = "CzVacation",
    NáhradníVolno = "CzCompensatoryTime",
    Lékař = "CzDoctorAppointment",
}

export enum PrAttendanceStatusCode {
    Locked = "Locked",
    Processed = "Processed",
    ToProcess = "ToProcess",
}

export enum PrChildDeductionOrderCode {
    _1 = "Cz1",
    _2 = "Cz2",
    _3 = "Cz3",
    N = "CzN",
}

export enum PrCzechReductionLimitCode {
    _20221st = "Cz2022_1",
    _20222nd = "Cz2022_2",
    _20223rd = "Cz2022_3",
    _2022Limit = "Cz2022_4",
    _20231st = "Cz2023_1",
    _20232nd = "Cz2023_2",
    _20233rd = "Cz2023_3",
    _2023Limit = "Cz2023_4",
    _20241st = "Cz2024_1",
    _20242nd = "Cz2024_2",
    _20243rd = "Cz2024_3",
    _2024Limit = "Cz2024_4",
}

export enum PrDisabilityDiscountAmountCode {
    BasicDisabilityDiscountFor2024 = "Cz2024Basic2024",
    ExtendedDisabilityDiscountFor2024 = "Cz2024Extended2024",
    DiscountForZTP_PHolderFor2024 = "Cz2024ZtpP2024",
}

export enum PrDisabilityTypeCode {
    I_Disability = "I",
    II_Disability = "II",
    III_Disability = "III",
}

export enum PrEmployeeStateCode {
    Current = "Current",
    Former = "Former",
}

export enum PrEmploymentTypeCode {
    DohodaOPracovníČinnosti = "CzDpc",
    DohodaOProvedeníPráce = "CzDpp",
    Employment = "Employment",
}

export enum PrExtraSalaryComputationTypeCode {
    AbsoluteAmountHour = "HourlyAbsolute",
    AverageHourlySalaryPercentage = "HourlyPercentage",
    AbsoluteAmountShift = "ShiftAbsolute",
}

export enum PrExtraSalaryLegislativeValueCode {
    Holiday100 = "CzHoliday100",
    Overtime25 = "CzOvertime25",
    Weekend10 = "CzWeekend10",
    Night20 = "CzNight20",
}

export enum PrExtraSalaryTypeCode {
    Holiday = "Holiday",
    Night = "Night",
    Overtime = "Overtime",
    Weekend = "Weekend",
    Custom = "Custom",
}

export enum PrFormOfStudyTypeCode {
    Combined = "Combined",
    FullTime = "FullTime",
}

export enum PrIdentityDocumentTypeCode {
    IDCard = "IdCard",
    Passport = "Passport",
}

export enum PrIncomeTaxDiscountCode {
    CzechSince2022 = "Cz2022",
}

export enum PrInsuranceBankAccountPredefinedCode {
    CPZPHealthInsuranceAccount01 = "CZ_H_CPZP_01",
    CPZPHealthInsuranceAccount02 = "CZ_H_CPZP_02",
    CPZPHealthInsuranceAccount03 = "CZ_H_CPZP_03",
    CPZPHealthInsuranceAccount04 = "CZ_H_CPZP_04",
    CPZPHealthInsuranceAccount05 = "CZ_H_CPZP_05",
    CPZPHealthInsuranceAccount06 = "CZ_H_CPZP_06",
    CPZPHealthInsuranceAccount07 = "CZ_H_CPZP_07",
    CPZPHealthInsuranceAccount08 = "CZ_H_CPZP_08",
    CPZPHealthInsuranceAccount09 = "CZ_H_CPZP_09",
    OZPHealthInsuranceAccount01 = "CZ_H_OZP_01",
    OZPHealthInsuranceAccount02 = "CZ_H_OZP_02",
    RBPHealthInsuranceAccount01 = "CZ_H_RBP_01",
    RBPHealthInsuranceAccount02 = "CZ_H_RBP_02",
    RBPHealthInsuranceAccount03 = "CZ_H_RBP_03",
    RBPHealthInsuranceAccount04 = "CZ_H_RBP_04",
    RBPHealthInsuranceAccount05 = "CZ_H_RBP_05",
    RBPHealthInsuranceAccount06 = "CZ_H_RBP_06",
    VZPHealthInsuranceAccount01 = "CZ_H_VZP_01",
    VZPHealthInsuranceAccount02 = "CZ_H_VZP_02",
    VZPHealthInsuranceAccount03 = "CZ_H_VZP_03",
    VZPHealthInsuranceAccount04 = "CZ_H_VZP_04",
    VZPHealthInsuranceAccount05 = "CZ_H_VZP_05",
    VZPHealthInsuranceAccount06 = "CZ_H_VZP_06",
    VZPHealthInsuranceAccount07 = "CZ_H_VZP_07",
    VZPHealthInsuranceAccount08 = "CZ_H_VZP_08",
    VZPHealthInsuranceAccount09 = "CZ_H_VZP_09",
    VZPHealthInsuranceAccount10 = "CZ_H_VZP_10",
    VZPHealthInsuranceAccount11 = "CZ_H_VZP_11",
    VZPHealthInsuranceAccount12 = "CZ_H_VZP_12",
    VZPHealthInsuranceAccount13 = "CZ_H_VZP_13",
    VZPHealthInsuranceAccount14 = "CZ_H_VZP_14",
    VZPHealthInsuranceAccount15 = "CZ_H_VZP_15",
    VZPHealthInsuranceAccount16 = "CZ_H_VZP_16",
    VZPHealthInsuranceAccount17 = "CZ_H_VZP_17",
    VZPHealthInsuranceAccount18 = "CZ_H_VZP_18",
    VZPHealthInsuranceAccount19 = "CZ_H_VZP_19",
    VZPHealthInsuranceAccount20 = "CZ_H_VZP_20",
    VZPHealthInsuranceAccount21 = "CZ_H_VZP_21",
    VZPHealthInsuranceAccount22 = "CZ_H_VZP_22",
    VZPHealthInsuranceAccount23 = "CZ_H_VZP_23",
    VZPHealthInsuranceAccount24 = "CZ_H_VZP_24",
    VZPHealthInsuranceAccount25 = "CZ_H_VZP_25",
    VZPHealthInsuranceAccount26 = "CZ_H_VZP_26",
    VZPHealthInsuranceAccount27 = "CZ_H_VZP_27",
    VZPHealthInsuranceAccount28 = "CZ_H_VZP_28",
    VZPHealthInsuranceAccount29 = "CZ_H_VZP_29",
    VZPHealthInsuranceAccount30 = "CZ_H_VZP_30",
    VZPHealthInsuranceAccount31 = "CZ_H_VZP_31",
    VZPHealthInsuranceAccount32 = "CZ_H_VZP_32",
    VZPHealthInsuranceAccount33 = "CZ_H_VZP_33",
    VZPHealthInsuranceAccount34 = "CZ_H_VZP_34",
    VZPHealthInsuranceAccount35 = "CZ_H_VZP_35",
    VZPHealthInsuranceAccount36 = "CZ_H_VZP_36",
    VZPHealthInsuranceAccount37 = "CZ_H_VZP_37",
    VZPHealthInsuranceAccount38 = "CZ_H_VZP_38",
    VZPHealthInsuranceAccount39 = "CZ_H_VZP_39",
    VZPHealthInsuranceAccount40 = "CZ_H_VZP_40",
    VZPHealthInsuranceAccount41 = "CZ_H_VZP_41",
    VZPHealthInsuranceAccount42 = "CZ_H_VZP_42",
    VOZPHealthInsuranceAccount01 = "CZ_H_VOZP_01",
    VOZPHealthInsuranceAccount02 = "CZ_H_VOZP_02",
    VOZPHealthInsuranceAccount03 = "CZ_H_VOZP_03",
    ZPSHealthInsuranceAccount01 = "CZ_H_ZPS_01",
    ZPSHealthInsuranceAccount02 = "CZ_H_ZPS_02",
    ZPSHealthInsuranceAccount03 = "CZ_H_ZPS_03",
    ZPSHealthInsuranceAccount04 = "CZ_H_ZPS_04",
    ZPMVHealthInsuranceAccount01 = "CZ_H_ZPMV_01",
    ZPMVHealthInsuranceAccount02 = "CZ_H_ZPMV_02",
    ZPMVHealthInsuranceAccount03 = "CZ_H_ZPMV_03",
    ZPMVHealthInsuranceAccount04 = "CZ_H_ZPMV_04",
    AllianzLifeInsuranceAccount01 = "CZ_L_ALLI_01",
    MetlifeLifeInsuranceAccount01 = "CZ_L_METL_01",
    MetlifeLifeInsuranceAccount02 = "CZ_L_METL_02",
    GeneraliLifeInsuranceAccount01 = "CZ_L_GENE_01",
    GeneraliLifeInsuranceAccount02 = "CZ_L_GENE_02",
    GeneraliLifeInsuranceAccount03 = "CZ_L_GENE_03",
    ČeskáSpořitelnaLifeInsuranceAccount01 = "CZ_L_SPOR_01",
    ČPPLifeInsuranceAccount01 = "CZ_L_CPP_01",
    ČSOBLifeInsuranceAccount01 = "CZ_L_CSOB_01",
    PillowLifeInsuranceAccount01 = "CZ_L_PILL_01",
    NNLifeInsuranceAccount01 = "CZ_L_NN_01",
    KooperativaLifeInsuranceAccount01 = "CZ_L_KOOP_01",
    UniqaLifeInsuranceAccount01 = "CZ_L_UNIQ_01",
    MaximaLifeInsuranceAccount01 = "CZ_L_MAXI_01",
    BNPParibasCardifLifeInsuranceAccount01 = "CZ_L_MAXI_02",
    ErgoLifeInsuranceAccount01 = "CZ_L_ERGO_01",
    HasičskáVzájemnáPojišťovnaLifeInsuranceAccount01 = "CZ_L_FIRE_01",
    AllianzPensionInsuranceAccount01 = "CZ_P_ALLI_01",
    ČeskáSpořitelnaPensionInsuranceAccount01 = "CZ_P_SPOR_01",
    ČSOBPensionInsuranceAccount01 = "CZ_P_CSOB_01",
    NNPensionInsuranceAccount01 = "CZ_P_NN_01",
    GeneraliPensionInsuranceAccount01 = "CZ_P_GENE_01",
}

export enum PrInsuranceBankAccountTypeCode {
    CompensationForDamages = "DC",
    Employee = "EM",
    NoTaxableIncome = "NT",
    PenaltiesAndFines = "PF",
    Selfemployed = "SE",
}

export enum PrInsuranceCompanyPredefinedCode {
    CZ_H_CPZP = "CZ_H_CPZP",
    CZ_H_OZP = "CZ_H_OZP",
    CZ_H_RBP = "CZ_H_RBP",
    CZ_H_VZP = "CZ_H_VZP",
    CZ_H_VOZP = "CZ_H_VOZP",
    CZ_H_ZPS = "CZ_H_ZPS",
    CZ_H_ZPMV = "CZ_H_ZPMV",
    CZ_L_ALLI = "CZ_L_ALLI",
    CZ_L_METL = "CZ_L_METL",
    CZ_L_GENE = "CZ_L_GENE",
    CZ_L_SPOR = "CZ_L_SPOR",
    CZ_L_CPP = "CZ_L_CPP",
    CZ_L_CSOB = "CZ_L_CSOB",
    CZ_L_PILL = "CZ_L_PILL",
    CZ_L_NN = "CZ_L_NN",
    CZ_L_KOMM = "CZ_L_KOMM",
    CZ_L_KOOP = "CZ_L_KOOP",
    CZ_L_UNIQ = "CZ_L_UNIQ",
    CZ_L_MAXI = "CZ_L_MAXI",
    CZ_L_BNP = "CZ_L_BNP",
    CZ_L_UNIC = "CZ_L_UNIC",
    CZ_L_YOUP = "CZ_L_YOUP",
    CZ_L_MUTU = "CZ_L_MUTU",
    CZ_L_ERGO = "CZ_L_ERGO",
    CZ_L_FIRE = "CZ_L_FIRE",
    CZ_L_RAIF = "CZ_L_RAIF",
    CZ_L_MONE = "CZ_L_MONE",
    CZ_L_SIMP = "CZ_L_SIMP",
    CZ_P_ALLI = "CZ_P_ALLI",
    CZ_P_AXA = "CZ_P_AXA",
    CZ_P_CONS = "CZ_P_CONS",
    CZ_P_SPOR = "CZ_P_SPOR",
    CZ_P_CSOB = "CZ_P_CSOB",
    CZ_P_KOMM = "CZ_P_KOMM",
    CZ_P_NN = "CZ_P_NN",
    CZ_P_GENE = "CZ_P_GENE",
}

export enum PrInsuranceCompanyTypeCode {
    HealthInsuranceCompany = "PrHealthInsuranceCompany",
    LifeInsuranceCompany = "PrLifeInsuranceCompany",
    PensionInsuranceCompany = "PrPensionInsuranceCompany",
}

export enum PrLegislativeAverageSalaryCode {
    CzechRepublic2023 = "Cz_2023",
    CzechRepublic2024 = "Cz_2024",
}

export enum PrPayrollComponentCategoryTypeCode {
    Deductions = "Deductions",
    HealthInsurace = "HealthInsurace",
    LifeInsurance = "LifeInsurace",
    MandatoryInsurace = "MandatoryInsurace",
    PensionInsurace = "PensionInsurace",
    SalaryParts = "SalaryParts",
    SocialInsurace = "SocialInsurace",
}

export enum PrPayrollComponentTypeCode {
    AdvanceTax = "AdvanceTax",
    BankruptcyWageDeduction = "BankruptcyWageDeduction",
    CateringAllowance = "CateringAllowance",
    DeductionChildSupport = "DeductionChildSupport",
    DeductionDay = "DeductionDay",
    DeductionInsurance = "DeductionInsurance",
    DeductionSavings = "DeductionSavings",
    EmployeePensionSavings = "EmployeePensionSavings",
    EmployerClaim = "EmployerClaim",
    ForeclosureClaim = "ForeclosureClaim",
    ForeclosureWageDeduction = "ForeclosureWageDeduction",
    GrossWage = "GrossWage",
    HealthInsuranceEmployee = "HealthInsuranceEmployee",
    HealthInsuranceEmployer = "HealthInsuranceEmployer",
    InsurancePaymentDiscount = "InsurancePaymentDiscount",
    LifeInsuranceEmployee = "LifeInsuranceEmployee",
    LifeInsuranceEmployerAppreciable = "LifeInsuranceEmployerAppreciable",
    LifeInsuranceEmployerNotAppreciable = "LifeInsuranceEmployerNotAppreciable",
    MandatoryInsurance = "MandatoryInsurance",
    NaturalWage = "NaturalWage",
    NonInsuraceIncome = "NonInsuraceIncome",
    OtherBenefits = "OtherBenefits",
    PayedVacation = "PayedVacation",
    PensionInsuranceEmployee = "PensionInsuranceEmployee",
    PensionInsuranceEmployerAppreciable = "PensionInsuranceEmployerAppreciable",
    PensionInsuranceEmployerNotAppreciable = "PensionInsuranceEmployerNotAppreciable",
    QuarantineBonus = "QuarantineBonus",
    SickBenefit = "SickBenefit",
    SickLeaveCompensation = "SickLeave",
    HalfDaySickLeaveCompensation = "SickLeaveHalf",
    SocialInsuranceEmployee = "SocialInsuranceEmployee",
    SocialInsuranceEmployer = "SocialInsuranceEmployer",
    TaxBonus = "TaxBonus",
    TaxOverpayment = "TaxOverpayment",
    TaxWitholding = "TaxWitholding",
}

export enum PrPayslipEmploymentItemTypeCode {
    SalaryForHoursWorked = "CzSalaryWorked",
    BonusSalary = "CzSalaryBonus",
    SalaryInKind = "CzSalaryInKind",
    Vacation = "CzVacation",
    Illness = "CzIllness",
    Overtime = "CzExtraSalaryOvertime",
    Night = "CzExtraSalaryNight",
    Weekend = "CzExtraSalaryWeekend",
    Holiday = "CzExtraSalaryHoliday",
    GrossSalaryEmployment = "CzEmploymentGrossSalary",
    IncomeTaxCalculationBasis = "CzEmploymentTaxCalcBase",
    BasisForHealthInsuranceCalculation = "CzHealthInsuranceCalcBase",
    HealthInsurancePaidByEmployee = "CzHealthInsuranceEmployee",
    HealthInsurancePaidByEmployer = "CzHealthInsuranceEmployer",
    BasisForSocialInsuranceCalculation = "CzSocialInsuranceCalcBase",
    SocialInsurancePaidByEmployee = "CzSocialInsuranceEmployee",
    SocialInsurancePaidByEmployer = "CzSocialInsuranceEmployer",
}

export enum PrPayslipItemTypeCode {
    GrossSalary = "CzGrossSalary",
    IncomeTaxCalculationBasis = "CzIncomeTaxCalcBasis",
    IncomeTaxBeforeAdvantages = "CzIncomeTaxGross",
    IncomeTaxReductions = "CzIncomeTaxReduction",
    IncomeTaxAdvantages = "CzIncomeTaxAdvantage",
    IncomeTaxNet = "CzIncomeTaxNet",
    IncomeTaxBonus = "CzIncomeTaxBonus",
    NetSalary = "CzNetSalary",
    Deduction = "CzDeduction",
    SalaryAdvance = "CzSalaryAdvanceSubtr",
    AmountToPayEmployee = "CzAmountToPayEmployee",
}

export enum PrPayslipLayoutGroupCode {
    Insurance = "Insurance",
    Tax = "Tax",
    ToPay = "ToPay",
}

export enum PrPayslipLayoutItemCode {
    ToPayCzAmountToPayEmployee = "ToPay_CzAmountToPayEmployee",
    ToPayCzGrossSalary = "ToPay_CzGrossSalary",
    ToPayCzEmploymentGrossSalary = "ToPay_CzEmploymentGrossSalary",
    TaxCzEmploymentTaxCalcBase = "Tax_CzEmploymentTaxCalcBase",
    ToPayCzNetSalary = "ToPay_CzNetSalary",
    ToPayCzSalaryAdvanceSubtr = "ToPay_CzSalaryAdvanceSubtr",
    TaxCzIncomeTaxNet = "Tax_CzIncomeTaxNet",
    TaxCzGrossSalary = "Tax_CzGrossSalary",
    TaxCzHealthInsuranceEmployer = "Tax_CzHealthInsuranceEmployer",
    TaxCzSocialInsuranceEmployer = "Tax_CzSocialInsuranceEmployer",
    TaxCzIncomeTaxReductions = "Tax_CzIncomeTaxReductions",
    TaxCzIncomeTaxAdvantages = "Tax_CzIncomeTaxAdvantages",
    TaxCzIncomeTaxBonus = "Tax_CzIncomeTaxBonus",
    InsuranceCzSocialInsuranceCalcBase = "Insurance_CzSocialInsuranceCalcBase",
    InsuranceCzSocialInsuranceEmployee = "Insurance_CzSocialInsuranceEmployee",
    InsuranceCzHealthInsuranceCalcBase = "Insurance_CzHealthInsuranceCalcBase",
    InsuranceCzHealthInsuranceEmployee = "Insurance_CzHealthInsuranceEmployee",
}

export enum PrSalaryComponentTypeCode {
    Hourly = "Hourly",
    Monthly = "Monthly",
    Fixed = "Fixed",
}

export enum PrSexCode {
    Female = "Female",
    Male = "Male",
}

export enum PrTaxDiscountTypeCode {
    TaxPayerDiscount = "CzPayer2024",
    BasicDisabilityDiscount = "CzDisBasic2024",
    ExtendedDisabilityDiscount = "CzDisExtended2024",
    DiscountForZTP_PHolder = "CzDisZtpP2024",
}

export enum PrUnseizableAmountCode {
    TemporaryValueToWorkWithSystemDateTime_Today = "Cz2020_1",
    From1_1_2022 = "Cz2022_1",
    From1_4_2022 = "Cz2022_4",
    From1_7_2022 = "Cz2022_7",
    From1_10_2022 = "Cz2022_10",
    From1_1_2023 = "Cz2023_1",
    From1_1_2024 = "Cz2024_1",
}

export enum PrWorkingPatternTypeCode {
    Monthly = "Monthly",
    Rotation = "Rotation",
    Weekly = "Weekly",
}

export enum PeriodDayCode {
    FirstDay = "First",
    LastDay = "Last",
}

export enum PostedStatusCode {
    Posted = "P",
    NotPosted = "N",
    N_A = "_",
}

export enum PostingRuleDocumentTypeCode {
    ReceivedDocuments = "AP",
    IssuedDocuments = "AR",
    InvoiceReceived = "InvoiceReceived",
    InvoiceIssued = "InvoiceIssued",
    ProformaInvoiceReceived = "ProformaInvoiceReceived",
    ProformaInvoiceIssued = "ProformaInvoiceIssued",
    OtherLiability = "OtherLiability",
    OtherReceivable = "OtherReceivable",
    CorrectiveInvoiceReceived = "CorrectiveInvoiceReceived",
    CorrectiveInvoiceIssued = "CorrectiveInvoiceIssued",
}

export enum PostingRuleOperatorCode {
    Equal = "EQ",
    NotEqual = "NE",
    GreaterThan = "GT",
    GreaterThanOrEqual = "GE",
    LessThan = "LT",
    LessThanOrEqual = "LE",
    Between = "BETWEEN",
    Contains = "CONTAINS",
}

export enum PrBaseTypeCode {
    Custom = "Custom",
    Legislative = "Legislative",
}

export enum PrChildSupportDeductionTypeCode {
    Owed = "Owed",
    Regular = "Regular",
    Substitute = "Substitute",
}

export enum PrDeductionCalculationTypeCode {
    Fixed = "Fixed",
    PercentageFromGrossSalary = "PercentageGross",
    PercentageFromNetSalary = "PercentageNet",
}

export enum PrDeductionEntityTypeCode {
    Group = "Group",
    Individual = "Individual",
}

export enum PrDeductionStatusCode {
    Deposited = "Deposited",
    Finished = "Finished",
    Ongoing = "Ongoing",
    Planned = "Planned",
    Suspended = "Suspended",
}

export enum PrDeductionTypeCode {
    ChildSupport = "ChildSupport",
    Custom = "Custom",
    Distrainment = "Distrainment",
    Insolvency = "Insolvency",
    MealVoucher = "MealVoucher",
}

export enum PrDistrainmentDeductionTypeCode {
    Standard = "Standard",
    Priority = "Priority",
}

export enum PrEntityValueSourceCode {
    Default = "D",
    Entity = "E",
    Template = "T",
    TemplateDefault = "TD",
}

export enum PrFixedBonusPayOutImpactCode {
    InKind = "InKind",
    ToPayOut = "ToPayOut",
}

export enum PrFixedBonusTaxImpactCode {
    Full = "Full",
    IncomeTaxOnly = "IncomeTaxOnly",
    None = "None",
}

export enum ProductCode {
    EvalaDemo = "Demo",
    EvalaForAccountants = "Acc",
    EvalaForCompanies = "Comp",
}

export enum PrPayslipOverrideStatusCode {
    Changed = "Changed",
    Removed = "Removed",
    Unchanged = "Unchanged",
}

export enum PrWorkIntervalTypeCode {
    MealBreak = "MealBreak",
    Work = "Work",
}

export enum PurchaseStatusCode {
    NotPurchased = "NotPurchased",
    Purchased = "Purchased",
}

export enum ReceivableAdjustmentGroupCode {
    SmallReceivableAdjustment = "CzSmall",
    LargeReceivableAdjustment = "CzLarge",
    InsolvencyReceivableAdjustment = "CzInsolvency",
    CancelReceivableAdjustment = "CzCancel",
    WriteOffReceivableAdjustment = "CzWriteOff",
}

export enum ReceivableAdjustmentTypeCode {
    ReceivableAdjustmentPer8cZORUnder30Thousand = "CZ_8c",
    ReceivableAdjustmentPer8aZOR50 = "CZ_8a_50",
    ReceivableAdjustmentPer8aZOR100 = "CZ_8a_100",
    ReceivableAdjustmentPer8ZORInsolvency = "CZ_8_Insolvency",
    ReceivableAdjustmentCancellationPer8ZOR = "CZ_8_Cancel",
    TaxWriteOff = "CZ_WriteOff_Tax",
    NontaxWriteOff = "CZ_WriteOff_NonTax",
}

export enum RecurrentPaymentStateCode {
    Paid = "Paid",
    Created = "Created",
    Failed = "Failed",
    OverTheCardLimit = "OverLimit",
}

export enum RecurringTaskIntervalCode {
    ToDate = "DATE",
    First = "FIRST",
    Fourth = "FOURTH",
    Last = "LAST",
    Second = "SECOND",
    Third = "THIRD",
}

export enum RecurringTaskPeriodCode {
    Daily = "D",
    Monthly = "M",
    Weekly = "W",
    Yearly = "Y",
}

export enum RecurringTaskStateCode {
    Active = "A",
    Blocked = "B",
    Paused = "P",
}

export enum RecurringTaskTypeCode {
    InvoiceIssued = "InvoiceIssued",
    InvoiceReceived = "InvoiceReceived",
    ProformaInvoiceIssued = "ProformaInvoiceIssued",
}

export enum ReportSectionTypeCode {
    BS_A = "BS_A",
    BS_L = "BS_L",
    BS_S = "BS_S",
    IS_N_I = "IS_N_I",
    IS_N_E = "IS_N_E",
    IS_N_S = "IS_N_S",
    IS_F_I = "IS_F_I",
    IS_F_E = "IS_F_E",
    IS_F_S = "IS_F_S",
}

export enum ReportTypeCode {
    BalanceSheet = "BS",
    IncomeStatementNatureOfExpense = "IS_N",
    IncomeStatementFunctionOfExpense = "IS_F",
}

export enum RequiredAccountTypeCode {
    ReceivableAdjustment = "ReceivableAdjustment",
    VatAccount = "VatAccount",
}

export enum RossumJobStatusCode {
    Done = "Done",
    Running = "Running",
    Canceled = "Canceled",
    Expired = "Expired",
}

export enum SelectionCode {
    None = "None",
    Default = "Default",
    Own = "Own",
    Copy = "Copy",
    Split = "Split",
}

export enum SignatoryPersonCode {
    _4aGeneralAttorneyIndividualAndLegalPerson = "4a",
    _4bIndividual_TaxAdvisorOrAttorney = "4b",
    _4cLegalEntityProvidingTaxConsultancy = "4c",
}

export enum SubscriptionContactTypeCode {
    Billing = "Billing",
    Technical = "Technical",
}

export enum SubscriptionStatusCode {
    Active = "Active",
    Cancelled = "Cancelled",
    Expired = "Expired",
    AwaitsRenewal = "AwaitsRenewal",
    AwaitsConfirmation = "AwaitsConfirm",
}

export enum SubscriptionTypeCode {
    Free = "Free",
    Standard = "Standard",
    EDU = "Edu",
}

export enum TaxApplicabilityCode {
    TaxApplicable = "Applicable",
    NotTaxApplicable = "NotApplicable",
}

export enum TaxCalendarTaxTypeCode {
    Biofuel = "Biofuel",
    CRS = "Crs",
    DAC6 = "Dac6",
    Ecotax = "Ecotax",
    ExciseTax = "Excise",
    ForeignAccountTaxComplianceAct = "Fatca",
    GamblingTax = "Gambling",
    IncomeTax = "Income",
    LandValueTax = "LandValue",
    ValueaddedTax = "ValueAdded",
    VehicleExciseDuty = "Vehicle",
    WindfallTax = "Windfall",
    DAC7 = "Dac7",
    ExcludedFromNotificationDuty = "ExclNote",
}

export enum TaxDepreciationCategoryCode {
    C1_CZ_93 = "C1_CZ_93",
    C1_CZ_05 = "C1_CZ_05",
    C1a_CZ = "C1a_CZ",
    C2_CZ_93 = "C2_CZ_93",
    C2_CZ_99 = "C2_CZ_99",
    C2_CZ_05 = "C2_CZ_05",
    C3_CZ_93 = "C3_CZ_93",
    C3_CZ_99 = "C3_CZ_99",
    C3_CZ_05 = "C3_CZ_05",
    C4_CZ_93 = "C4_CZ_93",
    C4_CZ_99 = "C4_CZ_99",
    C5_CZ_93 = "C5_CZ_93",
    C5_CZ_95 = "C5_CZ_95",
    C5_CZ_99 = "C5_CZ_99",
    C6_CZ_04 = "C6_CZ_04",
    C1cv_CZ_20 = "C1cv_CZ_20",
    C2cv_CZ_20 = "C2cv_CZ_20",
    C30_CZ_20 = "C30_CZ_20",
    C2cv_CZ_24 = "C2cv_CZ_24",
    C30b_CZ_20 = "C30b_CZ_20",
}

export enum TaxPriceLimitCode {
    C2_CZ_05_M1_24 = "C2_CZ_05_M1_24",
    C2cv_CZ_24_M1_24 = "C2cv_CZ_24_M1_24",
}

export enum TaxStatementSubmitterCode {
    Accountant = "Accountant",
    Customer = "Customer",
    TaxAdvisor = "TaxAdvisor",
}

export enum TicketStatusCode {
    InProgress = "InProgress",
    Done = "Done",
}

export enum TimeFormatCode {
    Hh_mm = "HoursMinutes24Clock",
    Hh_mm_ss = "HoursMinutesSeconds24Clock",
    Hh_mm_ssAM_PM = "HoursMinutesSecondsDesignator12Clock",
    Hh_mmAM_PM = "HoursMinutesDesignator12Clock",
}

export enum TimeZoneCode {
    UTC_1CentralEuropeanTime_Amsterdam = "Europe/Amsterdam",
    UTC_1CentralEuropeanTime_Andorra = "Europe/Andorra",
    UTC_2EasternEuropeanTime_Athens = "Europe/Athens",
    UTC_0GreenwichMeanTime_Belfast = "Europe/Belfast",
    UTC_1CentralEuropeanTime_Belgrade = "Europe/Belgrade",
    UTC_1CentralEuropeanTime_Berlin = "Europe/Berlin",
    UTC_1CentralEuropeanTime_Bratislava = "Europe/Bratislava",
    UTC_1CentralEuropeanTime_Brussels = "Europe/Brussels",
    UTC_2EasternEuropeanTime_Bucharest = "Europe/Bucharest",
    UTC_1CentralEuropeanTime_Budapest = "Europe/Budapest",
    UTC_1CentralEuropeanTime_Busingen = "Europe/Busingen",
    UTC_2EasternEuropeanTime_Chisinau = "Europe/Chisinau",
    UTC_1CentralEuropeanTime_Copenhagen = "Europe/Copenhagen",
    UTC_0GreenwichMeanTime_Dublin = "Europe/Dublin",
    UTC_1CentralEuropeanTime_Gibraltar = "Europe/Gibraltar",
    UTC_0GreenwichMeanTime_Guernsey = "Europe/Guernsey",
    UTC_2EasternEuropeanTime_Helsinki = "Europe/Helsinki",
    UTC_0GreenwichMeanTime_IsleOfMan = "Europe/Isle_of_Man",
    UTC_3Turkey_Istanbul = "Europe/Istanbul",
    UTC_0GreenwichMeanTime_Jersey = "Europe/Jersey",
    UTC_3MoscowStandardTime_Kaliningrad = "Europe/Kaliningrad",
    UTC_2EasternEuropeanTime_Kiev = "Europe/Kiev",
    UTC_0WesternEuropeanTime_Lisbon = "Europe/Lisbon",
    UTC_1CentralEuropeanTime_Ljubljana = "Europe/Ljubljana",
    UTC_0GreenwichMeanTime_London = "Europe/London",
    UTC_1CentralEuropeanTime_Luxembourg = "Europe/Luxembourg",
    UTC_1CentralEuropeanTime_Madrid = "Europe/Madrid",
    UTC_1CentralEuropeanTime_Malta = "Europe/Malta",
    UTC_2EasternEuropeanTime_Mariehamn = "Europe/Mariehamn",
    UTC_3BelarusStandardTime_Minsk = "Europe/Minsk",
    UTC_1CentralEuropeanTime_Monaco = "Europe/Monaco",
    UTC_3MoscowStandardTime_Moscow = "Europe/Moscow",
    UTC_2EasternEuropeanTime_Nicosia = "Europe/Nicosia",
    UTC_1CentralEuropeanTime_Oslo = "Europe/Oslo",
    UTC_1CentralEuropeanTime_Paris = "Europe/Paris",
    UTC_1CentralEuropeanTime_Podgorica = "Europe/Podgorica",
    UTC_1CentralEuropeanTime_Prague = "Europe/Prague",
    UTC_2EasternEuropeanTime_Riga = "Europe/Riga",
    UTC_1CentralEuropeanTime_Rome = "Europe/Rome",
    UTC_4Samara = "Europe/Samara",
    UTC_1CentralEuropeanTime_SanMarino = "Europe/San_Marino",
    UTC_1CentralEuropeanTime_Sarajevo = "Europe/Sarajevo",
    UTC_3MoscowStandardTime_Simferopol = "Europe/Simferopol",
    UTC_1CentralEuropeanTime_Skopje = "Europe/Skopje",
    UTC_2EasternEuropeanTime_Sofia = "Europe/Sofia",
    UTC_1CentralEuropeanTime_Stockholm = "Europe/Stockholm",
    UTC_2EasternEuropeanTime_Tallinn = "Europe/Tallinn",
    UTC_1CentralEuropeanTime_Tirane = "Europe/Tirane",
    UTC_2EasternEuropeanTime_Uzhgorod = "Europe/Uzhgorod",
    UTC_1CentralEuropeanTime_Vaduz = "Europe/Vaduz",
    UTC_1CentralEuropeanTime_Vatican = "Europe/Vatican",
    UTC_1CentralEuropeanTime_Vienna = "Europe/Vienna",
    UTC_2EasternEuropeanTime_Vilnius = "Europe/Vilnius",
    UTC_4Volgograd = "Europe/Volgograd",
    UTC_1CentralEuropeanTime_Warsaw = "Europe/Warsaw",
    UTC_1CentralEuropeanTime_Zagreb = "Europe/Zagreb",
    UTC_2EasternEuropeanTime_Zaporozhye = "Europe/Zaporozhye",
    UTC_1CentralEuropeanTime_Zurich = "Europe/Zurich",
}

export enum TypeOfBusinessCode {
    LegalPerson = "LP",
    NaturalPerson = "NP",
}

export enum UnitOfMeasureCode {
    Set = "SET",
    Centimeter = "CM",
    Gram = "GRAM",
    Kilogram = "KILO",
    Piece = "PIECE",
    Liter = "LITER",
    Meter = "METER",
    Pallet = "PAL",
    Tonne = "TONNE",
    Hour = "HOUR",
    Minute = "MINUTE",
    Day = "DAY",
}

export enum UserStatusCode {
    Invited = "INV",
    Active = "ACT",
    Inactive = "IAC",
    Deleted = "DEL",
    RoleAcceptancePending = "RAP",
}

export enum VariantAccessTypeCode {
    SystemVariant = "System",
    GlobalVariant = "Global",
    UserVariant = "User",
}

export enum VariantTypeCode {
    FormVariant = "Form",
    TableVariant = "Table",
}

export enum VatCode {
    CzStd2012 = "CzStd2012",
    CzRdc2012 = "CzRdc2012",
    CzStd2013 = "CzStd2013",
    Cz1Rdc2013 = "Cz1Rdc2013",
    Cz2Rdc2015 = "Cz2Rdc2015",
    CzRdc2024 = "CzRdc2024",
    SkStd2004 = "SkStd2004",
    Sk1Rdc2007 = "Sk1Rdc2007",
    Sk2Rdc2010 = "Sk2Rdc2010",
    SkStd2011 = "SkStd2011",
    SkRdc2011 = "SkRdc2011",
}

export enum VatControlStatementCode {
    KontrolníHlášeníDPHPlatnéOd1_1_2016 = "CZ_2016",
}

export enum VatControlStatementSectionCode {
    A_1 = "CZ_2016_A1",
    A_2 = "CZ_2016_A2",
    A_3 = "CZ_2016_A3",
    A_4 = "CZ_2016_A4",
    A_4_A_5 = "CZ_2016_A4_A5",
    A_5 = "CZ_2016_A5",
    B_1 = "CZ_2016_B1",
    B_2 = "CZ_2016_B2",
    B_2_B_3 = "CZ_2016_B2_B3",
    B_3 = "CZ_2016_B3",
    CZ_2016_N = "CZ_2016_N",
}

export enum VatDeductionTypeCode {
    Proportional = "Proportional",
    Reduced = "Reduced",
    ReducedAndProportional = "ReducedAndProportional",
}

export enum VatOssCode {
    Žádný = "None",
    ElektronickáSlužba = "ElService",
    ZbožíNaDálku = "AtDistance",
}

export enum VatRateTypeCode {
    Standard = "Std",
    FirstReduced = "1Rdc",
    SecondReduced = "2Rdc",
}

export enum VatReverseChargeCode {
    Ano = "Yes",
    Ne = "No",
    Zlato = "1",
    Zlato_ZprostředkováníDodáníInvestičníhoZlata = "1a",
    DodáníNemovitéVěci = "3",
    DodáníNemovitéVěciVNucenémProdeji = "3a",
    StavebníAMontážníPráce = "4",
    StavebníAMontážníPráce_PoskytnutíPracovníků = "4a",
    ZbožíUvedenéVPřílozeČ_5 = "5",
    DodáníZbožíPoskytnutéhoPůvodněJakoZáruka = "6",
    DodáníZbožíPoPostoupeníVýhradyVlastnictví = "7",
    PovolenkyNaEmiseSkleníkovýchPlynů = "11",
    ObilovinyATechnickéPlodiny = "12",
    Kovy = "13",
    MobilníTelefony = "14",
    IntegrovanéObvody = "15",
    PřenosnáZařízeníProAutomatizovanéZpracováníDat = "16",
    VideoherníKonzole = "17",
    DodáníCertifikátůElektřiny = "18",
    DodáníElektřinySoustavamiNeboSítěmiObchodníkovi = "19",
    DodáníPlynuSoustavamiNeboSítěmiObchodníkovi = "20",
    PoskytnutíVymezenýchSlužebElektronickýchKomunikací = "21",
}

export enum VatStatementCode {
    PřiznáníKDaniZPřidanéHodnotyPlatnéOd1_1_2011 = "CZ_2011",
}

export enum VatStatementFrequencyCode {
    Monthly = "Monthly",
    Quarterly = "Quarterly",
}

export enum VatStatementSectionCode {
    CZ_2011_3_4 = "CZ_2011_3_4",
    CZ_2011_5_6 = "CZ_2011_5_6",
    CZ_2011_12_13 = "CZ_2011_12_13",
    CZ_2011_9 = "CZ_2011_9",
    CZ_2011_7_8 = "CZ_2011_7_8",
    CZ_2011_10_11 = "CZ_2011_10_11",
    CZ_2011_40_41 = "CZ_2011_40_41",
    CZ_2011_42 = "CZ_2011_42",
    CZ_2011_61 = "CZ_2011_61",
    CZ_2011_30 = "CZ_2011_30",
    CZ_2011_47 = "CZ_2011_47",
    CZ_2011_32 = "CZ_2011_32",
    CZ_2011_1_2 = "CZ_2011_1_2",
    CZ_2011_20 = "CZ_2011_20",
    CZ_2011_22 = "CZ_2011_22",
    CZ_2011_23 = "CZ_2011_23",
    CZ_2011_24 = "CZ_2011_24",
    CZ_2011_26 = "CZ_2011_26",
    CZ_2011_50 = "CZ_2011_50",
    CZ_2011_61D = "CZ_2011_61D",
    CZ_2011_31 = "CZ_2011_31",
    CZ_2011_21 = "CZ_2011_21",
    CZ_2011_25 = "CZ_2011_25",
    CZ_2011_3_4_IP = "CZ_2011_3_4_IP",
    CZ_2011_5_6_IP = "CZ_2011_5_6_IP",
    CZ_2011_12_13_IP = "CZ_2011_12_13_IP",
}

export enum VatStatementSectionCategoryCode {
    DocumentReceivedInReverseChargeMode = "DocRcvdRCh",
    DocumentReceivedInAutomatedVATInclusionMode = "DocRcvdAvim",
    DocumentReceivedInAutomatedVATInclusionModeIdentifiedPerson = "DocRcvdAvimIp",
    DocumentReceived = "DocRcvd",
    DocumentIssued = "DocIssd",
    InternalVATStatementSection = "Internal",
}

export enum VatStatementStatusCode {
    Filed = "F",
    FiledAndModified = "M",
    PartiallyFiled = "P",
    NotFiled = "N",
    N_A = "_",
    Undefined = "U",
}

export enum VatStatusCode {
    VATRegistered = "VatRegistered",
    NotVATRegistered = "NotVatRegistered",
    IdentifiedPerson = "IdentifiedPerson",
}

export enum VatViesCode {
    Kód0 = "0",
    Kód1 = "1",
    Kód2 = "2",
    Kód3 = "3",
}

export enum WebSocketMessageTypeCode {
    Notification = "Notification",
    SessionInvalidated = "SessionInvalidated",
    SessionTerminated = "SessionTerminated",
    BackgroundJob = "BackgroundJob",
    ActionProgress = "ActionProgress",
    Login = "Login",
    System = "System",
}

export enum WriteOffTypeCode {
    No = "None",
    Nottax = "NotTax",
    Tax = "Tax",
}

