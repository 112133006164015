import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { BeaverAnim, MoleAnim } from "../../animations/Animations";
import { QueryParam } from "../../enums";
import { getQueryParameters } from "../../routes/Routes.utils";
import { StyledErrorPage } from "./NotFound.styles";

export function NotFound(): ReactElement {
    const { t } = useTranslation("Common");
    const entityWasDeleted = getQueryParameters()?.[QueryParam.EntityWasDeleted] === "true";
    return (
        <StyledErrorPage>
            <BeaverAnim/>
            <h2>{t(`Common:Errors.${entityWasDeleted ? "EntityWasDeleted" : "Error404"}`)}</h2>
        </StyledErrorPage>
    );
}

export function NoPermission(): ReactElement {
    const { t } = useTranslation("Common");
    return (
        <StyledErrorPage>
            <MoleAnim/>
            <h2>{t("Common:Errors.Error403")}</h2>
        </StyledErrorPage>
    );
}