import { StyledCollapsibleContent } from "@components/collapsibleContent/CollapsibleContent.styles";
import { StyledSwitch } from "@components/inputs/switch/Switch.styles";
import styled from "styled-components/macro";

import { T_PLAIN_big } from "../../../global.style";
import { UserNameStyled } from "../users/Users.styles";

export const AssignedUsersTableWrapper = styled.div`
    width: 100%;
    ${StyledCollapsibleContent} {
        width: 100%;
    }
    ${UserNameStyled} {
        color: ${props => props.theme.C_TEXT_link}
    }
`;

export const PermissionGroup = styled.div`
    display: flex;
    align-items: flex-start;
    ${StyledSwitch} {
        margin-left: 40px;
    }
`;

export const LeftColumn = styled.div`
    width: 215px;
    h5 {
        margin-bottom: 9px;
    }
    span {
        ${T_PLAIN_big};

        & + div {
            margin-top: 9px;
        }
    }
`;

export const PermissionsGrid = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 40px 80px;
    justify-content: flex-start;
`;