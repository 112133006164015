import { IGetValueArgs } from "@components/smart/FieldInfo";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { AccountingCode, CurrencyCode, VatStatusCode } from "@odata/GeneratedEnums";

import { FILES_API_URL } from "../constants";
import { AppMode, IAppContext } from "../contexts/appContext/AppContext.types";
import DateType, { getUtcDate, getUtcDayjs } from "../types/Date";
import { desc } from "./general";
import { logger } from "./log";

const firstSystemLogoId = -1;
const lastSystemLogoId = -50; // TODO back to -100 when all avatars are used again

export const getCompanyLogoUrl = (company: ICompanyEntity): string => {
    const imageId = company?.Logo?.Id;
    return FILES_API_URL + "/" + imageId;
};

export const getNextSystemCompanyLogoId = (companies: ICompanyEntity[] = []): number => {
    if (companies.length === 0)
        return firstSystemLogoId;

    // we want to find the least used system logo Id starting with -1

    const systemLogoIds = companies
        .filter(c => c.Logo != null && c.Logo.Id < 0)
        .map(c => c.Logo.Id)
        .sort(desc); // sort from -1 to -100

    if (systemLogoIds.length === 0 || systemLogoIds[0] !== firstSystemLogoId)
        return firstSystemLogoId;

    let candidateId = 0;
    let candidateOccurrences = Number.MAX_SAFE_INTEGER;

    let currentId = firstSystemLogoId;
    let currentOccurrences = 1;

    for (let i = 1; i < systemLogoIds.length; i++) {
        if (systemLogoIds[i] === currentId) {
            currentOccurrences++;
        } else if (currentId - 1 > systemLogoIds[i]) {
            return currentId - 1;
        } else {
            if (currentOccurrences < candidateOccurrences) {
                candidateId = currentId;
                candidateOccurrences = currentOccurrences;
            }

            currentId = systemLogoIds[i];
            currentOccurrences = 1;
        }
    }

    if (currentId > lastSystemLogoId)
        return currentId - 1;

    return candidateOccurrences <= currentOccurrences ? candidateId : currentId;
};

export function getCompanyAccountingCode(context: IAppContext): AccountingCode {
    return context?.getCompany()?.AccountingCode as AccountingCode;
}

export function isCashBasisAccountingCompany(context: IAppContext): boolean {
    return getCompanyAccountingCode(context) === AccountingCode.CashBasisAccounting;
}

export function isAccountAssignmentCompany(context: IAppContext): boolean {
    return getCompanyAccountingCode(context) === AccountingCode.AccountingForBusiness;
}

export function getCompanyCurrency(context: IAppContext): CurrencyCode {
    if (!context) {
        logger.warn(`AppContext missing for getCompanyCurrency function, currency might not be correct.`);
    }
    return context?.getCompany()?.CurrencyCode as CurrencyCode ?? CurrencyCode.CzechKoruna;
}

export const isAccountAssignmentCompanyValue = (args: IGetValueArgs): boolean => isAccountAssignmentCompany(args.context ?? args.storage.context);

export function getCompanyVatStatusesSorted(company: ICompanyEntity): ICompanyEntity["VatStatuses"] {
    return company?.VatStatuses?.sort(
        (a, b) => a.DateValidFrom.getTime() - b.DateValidFrom.getTime()
    );
}

export function getCompanyLatestVatStatus(company: ICompanyEntity): VatStatusCode {
    const vatStatuses = getCompanyVatStatusesSorted(company);

    if (vatStatuses == null || vatStatuses.length === 0) {
        return null;
    }

    return vatStatuses[vatStatuses.length - 1].VatStatusCode as VatStatusCode;
}

export function getCompanyVatStatusForDate(company: ICompanyEntity, date: Date): VatStatusCode {
    const vatStatuses = getCompanyVatStatusesSorted(company);

    if (vatStatuses == null || vatStatuses.length === 0) {
        return null;
    }

    for (let i = vatStatuses.length - 1; i >= 0; i--) {
        if (getUtcDayjs(vatStatuses[i].DateValidFrom).isSameOrBefore(date, "day")) {
            return vatStatuses[i].VatStatusCode as VatStatusCode;
        }
    }

    return null;
}

/**
 * Returns true if currently selected company (agenda) is registered tax payer
 * or undefined if given date is not valid
 * By default checks for today. Pass another date to check for different date.
 * @param context
 * @param date today by default
 */
export function isVatRegisteredCompany(context: IAppContext, date = getUtcDate()): boolean {
    if (!DateType.isValid(date)) {
        // if date is not valid date, we return undefined and caller
        // should handle the undefined state according to the situation
        return undefined;
    }
    return getCompanyVatStatusForDate(context.getCompany(), date) === VatStatusCode.VATRegistered;
}

/** Some functionalities have to always be available, if the company was at least once VatRegistered.
 * Identified person usually means that the vat functionality should be shown as well. */
export const hasEverBeenVatRegisteredCompany = (context: IAppContext, ignoreIdentifiedPerson?: boolean): boolean => {
    const company = context.getCompany();

    if (!company) {
        return false;
    }

    const today = getUtcDayjs();

    return getCompanyVatStatusesSorted(company)
        .filter(status => getUtcDayjs(status.DateValidFrom).isSameOrBefore(today))
        .some(status => status.VatStatusCode === VatStatusCode.VATRegistered || (!ignoreIdentifiedPerson && status.VatStatusCode === VatStatusCode.IdentifiedPerson));
};

export function isIdentifiedPersonCompany(context: IAppContext, date = getUtcDate()): boolean {
    if (!DateType.isValid(date)) {
        // if date is not valid date, we return undefined and caller
        // should handle the undefined state according to the situation
        return undefined;
    }
    return getCompanyVatStatusForDate(context.getCompany(), date) === VatStatusCode.IdentifiedPerson;
}

export function isDemoTenant(context: IAppContext): boolean {
    const data = context.getData();
    return !!data.tenant?.IsDemo;
}

export function getCompanySelectorTitleKey(context: IAppContext): string {
    const isDemo = isDemoTenant(context);
    return isDemo ? "DemoTitle" : "Title";
}

export function isOnOrganizationLevel(args: IGetValueArgs): boolean {
    const { context } = args;
    return context.getAppMode() === AppMode.OrganizationSettings;
}