import { IBaseAlertProps } from "@components/alert/Alert";
import { fileNameToMime, getFileNameExtension, isFileCsv } from "@components/fileUploader/File.utils";
import { formatDateToDateString, formatDateToDateTimeOffsetString } from "@components/inputs/date/utils";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import {
    ISmartBankAccountFilterCustomData
} from "@components/smart/smartBankAccountFilter/SmartBankAccountFilter.utils";
import { IGroupRenderFn } from "@components/smart/smartFormGroup/SmartFormGroup";
import { isODataError, ODataError } from "@odata/Data.types";
import { getNewItemsMaxId } from "@odata/Data.utils";
import {
    BankStatementEntity,
    BankTransactionEntity,
    CompanySettingEntity,
    EntitySetName,
    IBankStatementEntity,
    IBankTransactionEntity,
    ICompanyBankAccountEntity,
    ICompanySettingEntity,
    IEntityAttachmentEntity,
    IExchangeRateEntity,
    IFileMetadataEntity,
    InitialAccountBalanceEntity
} from "@odata/GeneratedEntityTypes";
import { ClearedStatusCode, CurrencyCode } from "@odata/GeneratedEnums";
import { BatchRequest, isBatchResultOk, OData } from "@odata/OData";
import { transformToODataString } from "@odata/OData.utils";
import { parseResponse } from "@odata/ODataParser";
import { canChangeNumberRange, getRangeByDefinitionId, replaceWildcards } from "@pages/numberRange/NumberRange.utils";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import Dialog from "../../../components/dialog";
import HiddenFileInput from "../../../components/fileUploader/HiddenFileInput";
import SmartFastEntryList, {
    ActionType,
    ISmartFastEntriesActionEvent
} from "../../../components/smart/smartFastEntryList";
import { BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX, BANK_STATEMENTS, REST_API_URL } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Status, ValueType } from "../../../enums";
import { TRecordAny } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext, { TEntityKey } from "../../../odata/BindingContext";
import { getUtcDate } from "../../../types/Date";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import FileStorage from "../../../utils/FileStorage";
import { getAlertFromError } from "../../../views/formView/Form.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import CsvImport, { IUploadArgs } from "../../csvImport/CsvImport";
import { parseTransactions } from "../../csvImport/CsvImport.utils";
import { DRAFT_ITEM_ID_PATH, loadExchangeRate } from "../../documents/Document.utils";
import { ClosingBalancePath } from "./BankStatementsDef";

export const getUploadErrorMessage = (error: ODataError): string => {
    const innerError = error?._validationMessages?.[0];
    const code = innerError?.code;
    const tranCode = `Error:${code}`;
    const { sign, line, ...restParams } = innerError.messageParameters ?? {};
    const opts = {
        ...restParams,
        sign: sign ?? "?",
        line: line ?? "?"
    };
    return code && i18next.exists(tranCode) ? i18next.t(tranCode, opts)
        : error?._message ?? i18next.t("Banks:Validation.WrongFormat");
};

export const showError = (storage: Model, title: string, subTitle: string): void => {
    (storage as FormStorage).setFormAlert({
        status: Status.Error,
        title: title,
        subTitle: subTitle
    });
};

export const parseGpc = async (storage: Model, items: TRecordAny[]): Promise<void> => {
    let maxId = (getNewItemsMaxId(storage.data.entity.Transactions) || 0) + 1;
    let order = storage.data.entity.Transactions?.length ?? 1;

    for (const item of items || []) {
        const data: IBankTransactionEntity = {
            DateBankTransaction: getUtcDate(item.DateBankTransaction),
            RemittanceInformation: item.RemittanceInformation,
            SymbolConstant: item.SymbolConstant,
            SymbolSpecific: item.SymbolSpecific,
            SymbolVariable: item.SymbolVariable,
            TransactionCurrencyCode: item.TransactionCurrencyCode,
            TransactionAmount: item.TransactionAmount,
            PaymentInformation: item.PaymentInformation,
            BankInternalId: item.BankInternalId,
        };

        if (item.BusinessPartner?.BusinessPartner?.Id) {
            const bp = item.BusinessPartner;
            data.BusinessPartner = {
                BusinessPartner: {
                    Id: item.BusinessPartner.BusinessPartner.Id
                },
                City: bp.City,
                CountryCode: bp.CountryCode,
                Email: bp.Email,
                FirstName: bp.FirstName,
                LastName: bp.LastName,
                LegalNumber: bp.LegalNumber,
                Name: bp.Name,
                PhoneNumber: bp.PhoneNumber,
                PostalCode: bp.PostalCode,
                Street: bp.Street,
                TaxNumber: bp.TaxNumber,
                VatStatusCode: bp.VatStatusCode
            };
        }

        if (item.BankAccount) {
            const itemBa = item.BankAccount;
            data.BankAccount = {
                AbaNumber: itemBa.AbaNumber,
                AccountNumber: itemBa.AccountNumber,
                BankCode: itemBa.BankCode,
                CountryCode: itemBa.CountryCode,
                IBAN: itemBa.IBAN,
                SWIFT: itemBa.SWIFT
            };
        }

        const id = maxId++;
        const newEntity = BindingContext.createNewEntity(id, data);

        newEntity[BankTransactionEntity.Order] = order;
        order += 1;

        // for saving purposes we have to make this items 'dirty'
        storage.setDirty(storage.data.bindingContext.navigate(`Transactions(${BindingContext.NEW_ENTITY_ID_PROP}=${id})`));

        if (!storage.data.entity.Transactions) {
            storage.data.entity.Transactions = [newEntity];
        } else {
            storage.data.entity.Transactions.push(newEntity);
        }
    }

    await assignExchangeRatesToTransactions(storage.data.entity.Transactions, storage.data.entity.BankAccount.TransactionCurrencyCode as CurrencyCode, storage.context);
};

const assignExchangeRatesToTransactions = async (transactions: IBankTransactionEntity[], currencyCode: CurrencyCode, context: IAppContext): Promise<IBankTransactionEntity[]> => {
    const ratesMap: Record<string, number> = {};

    if (currencyCode !== getCompanyCurrency(context)) {
        const dates = new Set();
        for (const t of transactions) {
            if (t.DateBankTransaction) {
                dates.add(formatDateToDateTimeOffsetString(t.DateBankTransaction));
            }
        }
        const res = await customFetch(`${REST_API_URL}/ExchangeRate/GetExchangeRatesForDates`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                exchangeRateDates: [...dates],
                currencyCode
            })
        });

        const rates = await res.json();
        for (const [key, val] of Object.entries(rates)) {
            ratesMap[formatDateToDateString(key)] = (val as IExchangeRateEntity)?.ExchangeRatePerUnit;
        }
    }

    for (const t of transactions) {
        t.ExchangeRatePerUnit = ratesMap[formatDateToDateString(t.DateBankTransaction)] ?? (currencyCode === getCompanyCurrency(context) ? 1 : null);
    }

    return transactions;
};

export const isFieldTransactionDisabled = (args: IGetValueArgs): boolean => {
    return isTransactionDisabled(args, args.bindingContext.getParent());
};

export const isTransactionDisabled = (args: IGetValueArgs, bc: BindingContext): boolean => {
    const row = args.storage.getValue(bc);
    const isLocked = !!row?.Lock?.Id;
    const isCleared = row?.ClearedStatusCode === ClearedStatusCode.Cleared || row?.ClearedStatusCode === ClearedStatusCode.PartiallyCleared;

    return isLocked || isCleared;
};

export const calculateAndStoreTransactionCount = (storage: FormStorage<IBankStatementEntity, ISmartBankAccountFilterCustomData>): number => {
    let count = 0;

    if (storage.data.entity.Transactions) {
        for (const element of storage.data.bindingContext.iterateNavigation("Transactions", storage.data.entity.Transactions)) {
            if (!element.bindingContext.isNew() || storage.isDirty(element.bindingContext) || element.entity[DRAFT_ITEM_ID_PATH]) {
                count++;
            }
        }
    }

    storage.setCustomData({ transactionCount: count });
    return count;
};


export const recalculateBalance = (storage: FormStorage): void => {
    const { Transactions } = storage.data.entity;
    let CreditTurnover = 0,
        DebitTurnover = 0;
    (Transactions ?? []).forEach((transaction: IBankTransactionEntity) => {
        if (isNaN(transaction.TransactionAmount)) {
            return; // skip if not valid
        }
        if (transaction.TransactionAmount > 0) {
            CreditTurnover += transaction.TransactionAmount ?? 0;
        } else {
            DebitTurnover += transaction.TransactionAmount ?? 0;
        }
    });
    storage.setValueByPath("DebitTurnover", DebitTurnover);
    storage.setValueByPath("CreditTurnover", CreditTurnover);
    storage.setValueByPath("TransactionsTurnover", CreditTurnover + DebitTurnover);
    storage.addActiveField(storage.data.bindingContext.navigate(ClosingBalancePath));
};

export const refreshBankStatementNumberRange = (storage: FormStorage, rangeId: number): void => {
    if (rangeId) {
        const range = getRangeByDefinitionId(storage, rangeId);
        if (range) {
            const numberOurs = replaceWildcards(range.NextNumber, storage.data.entity, { useNow: false });
            storage.setValueByPath("NumberRange", range, true);
            storage.setValueByPath("NumberOurs", numberOurs, true);
        }
    }
};

export const onDateFromChange = async (storage: FormStorage, dateFrom: Date) => {
    const entity = storage.data.entity;
    // if there is only one UNMODIFIED line, we change its date accordingly
    if (entity.Transactions?.length === 1) {
        const id = entity.Transactions[0][BindingContext.NEW_ENTITY_ID_PROP];
        if (id) {
            const bc = storage.data.bindingContext.navigate(`Transactions`).addKey(id, true);
            if (!storage.isDirty(bc)) {
                const bcDate = bc.navigate("DateBankTransaction");
                storage.setValue(bcDate, dateFrom);

                const currencyCode = storage.data.entity.BankAccount?.TransactionCurrency?.Code;
                const rate = await loadExchangeRate(storage, currencyCode, dateFrom);
                if (rate) {
                    const bcExchange = bc.navigate("ExchangeRatePerUnit");
                    storage.clearAndSetValue(bcExchange, rate);
                    storage.refreshFields();
                }

            }
        }
    }

    if (canChangeNumberRange(storage)) {
        const rangeId = storage.data.entity.BankAccount?.BankStatementNumberRangeDefinition?.Id;
        refreshBankStatementNumberRange(storage, rangeId);
    }
};

function onAfterCreateBankStatementItems(storage: FormStorage): void {
    calculateAndStoreTransactionCount(storage);
    recalculateBalance(storage);

    // set DateFrom to the last transaction date if DateFrom has not been changed by user
    // https://solitea-cz.atlassian.net/browse/DEV-28739
    const transactions = storage.data.entity.Transactions;

    if (storage.data.bindingContext.isNew() && transactions?.length) {
        const newest = transactions.reduce(function(prev: IBankTransactionEntity, current: IBankTransactionEntity) {
            return prev.DateBankTransaction > current.DateBankTransaction ? prev : current
        })?.DateBankTransaction;

        if (newest && !storage.isDirty(storage.data.bindingContext.navigate(BankStatementEntity.DateFrom))) {
            storage.setValueByPath(BankStatementEntity.DateFrom, newest);
            onDateFromChange(storage, newest);
        }
    }

    storage.setBusy(false);
    // refresh file upload component too (this refresh full page)
    storage.refresh(true);
}

/**
 * Function applies bank statement attachment to BS - could be used by clicking context action in attachments file view
 * or using "add from file" button under the SmartFastEntryList under the form.
 */
export async function handleApplyBankStatementAttachment(storage: FormStorage<IBankStatementEntity, ISmartBankAccountFilterCustomData>, file: IFileMetadataEntity): Promise<true | IBaseAlertProps> {
    const { entity } = storage.data;
    const bankAccId = entity.BankAccount?.Id;

    const fileExtension = getFileNameExtension(file?.Name);
    const fileMime = fileNameToMime(fileExtension);
    const isCsv = isFileCsv(fileMime);

    if (isCsv) {
        const csvFile = await FileStorage.get(file.Id);
        storage.setCustomData({ csvFile, uploadCsv: false });
        storage.refresh();
        return true;
    }

    const url = `${BANK_STATEMENTS}/DeserializeAttachment/${bankAccId}/${file.Id}`;
    const response = await customFetch(url, {
        method: "POST"
    });
    const data = await parseResponse<TRecordAny[]>(response);

    if (isODataError(data)) {
        return getAlertFromError(data);
    }

    if (data?.length > 0) {
        await parseGpc(storage, data);
        onAfterCreateBankStatementItems(storage);
    }

    return true;
}

export const getTransactionsRender = (args: IGroupRenderFn): React.ReactElement => {
    const storage = args.storage as FormStorage<IBankStatementEntity, ISmartBankAccountFilterCustomData>;
    const isNew = storage.data.bindingContext.isNew();

    const _fileInput = React.createRef<HTMLInputElement>();

    const _uploadFileToAttachments = async (file: File) => {
        const batch: BatchRequest = storage.oData.batch();
        batch.beginAtomicityGroup("group1");
        const attachBc = storage.data.bindingContext.navigate("Attachments");
        const queryableEntity = isNew ? null : batch.fromPath(attachBc.toString());

        try {
            const metadata = await FileStorage.upload({ file, name: file.name });
            const attachments = [...storage.data.entity.Attachments || []];

            const attachment: IEntityAttachmentEntity = {
                File: metadata
            };

            if (isNew) {
                attachment.Id = attachment.File.Id;
                attachments.push(attachment);
                // unsaved
                storage.setValue(attachBc, attachments);
            } else {
                queryableEntity.create(attachment);

                const batchResponse = await batch.execute();
                const result = batchResponse[0];
                if (isBatchResultOk(result)) {
                    attachments.push(result.body.value);

                    storage.setValueToEntityAndOrigEntity(attachBc, attachments);
                } else {
                    showError(storage, i18next.t("Banks:Statements.UnableToAttach"), (result.body as ODataError)?._message);
                }
            }
        } catch (e) {
            showError(storage, i18next.t("Banks:Statements.UnableToAttach"), e.message);
        } finally {
            storage.clearEmptyLineItems("Transactions");
        }
    };

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        const accId = storage.data.entity?.BankAccount?.Id;

        const fileExtension = getFileNameExtension(file?.name);
        const fileMime = fileNameToMime(fileExtension);
        const isCsv = isFileCsv(fileMime);

        try {
            if (isCsv) {
                storage.setCustomData({ csvFile: file, uploadCsv: true });
                storage.refresh(true);
                return;
            }

            const companyId = storage.context.getCompany().Id;
            const url = `${BANK_STATEMENTS}/DeserializeTransactions/${accId}?CompanyId=${companyId}`;
            const items = await FileStorage.upload({
                file,
                url,
                name: file.name
            }) as IBankTransactionEntity[];

            if (items?.length) {
                await parseGpc(storage, items);
                await _uploadFileToAttachments(file);

                onAfterCreateBankStatementItems(storage);
            }

        } catch (error) {
            showError(storage, i18next.t("Banks:Transactions.UploadError"), getUploadErrorMessage(error));
        } finally {
            (storage as FormStorage).setBusy(false);
            storage.refresh(true);
        }
    };

    const handleCustomActionButtonClick = () => {
        _fileInput.current.click();
    };

    const _handleAction = (event: ISmartFastEntriesActionEvent) => {
        if (event.actionType === ActionType.Custom) {
            handleCustomActionButtonClick();
        } else {
            if (event.actionType !== ActionType.Reorder) {
                const orderedItem = event.affectedItems[0];
                const item = event.items.find(i => i.Id === orderedItem.Id || i[BindingContext.NEW_ENTITY_ID_PROP] === orderedItem[BindingContext.NEW_ENTITY_ID_PROP]);
                const isAdd = event.actionType === ActionType.Add;
                const isClone = event.actionType === ActionType.Clone;

                if (isAdd || isClone) {
                    delete item.PostedStatus;
                    delete item.PostedStatusCode;
                    delete item.ClearedStatus;
                    delete item.ClearedStatusCode;
                    delete item.PaymentStatus;
                    delete item.PaymentStatusCode;
                    delete item.Lock;
                }

                if (isClone) {
                    delete item[BindingContext.METADATA_KEY];
                    delete item.NumberOurs;
                }
            }

            args.props.onAction?.(event);
        }
    };

    const _canUpload = () => {
        return !!args.props.storage.data.entity.BankAccount?.Id;
    };

    const handleSettingsCancel = () => {
        args.storage.setCustomData({ csvFile: null });
        args.storage.refresh();
    };

    const handleUploadFromSettingsScreen = async (csvArgs: IUploadArgs) => {
        const entity = args.storage.data.entity as IBankStatementEntity;
        const transactions = await parseTransactions(csvArgs.transformedRows, csvArgs.dateFormat, args.storage.oData);
        let maxId = (getNewItemsMaxId(entity.Transactions) || 0) + 1;

        await assignExchangeRatesToTransactions(transactions, entity.BankAccount.TransactionCurrencyCode as CurrencyCode, args.storage.context);

        if (!entity.Transactions) {
            args.storage.data.entity.Transactions = [];
        }
        for (const trans of transactions) {
            const id = maxId++;
            const newEntity = BindingContext.createNewEntity(id, trans);
            entity.Transactions.push(newEntity);

            // for saving purposes we have to make this items 'dirty'
            storage.setDirty(storage.data.bindingContext.navigate(`Transactions(${BindingContext.NEW_ENTITY_ID_PROP}=${id})`));
        }
        if (storage.getCustomData().uploadCsv) {
            await _uploadFileToAttachments(storage.getCustomData().csvFile);
        }
        // recalculate
        onAfterCreateBankStatementItems(storage);
    };

    const isStatementDialog = (args.storage as FormStorage<IBankStatementEntity, ISmartBankAccountFilterCustomData>).getCustomData().isStatementDialog;

    return <>
        <SmartFastEntryList bindingContext={args.props.bindingContext}
                            storage={args.storage as FormStorage}
                            onChange={args.props.onChange}
                            isItemDisabled={args.props.isItemDisabled}
                            isReadOnly={args.props.isReadOnly}
                            isItemRemovable={(_args: IGetValueArgs) => {
                                return !args.props.isReadOnly && !isTransactionDisabled(_args, _args.bindingContext);
                            }}
                            isTheOnlyItemRemovable={args.props.isTheOnlyItemRemovable}
                            useLabelWrapping
                            groupId={args.groupId}
                            canAdd
                            canReorder
                            order="Order"
                            showLineNumbers={args.props.showLineNumbers}
                            onAction={_handleAction}
                            columns={args.props.columns}
                            customActionButtons={isStatementDialog ? null : [{
                                id: "upload",
                                isDisabled: !_canUpload() || args.props.storage.isDisabled || args.props.storage.data.locked,
                                title: args.storage.t("Banks:Statements.AddFromFile")
                            }]}
                            onBlur={args.props.onBlur}/>
        <HiddenFileInput passRef={_fileInput}
                         onChange={handleUpload}/>
        {storage.getCustomData().csvFile &&
            <Dialog
                title={args.storage.t("CsvImport:DialogHeader")}
                isEditableWindow={true}
                onConfirm={null}
                onClose={handleSettingsCancel}
                renderFooterForPortal>
                <CsvImport
                    file={storage.getCustomData().csvFile}
                    bankAccountId={args.storage.data.entity.BankAccount.Id}
                    onCancel={handleSettingsCancel}
                    onUpload={handleUploadFromSettingsScreen}
                />
            </Dialog>
        }
    </>;
};


export const refreshExchangesRates = async (storage: FormStorage, currencyCode: CurrencyCode): Promise<void> => {
    const transactions = storage.data.entity.Transactions || [];
    for (const transaction of transactions) {
        delete transaction.ExchangeRatePerUnit;
    }
    await assignExchangeRatesToTransactions(transactions, currencyCode, storage.context);
    storage.refresh();
};

export async function getBankAccountBalanceForDate(oData: OData, context: IAppContext, date: Date, bankAccountId: TEntityKey, statementId?: number): Promise<number> {
    const batch: BatchRequest = oData.batch();
    batch.beginAtomicityGroup("group1");

    batch.getEntitySetWrapper(EntitySetName.CompanyBankAccounts).get(bankAccountId);

    const formattedDate = transformToODataString(date, ValueType.Date);
    const filterForOlderStatements = statementId ? `${BankStatementEntity.DateFrom} lt ${formattedDate} OR (${BankStatementEntity.DateFrom} eq ${formattedDate} AND ${BankStatementEntity.Id} lt ${statementId})`
        : `${BankStatementEntity.DateFrom} le ${formattedDate}`;
    batch.getEntitySetWrapper(EntitySetName.BankStatements).query()
        .filter(`BankAccount/Id eq ${bankAccountId} AND (${filterForOlderStatements})`)
        .groupBy("BankAccount/Id")
        .aggregate("TransactionsTurnover with sum as TransactionsTurnover");

    const batchResponse = await batch.execute();

    const bankAccount = (isBatchResultOk(batchResponse[0]) ? batchResponse[0].body.value : null) as ICompanyBankAccountEntity;
    let initialBalance;
    if (isCashBasisAccountingCompany(context)) {
        initialBalance = bankAccount?.InitialTransactionBalance ?? 0;
    } else {
        // we need to get initial Balance from JournalEntries
        const bankAccountAnalyticNumber = `${BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX}${bankAccount.BalanceSheetAccountNumberSuffix}`;
        const res = await oData.getEntitySetWrapper(EntitySetName.CompanySettings).query()
            .select(CompanySettingEntity.Id)
            .expand(CompanySettingEntity.InitialAccountBalances, (query) => {
                query.filter(`${InitialAccountBalanceEntity.Number} eq '${bankAccountAnalyticNumber}'`)
                    .select(InitialAccountBalanceEntity.InitialTransactionBalance, InitialAccountBalanceEntity.Number);
            })
            .top(1)
            .fetchData<ICompanySettingEntity[]>();
        const companySettings = (res.value as ICompanySettingEntity[])[0];

        initialBalance = companySettings.InitialAccountBalances?.[0]?.InitialTransactionBalance ?? 0;
    }
    const [summaryStatement] = (isBatchResultOk(batchResponse[1]) ? batchResponse[1].body.value : null) as IBankStatementEntity[];

    return initialBalance + (summaryStatement?.TransactionsTurnover ?? 0);
}
