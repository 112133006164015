import { Property } from "@evala/odata-metadata/src";
import { getBoundValue } from "@odata/Data.utils";
import { getFieldInfo, IFieldInfo } from "@odata/FieldInfo.utils";
import { formatValue, IFormatOptions } from "@odata/OData.utils";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { ActionState, GroupStatus, RowAction, ToggleState } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import LocalSettings from "../../../utils/LocalSettings";
import { strongTextFormatter } from "../../fileUploader/File.utils";
import { getSelectDisplayValue, isSelectBasedComponent } from "../../inputs/select/SelectAPI";
import { IRow, IRowAction, IRowValues, TCellValue, TId } from "../../table";
import { IRowProps } from "../../table/Rows";
import { getInfoValue, IFieldDef } from "../FieldInfo";

interface IPrepareColumns {
    bindingContext: BindingContext;
    context: IAppContext;
    columns: IFieldDef[];
}

interface ICustomRowAction extends IRowAction {
    /**
     *  If true, action is disabled. Should we rename this to better reflect the current state?
     *  If you want to completely remove the action, custom row action has to be used instead with getActionState returning ActionState.None
     * */
    isRowWithoutAction?: (rowId: TId, action: RowAction, row: IRow) => boolean;
    /** During the time the custom action is active,
     * table can be reloaded e.g. when parameter/filter is changed and the table will then contain new set of rows.
     * In that case, the custom state used for the custom action, most certainly, has to be reset to some initial state.
     * This callback is redundant to Table.onAfterTableLoad, but this way it is enforced when the custom action handling is used.
     * */
    onTableReloaded?: () => void;
}

/** If onClick/getActionState is handled manually, toggle state has to be handled as well.
 *  This is so that table can fire onToggleChange when filter is change, to clear the selection.
 *  */
interface TCustomRowActionWithCustomHandling extends ICustomRowAction {
    onClick: (rowId: TId, row: IRowProps) => void;
    getActionState: (rowId: TId, row: IRowProps) => ActionState;
    toggleState: ToggleState;
    onToggleChange: (toggleState: ToggleState) => void;
    onTableReloaded: () => void;
}

interface TCustomRowActionWithoutCustomHandling extends ICustomRowAction {
    getActionState?: never;
    toggleState?: never;
    onToggleChange?: never;
    onTableReloaded?: never;
}

// use two different interfaces to enforce multiple pros as required when one of them is used.
export type TCustomRowAction = TCustomRowActionWithCustomHandling | TCustomRowActionWithoutCustomHandling;

export const prepareColumns = async ({
                                         bindingContext,
                                         context: reactContext,
                                         columns
                                     }: IPrepareColumns): Promise<IFieldInfo[]> => {
    const entityType = bindingContext.getEntityType();
    const preparedColumns = [];
    const visibleColumnsInOrder = columns || entityType?.getUiProperties().map((p: Property) => ({ id: p.getName() })) || [];
    const promises: Promise<IFieldInfo>[] = [];

    for (let i = 0; i < visibleColumnsInOrder.length; i++) {
        const column = visibleColumnsInOrder[i];
        const columnBindingContext = bindingContext.navigate(column.id);

        promises.push(
            getFieldInfo({
                bindingContext: columnBindingContext,
                context: reactContext,
                fieldDef: column
            })
        );
    }

    const results = await Promise.all(promises);

    for (let i = 0; i < visibleColumnsInOrder.length; i++) {
        const column = visibleColumnsInOrder[i];
        const data = results[i];

        preparedColumns.push({
            ...data,
            ...column
        });
    }

    return preparedColumns;
};

export interface IExportCellValue {
    id: string,
    label: string,
    value: string
}

export type TFormatterFn = (val: TValue, args?: IFormatOptions) => TCellValue | Promise<TCellValue> | TFieldValue;
export type TExportFormatterFn = (args?: IFormatOptions) => IExportCellValue[];

// usually fields has string, can have other values also - Switch (boolean), NumericInput...
export type TFieldValue = string | string [] | number | boolean;

interface IFormatValuesArgs {
    column?: IFieldInfo;
    values: IEntity;
    valuesBindingContext: BindingContext;
    storage?: Model<any>;
}

// each column will have its type
export const getFormattedValueFromValues = ({ column, values, valuesBindingContext, storage }: IFormatValuesArgs) => {
    let columnBcWithKey;
    const unit = getInfoValue(column.fieldSettings, "unit", { storage });

    try {
        // try to use valuesBindingContext, which contains keys => can be used to access value with getValue
        columnBcWithKey = valuesBindingContext.navigate(column.id);
    } catch {
        // fallback for "hierarchical" SmartTables, e.g. FiscalYears with Periods/Name columns
        // which results in wrong navigation
        columnBcWithKey = column.bindingContext;
    }

    // for line items in read only mode, we need to format values in smart table as we would format select...
    if (isSelectBasedComponent(column.type)) {
        // const value = getBoundValue({
        //     bindingContext: column.bindingContext.isNavigation() ? column.bindingContext.navigate(column.bindingContext.getKeyPropertyName()) : column.bindingContext,
        //     data: values,
        //     dataBindingContext: valuesBindingContext
        // });
        // const selectValue = getSelectDisplayValueForOneItem(value, {
        //     storage: storage,
        //     info: column,
        //     fieldBindingContext: column.bindingContext
        // });

        // for line items, we need to add key for each
        // local context can be used => isCollection is not good enough check
        const isCollection = valuesBindingContext.isCollection() || Array.isArray(storage.getValue(valuesBindingContext));
        const baseBc = isCollection && !valuesBindingContext.getKey() ? valuesBindingContext.addKey(values) : valuesBindingContext;
        const columnBc = baseBc.navigate(column.id);
        const bc = columnBc.isNavigation() ? columnBc.navigate(columnBc.getKeyPropertyName()) : columnBc;

        const selectValue = getSelectDisplayValue({
            storage,
            info: column,
            fieldBindingContext: bc,
            processMultiValue: true
        });

        if (selectValue) {
            return selectValue;
        }
    }

    const bindingContext = columnBcWithKey.isNavigation() ? columnBcWithKey.navigate(column.fieldSettings?.displayName ?? columnBcWithKey.getKeyPropertyName()) : columnBcWithKey;

    const value = getBoundValue({
        bindingContext: bindingContext,
        data: values,
        dataBindingContext: valuesBindingContext
    });

    const placeholder = getInfoValue(column.fieldSettings, "placeholder", { storage, bindingContext }) ?? "";

    return formatValue(value, column, {
        entity: values,
        item: values,
        readonly: true,
        info: column,
        unit,
        bindingContext: columnBcWithKey,
        placeholder,
        storage
    });
};

export const getFormattedValues = ({
                                       columns,
                                       values,
                                       valuesBindingContext,
                                       storage
                                   }: IFormatValuesArgs & { columns: IFieldInfo[] }) => {
    const formattedValues: IEntity = {};

    columns.forEach(column => {
        formattedValues[column.id] = getFormattedValueFromValues({
            column, values, valuesBindingContext, storage
        });
    });

    return formattedValues;
};

export const getLoadingValues = (columns: IFieldInfo[]) => {
    return columns.reduce((values: IRowValues, column) => {
        values[column.id] = "loading";
        return values;
    }, {});
};

/** Get one row by id */
export const getRow = (rows: Record<string, IRow>, id: TId): IRow => {
    return rows[id.toString()];
};

/** Get one row by id */
export const getRowFromArray = (rows: IRow[], id: TId): IRow => {
    for (const row of rows) {
        // row can be undefined - not yet loaded row
        if (!row) {
            continue;
        }

        if (row.id.toString() === id.toString()) {
            return row;
        }

        if (row.rows) {
            const foundRow = getRowFromArray(row.rows, id);

            if (foundRow) {
                return foundRow;
            }
        }
    }

    return null;
};

/** rows is the source of truth for the rows data
 * => use the rowsOrder to retrieve the row data for each row.
 * recursively, to ensure that even nested rows are in correct state. */
export const getRowsArrayFromRows = (rows: Record<string, IRow>, rowsOrder: string[]): IRow[] => {
    return rowsOrder.map(key => {
        const row = rows[key];

        if (!row) {
            return null;
        }

        if (row.rows) {
            row.rows = getRowsArrayFromRows(rows, row.rows.map(r => r.id.toString()));
        }

        return row;
    });
};
/** Update one row by id */
export const updateRow = (rows: Record<string, IRow>, id: TId, updateFn: (row: IRow) => IRow, preventUpwardsPropagation?: boolean): Record<string, IRow> => {
    const stringId = id.toString();
    const newRow = updateFn(rows[stringId]);

    const updatedRows = {
        ...rows,
        [stringId]: newRow
    };

    // keep references in sync
    if (!preventUpwardsPropagation && newRow.customData?.parent) {
        let currentRow = newRow;
        let parent = newRow.customData.parent;

        while (parent) {
            const parentId = parent.id.toString();

            updatedRows[parentId] = {
                // use the current row, not the parent object
                ...rows[parentId],
                rows: parent.rows.map((r: IRow) => {
                    if (r?.id?.toString() !== currentRow.id.toString()) {
                        return r;
                    } else {
                        return currentRow;
                    }
                })
            };

            currentRow = updatedRows[parentId];
            parent = updatedRows[parentId].customData?.parent;
        }
    }

    return updatedRows;
};


/** Update one row by id */
export const updateRowInArray = (rows: IRow[], id: TId, updateFn: (row: IRow, parents: IRow[]) => IRow, parents: IRow[] = []): IRow[] => {
    return rows.map(row => {
        // row can be undefined - not yet loaded row
        if (!row) {
            return row;
        }

        let newRow: IRow;
        if (row?.id.toString() === id.toString()) {
            newRow = updateFn(row, parents);
        } else {
            newRow = row;
            if (row.rows) {
                newRow = {
                    ...row,
                    rows: updateRowInArray(row.rows, id, updateFn, [...parents, row])
                };
            }
        }

        return newRow;
    });
};


interface IUpdateRowsOptions {
    level?: number;
    parents?: IRow[];
}

interface IUpdateRowsTwo {
    rows: Record<string, IRow>;
    rowsOrder: string[];
    updateFn: (row: IRow, updateFnOptions: IUpdateRowsOptions) => IRow;
    options?: IUpdateRowsOptions;
}

export const updateRows = (args: IUpdateRowsTwo): Record<string, IRow> => {
    const level = args.options?.level ?? 0;

    // only create new object for first level, that's enough
    const updatedRows = level === 0 ? { ...args.rows } : args.rows;

    for (const rowId of args.rowsOrder) {
        const row = args.rows[rowId];

        updatedRows[rowId] = {
            ...row,
            ...args.updateFn(row, { level })
        };

        if (row.rows) {
            updateRows({
                rows: updatedRows,
                rowsOrder: row.rows.map(r => r.id.toString()),
                updateFn: args.updateFn,
                options: {
                    level: level + 1
                }
            });

            // update with new references, from the current ones in updatedRows
            for (let i = 0; i < row.rows.length; i++) {
                row.rows[i] = updatedRows[row.rows[i].id.toString()];
            }
        }

    }

    return updatedRows;
};

/** Iterate through all the rows and call callbackFn for each one of them.
 * NOT MEANT TO BE USED FOR UPDATING THE ROWS. Just to read from them. */
export const iterateOverRows = (
    rows: IRow[],
    callbackFn: (row: IRow, updateFnOptions: IUpdateRowsOptions) => IRow,
    { level }: IUpdateRowsOptions = { level: 0 }
) => {

    for (const row of rows) {
        // row can be undefined - not yet loaded row
        if (!row) {
            continue;
        }

        callbackFn(row, { level: level });

        if (row.rows) {
            iterateOverRows(row.rows, callbackFn, { level: level + 1 });
        }
    }
};


/** Iterate through all the rows and call updateFn for each one of them.
 * Returns the updated array with new rows.
 * Only use for array of rows, e.g. in rowsFactory */

export const updateRowsArray = (
    rows: IRow[],
    updateFn: (row: IRow, updateFnOptions: IUpdateRowsOptions) => IRow,
    { level }: IUpdateRowsOptions = { level: 0 }
): IRow[] => {
    return rows.map(row => {
        // row can be undefined - not yet loaded row
        if (!row) {
            return row;
        }

        let newRow = updateFn(row, { level: level });

        if (newRow.rows) {
            newRow = {
                ...newRow,
                rows: updateRowsArray(newRow.rows, updateFn, { level: level + 1 })
            };
        }

        return newRow;
    });
};

/** Table rows can be nested. This method will return all rows (even nested) in one array. */
export const getAllRows = (rows: IRow[], allRows: IRow[] = []): IRow[] => {
    for (const row of rows) {
        allRows.push(row);

        if (row?.rows?.length > 0) {
            getAllRows(row.rows, allRows);
        }
    }


    return allRows;
};

/** Traverse all levels of children and return only those that apply to testFn*/
export const filterChildRowsBy = (row: IRow, testFn: (row: IRow) => boolean): IRow[] => {
    const filteredChildRows: IRow[] = [];

    iterateOverRows(row.rows, (childRow: IRow) => {
        if (testFn(childRow)) {
            filteredChildRows.push(childRow);
        }

        return childRow;
    });

    return filteredChildRows;
};

export const getToggleState = (rows: IRow[], isRowToggled: (row: IRow) => boolean, isRowDisabled?: (row: IRow) => boolean, areAllRowsLoaded?: boolean): ToggleState => {
    let allToggled = true;
    let allUntoggled = true;

    let allDisabled = true;

    iterateOverRows(rows, (row: IRow) => {
        const rowToggled = isRowToggled(row);
        const rowDisabled = isRowDisabled ? isRowDisabled(row) : row.isDisabled;

        allDisabled = allDisabled && rowDisabled;
        allToggled = allToggled && (rowToggled || rowDisabled);
        allUntoggled = allUntoggled && (!rowToggled || rowDisabled);

        return row;
    });

    if (allDisabled) {
        return areAllRowsLoaded ? ToggleState.Disabled : ToggleState.AllUnchecked;
    }
    if (allToggled) {
        return ToggleState.AllChecked;
    } else if (allUntoggled) {
        return ToggleState.AllUnchecked;
    } else {
        return ToggleState.Other;
    }
};

export const getGroupToggleState = (rows: IRow[], { ignoreCount = false, rowCount = null } = {}): GroupStatus => {
    if (!ignoreCount && rowCount !== rows.length) {
        return GroupStatus.Unknown;
    }

    let allExpanded = true;
    let hasExpandableRow = false;

    iterateOverRows(rows, (row: IRow) => {
        if (row.rows?.length > 0) {
            hasExpandableRow = true;
            allExpanded = allExpanded && row.open;
        }

        return row;
    });

    if (!hasExpandableRow) {
        return GroupStatus.Unknown;
    }

    return allExpanded ? GroupStatus.Expanded : GroupStatus.Collapsed;
};

export const saveGroupRowsState = (rows: IRow[], tableId: string, storage: TableStorage): void => {
    let flattenRows = [...rows];
    const addChildRows = (rows: IRow[]) => {
        for (const row of rows) {
            if (row.rows?.length) {
                flattenRows = [...flattenRows, ...row.rows];
                addChildRows(row.rows);
            }
        }
    };
    addChildRows(rows);
    const openedRowsIds: Record<string, boolean> = {};
    flattenRows.filter(row => row.rows).forEach(row => openedRowsIds[row.id.toString()] = !!row.open);
    // get unique id for each table or each table distinct by parent key, eg. ChartOfAccounts
    LocalSettings.set(getTableKey(storage, tableId), { openedRowsIds });
};

export const getTableKey = (storage: TableStorage, tableId: string): string => {
    const parent = storage?.data.bindingContext.getParent();
    return parent ? `${tableId}_${parent.getKey()}` : tableId;
};

export function smartStrongTextFormatter(value: TValue, options: IFormatOptions): TCellValue {
    if (!value) {
        return null;
    }
    const info = { ...options.info };
    delete info.formatter;
    // formattedValue without formatter, (e.g. date is correctly formatted, we make it just bold)
    const formattedValue = formatValue(value, info, options);
    return strongTextFormatter(formattedValue);
}