import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { createPath } from "@odata/BindingContext";
import {
    IInvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationSettingsEntity
} from "@odata/GeneratedEntityTypes";
import { IntegrationApiImportedDocumentsCode } from "@odata/GeneratedEnums";
import { IIntegrationsCustomData } from "@pages/integrations/Integration.types";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IDialogFormViewProps } from "../../views/formView/DialogFormView";
import { FormViewForExtend } from "../../views/formView/FormView";


class IntegrationSettingsFormView extends FormViewForExtend<IInvoiceServiceIntegrationEntity, IDialogFormViewProps<IInvoiceServiceIntegrationEntity, IIntegrationsCustomData>> {
    static defaultProps = {
        hideBusyIndicator: true
    };

    onAfterLoad(): Promise<void> {
        const storage = this.props.storage;
        if (!!storage.data.entity?.Settings?.Id) {
            const lblInfo = storage.getInfo(storage.data.bindingContext.navigate(createPath(InvoiceServiceIntegrationEntity.Settings, InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFromWhen)));
            if (lblInfo) {
                lblInfo.label = i18next.t("Integrations:ImportRangeLabelConnected");
            }
        }
        return super.onAfterLoad();
    }

    handleChange(e: ISmartFieldChange) {
        if (e.bindingContext.getPath() === InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFromWhen) {
            this.props.storage.addActiveField(e.bindingContext.getParent().navigate(InvoiceServiceIntegrationSettingsEntity.DateCustom));
        }
        super.handleChange(e);
    }

    onBeforeSave(): IInvoiceServiceIntegrationEntity {
        const entity = cloneDeep(this.entity);
        entity.Settings.IntegrationApiImportedDocumentsCode = IntegrationApiImportedDocumentsCode.NewAndChanged;
        return entity;
    }
}

export default withPermissionContext(IntegrationSettingsFormView);