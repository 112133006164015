import {
    formatDateToDateString,
    formatDateToDateTimeOffsetString,
    formatTimeOfDayToTimeString
} from "@components/inputs/date/utils";
import { TId } from "@components/table";
import { fetchMetadata, Metadata } from "@evala/odata-metadata/src";
import { isODataError, ODataError } from "@odata/Data.types";
import { getDefaultPostParams } from "@utils/customFetch";
import { isDefined, isNotDefined, isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import { TFetchFn } from "@utils/oneFetch";
import { createUrl, join, joinParameters } from "@utils/url";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { TRecordAny, TRecordString } from "../global.types";
import memoize from "../utils/memoize";
import BindingContext, { IEntity, TEntityKey } from "./BindingContext";
import { getNestedValue, setNestedValue } from "./Data.utils";
import { EntitySetName, IOdataActionParameters, OdataActionName, ODataActionPath } from "./GeneratedEntityTypes";
import { getEnumDisplayValue, getEnumNameSpaceName } from "./GeneratedEnums.utils";
import { ODataQueryResult, parseError, parseQueryResult, parseResponse } from "./ODataParser";

type TRequest = IRequest | IUrl;

export const MAX_URL_LENGTH = 2048;
// header used to inject disabled fields metadata into odata get request response
export const EVALA_METADATA_HEADER = "evala-add-metadata";

class UrlOptions {
    id: string;
    atomicityGroup: string;
    queryParameters: TRecordString;
}

interface IUrl {
    __options: UrlOptions;

    toUrl?: (urlPrefix: string) => string;
}

export function isIUrl(query: TRequest): query is IUrl {
    return (query as IUrl).toUrl !== undefined;
}

export interface IOrderBy {
    propertyName: TId;
    asc: boolean;
}

export interface ICommandArgs {
    headers?: Record<string, string>;
    index?: number;
    // custom string pasted into url
    customUrlSuffix?: string;
    fetchFn?: TFetchFn;
}

export interface IRequest {
    id?: string;
    atomicityGroup?: string;
    url?: string;
    method?: string;
    headers?: Record<string, string>;
    body?: IEntity;
}

/** Converts values to their correct oData representation.
 * Properties are checked against their EntityType so that e.g. Date can be converted to Edm.DateTime. */
export function encodeOdataObject(obj: IEntity, metadata: Metadata, path: string) {
    if (isNotDefined(obj) || typeof obj !== "object") {
        return obj;
    }

    let entityType;

    try {
        entityType = metadata.getTypeForPath(path);
    } catch (e) {
        // ignore fail (e.g. odata@id key)
        // we only want to get entityType for proper paths
    }

    const result: IEntity = {};

    for (let [key, value] of Object.entries(obj)) {
        const newPath = `${path}/${key.replace("@odata.delta", "")}`;
        const property = entityType?.getProperty(key);
        const isNotCollection = !property?.getType()?.isCollection();
        const typeName = property?.getType()?.getName();

        if (isNotCollection && typeName === "Date") {
            value = formatDateToDateString(value);
        } else if (isNotCollection && typeName === "DateTimeOffset") {
            value = formatDateToDateTimeOffsetString(value);
        } else if (isNotCollection && typeName === "TimeOfDay") {
            value = formatTimeOfDayToTimeString(value);
        } else {
            switch (typeof (value)) {
                case "object":
                    if (Array.isArray(value)) {
                        value = value.map(val => {
                            return encodeOdataObject(val, metadata, newPath);
                        });
                    } else if (!(value instanceof Date)) {
                        value = encodeOdataObject(value, metadata, newPath);
                    }
                    break;
                case "number":
                    value = String(value);
                    break;
            }
        }

        result[key] = value;
    }
    return result;
}

export class Query implements IUrl {
    _path: string;
    _action: string;
    _aggregate: string;
    _top: number;
    _count: boolean;
    _skip: number;
    _orderBy: IOrderBy[];
    _select: string[];
    _filter: string;
    _groupBy: string[];
    _expand: Record<string, ExpandQueryBuilder>;
    __options: UrlOptions;

    _oData: OData;

    constructor(path: string, oData?: OData, action?: string) {
        this._path = path;
        this._action = action;
        this.__options = new UrlOptions();

        this._oData = oData;
    }

    queryParameters(parameters: TRecordString) {
        this.__options.queryParameters = parameters;
        return this;
    }

    top(count: number) {
        this._top = count;
        return this;
    }

    skip(count: number) {
        this._skip = count;
        return this;
    }

    orderBy(propertyName: TId, asc = true) {
        if (!this._orderBy) {
            this._orderBy = [];
        }
        this._orderBy.push({
            propertyName: propertyName,
            asc: asc
        });
        return this;
    }

    aggregate(aggregation: string) {
        this._aggregate = aggregation;
        return this;
    }

    count() {
        this._count = true;
        return this;
    }

    select(...propertyNames: string[]) {
        if (!this._select) {
            this._select = [];
        }
        for (const nameOrArray of propertyNames) {
            if (Array.isArray(nameOrArray)) {
                for (const name of nameOrArray) {
                    if (!this._select.includes(name)) {
                        this._select.push(name);
                    }
                }
            } else {
                if (!this._select.includes(nameOrArray)) {
                    this._select.push(nameOrArray);
                }
            }
        }
        return this;
    }

    filter(query: string) {
        this._filter = query;
        return this;
    }

    // ToDo: There is currently just two use cases,
    //  1. in value help to select with apply and groupBy, so let's keep it simple,
    //  2. to get counts in filter tabs over table in documents
    //  but we may consider to support further use cases later.
    groupBy(...propertyNames: string[]) {
        if (!this._groupBy) {
            this._groupBy = [];
        }
        for (const nameOrArray of propertyNames) {
            if (Array.isArray(nameOrArray)) {
                for (const name of nameOrArray) {
                    this._groupBy.push(name);
                }
            } else {
                this._groupBy.push(nameOrArray);
            }
        }
        return this;
    }

    expand(expandProperty: string, callback?: (query: ExpandQueryBuilder) => void) {
        if (!this._expand) {
            this._expand = {};
        }
        if (!this._expand[expandProperty]) {
            this._expand[expandProperty] = new ExpandQueryBuilder(expandProperty, this._getPath(), this._oData);
        }
        if (callback) {
            callback(this._expand[expandProperty]);
        }
        return this;
    }

    _getPath() {
        return this._path;
    }

    /** Check whether we can use select instead of expand to improve backend performance.
     * Only enum expands that don't use other columns than Code and Name are valid.*/
    _isEnumOnlyExpand = (expand: ExpandQueryBuilder) => {
        const expandParentEntityType = this._oData.getMetadata().getTypeForPath(expand._fullPath.split("/").slice(0, -1).join("/"));
        const isExpandCollection = expandParentEntityType.getProperty(expand._path).getType().isCollection();

        if (isExpandCollection) {
            return false;
        }

        const hasChildExpand = expand._expand && Object.keys(expand._expand).length > 0;
        if (hasChildExpand) {
            return false;
        }

        const entityType = this._oData.getMetadata().getTypeForPath(expand._fullPath);
        const allowedColumns = ["Code", "Name"];
        // we can only apply !expand._select rule if the entity has only Code and Name properties,
        // some enums contains more than those two values and caller can expect to get all properties even without specifying "select"
        const noExtraColumnsSelected = (!expand._select && Object.keys(entityType.getProperties()).length === 2)
            || expand._select?.every(select => allowedColumns.includes(select));

        return entityType.getKeys()[0]?.getName() === BindingContext.ENUM_KEY_PROP && noExtraColumnsSelected;
    };

    _getEnumNameSpace = memoize((expandFullPath: string) => {
        const entityType = this._oData.getMetadata().getTypeForPath(expandFullPath);

        return getEnumNameSpaceName(entityType.getName());
    });

    // returns full paths of every expand property
    _getExpandToSelectProperties = (): string[] => {
        if (!this._expand) {
            return [];
        }

        const params: string[] = [];

        for (const expand of Object.values(this._expand)) {
            if (this._isEnumOnlyExpand(expand)) {
                params.push(expand._fullPath);
            }
        }

        return params;
    };

    /** Enhanced results with navigation objects for enum properties that were previously converted from expand to select */
    _enhanceValueWithEnumExpands = async (value: IEntity | IEntity[]): Promise<void[]> => {
        // use loop with array of promises instead of recursive function with await on each level
        // to  improve the functions performance
        // todo still kinda slow, could be better
        const allPromises: Promise<void>[] = [];
        const expandsToProcess: {
            values: IEntity | IEntity[];
            expandQueryBuilder: ExpandQueryBuilder;
        }[] = [{
            values: value,
            expandQueryBuilder: this as unknown as ExpandQueryBuilder
        }];


        while (expandsToProcess.length > 0) {
            const { values: tmpVal, expandQueryBuilder } = expandsToProcess.pop();
            const values = Array.isArray(tmpVal) ? tmpVal : [tmpVal];

            if (!expandQueryBuilder._expand || Object.values(expandQueryBuilder._expand).length === 0) {
                continue;
            }

            const expandToSelectProps = expandQueryBuilder._getExpandToSelectProperties();
            const nameSpaces = expandToSelectProps.map(expandQueryBuilder._getEnumNameSpace);

            for (const row of values) {
                if (isNotDefined(value)) {
                    continue;
                }

                for (const expand of Object.values(expandQueryBuilder._expand)) {
                    if (expandToSelectProps.includes(expand._fullPath)) {
                        const entityType = expandQueryBuilder._oData.getMetadata().getTypeForPath(expand._fullPath);
                        const enumName = entityType.getName();
                        const code = getNestedValue(`${expand._path}Code`, row);

                        if (isDefined(code)) {
                            const setValueFn = () => {
                                setNestedValue({
                                    Code: code,
                                    Name: getEnumDisplayValue(enumName, code)
                                }, expand._path, row);
                            };

                            if (nameSpaces.some(namespace => !i18next.hasLoadedNamespace(namespace as string))) {
                                const promise = i18next.loadNamespaces(nameSpaces as string[]).then(setValueFn);

                                allPromises.push(promise);
                            } else {
                                setValueFn();
                            }
                        }
                    } else {
                        // enhance nested items
                        if (row[expand._path]) {
                            expandsToProcess.push({
                                values: row[expand._path],
                                expandQueryBuilder: expand
                            });
                        }
                    }
                }
            }
        }

        return await Promise.all(allPromises);
    };

    _applyExpandParams = (params: TRecordAny): void => {
        const expandToSelectProps = this._getExpandToSelectProperties();

        for (const expand of Object.values(this._expand)) {
            if (expandToSelectProps.includes(expand._fullPath)) {
                const selectProperty = `${expand._path}Code`;

                // only add code to $select if theres already some other value
                // otherwise, caller could expect all the properties to be returned
                if (params["$select"]) {
                    params["$select"] = `${params["$select"]},${selectProperty}`;
                }
            } else {
                if (!params["$expand"]) {
                    params["$expand"] = [];
                }

                params["$expand"].push(expand.toUrl());
            }
        }
    };

    _getParams() {
        const params: TRecordAny = {};
        // should accept 0 as well
        if (this._top !== undefined) {
            params["$top"] = this._top;
        }
        if (this._skip !== undefined) {
            params["$skip"] = this._skip;
        }
        if (this._orderBy) {
            params["$orderBy"] = this._orderBy.map(o => o.propertyName + " " + (o.asc ? "asc" : "desc")).join(", ");
        }
        if (this._count) {
            params["$count"] = this._count;
        }
        if (this._select) {
            params["$select"] = this._select.join(",");
        }
        if (this._groupBy || this._aggregate) {
            const prefix = this._filter ? `filter(${this._filter})/` : "";
            if (this._groupBy) {
                params["$apply"] = `${prefix}groupby((${this._groupBy.join(",")})${this._aggregate ? `,aggregate(${this._aggregate})` : ""})`;
            } else {
                params["$apply"] = `${prefix}aggregate(${this._aggregate})`;
            }
        } else if (this._filter) {
            params["$filter"] = this._filter;
        }
        if (this._expand) {
            this._applyExpandParams(params);
        }
        if (this.__options.queryParameters) {
            for (const [key, val] of Object.entries(this.__options.queryParameters)) {
                params[key] = val;
            }
        }
        return params;
    }

    toUrl(urlPrefix: string, withParams = true): string {
        const url = join(...[urlPrefix, this._path, this._action].filter(i => !!i));

        const params = withParams ? this._getParams() : {};
        return createUrl(url, params);
    }

    getId(): string {
        return this.__options?.id;
    }
}

export class ExpandQueryBuilder extends Query {
    _fullPath: string;

    constructor(path: string, parentPath: string, oData?: OData) {
        super(path, oData);

        // keep full path for enum expansion workaround
        // we need to be able to tell if the expand property isEnum or not
        this._fullPath = `${parentPath}/${path}`;
    }

    _getPath(): string {
        return this._fullPath;
    }

    toUrl(): string {
        let queryString = joinParameters(this._getParams(), ";", false);
        if (queryString) {
            queryString = `(${queryString})`;
        }
        return `${this._path}${queryString}`;
    }
}

export class ODataQueryBuilder extends Query {

    clone(): ODataQueryBuilder {
        const cloned = new ODataQueryBuilder(this._path, this._oData, this._action);
        for (const key in this) {
            if (this.hasOwnProperty(key) && !["_oData", "_path", "_action"].includes(key) && !(typeof this[key] === "function")) {
                // @ts-ignore
                cloned[key] = cloneDeep(this[key]);
            }
        }
        return cloned;
    }

    async fetchData<T = never>(fetchFn?: TFetchFn, options: RequestInit = {}, body?: TRecordAny): Promise<ODataQueryResult<T>> {
        let url = this.toUrl(this._oData.getMetadata().getUrl(), false);
        const params = this._getParams();

        if (url.length > MAX_URL_LENGTH) {
            // there is maximum limit of URL length which can be processed on IIS server. In that cases, we built
            // the request using $query operator and put parameters to request body. We want to keep original approach
            // for better readability of requests for debugging purposes, when it's not needed

            // we want queryParams to be part of url, not the body
            const queryParams: TRecordString = {};
            const oDataParams: TRecordString = {};

            for (const [key, value] of Object.entries(params)) {
                if (key.startsWith("$")) {
                    oDataParams[key] = value;
                } else {
                    queryParams[key] = value;
                }
            }

            url += createUrl("/$query", queryParams);
            options = {
                ...options,
                method: "POST",
                headers: {
                    ...options.headers,
                    "Content-Type": "text/plain"
                },
                body: joinParameters(oDataParams)
            };
        } else {
            url = createUrl(url, params);
        }

        if (body || this._action) {
            options = {
                ...getDefaultPostParams(),
                ...(options ?? {}),
                body: JSON.stringify(encodeOdataObject(body ?? {}, this._oData.getMetadata(), this._path))
            };
        }

        const fetchFunction = fetchFn ?? fetch;
        let response;
        if (!isObjectEmpty(options)) {
            response = await fetchFunction(url, options);
        } else {
            response = await fetchFunction(url);
        }
        // TODO error handling, fails when tries to load InvoicesReceived(non existing number)

        if (response.status === 404) {
            logger.warn(`fetchData ${url} returned 404`);
            return null;
        } else {
            const contentType = response.headers?.get("Content-Type");
            const isJSON = contentType?.includes("application/json");
            if (isJSON) {
                let data;
                try {
                    data = await response.json();
                } catch (e) {
                    logger.error("Error parsing JSON response", e);
                    data = {};
                }

                if (response.ok) {
                    const queryResult = parseQueryResult<T>(data, this._oData.getMetadata(), this._getPath());

                    await this._enhanceValueWithEnumExpands(queryResult.value);

                    return queryResult;
                } else {
                    throw parseError(data);
                }
            } else {
                return { value: null };
            }
        }
    }
}

class PathBuilder implements IUrl {
    _path: string;
    _suffix: string;
    _key: TEntityKey;
    __options: UrlOptions;

    constructor(path: string, suffix?: string) {
        this._path = path;
        this._suffix = suffix;
        this.__options = new UrlOptions();
    }

    /**
     *
     * @param {Object} key
     * @returns {PathBuilder}
     */
    get(key: TEntityKey) {
        this._key = key;
        return this;
    }

    navigate(navigation: string) {
        const url = join(this.toUrl(), navigation);
        return new PathBuilder(url);
    }

    toUrl(urlPrefix?: string) {
        let result = this._path;
        if (urlPrefix) {
            result = join(urlPrefix, result);
        }
        if (this._key !== undefined) {
            const keys = this._key.toString();

            if (keys.startsWith("$")) {
                // relative path, remove last segment as it's saved in the $prop
                const lastSegmentIndex = result.lastIndexOf("/");
                result = result.substring(0, lastSegmentIndex + 1) + keys;
            } else {
                result += "(" + keys + ")";
            }
        }
        if (this._suffix) {
            result += "/" + this._suffix;
        }
        if (this.__options.queryParameters) {
            result = createUrl(result, this.__options.queryParameters);
        }

        return result;
    }
}

export abstract class Wrapper<CommandRetVal> {
    path: string;
    metadata: Metadata;
    qparams: TRecordString;

    protected constructor(path: string, metadata: Metadata) {
        this.path = path;
        this.metadata = metadata;
    }

    // // TODO for me the naming cause confusion
    // // what is the exact difference between query (which accepts key) and key (which is something like get?)
    // key(key: TEntityKey) {
    //     let pathBuilder = this._getPathBuilder(key);
    //     let type = this.metadata.getTypeForPath(this.path);
    //     let navigationProperties = type.getNavigationProperties();
    //     let result: Record<string, NestedEntityWrapper> = {};
    //     for (let navProp of navigationProperties) {
    //         result[navProp.getName()] = new NestedEntityWrapper(pathBuilder.navigate(navProp.getName()).toUrl(), this);
    //     }
    //     return result;
    // }

    abstract query(a?: TEntityKey, ...args: any): Query

    abstract addKey(key: TEntityKey): Wrapper<CommandRetVal>

    abstract navigate(navigationProperty: string): Wrapper<CommandRetVal>

    abstract _handleCommand(method: string, command: TRequest, ...args: any): CommandRetVal

    _getPathBuilder(keys: TEntityKey, action?: string): PathBuilder {
        const builder = new PathBuilder(this.path, action);
        if (keys) {
            builder.get(keys);
        }
        return builder;
    }

    queryParameters(parameters: TRecordString) {
        this.qparams = parameters;
        return this;
    }

    getQueryParameters(): TRecordString {
        return this.qparams;
    }

    getUrl(keys?: TEntityKey, action?: string): string {
        return this._getPathBuilder(keys, action).toUrl(this.metadata.getUrl());
    }

    get(key?: string | number, args: ICommandArgs = {}): IEntity {
        const request: IRequest = {
            url: this.getUrl(key),
            method: "GET",
            headers: {
                // bug on backend, headers (even empty) object is needed on all batch call
            }
        };
        return this._handleCommand("get", request, args) as IEntity;
    }

    create(values: object, args: ICommandArgs = {}) {
        const request: IRequest = {
            url: this.getUrl(),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...args.headers
            },
            body: values
        };
        return this._handleCommand("create", request, args);
    }

    delete(keys: TEntityKey, args: ICommandArgs = {}) {
        const request: IRequest = {
            url: this.getUrl(keys),
            // bug on backend, headers (even empty) object is needed on all batch call
            headers: {
                ...args.headers
            },
            method: "DELETE"
        };

        if (args.customUrlSuffix) {
            // used to delete a reference to an entity in collection
            request.url += args.customUrlSuffix;
        }

        return this._handleCommand("delete", request, args);
    }

    update<E = IEntity>(keys: TEntityKey, values: Partial<E>, args: ICommandArgs = {}) {
        const request: IRequest = {
            url: this.getUrl(keys),
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                ...args.headers
            },
            body: values
        };
        return this._handleCommand("update", request, args);
    }

    /**
     * Actions are additional operations to the basic CRUD operations. Could be more complex,
     * feel free to enhance this method to support more method types, expanded content
     * or parsing JSON responses, when needed
     * @param action
     * @param keys
     * @param values
     * @param args
     */
    action<A extends OdataActionName>(action: A, keys?: TEntityKey, values?: IOdataActionParameters[A], args: ICommandArgs = {}) {
        const actionPath = ODataActionPath[action] ?? action;
        const request: IRequest = {
            url: this.getUrl(keys, actionPath),
            method: "POST",
            headers: {
                ...args.headers
            },
            body: values ?? {}
        };
        return this._handleCommand("action", request, args);
    }

    /**
     * Fixes a bug in OData library which internally handles batch request as if
     * the type was IEEE754Compatible JSON.
     * @param {} command
     */
    _encodeCommand(command: IRequest): void {
        if (command.body) {
            command.headers["Content-Type"] = "application/json;IEEE754Compatible=true";
            command.body = encodeOdataObject(command.body, this.metadata, this.path);
        }
    }
}

type THandleCommandResponse = Promise<boolean | ODataQueryResult | void>;

export class EntitySetWrapper extends Wrapper<THandleCommandResponse> {
    oData: OData;

    constructor(entitySetName: string, oData: OData) {
        super(entitySetName, oData.getMetadata());
        this.oData = oData;
    }

    addKey(key: TEntityKey) {
        const pathBuilder = this._getPathBuilder(key);

        return new EntitySetWrapper(pathBuilder.toUrl(), this.oData);
    }

    navigate(navigationProperty: string) {
        const pathBuilder = this._getPathBuilder("");
        const type = this.metadata.getTypeForPath(this.path);
        const navigationProperties = type.getNavigationProperties();
        const result: Record<string, EntitySetWrapper> = {};

        for (const navProp of navigationProperties) {
            result[navProp.getName()] = new EntitySetWrapper(pathBuilder.navigate(navProp.getName()).toUrl(), this.oData);
        }

        return result[navigationProperty];
    }

    _fetch(request: IRequest, fetchFn?: TFetchFn) {
        this._encodeCommand(request);

        let { url, body, ...rest } = request;
        const newRequest: RequestInit = rest;

        if (body) {
            newRequest.body = JSON.stringify(body);
        }
        newRequest.redirect = "error";

        const queryParameters = this.getQueryParameters();

        if (queryParameters) {
            url = createUrl(url, queryParameters);
            this.queryParameters(null);
        }

        const fetchFunction = fetchFn ?? fetch;

        return fetchFunction(url, newRequest);
    }

    async _handleCommand(method: string, command: IRequest, args: ICommandArgs = {}): THandleCommandResponse {
        const path = command.url;
        const response = await this._fetch(command, args?.fetchFn);

        if (response.ok) {
            if (method === "create" || method === "get" || method === "action") {
                try {
                    const data = await response.json();
                    return parseQueryResult(data, this.metadata, path);
                } catch (e) {
                    if (method === "action") {
                        // action method might not have response body, just return true in that case...
                        return true;
                    }
                    // error in parsing create or get method
                    throw e;
                }
            } else {
                return true;
            }
        } else {
            let data;
            try {
                data = await response.json();
            } catch (e) {
                data = {};
            }
            throw parseError(data);
        }
    }

    query(key?: TEntityKey, action?: string): ODataQueryBuilder {
        const query = new ODataQueryBuilder(this._getPathBuilder(key).toUrl(), this.oData, action);
        const queryParameters = this.getQueryParameters();
        if (queryParameters) {
            query.queryParameters(queryParameters);
            this.queryParameters(null);
        }
        return query;
    }
}

export class BatchEntitySetWrapper extends Wrapper<string> {
    _batchRequest: BatchRequest;

    constructor(batchRequest: BatchRequest, entitySetName: string) {
        super(entitySetName, batchRequest.oData.getMetadata());
        this._batchRequest = batchRequest;
    }

    addKey(key: TEntityKey) {
        const pathBuilder = this._getPathBuilder(key);

        return new BatchEntitySetWrapper(this._batchRequest, pathBuilder.toUrl());
    }

    navigate(navigationProperty: string) {
        const pathBuilder = this._getPathBuilder("");
        const type = this._batchRequest.oData.metadata.getTypeForPath(this.path);
        const navigationProperties = type.getNavigationProperties();
        const result: Record<string, BatchEntitySetWrapper> = {};

        for (const navProp of navigationProperties) {
            result[navProp.getName()] = new BatchEntitySetWrapper(this._batchRequest, pathBuilder.navigate(navProp.getName()).toUrl());
        }

        return result[navigationProperty];
    }

    batchId(id: string) {
        this._batchRequest.setNextId(id);
        return this;
    }

    query(key?: string, args: ICommandArgs = {}) {
        const query = new Query(this._getPathBuilder(key).toUrl(), this._batchRequest.oData);

        const queryParameters = this.getQueryParameters();
        if (queryParameters) {
            query.queryParameters(queryParameters);
        }

        this._batchRequest._addRequest(query, args.index);
        return query;
    }

    get(key?: string | number, args: ICommandArgs = {}) {
        return super.get(key, args);
    }

    create(values: object, args: ICommandArgs = {}) {
        return super.create(values, args);
    }

    delete(keys: TEntityKey, args: ICommandArgs = {}) {
        return super.delete(keys, args);
    }

    update(keys: TEntityKey, values: object, args: ICommandArgs = {}) {
        return super.update(keys, values, args);
    }

    // returns batch id of the created request
    _handleCommand(method: string, command: IRequest, args: ICommandArgs = {}): string {
        this._encodeCommand(command);

        const queryParams = this.getQueryParameters();

        if (queryParams) {
            command.url = createUrl(command.url, queryParams);
            this.queryParameters(null);
        }
        return this._batchRequest._addRequest(command, args.index);
    }
}

export interface IOriginalBatchResult {
    id: string;
    status: number;
    headers: HeadersInit;
    body: unknown;
}

export interface IOriginalBatchBody {
    responses: IOriginalBatchResult[];
}

export interface IBatchResult<T = any> {
    id: string;
    status: number;
    headers: Headers;
    body: T;
}

export function isBatchResultOk(batchResult: IBatchResult<ODataQueryResult | ODataError>): batchResult is IBatchResult<ODataQueryResult> {
    return batchResult.status < 300;
}

type TBatchResult<T = unknown> = IBatchResult<ODataQueryResult<T> | ODataError>;

function parseBatchResult(data: any, metadata: Metadata, paths: string[]): IBatchResult<ODataQueryResult | ODataError>[] {
    if (data.responses) {
        return data.responses.map((result: IBatchResult, i: number) => {
            const res = {
                id: result.id,
                status: result.status,
                headers: new Headers(result.headers),
                body: undefined as object
            };
            if (result.status < 300 && result.body) {
                res.body = parseQueryResult(result.body, metadata, paths[i]);
            } else if (result.body && result.body.error) {
                res.body = parseError(result.body);
            }
            return res;
        });
    } else {
        return data;
    }
}

abstract class EntitySetsWrappersHolder {
    abstract getEntitySetWrapper(entitySetName: string): Wrapper<any>

    fromPath(path: string, metadata: Metadata) {
        const { entitySet, entityKey, navigation } = metadata.getLastValidEntitySet(path);
        const entityRegex = /^(?<name>\w+)(\((?<key>[^)]+)\))?$/;
        const parts = navigation.split("/");
        let entitySetWrapper = this.getEntitySetWrapper(entitySet.getName());

        if (entityKey) {
            entitySetWrapper = entitySetWrapper.addKey(entityKey);
        }

        for (const part of parts) {
            if (!part) {
                continue;
            }

            const match = part.match(entityRegex);

            if (!match) {
                throw new Error(`Unexpected format of first part of path: ${part}`);
            }

            const entityName = match.groups.name;
            const entityKey = match.groups.key;

            entitySetWrapper = entitySetWrapper.navigate(entityName);

            if (!entitySetWrapper) {
                throw new Error(`Entity set or navigation ${entityName} not found.`);
            }

            if (entityKey) {
                entitySetWrapper = entitySetWrapper.addKey(entityKey);
            }
        }

        return entitySetWrapper;
    }
}

export class BatchRequest extends EntitySetsWrappersHolder {
    oData: OData;
    requests: TRequest[];
    promises: Record<string, {
        promise: Promise<TBatchResult>,
        resolve: (batchResult: TBatchResult) => void
    }>;
    abortController: AbortController;
    nextId: string;
    currentAtomicityGroup: string;
    _entitySets: Partial<Record<EntitySetName, BatchEntitySetWrapper>> = {};

    constructor(oData: OData) {
        super();

        this.oData = oData;
        this.requests = [];
        this.promises = {};
        this.nextId = "0";
        this.currentAtomicityGroup = null;
        for (const setName of Object.keys(oData.getMetadata().entitySets)) {
            this._entitySets[setName as EntitySetName] = new BatchEntitySetWrapper(this, setName);
        }
    }

    isEmpty() {
        return this.requests.length === 0;
    }

    getRequests() {
        return this.requests;
    }

    fromPath(path: string): BatchEntitySetWrapper {
        return super.fromPath(path, this.oData.metadata) as BatchEntitySetWrapper;
    }

    getEntitySetWrapper(entitySetName: EntitySetName) {
        return this._entitySets[entitySetName];
    }

    getNextId() {
        return this.nextId;
    }

    setNextId(id: string) {
        this.nextId = id;
    }

    beginAtomicityGroup(name: string) {
        this.currentAtomicityGroup = name;
    }

    _getApiUrl() {
        return this.oData.getMetadata().getUrl();
    }

    _addRequest(data: TRequest, index?: number) {
        const currentId = this.getNextId();

        if (isIUrl(data)) {
            data.__options.id = currentId;
            if (this.currentAtomicityGroup) {
                data.__options.atomicityGroup = this.currentAtomicityGroup;
            }
        } else if (!data.id) {
            data.id = currentId;
            if (this.currentAtomicityGroup) {
                data.atomicityGroup = this.currentAtomicityGroup;
            }
        }

        if (isNotDefined(index)) {
            this.requests.push(data);
        } else {
            this.requests.splice(index, 0, data);
        }

        this.setNextId(String(this.requests.length));

        return currentId;
    }

    _getRequestJson() {
        const urlPrefix = this.oData.getMetadata().getUrl();
        return this.requests.map(obj => {
            if (isIUrl(obj)) {
                const result = {
                    id: obj.__options.id,
                    url: obj.toUrl(urlPrefix),
                    method: "GET",
                    headers: {
                        // bug on backend, headers (even empty) object is needed on all batch call
                    },
                    atomicityGroup: undefined as string
                };
                if (obj.__options.atomicityGroup) {
                    result.atomicityGroup = obj.__options.atomicityGroup;
                }
                return result;
            } else {
                return obj;
            }
        });
    }

    async execute<T extends [...any[]]>(): Promise<TBatchResult<T[0]>[]> {
        const url = join(this._getApiUrl(), "$batch");
        const data = {
            requests: this._getRequestJson()
        };

        this.abortController = new AbortController();

        const response = await fetch(url, {
            method: "POST",
            redirect: "error",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Content-Transfer-Encoding": "binary"
            },
            body: JSON.stringify(data),
            signal: this.abortController.signal
        });
        const body = await parseResponse(response);

        // TODO we should somehow call FetchWithMiddleware after (or even before?) callbacks
        //  for each request in batch, so that we can handle errors in the same way as in FetchWithMiddleware

        if (isODataError(body)) {
            throw body;
        } else {
            const batchResult = parseBatchResult(body, this.oData.getMetadata(), data.requests.map(r => r.url));

            for (let i = 0; i < batchResult.length; i++) {
                const result = batchResult[i];
                const request = this.requests[i];

                if (result.status < 300 && request instanceof Query) {
                    await request._enhanceValueWithEnumExpands((result.body as ODataQueryResult).value);
                }

                if (this.promises[data.requests[i].id]) {
                    this.promises[data.requests[i].id].resolve(result);
                }
            }

            return batchResult;
        }
    }

    abort() {
        this.abortController?.abort();
    }

    /** requests can be added into one Batch from multiple places in code
     * this adds possibility to await one particular request from batch
     * */

    async awaitRequest(requestId: string) {
        if (!this.promises[requestId]) {

            let resolveFn: (batchResult: TBatchResult) => void;
            const promise = new Promise<TBatchResult>((resolve) => {
                resolveFn = resolve;
            });

            this.promises[requestId] = {
                promise,
                resolve: resolveFn
            };

        }

        return this.promises[requestId].promise;
    }
}

class OData extends EntitySetsWrappersHolder {
    metadata: Metadata;
    _entitySets: Record<string, EntitySetWrapper> = {};

    constructor(metadata: Metadata) {
        super();

        this.metadata = metadata;
        for (const setName of Object.keys(metadata.entitySets)) {
            this._entitySets[setName] = new EntitySetWrapper(setName, this);
        }
    }

    getEntitySetWrapper(entitySetName: EntitySetName) {
        return this._entitySets[entitySetName];
    }

    getMetadata() {
        return this.metadata;
    }

    batch() {
        return new BatchRequest(this);
    }

    fromPath(path: string): EntitySetWrapper {
        return super.fromPath(path, this.metadata) as EntitySetWrapper;
    }
}

const repository: Record<string, Metadata> = {};

async function getOData(url: string) {
    if (!url) {
        throw new Error("Url parameter must be non null.");
    }
    if (!repository[url]) {
        repository[url] = await fetchMetadata(url);
    }
    return new OData(repository[url]);
}

export { getOData, OData };