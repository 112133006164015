import React from "react";
import { WithOData, withOData } from "@odata/withOData";
import { WithAuthContext, withAuthContext } from "../authContext/withAuthContext";
import { AppContext, ContextEvents, IAppContext } from "../appContext/AppContext.types";
import {
    CompanyRoleEntity,
    EntitySetName,
    GeneralRoleEntity,
    IUserEntity,
    UserCompanyRoleEntity,
    UserEntity,
    UserGeneralRoleEntity
} from "@odata/GeneratedEntityTypes";
import BusyIndicator from "../../components/busyIndicator";
import { CompanyPermissionCode, GeneralPermissionCode, WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import memoizeOne from "../../utils/memoizeOne";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import { BusyIndicatorSize } from "@components/busyIndicator/BusyIndicator.utils";
import { logger } from "@utils/log";

export const PermissionContext = React.createContext<IPermissionContext>(undefined);

export type TCompanyPermissions = Set<CompanyPermissionCode>;
export type TGeneralPermissions = Set<GeneralPermissionCode>;

export interface IPermissionContext {
    companyPermissions: TCompanyPermissions;
    ownOnlyPermissions: TCompanyPermissions;
    generalPermissions: TGeneralPermissions;
    refreshUserPermissions: () => Promise<void>;
}

type TCompaniesPermissions = Record<number | string, TCompanyPermissions>;
type TOwnOnlyPermissions = Record<number | string, TCompanyPermissions>;

interface IState extends Pick<IPermissionContext, "generalPermissions"> {
    companiesPermissions: TCompaniesPermissions;
    ownOnlyPermissions: TOwnOnlyPermissions;
    isLoaded: boolean;
}

interface IProps extends WithOData, WithAuthContext {
    onPermissionsLoaded: (companyPermissions: Record<string, Set<CompanyPermissionCode>>, generalPermissions: Set<GeneralPermissionCode>) => void;
}

class PermissionContextProvider extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        companiesPermissions: {},
        ownOnlyPermissions: {},
        generalPermissions: new Set(),
        isLoaded: false
    };

    _unsubscribeWebsocket: () => void;
    getCurrentCompanyPermissions = memoizeOne((): Set<CompanyPermissionCode> => {
        return this.state.companiesPermissions[this.context.getCompanyId()] ?? new Set();
    }, () => [this.context.getCompanyId(), this.state.companiesPermissions]);

    getCurrentOwnOnlyPermissions = memoizeOne((): Set<CompanyPermissionCode> => {
        return this.state.ownOnlyPermissions[this.context.getCompanyId()] ?? new Set();
    }, () => [this.context.getCompanyId(), this.state.ownOnlyPermissions]);

    constructor(props: IProps, context: IAppContext) {
        super(props, context);
        context.eventEmitter.on(ContextEvents.CompanyChanged, this.companyChange);

        this.refreshUserPermissions = this.refreshUserPermissions.bind(this);
    }

    componentDidMount() {
        if (this.props.authContext && this.props.oData) {
            this.refreshUserPermissions();
        }

        this._unsubscribeWebsocket = WebsocketManager.subscribe({
            callback: this.handleSessionInvalidated,
            types: [WebSocketMessageTypeCode.SessionInvalidated]
        });
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if ((this.props.authContext && this.props.oData) && (!prevProps.oData || !prevProps.authContext)) {
            this.refreshUserPermissions();
        }
    }

    componentWillUnmount(): void {
        this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.companyChange);
        this._unsubscribeWebsocket?.();
    }

    companyChange = (id: number) => {
        this.refreshUserPermissions(true);
    };

    /** Retrieves and stores all General and Company permissions for current user */
    refreshUserPermissions = async (withoutFetch?: boolean) => {
        if (withoutFetch && this.state.isLoaded) {
            // we don't always need to fetch data again,
            // e.g. when changing company, it unnecessarily delays menu items re-render
            this.props.onPermissionsLoaded(this.state.companiesPermissions, this.state.generalPermissions);

            return;
        }

        const companiesPermissions: TCompaniesPermissions = {};
        const ownOnlyPermissions: TOwnOnlyPermissions = {};
        const generalPermissions: Set<GeneralPermissionCode> = new Set();
        const query = this.props.oData.getEntitySetWrapper(EntitySetName.Users)
            .query(this.props.authContext.userId)
            .expand(UserEntity.GeneralRoles,
                q => q.expand(UserGeneralRoleEntity.GeneralRole,
                    q => q.expand(GeneralRoleEntity.GeneralRolePermissions)))
            .expand(UserEntity.CompanyRoles,
                q => q.expand(UserCompanyRoleEntity.CompanyRole,
                    q => q.expand(CompanyRoleEntity.CompanyRolePermissions))
                    .expand(UserCompanyRoleEntity.Company, q => q.select(UserCompanyRoleEntity.Id))
            );

        // most odata requests will fail for canceled tenant
        // but, we need to try to fetch the new data in case user just restored the tenant, but the new tenant data have not yet been fetched
        try {
            const userData = await query.fetchData<IUserEntity>();
            const user = userData.value;

            for (const role of (user.CompanyRoles || [])) {
                const companyPermissions: Set<CompanyPermissionCode> = new Set();
                const companyOwnOnlyPermissions: Set<CompanyPermissionCode> = new Set();
                const perms = role.CompanyRole.CompanyRolePermissions.filter(p => p.IsEnabled);

                for (const p of perms) {
                    companyPermissions.add(p.CompanyPermissionCode as CompanyPermissionCode);
                    if (p.OwnOnly) {
                        companyOwnOnlyPermissions.add(p.CompanyPermissionCode as CompanyPermissionCode);
                    }
                }

                companiesPermissions[role.Company.Id] = companyPermissions;
                ownOnlyPermissions[role.Company.Id] = companyOwnOnlyPermissions;
            }

            for (const role of (user.GeneralRoles || [])) {
                const perms = role.GeneralRole.GeneralRolePermissions.filter(p => p.IsEnabled);
                for (const p of perms) {
                    generalPermissions.add(p.PermissionCode as GeneralPermissionCode);
                }
            }
        } catch (e) {
            logger.error(e.toString());
        }
        const currentCompanyId = this.context.getCompanyId();
        // check if permissions are correctly loaded, otherwise stay in loading state -> AppContext should handle
        // changing company or redirecting to login / tenant login
        const isLoaded = !currentCompanyId ||
                (currentCompanyId && companiesPermissions[currentCompanyId]?.size > 0);

        this.setState({ companiesPermissions, generalPermissions, ownOnlyPermissions, isLoaded });
        this.props.onPermissionsLoaded(companiesPermissions, generalPermissions);
    };

    getContext = memoizeOne((): IPermissionContext => {
        return {
            companyPermissions: this.getCurrentCompanyPermissions(),
            ownOnlyPermissions: this.getCurrentOwnOnlyPermissions(),
            generalPermissions: this.state.generalPermissions,
            refreshUserPermissions: this.refreshUserPermissions
        };
    }, () => [this.state, this.context.getCompanyId()]);

    /* Fired when backend session changes (not login cookies session, but BE api/auth/session whatever that means)
    * => re-fetch permissions */
    handleSessionInvalidated = () => {
        this.refreshUserPermissions();
    };

    render() {
        // we have to render children to be able to redirect to login
        if (this.props.authContext?.isAuthenticated && !this.state.isLoaded && !(this.context as IAppContext).error) { //
            return <BusyIndicator size={BusyIndicatorSize.L} isDelayed/>;
        }

        return (
            <PermissionContext.Provider value={this.getContext()}>
                {this.props.children}
            </PermissionContext.Provider>
        );
    }
}

export default withAuthContext(withOData(PermissionContextProvider));