import DateType from "../../../types/Date";
import i18next from "i18next";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import { PairTableViewForExtend } from "../PairTableView";
import { isNotDefined } from "@utils/general";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { canRowBePaired, createDefaultPairFilter, getBankTransactionType } from "./BankTransactions.utils";
import { IPairTableViewBaseProps } from "../PairTableViewBase";
import { withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { formatHeaderValue } from "@components/readOnlyList/ReadOnlyList";
import { TId } from "@components/table";
import { BankTransactionTypeCode } from "@odata/GeneratedEnums";

class BankTransactionPairTableView extends PairTableViewForExtend {
    constructor(props: IPairTableViewBaseProps) {
        super(props);

        this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        this.presetDefaultFilters();
    }

    get transactionType() {
        if (isNotDefined(this.props.rootStorage.data.entity.BankTransactionType?.Code)) {
            return BankTransactionTypeCode.Other;
        }

        return this.props.rootStorage.data.entity.BankTransactionType.Code;
    }

    getTransactionType = () => {
        return getBankTransactionType(this.props.rootStorage);
    };

    get successSubtitle(): string {
        return i18next.t("Banks:Pairing.Saved");
    }

    presetDefaultFilters() {
        createDefaultPairFilter(this.props.storage, this.transactionType);
        this.props.storage.applyFilters();
    }

    canRowBePaired = (rowId: TId) => {
        return canRowBePaired({
            storage: this.props.storage,
            paymentType: this.transactionType,
            origPairedDocuments: this.origPairedDocuments,
            rowId
        });
    };


    getTitle = () => {
        if (this.transactionType === BankTransactionTypeCode.Other) {
            return i18next.t(`Banks:Transactions.Payment`);
        }

        return i18next.t(`Banks:Pairing.${this.transactionType === BankTransactionTypeCode.IncomingPayment ? "InPayment" : "OutPayment"}`);
    };

    getHeaderData = () => {
        const acc = generalBankAccountFormatter(null, {
            item: this.props.rootStorage.data.entity.BankStatement?.BankAccount
        });

        const accName = this.props.rootStorage.data.entity.BankStatement?.BankAccount?.Name;

        return [{
            label: this.props.rootStorage.t("Banks:Pairing.DateOfPay"),
            value: formatHeaderValue(DateType.format(this.props.rootStorage.data.entity.DateBankTransaction))
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.Amount"),
            value: formatHeaderValue(this.formatCurrency(Math.abs(this.props.rootStorage.data.entity.TransactionAmount || 0)))
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.SymbolVariable"),
            value: formatHeaderValue(this.props.rootStorage.data.entity.SymbolVariable)
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.FromAccount"),
            value: formatHeaderValue(acc),
            description: accName ? ` | ${accName}` : ""
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.ToAccount"),
            value: formatHeaderValue(generalBankAccountFormatter(null, {
                item: this.props.rootStorage.data.entity.BankAccount
            }))
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.Note"),
            value: formatHeaderValue(this.props.rootStorage.data.entity.RemittanceInformation)
        }];
    };
}

export default withBusyIndicator({ passBusyIndicator: true, isDelayed: true })(withPermissionContext(BankTransactionPairTableView));