import Field from "@components/inputs/field";
import { Select } from "@components/inputs/select";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import KeyboardShortcutsManager, { KeyboardShortcut } from "@utils/keyboardShortcutsManager";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getUtcDayjs } from "../../types/Date";
import memoize from "../../utils/memoize";

const getInitialYearSelectItems = memoize((): ISelectItem[] => {
    const thisYear = getUtcDayjs().get("year");
    const items: ISelectItem[] = [];

    for (let i = thisYear - 5; i < thisYear + 2; i++) {
        items.push({
            id: i,
            label: i.toString()
        });
    }

    return items;
});

export const CbaInitialYearSelection: React.FunctionComponent<{
    initialYear: number;
    onChange: (value: number) => void;
    onSave: () => void;
}> = React.memo((props) => {
    const { initialYear, onSave, onChange } = props;
    const { t } = useTranslation(["Companies", "Common"]);

    const handleYearChange = useCallback((e: ISelectionChangeArgs) => {
        onChange(e.value as number);
    }, [onChange]);

    const handleKeyboardShortcut = useCallback((shortcut: KeyboardShortcut, event: KeyboardEvent) => {
        if (shortcut === KeyboardShortcut.ALT_S) {
            onSave();
            return true;
        }
        return false;
    }, [onSave]);

    useEffect(() => {
        return KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_S],
            callback: handleKeyboardShortcut
        });
    }, [handleKeyboardShortcut]);

    return (
            <Field label={t("Companies:Wizard.InitialYear")}
                   isRequired>
                <Select
                        onChange={handleYearChange}
                        value={initialYear}
                        items={getInitialYearSelectItems()}
                />
            </Field>
    );
});