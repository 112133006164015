import { IToolbarItem, TToolbarItem } from "@components/toolbar/Toolbar.types";
import { getConfirmationButtonsToolbarDefinition } from "@components/toolbar/Toolbar.utils";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";
import { isDefined } from "@utils/general";
import { pull } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Toolbar } from "../../components/toolbar";
import { DASH_CHARACTER } from "../../constants";
import { SessionType } from "../../contexts/authContext/Auth.utils";
import { WithAuthContext, withAuthContext } from "../../contexts/authContext/withAuthContext";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { TableViewMode, ToolbarItemType } from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { IProps as IConfirmationButtonProps } from "./ConfirmationButtons";
import {
    exportButtonActionGroup,
    ICustomButtonDefArgs,
    TableActionOrder,
    TableButtonsAction,
    TableButtonsActionType
} from "./TableToolbar.utils";

interface IProps extends WithTranslation, WithPermissionContext, WithAuthContext {
    activeButtons?: TableButtonsActionType[];
    visibleButtons?: TableButtonsActionType[];
    disabledButtons?: TableButtonsActionType[];
    staticItems?: TToolbarItem[];
    isExpandable?: boolean;
    isDisabled?: boolean;
    onClick: (key: TableButtonsActionType, value?: TValue) => void;
    hasDrafts?: boolean;
    draftsCount?: number;
    documentsCount?: number;
    draftViewMode?: TableViewMode;
    getCustomButtonDef?: (type: TableButtonsActionType, args: ICustomButtonDefArgs) => IToolbarItem;
    /** Can be used to extend the default toolbar button definition. */
    overrideDefaultToolbarButtonDef?: (item: IToolbarItem) => IToolbarItem;
    confirmationButtons?: IConfirmationButtonProps;
    // for custom definitions
    children?: IToolbarItem[];
}

class TableToolbar extends React.Component<IProps> {
    handleClick = (key: TableButtonsActionType, value: TValue) => {
        this.props.onClick(key, value);
    };

    handleItemChange = (id: string, value: TValue) => {
        this.handleClick(id as TableButtonsActionType, value);
    };

    getButtonDef = (buttonType: TableButtonsActionType): IToolbarItem => {
        let label, iconName, itemType, order;
        const id = buttonType;
        let isDisabled = this.props.isDisabled || this.props.disabledButtons?.includes(buttonType);
        const isActive = this.props.activeButtons?.includes(buttonType);
        let itemProps: TRecordAny = {
            isTransparent: true
        };

        // customer doesn't have any permissions like normal user, so export should be enabled by default on every table
        const isInApp = this.props.authContext?.sessionType === SessionType.Application;
        const canExport = this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.CanExportDataFromTheSystem);

        switch (buttonType) {
            case TableButtonsAction.Add:
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.AddItem");
                iconName = "Add";
                order = TableActionOrder.Add;
                break;
            case TableButtonsAction.Remove:
                order = TableActionOrder.Remove;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.RemoveItem");
                iconName = "Bin";
                break;
            case TableButtonsAction.MassEdit:
                order = TableActionOrder.MassEdit;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.MassEdit");
                iconName = "BulkEdit";
                break;
            case TableButtonsAction.ExpandCollapseAll:
                order = TableActionOrder.ExpandCollapseAll;
                itemType = ToolbarItemType.Icon;
                label = this.props.t(`Components:Table.${this.props.isExpandable ? "OpenAll" : "CloseAll"}`);
                iconName = this.props.isExpandable ? "ExpandAll" : "CollapseAll";
                break;
            case TableButtonsAction.Lock:
                order = TableActionOrder.Lock;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.ColumnLock");
                iconName = "Lock";
                break;
            case TableButtonsAction.FullscreenOpen:
                order = TableActionOrder.Fullscreen;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.FullscreenOpen");
                iconName = "FullscreenOpen";
                break;
            case TableButtonsAction.FullscreenClose:
                order = TableActionOrder.Fullscreen;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.FullscreenClose");
                iconName = "FullscreenClose";
                break;
            case TableButtonsAction.XLSXExport:
            case TableButtonsAction.CSVExport:
            case TableButtonsAction.PdfExport:
            case TableButtonsAction.PdfExportReportFull:
            case TableButtonsAction.PdfExportReportShort:
                order = TableActionOrder.Export;
                itemType = ToolbarItemType.IconSelect;
                label = this.props.t("Components:Table.ExportHeader");
                iconName = "Export";
                isDisabled = isDisabled || (!canExport && isInApp);
                itemProps = {
                    selectProps: { ...itemProps, width: "220px" },
                    headerText: this.props.t("Components:Table.ExportHeader"),
                    items: exportButtonActionGroup.filter(buttonType => this.props.visibleButtons.includes(buttonType))
                        .map(buttonType => this.getExportActionItemDef(buttonType))
                };
                break;
            case TableButtonsAction.Sorting:
                order = TableActionOrder.Sort;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.Sorting");
                iconName = "Sorting";
                break;
            case TableButtonsAction.Settings:
                order = TableActionOrder.Settings;
                itemType = ToolbarItemType.Icon;
                label = this.props.t("Components:Table.Settings");
                iconName = "Settings";
                break;

            case TableButtonsAction.ToggleDraftView:
                order = TableActionOrder.ToggleDraftView;
                itemType = ToolbarItemType.SegmentedButton;
                itemProps = {
                    def: [{
                        id: TableViewMode.Saved,
                        label: `/${this.props.documentsCount}/`,
                        title: `${this.props.t("Components:Table.SavedDocs")} /${this.props.documentsCount}/`,
                        iconName: "DocumentSaved"
                    }, {
                        id: TableViewMode.Draft,
                        label: `/${this.props.draftsCount ?? DASH_CHARACTER}/`,
                        title: `${this.props.t("Components:Table.Drafts")} /${this.props.draftsCount ?? DASH_CHARACTER}/`,
                        iconName: "DocumentDraft"
                    }],
                    selectedButtonId: this.props.draftViewMode
                };
                break;
            default:
                return this.props.getCustomButtonDef(buttonType, { isActive, isDisabled });
        }

        let toolbarItem: IToolbarItem = {
            id, label, iconName, itemProps, itemType, isDisabled, isActive, order
        };

        if (this.props.overrideDefaultToolbarButtonDef) {
            toolbarItem = this.props.overrideDefaultToolbarButtonDef(toolbarItem);
        }

        return toolbarItem;
    };

    getExportActionItemDef = (buttonType: TableButtonsAction) => {
        switch (buttonType) {
            case TableButtonsAction.XLSXExport:
                return {
                    id: buttonType,
                    label: this.props.t("Components:Table.XLSXExport"),
                    iconName: "ExportExcel"
                };
            case TableButtonsAction.CSVExport:
                return {
                    id: buttonType,
                    label: this.props.t("Components:Table.CSVExport"),
                    iconName: "ExportSvg"
                };
            case TableButtonsAction.PdfExport:
                return {
                    id: buttonType,
                    label: this.props.t("Components:Table.PdfExport"),
                    iconName: "ExportPdf"
                };
            case TableButtonsAction.PdfExportReportFull:
                return {
                    id: buttonType,
                    label: this.props.t("Components:Table.PdfExportReportFull"),
                    iconName: "ExportPdf"
                };
            case TableButtonsAction.PdfExportReportShort:
                return {
                    id: buttonType,
                    label: this.props.t("Components:Table.PdfExportReportShort"),
                    iconName: "ExportPdf"
                };
        }

        return null;
    };

    render() {
        const visibleButtons = [...this.props.visibleButtons];

        if (exportButtonActionGroup.some((buttonAction) => visibleButtons.includes(buttonAction))) {
            // it's button group, include only once
            pull(visibleButtons, ...exportButtonActionGroup);
            visibleButtons.push(exportButtonActionGroup[0]);
        }

        if (isDefined(this.props.hasDrafts)) {
            visibleButtons.push(TableButtonsAction.ToggleDraftView);
        }

        const buttonDefs = visibleButtons.map(buttonType => this.getButtonDef(buttonType))
            .sort((a, b) => a.order - b.order);

        const itemsDef = [...buttonDefs];

        if (this.props.children) {
            itemsDef.unshift(...this.props.children);
        }

        if (this.props.confirmationButtons) {
            itemsDef.unshift(getConfirmationButtonsToolbarDefinition(this.props.confirmationButtons));
        }

        return (
            <Toolbar
                staticItems={this.props.staticItems}
                onItemChange={this.handleItemChange}
            >
                {itemsDef}
            </Toolbar>
        );
    }
}

export default withTranslation(["Common", "Components"])(withAuthContext(withPermissionContext(TableToolbar)));