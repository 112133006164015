import { parseError } from "@odata/ODataParser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fireGAEvent, fireGATag, fireSklikEvent } from "@utils/analytics";
import { getDefaultPostParams } from "@utils/customFetch";
import i18next from "i18next";

import {
    AUTH_REQUEST_RESET_PASS_URL,
    AUTH_VERIFICATION_ACTIVATE_URL,
    AUTH_VERIFICATION_VERIFY_URL,
    REGISTER_RESEND_EMAIL_URL,
    REGISTER_URL
} from "../../constants";
import { HTTPStatusCode } from "../../enums";
import { AnimationPlayStatus, AnimationType, ILoginOptions, RequestStatus } from "../Login.utils";
import { setInvitationAccepted } from "./invitationSlice";
import { loadSession, login } from "./sessionSlice";
import { AppThunk, TRootLoginState } from "./store";

interface IAnimationConfig {
    type: AnimationType;
    step: number;
    status?: AnimationPlayStatus;
}

export enum VerificationType {
    Email = "Email",
    Device = "Device",
    Password = "Password",
    Company = "Company",
    DeleteUserSessions = "DeleteUserSessions",
    AcceptOwnerRole = "AcceptOwnerRole"
}

export interface IVerificationObject {
    Type: VerificationType;
    Parameters: string;
    DateCreated: string;
    DateValidUntil: string;
    IsPostLoginActionRequired: boolean;
    UserGuid: string;
    VerificationGuid: string;
}

export interface IVerificationInfo<FrontendParameters = unknown> {
    FrontendParameters: FrontendParameters;
    Verification: IVerificationObject;
}

export interface IPasswordChangeVerificationInfo extends IVerificationInfo<string> {
    Type: VerificationType.Password;
}

export interface IAcceptOwnerRoleVerificationInfo extends IVerificationInfo<{
    UserName: string;
    EditingUserName: string;
    TenantName: string;
    OriginalRoleName: string;
    IsEdu: boolean;
}> {
    Type: VerificationType.AcceptOwnerRole;
}

interface IValidationMessage {
    message: string;
}

type TValidationMessages = Record<string, string[]>;

interface ILoginState {
    email: string;
    nextURL: string;
    resetPasswordSent: string; // email, where the reset password has been sent
    verificationCode: string;
    // VerificationInfo retrieved from verificationCode
    verificationInfo: IVerificationInfo;
    animation: IAnimationConfig;
    status: RequestStatus;
    error: string;
    validationMessages: TValidationMessages;
    postLoginVerifications: IVerificationObject[];
    loginOption: ILoginOptions;
}

export interface IRegistrationData {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    IsAgreedToCollectData?: boolean;
    IsAgreedToCollectPersonalDataForMarketing?: boolean;
    IsAgreedToCollectPersonalDataForMarketingOwner?: boolean;
    Invitation?: string;
}

const initialState: ILoginState = {
    email: "",
    nextURL: null,
    resetPasswordSent: null,
    verificationCode: "",
    verificationInfo: null,
    animation: null,
    status: RequestStatus.Idle,
    error: "",
    validationMessages: null,
    postLoginVerifications: null,
    loginOption: null
};

const INVALID_VERIFICATION_CODE = "INV";

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoginEmail(state, action: PayloadAction<string>) {
            state.email = action.payload?.trim() ?? "";
        },
        setNextURL(state, action: PayloadAction<string>) {
            state.nextURL = action.payload;
        },
        setVerificationCode(state, action: PayloadAction<string>) {
            state.verificationCode = action.payload;
        },
        setVerificationInfo(state, action: PayloadAction<IVerificationInfo>) {
            state.verificationInfo = action.payload;
        },
        setPostLoginActions(state, action: PayloadAction<IVerificationObject[]>) {
            state.postLoginVerifications = action.payload;
        },
        setResetPasswordSent(state, action: PayloadAction<string>) {
            state.resetPasswordSent = action.payload;
        },
        setLoginOptions(state, action: PayloadAction<ILoginOptions>) {
            state.loginOption = action.payload;
        },
        setAnimation(state, action: PayloadAction<Partial<IAnimationConfig>>) {
            state.animation = {
                type: AnimationType.StaticBiker,
                step: 0, // default
                ...(state.animation ?? {}),
                ...action.payload
            };
        },
        setAnimationType(state, action: PayloadAction<AnimationType>) {
            state.animation = {
                type: action.payload,
                step: 0,
                status: AnimationPlayStatus.Stopped
            };
        },
        setAnimationStatus(state, action: PayloadAction<AnimationPlayStatus>) {
            if (state.animation) {
                state.animation.status = action.payload;
            }
        },
        handleStart(state) {
            state.status = RequestStatus.Pending;
            state.error = null;
            state.validationMessages = null;
        },
        handleSuccess(state) {
            state.status = RequestStatus.Idle;
        },
        handleError(state, action: PayloadAction<string | TValidationMessages>) {
            state.status = RequestStatus.Error;
            if (typeof action.payload === "string") {
                state.error = action.payload;
            } else {
                state.validationMessages = action.payload;
            }
        },
        setValidationMessage(state, action: PayloadAction<{ key: string, message: string }>) {
            const all = { ...(state.validationMessages ?? {}) };
            const { key, message } = action.payload;
            if (message) {
                all[key] = [message];
            } else {
                delete all[key];
            }
            // this is used by user when editing the form -> switch to Idle state,
            // so we don't display the error message anymore
            state.status = RequestStatus.Idle;
            state.validationMessages = Object.keys(all).length ? all : null;
        },
        clearRequestState(state) {
            state.status = RequestStatus.Idle;
            state.error = null;
            state.validationMessages = null;
        }
    }
});

export const {
    setLoginEmail,
    setNextURL,
    setVerificationCode,
    setVerificationInfo,
    setPostLoginActions,
    setResetPasswordSent,
    setAnimation,
    setAnimationType,
    setAnimationStatus,
    setLoginOptions,
    handleStart, handleSuccess, handleError, clearRequestState,
    setValidationMessage
} = loginSlice.actions;

export default loginSlice.reducer;

export const handleErrorResponse = (response: Response): AppThunk => async dispatch => {
    const errors: string[] = [];
    const validationMessages: TValidationMessages = {};
    if (response.status === HTTPStatusCode.BadRequest) {
        const parsedError = parseError(await response.json());
        parsedError?._validationMessages?.forEach(m => {
            if (m.property) {
                if (!validationMessages[m.property]) {
                    validationMessages[m.property] = [];
                }
                validationMessages[m.property].push(m.message);
            } else if (m.message) {
                errors.push(m.message);
            }
        });
    }
    const error = Object.keys(validationMessages)?.length ? validationMessages
        : errors.length ? errors.join("\n") : i18next.t("Common:Errors.ErrorHappened");
    dispatch(handleError(error));
};

export const resetPasswordRequest = (email: string): AppThunk => async dispatch => {
    try {
        dispatch(handleStart());
        const response = await fetch(AUTH_REQUEST_RESET_PASS_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(email)
        });

        // most likely response status 404 should not be already used...
        if (response.ok || response.status === HTTPStatusCode.NotFound) {
            dispatch(setResetPasswordSent(email));
            dispatch(handleSuccess());
        } else {
            dispatch(handleErrorResponse(response));
        }
    } catch (e) {
        dispatch(handleError());
    }
};
export const resetPassword = (code: string, email: string, password: string): AppThunk => async dispatch => {
    try {
        dispatch(handleStart());
        const response = await fetch(`${AUTH_VERIFICATION_ACTIVATE_URL}/${code}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify(password)
        });

        if (response.ok) {
            // directly log in to system when success
            dispatch(login(email, password));
            dispatch(handleSuccess());
        } else if ([HTTPStatusCode.NotFound, HTTPStatusCode.Conflict].includes(response.status)) {
            dispatch(setVerificationCode(INVALID_VERIFICATION_CODE));
            // not really success, but user is redirected away, so the behavior is same
            dispatch(handleSuccess());
        } else {
            dispatch(handleErrorResponse(response));
        }
    } catch (e) {
        // General (unknown) error
        dispatch(handleError());
    }
};

/** Validates that given code correspones to Validation object and retrieves ValidationType and its parameters
 * */
export const getVerificationInfo = (code: string): AppThunk => async dispatch => {
    try {
        const response = await (code && fetch(`${AUTH_VERIFICATION_VERIFY_URL}/${code}`));

        if (!code || !response?.ok) {
            dispatch(setVerificationCode(INVALID_VERIFICATION_CODE));
        } else {
            const verificationInfo = (await response.json()) as IVerificationInfo;

            if (verificationInfo.Verification.Type === VerificationType.Password) {
                dispatch(setLoginEmail((verificationInfo as IPasswordChangeVerificationInfo).FrontendParameters));
            } else if (verificationInfo.Verification.Type === VerificationType.AcceptOwnerRole) {
                verificationInfo.FrontendParameters = JSON.parse(verificationInfo.FrontendParameters as string);
            }

            if (typeof verificationInfo.Verification.Parameters === "string") {
                verificationInfo.Verification.Parameters = JSON.parse(verificationInfo.Verification.Parameters as string);
            }

            dispatch(setVerificationCode(code));
            dispatch(setVerificationInfo(verificationInfo));
        }
    } catch (e) {
        dispatch(handleError());
    }
};

/** Confirms or rejects verification object */
export const activateVerification = (code: string, activate = true): AppThunk => async dispatch => {
    try {
        dispatch(handleStart());

        const response = await fetch(`${AUTH_VERIFICATION_ACTIVATE_URL}/${code}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify(!!activate)
        });

        if (response.ok) {
            // "confirm" the action by removing verification object to trigger redirect in Verify
            dispatch(setVerificationInfo(null));
            dispatch(handleSuccess());
        } else {
            const parsedError = parseError(await response.json());
            const error = parsedError._validationMessages[0].message;

            dispatch(handleError(error));
        }
    } catch (e) {
        dispatch(handleError(null));
    }
};

export const register = (data: IRegistrationData): AppThunk => async dispatch => {
    try {
        dispatch(handleStart());
        const response = await fetch(REGISTER_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                ...data
            })
        });

        const hasInvitation = !!data.Invitation;

        if (response.ok) {
            const responseData = await response.json();
            fireGAEvent("vyplnena_registrace", responseData.UserGuid);
            fireGATag("registration_success");
            fireSklikEvent();
            dispatch(loadSession());

            if (hasInvitation) {
                dispatch(setInvitationAccepted());
            }
            dispatch(handleSuccess());
        } else if (response.status === HTTPStatusCode.Conflict) {
            const message = i18next.t("Login:Registration.EmailAlreadyRegisteredError");
            // handle error in different way according to screen - registration screen has username field, invitation screen not (general error)
            const error = hasInvitation ? message : { username: [message] };
            dispatch(handleError(error));
        } else {
            dispatch(handleErrorResponse(response));
        }
    } catch (e) {
        dispatch(handleError());
    }
};

export const resendRegisterEmail = (): AppThunk => async dispatch => {
    try {
        dispatch(handleStart());
        const response = await fetch(REGISTER_RESEND_EMAIL_URL, {
            method: "POST"
        });
        if (response.ok) {
            // no action, user stays on the page, still need to click on the link in the email
            dispatch(handleSuccess());
        } else if ([HTTPStatusCode.Unauthorized, HTTPStatusCode.NotFound, HTTPStatusCode.Conflict].includes(response.status)) {
            // token not valid anymore or session expired, try to reload it, redirects are then handled
            // according to new session info
            dispatch(loadSession());
        } else {
            // unknown error
            dispatch(handleError());
        }
    } catch (e) {
        dispatch(handleError());
    }
};

// Selector to get the session data from the state
export const selectLoginEmail = (state: TRootLoginState): string => state.login.email;
export const selectError = (state: TRootLoginState): string => state.login.error;
export const selectValidationMessages = (state: TRootLoginState): Record<string, IValidationMessage> => {
    const ret: Record<string, IValidationMessage> = {};
    if (state.login.validationMessages) {
        Object.keys(state.login.validationMessages).forEach(key => {
            ret[key] = { message: state.login.validationMessages[key].join("\n") };
        });
        return ret;
    }
    return null;
};
export const selectRequestStatus = (state: TRootLoginState): RequestStatus => state.login.status;
export const selectNextURL = (state: TRootLoginState): string => state.login.nextURL;
export const selectVerificationCode = (state: TRootLoginState): string => state.login.verificationCode !== INVALID_VERIFICATION_CODE ? state.login.verificationCode : null;
export const selectIsInvalidVerificationCode = (state: TRootLoginState): boolean => state.login.verificationCode === INVALID_VERIFICATION_CODE;
export const selectVerificationInfo = (state: TRootLoginState): IVerificationInfo => state.login.verificationCode !== INVALID_VERIFICATION_CODE ? state.login.verificationInfo : null;
export const selectPostLoginVerifications = (state: TRootLoginState): IVerificationObject[] => state.login.postLoginVerifications;
export const selectAnimation = (state: TRootLoginState): IAnimationConfig => state.login.animation;
export const selectAnimationStatus = (state: TRootLoginState): AnimationPlayStatus => state.login.animation?.status ?? AnimationPlayStatus.Stopped;
export const selectLoginOption = (state: TRootLoginState): ILoginOptions => state.login.loginOption;
export const selectRegisterIsBusy = ({ login, session }: TRootLoginState): boolean =>
    login.status === RequestStatus.Pending || session.status === RequestStatus.Pending;

