import { handleRefHandlers } from "@utils/general";
import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { WithTranslation, withTranslation } from "react-i18next";
import { VariableSizeList } from "react-window";

import { LabelStatus } from "../../enums";
import TestIds from "../../testIds";
import { CollapsibleContent } from "../collapsibleContent";
import { FIELD_VER_MARGIN, FIELD_VER_MARGIN_SMALL } from "../inputs/field/Field.styles";
import SortableList from "../sortableList";
import {
    LabelsOverflowWrapper,
    LabelsWrapper,
    PrimaryContent,
    PrimaryContentTitle,
    StyledFastEntryList
} from "./FastEntryList.styles";

export interface IFastEntryListChildRender {
    dragHandleProps?: DraggableProvidedDragHandleProps;
    connectEntryRef: React.RefCallback<HTMLDivElement>;
    labelStatus: LabelStatus;
}

export interface IFastEntryListChild {
    id: string;
    render: (args: IFastEntryListChildRender) => React.ReactElement;
    showIndicator?: () => boolean;
    isEmptyItem?: boolean;
    emptyItemText?: string;
}

export interface IProps extends WithTranslation {
    id: string;
    recalculate?: string;
    onReorder?: (dragIndex: number, hoverIndex: number) => void;
    labels: React.ReactElement[];
    labelsLeftPad?: number;
    containsPrimary?: boolean;
    customPreContent?: React.ReactElement;
    canReorder?: boolean;
    isCollapsible?: boolean;
    isCollapsed?: boolean;
    onCollapsed?: () => void;
    onCollapsingEnd?: () => void;
    useVirtualization?: boolean;
    // use together with virtualization
    height?: number | string;
    useLabelWrapping?: boolean;
    showLineNumbers?: boolean;
    virtualizedListRef?: React.RefObject<VariableSizeList>;

    onWrapChange?: (isWrapped: boolean) => void;

    children: IFastEntryListChild | IFastEntryListChild[];
    passRef?: React.Ref<HTMLDivElement>;
}

interface IState {
    wrapped: boolean;
    collapsibleMargin?: number;
    recalculateList?: number;
}

class FastEntryList extends React.Component<IProps, IState> {
    _fastEntryRef = React.createRef<HTMLDivElement>();
    _primaryContentRef = React.createRef<HTMLDivElement>();

    state: IState = {
        wrapped: false
    };

    componentDidMount() {
        this.handleResize();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        this.handleCollapsibleContentHeightAndMargin();

        if (this.state.wrapped !== prevState.wrapped || this.props.recalculate !== prevProps.recalculate) {
            this.setState(({ recalculateList }) => ({ recalculateList: (recalculateList ?? 0) + 1 }));
            this.props.onWrapChange?.(this.state.wrapped);
        }
    }

    get isWrapped(): boolean {
        return this.state.wrapped && this.props.useLabelWrapping;
    }

    handleCollapsibleContentHeightAndMargin = () => {
        if (this._fastEntryRef.current) {
            const firstChildHeight = this._fastEntryRef.current.offsetHeight;

            if (this.props.containsPrimary && this._primaryContentRef.current) {
                const offset = this.state.wrapped ? 3 : 19;
                const countedHeight = firstChildHeight + offset + parseInt(FIELD_VER_MARGIN);

                this._primaryContentRef.current.style.height = `${countedHeight}px`;
            }

            if (this.props.isCollapsible) {
                const collapsibleMargin = firstChildHeight + (this.state.wrapped ? 15 : 0);
                if (this.state.collapsibleMargin !== collapsibleMargin) {
                    this.setState({
                        collapsibleMargin: collapsibleMargin
                    });
                }
            }
        }
    };

    handleResize = () => {
        if (this.props.useLabelWrapping && this._fastEntryRef.current) {
            let isWrapped = false;
            const firstRowTop = this._fastEntryRef.current.children[0]?.getBoundingClientRect().top;

            for (const child of Array.from(this._fastEntryRef.current.children)) {
                if (child.getBoundingClientRect().top > firstRowTop) {
                    isWrapped = true;
                    break;
                }
            }

            if (isWrapped !== this.state.wrapped) { // setState cause rerender even if the state remains the same
                this.setState({
                    wrapped: isWrapped
                }, this.handleCollapsibleContentHeightAndMargin);
            } else {
                this.handleCollapsibleContentHeightAndMargin();
            }
        }
    };

    handleReorder = (dragIndex: number, hoverIndex: number) => {
        this.props.onReorder?.(dragIndex, hoverIndex);
    };

    handleFastEntryRef = (ref: HTMLDivElement) => {
        handleRefHandlers(ref, this._fastEntryRef);
    };

    renderList = (children: IFastEntryListChild[]) => {
        return (
                <SortableList
                        id={this.props.id}
                        useVirtualization={this.props.useVirtualization}
                        height={this.props.height}
                        useDnD={this.props.canReorder}
                        showLineNumbers={this.props.showLineNumbers}
                        lineNumberTopOffset={!this.isWrapped ? "8px" : "28px"}
                        onResize={this.handleResize}
                        onReorder={this.handleReorder}
                        rowGap={this.isWrapped ? FIELD_VER_MARGIN : FIELD_VER_MARGIN_SMALL}
                        recalculate={this.state.recalculateList}
                        virtualizedListRef={this.props.virtualizedListRef}>
                    {children.map((fastEntry: IFastEntryListChild, i) => {
                        return (
                                {
                                    id: fastEntry.id,
                                    render: (dragHandleProps: DraggableProvidedDragHandleProps) => {
                                        return fastEntry.render({
                                            dragHandleProps,
                                            connectEntryRef: this.handleFastEntryRef,
                                            labelStatus: !this.state.wrapped && this.props.useLabelWrapping ? LabelStatus.Removed : LabelStatus.Visible
                                        });
                                    },
                                    showIndicator: fastEntry.showIndicator,
                                    isEmptyItem: fastEntry.isEmptyItem,
                                    emptyItemText: fastEntry.emptyItemText
                                }
                        );
                    })}
                </SortableList>
        );
    };

    isCollapsible = () => {
        return this.props.isCollapsible;
    };

    renderContent = (children: IFastEntryListChild[]) => {
        if (this.isCollapsible()) {
            return (
                    <CollapsibleContent
                            topMargin={this.state.collapsibleMargin}
                            isCollapsed={this.props.isCollapsed}
                            onCollapsingEnd={this.props.onCollapsingEnd}
                            onCollapsed={this.props.onCollapsed}>
                        {this.renderList(children)}
                    </CollapsibleContent>
            );
        }

        return this.renderList(children);
    };

    render = () => {
        let children: IFastEntryListChild[];

        if (this.props.children) {
            children = Array.isArray(this.props.children as IFastEntryListChild[]) ? this.props.children as IFastEntryListChild[] : [this.props.children as IFastEntryListChild];
        } else {
            children = [];
        }

        return (
                <StyledFastEntryList
                        hasPrimary={this.props.containsPrimary}
                        isCollapsible={this.props.isCollapsible}
                        onlyPrimary={this.props.containsPrimary && children.length === 1}
                        data-testid={TestIds.FastEntryList}
                        useVirtualization={this.props.useVirtualization}
                        ref={this.props.passRef}>
                    {this.props.containsPrimary && children.length > 0 &&
                            <PrimaryContent ref={this._primaryContentRef}
                                            data-testid={TestIds.PrimaryContent}
                                            showLineNumbers={this.props.showLineNumbers}>
                                <PrimaryContentTitle>{this.props.t("Common:General.Primary")}</PrimaryContentTitle>
                            </PrimaryContent>
                    }
                    {this.props.useLabelWrapping && !this.state.wrapped && (this.props.children as []).length > 0 &&
                            // not every child is emptyItem
                            !children.every(child => !!child?.isEmptyItem) &&
                            <LabelsOverflowWrapper>
                                <LabelsWrapper data-testid={TestIds.FastEntryListHeader}
                                               _lpad={this.props.labelsLeftPad}>
                                    {this.props.labels}
                                </LabelsWrapper>
                            </LabelsOverflowWrapper>
                    }
                    {this.props.customPreContent}
                    {this.renderContent(children)}
                </StyledFastEntryList>
        );
    };
}

export default withTranslation("Common")(FastEntryList);