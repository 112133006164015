import { formatDateToDateString } from "@components/inputs/date/utils";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { IEntity } from "@odata/BindingContext";
import { parseDateFields } from "@odata/Data.utils";
import {
    AssetEntity,
    IAccountingDepreciationPolicyItemEntity,
    IAssetEntity,
    ITaxPriceLimitEntity
} from "@odata/GeneratedEntityTypes";
import { AssetStatusCode, DepreciationPolicyTypeCode, DepreciationTypeCode } from "@odata/GeneratedEnums";
import { getTaxPriceLimitsMemoized } from "@pages/asset/fixedAsset/FixedAsset.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { sortCompareFn } from "@utils/general";
import React from "react";

import BusyIndicator from "../../../components/busyIndicator";
import { Button } from "../../../components/button";
import Dialog from "../../../components/dialog";
import ReadOnlyList, { EMPTY_DASH } from "../../../components/readOnlyList/ReadOnlyList";
import { ASSET_API_URL } from "../../../constants";
import { TRecordAny } from "../../../global.types";
import DateType, { getUtcDayjs } from "../../../types/Date";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { FormStorage } from "../../../views/formView/FormStorage";
import {
    getFiscalYearByDate,
    getNewestInactiveFY,
    getSortedFYs,
    isFiscalYearUsed
} from "../../fiscalYear/FiscalYear.utils";
import { fetchAccountingDepreciationPolicyItems } from "../Asset.utils";
import { AccountingDepreciationPolicyTable } from "./AccountingDepreciationPolicyTable";
import {
    getAssetCurrency,
    isCategoryWithFlexibleAccountingDepreciation,
    isCategoryWithFlexibleTaxDepreciation,
    isImportedAsset
} from "./FixedAssetDef";
import { ITaxDepreciationPolicyItemEntityExtended, TaxDepreciationPolicyTable } from "./TaxDepreciationPolicyTable";
import { isVisibleByPath } from "@components/smart/FieldInfo";
import Alert from "@components/alert";
import { Status } from "../../../enums";


interface IProps {
    storage: FormStorage;
    type: DepreciationPolicyTypeCode;
    onClose: () => void;
}

interface IState {
    loaded: boolean;
    data?: TRecordAny;
    TaxPriceLimits?: ITaxPriceLimitEntity[];
}

interface IFetchDeprecationPolicyData {
    Id?: number;
    AssetValue: number;
    DateFrom: string;
    // Accounting deprecation policy
    DepreciationTypeCode?: DepreciationTypeCode;
    DateRequestedLastAccountingDepreciation?: string;
    UsefulLife?: number;
    // Tax deprecation policy
    CoefficientCode?: string;
    TaxPriceLimitCode?: string;
    ReductionRate?: number;
    DateRequestedLastTaxDepreciation?: string;
    // ImportedAsset fields
    IsImportedAsset?: boolean;
    ImportedAssetPrice?: number;
    RemainingAccountingPrice?: number;
    RemainingTaxPrice?: number;
    RemainingTaxYears?: number;
    IsImproved?: boolean;
}

export default class PolicyDepreciationDialog extends React.Component<IProps, IState> {
    // default state
    state: IState = {
        loaded: false
    };

    get dialogTitle(): string {
        return this.props.storage.t(`FixedAsset:Form.${this.isTaxRelated ? "TaxDepreciationPolicy" : "AccountingDepreciationPolicy"}`);
    }

    get isTaxRelated(): boolean {
        return this.props.type === DepreciationPolicyTypeCode.TaxDepreciation;
    }

    componentDidMount() {
        this.init();
    }

    getData(): Promise<TRecordAny> {
        if (this.props.storage.isDirty()) {
            // shows data according to latest changes in the form
            return this.fetchRestData();
        } else if (this.isTaxRelated) {
            // uses data from entity (data are loaded with the main form
            return Promise.resolve(this.setDataFromEntity());
        } else if (!isCashBasisAccountingCompany(this.props.storage.context)) {
            // load data for AccountingDepreciationPolicy/Items from oData endpoint
            return this.fetchAccountingDepreciationItems();
        }
        return null;
    }

    async init(): Promise<void> {
        const [data, TaxPriceLimits] = await Promise.all([this.getData(), this.isTaxRelated && getTaxPriceLimitsMemoized(this.props.storage)]);
        this.setState({ loaded: true, data, TaxPriceLimits });
    }

    enhanceTaxDepreciationPolicyItems(items: ITaxDepreciationPolicyItemEntityExtended[]): ITaxDepreciationPolicyItemEntityExtended[] {
        const { storage } = this.props;

        return items.reduce((filteredItems, item, idx) => {
            if (item.IsForReportOnly !== true) {
                filteredItems.push({
                    ...item,
                    FiscalYear: item.DateDepreciation && getFiscalYearByDate(storage.context, item.DateDepreciation)
                });
            }
            return filteredItems;
        }, []);
    }

    async fetchRestData(): Promise<TRecordAny> {
        const { storage } = this.props;
        const entity = storage.data.entity as IAssetEntity;
        const isImported = isImportedAsset({ storage });
        const isFlexibleTaxDepreciation = isCategoryWithFlexibleTaxDepreciation({ storage });
        const isFlexibleAccDepreciation = isCategoryWithFlexibleAccountingDepreciation({ storage });

        const data: IFetchDeprecationPolicyData = {
            AssetValue: entity.CalculatedPrice ?? 0,
            DateFrom: formatDateToDateString(entity.DateFirstPutInUse),
            IsImportedAsset: isImported
        };
        if (entity.Status?.Code !== AssetStatusCode.Created) {
            data.Id = entity.Id;
        }
        if (isImported) {
            data.ImportedAssetPrice = entity.ImportedAssetPrice;
        }
        if (this.isTaxRelated) {
            data.CoefficientCode = entity.TaxDepreciationPolicy.Coefficient?.Code ?? null;
            data.ReductionRate = entity.TaxDepreciationPolicy.ReductionRate ?? 0;
            data.TaxPriceLimitCode = entity.TaxDepreciationPolicy.TaxPriceLimitCode ?? entity.TaxDepreciationPolicy.TaxPriceLimit?.Code ?? null;
            if (isFlexibleTaxDepreciation) {
                data.DateRequestedLastTaxDepreciation = formatDateToDateString(entity.TaxDepreciationPolicy.DateRequestedLastDepreciation);
            }
            if (isImported) {
                data.RemainingTaxPrice = entity.RemainingTaxPrice;
                if (isVisibleByPath(storage, AssetEntity.RemainingTaxYears)) {
                    data.RemainingTaxYears = entity.RemainingTaxYears ?? 0;
                }
                data.IsImproved = !!entity.IsImproved;
            }
        } else {
            data.DepreciationTypeCode = entity.AccountingDepreciationPolicy?.DepreciationType?.Code as DepreciationTypeCode;
            if (isFlexibleAccDepreciation) {
                data.DateRequestedLastAccountingDepreciation = formatDateToDateString(entity.AccountingDepreciationPolicy.DateRequestedLastDepreciation);
            } else {
                data.UsefulLife = entity.AccountingDepreciationPolicy?.UsefulLife;
            }
            if (isImported) {
                data.RemainingAccountingPrice = entity.RemainingAccountingPrice;
            }
        }

        const result = await customFetch(`${ASSET_API_URL}/policy/${this.props.type}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify(data)
        });

        if (result.ok) {
            let data = await result.json();
            parseDateFields(data);

            data = data.sort((a: ITaxDepreciationPolicyItemEntityExtended, b: ITaxDepreciationPolicyItemEntityExtended) =>
                sortCompareFn(a.Order, b.Order));

            if (this.isTaxRelated) {
                data = this.enhanceTaxDepreciationPolicyItems(data);
            }

            return data;
        }
        return null;
    }

    async fetchAccountingDepreciationItems(): Promise<IAccountingDepreciationPolicyItemEntity[]> {
        return fetchAccountingDepreciationPolicyItems(this.props.storage);
    }

    setDataFromEntity(): ITaxDepreciationPolicyItemEntityExtended[] {
        const { storage } = this.props;
        const entity = storage.data.entity as IAssetEntity;
        const items = ((this.isTaxRelated ? entity.TaxDepreciationPolicy.Items : entity.AccountingDepreciationPolicy.Items) ?? []) as ITaxDepreciationPolicyItemEntityExtended[];
        return this.enhanceTaxDepreciationPolicyItems(items)
            .sort((a: IEntity, b: IEntity) => sortCompareFn(parseInt(a.Order), parseInt(b.Order)));
    }

    getHeaderData = (): IReadOnlyListItem[] => {
        const { storage } = this.props;
        const { data } = this.state;

        const first = data[0];
        const last = data[data.length - 1];

        const headerData: IReadOnlyListItem[] = [];
        headerData.push({
            label: storage.t("FixedAsset:DepreciationTable.FirstDepreciation"),
            value: first?.DateDepreciation ? DateType.format(getUtcDayjs(first.DateDepreciation)): EMPTY_DASH
        });
        headerData.push({
            label: storage.t("FixedAsset:DepreciationTable.LastDepreciation"),
            value: last?.DateDepreciation ? DateType.format(getUtcDayjs(last.DateDepreciation)) : EMPTY_DASH
        });
        if (!this.isTaxRelated) {
            const md = (storage.data.entity as IAssetEntity).DepreciationAccount?.Number;
            const d = (storage.data.entity as IAssetEntity).AccumulatedDepreciationsAccount?.Number;
            const shortName = md && d && `${md}/${d}`;
            if (shortName) {
                headerData.push({
                    label: storage.t("FixedAsset:DepreciationTable.AccountAssignment"),
                    value: shortName
                });
            }
        }
        return headerData;
    };

    renderConfirmationButtons(): React.ReactElement {
        return (
            <Button onClick={this.props.onClose}>
                {this.props.storage.t("Common:General.Close")}
            </Button>
        );
    }

    renderError(): React.ReactElement {
        const { storage } = this.props;
        return (
            <Alert status={Status.Error} title={storage.t("Common:General.Error")} subTitle={storage.t("FixedAsset:DepreciationTable.ErrorLoadingData")} isOneLiner />
        );
    }

    renderContent(): React.ReactElement {
        const { context, data } = this.props.storage;
        if (!this.state.data) {
            // no data loaded -> show error. BE most likely failed to return data with unexpected error
            return this.renderError();
        }
        const asset = data.entity as IAssetEntity;
        const currency = getAssetCurrency(asset, context);
        const openedFYStartDate = getNewestInactiveFY(context)?.DateEnd;

        if (this.isTaxRelated) {
            const FYs = getSortedFYs(context)
                    .filter(isFiscalYearUsed);
            const currentFY = getFiscalYearByDate(context, getUtcDayjs());
            return (
                <TaxDepreciationPolicyTable header={<ReadOnlyList data={this.getHeaderData()}/>}
                                            currency={currency}
                                            FYs={FYs}
                                            putInUseDate={asset.DateFirstPutInUse}
                                            TaxPriceLimits={this.state.TaxPriceLimits}
                                            currentFY={currentFY}
                                            disableBeforeDate={openedFYStartDate}
                                            data={this.state.data as ITaxDepreciationPolicyItemEntityExtended[]}/>
            );
        }

        return (
            <AccountingDepreciationPolicyTable header={<ReadOnlyList data={this.getHeaderData()}/>}
                                               currency={currency}
                                               disableBeforeDate={openedFYStartDate}
                                               data={this.state.data as IAccountingDepreciationPolicyItemEntity[]}/>
        );
    }

    render() {
        return (
            <Dialog title={this.dialogTitle}
                    onClose={this.props.onClose}
                    onConfirm={null}
                    footer={this.renderConfirmationButtons()}
            >
                {this.state.loaded ? this.renderContent() : <BusyIndicator/>}
            </Dialog>
        );
    }
}