import styled, { css } from "styled-components/macro";
import { getFocusBorderElement, T_PLAIN_big_hig, T_PLAIN_small } from "../../global.style";
import { BorderSize, TableSizes } from "../../enums";
import { headerCellBoxShadow, RowDivider } from "./Rows.styles";
import { CaretIcon, DarkIcon } from "../icon";

import { TooltipIconInfo } from "../tooltipIcon";
import { AnimationSize, SpiderAnim } from "../../animations/Animations";
import Checkbox from "../inputs/checkbox";
import { RadioButton } from "../inputs/radioButtonGroup/RadioButtonGroup";

import { MENU_ICON_SIZE } from "@components/toolbar/Toolbar.types";

const ICON_SPACE = MENU_ICON_SIZE;
const HEADER_ICON_BAR_MARGIN = `${ICON_SPACE}px`;

export const StyledTable = styled.div<{ isVirtualized: boolean, isForPrint: boolean; isBusy: boolean }>`
    height: ${props => props.isForPrint ? "fit-content" : "100%"};
    width: ${props => props.isForPrint ? "fit-content" : "100%"};
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: ${props => props.isVirtualized || props.isBusy ? "150px" : "auto"};
    color: ${props => props.theme.C_TEXT_primary};

    // simplebar sometimes doesn't refresh its inner width resulting in table overflowing outside of its container
    // this seems to fix it 
    // https://github.com/Grsmto/simplebar/issues/339
    // https://github.com/Grsmto/simplebar/issues/253
    .simplebar-placeholder {
        display: ${props => props.isVirtualized ? "none" : "block"};
    }

    ${getFocusBorderElement({ offset: 0 })};
`;

interface IFakeHeightProps {
    _width?: number;
    _height?: number;
}

export const FakeHeight = styled.div.attrs<IFakeHeightProps>(props => {
    return {
        style: {
            width: `${props._width}px`,
            height: `${props._height}px`
        }
    };
})<IFakeHeightProps>`
    min-height: 100%;
`;

export const AutoSizerWrapper = styled.div`
    flex: 1 1 auto;
`;

export const StyledHeader = styled.div`
    ${T_PLAIN_big_hig};
    display: flex;
    align-items: stretch;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
`;

export const HeaderActionWrapperPositioner = styled.div`
    position: relative;
`;

export const HeaderActionWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    top: -30px;
    width: ${HEADER_ICON_BAR_MARGIN};
`;

interface IStyledBodyProps {
    minWidth: number;
    isVirtualized: boolean;
}

export const StyledBody = styled.div.attrs<IStyledBodyProps>(props => ({
    style: {
        minWidth: `${props.minWidth}px` // changed often when resizing columns
    }
}))<IStyledBodyProps>`
    width: 100%;
    position: ${props => props.isVirtualized ? "absolute" : "relative"};
    top: 0;
    transform-style: preserve-3d;
`;

export const NewRowContent = styled.div`
    ${T_PLAIN_small};
    font-style: italic;
    color: ${props => props.theme.C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_TOP};
`;

interface IActive {
    isActive: boolean;
}

export const IconBarScroll = styled.div<IActive>`
    position: relative;
    height: 100%;
    // has to be hidden, otherwise huge scrollbar is shown in table view
    overflow: hidden;
    left: ${`-${ICON_SPACE}px`};
    margin-right: ${`-${ICON_SPACE}px`};
    padding-left: ${`${ICON_SPACE}px`};
    flex: 0 0 auto;
`;

export const RowIcon = styled(DarkIcon)`
    position: absolute;
    left: -29px;
`;

export const WholeTableShadowWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

export const TableWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const RowShadowScroll = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;

    padding: ${TableSizes.ShadowPadding}px;
    box-sizing: content-box;
    top: -${TableSizes.ShadowPadding}px;
    left: -${TableSizes.ShadowPadding}px;
    z-index: 1;
    pointer-events: none;
`;

export const IconBar = styled.div<IActive & IFakeHeightProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-height: 100%;
    height: ${props => props._height}px;
`;

export const BeforeRowContentWrapper = styled.div<{
    isDivider?: boolean;
}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: ${`${TableSizes.RowHeight - TableSizes.RowSpacing}px`};
    margin-bottom: ${`${TableSizes.RowSpacing}px`};

    ${props => props.isDivider && css`
        &::after {
            content: "";
            ${RowDivider};
        }
    `};
`;

export const ActionIconWrapper = styled.div<{
    showOnHover?: boolean;
} & IActive>`
    display: flex;
    justify-content: center;
    width: ${props => props.isActive ? `${ICON_SPACE}px` : "0px"};

    ${props => props.showOnHover && css`
        & > * {
            visibility: hidden;
        }

        &:hover > *, &.hover > * {
            visibility: visible;
        }
    `}
`;

export const InnerWrapper = styled.div.attrs<{ _height: number }>(props => ({
    style: {
        height: `${props._height}px`
    }
}))<{ _height: number }>`
    position: relative;
    display: flex;
    outline: none;
`;

interface IMetaColumn {
    isHighlighted?: boolean;
    border?: BorderSize;
    isForPrint?: boolean;
}

export const MetaColumn = styled.div<IMetaColumn>`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;

    ${headerCellBoxShadow};
`;

export const MetaColumnLabel = styled.div<IMetaColumn>`
    display: flex;
    flex-direction: column;

    text-align: right;
    align-self: center;
    padding: 0 12px;
    background-color: ${props => props.isHighlighted ? props.theme.C_BG_menu : "transparent"};
`;

export const MetaColumnContent = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const HeaderTooltipIcon = styled(TooltipIconInfo)`
    position: relative;
    height: 0;
    top: 8px;
    flex: 0 0 auto;
    margin-left: 4px;
    order: 2;
`;

export const SpiderAnimStyled = styled(SpiderAnim)`
    width: ${AnimationSize.M}px;
    height: ${AnimationSize.M}px;
    // 60px space for two lines of text below
    max-height: calc(100% - 60px);
    max-width: 50%;
    flex: 0 1 auto;
`;

export const ActionCheckBoxStyled = styled(Checkbox)`
    right: 2px;
`;

export const ActionRadioBoxStyled = styled(RadioButton)`
    right: 2px;
`;

export const CaretIconStyle = styled(CaretIcon)`
    transform: rotate(-90deg);
`;