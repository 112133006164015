import styled from "styled-components/macro";
import { ColoredIcon } from "../icon";
import { FileStripItemSize } from "./FileStrip.utils";
import { Theme } from "../../theme";
import { getFocusBorderElement } from "../../global.style";
import { transparentize } from "polished";


export const StyledFileStrip = styled.div<{ _width: number }>`
    background-color: ${props => props.theme.C_BG_filestrip};

    box-sizing: border-box;
    height: 100%;
    max-width: 180px;
    width: ${props => props._width}px;
    padding-top: 79px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
`;

export const FileStripFooter = styled.div`
    margin-top: auto;
    padding: 38px 0;
    text-align: center;
    z-index: 2; /* display over split layout splitter */
`;

interface IFileStripItemSized {
    size: FileStripItemSize;
}

interface IFileStripItemState {
    isSelected: boolean;
    isHighlighted: boolean;
}

interface IFileStripItemStyled extends IFileStripItemSized, IFileStripItemState {
    height: number;
    margin: number;
}

function getFileStripItemColor(theme: Theme, isHighlighted = false, isSelected = false, isBackground = false) {
    if (isHighlighted && isBackground && !isSelected) {
        return transparentize(.8, theme.C_ACT_main);
    }
    if (isSelected) {
        return isBackground ? "currentColor" : theme.C_TEXT_selection;
    }

    return isBackground ? "none" : theme.C_ACT_main;
}

export const StyledFileStripItem = styled.div<IFileStripItemStyled>`
    position: relative;
    height: ${props => props.height}px;
    width: 100%;
    margin-bottom: ${props => props.margin}px;
    color: ${props => getFileStripItemColor(props.theme)};
    cursor: pointer;
    overflow: hidden;
    ${props => getFocusBorderElement({ offset: -1, keyboardOnly: true, isLight: props.isSelected })};
`;


export const ShortcutIconWrapper = styled.div`
`;

export const ShortcutText = styled.span<{ size: FileStripItemSize }>`
    font-weight: bold;
    font-size: ${props => [FileStripItemSize.XL, FileStripItemSize.L].includes(props.size) ? "143%" : "100%"};
`;

export const FolderColoredIcon = styled(ColoredIcon)`
`;

export const CaretColoredIcon = styled(ColoredIcon)<IFileStripItemState>`
    visibility: ${props => props.isSelected || props.isHighlighted ? "visible" : "hidden"};
`;

interface ILayout {
    _folderIconSize: number;
    hasSmallBadge?: boolean;
}

export const LinearLayout = styled(StyledFileStripItem)<ILayout>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${FolderColoredIcon} {
        margin: 0 28px;
    }

    ${ShortcutIconWrapper}, ${ShortcutText} {
        margin: 0 auto 0 0;
        width: fit-content;
        
        svg {
            width: auto !important;
        }
    }

    ${CaretColoredIcon} {
        transform: rotate(-90deg);
    }

    // background is set to whole item
    background-color: ${props => getFileStripItemColor(props.theme, props.isHighlighted, props.isSelected, true)};
    // therefore all icons and text should invert color
    ${ShortcutText}, ${CaretColoredIcon}, ${FolderColoredIcon}, ${ShortcutIconWrapper} {
        flex: 0 0 auto;
        color: ${props => getFileStripItemColor(props.theme, props.isHighlighted, props.isSelected)};
    }

    //highlight also when focused
    &:hover, &:active, &:focus {
        background-color: ${props => getFileStripItemColor(props.theme, true, props.isSelected, true)};

        ${ShortcutText}, ${CaretColoredIcon}, ${FolderColoredIcon}, ${ShortcutIconWrapper} {
            flex: 0 0 auto;
            color: ${props => getFileStripItemColor(props.theme, true, props.isSelected)};
        }
    }
`;

export const CenteredLayout = styled(StyledFileStripItem)<ILayout>`
    width: ${props => props.height}px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;

    ${ShortcutIconWrapper} {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
    }

    ${ShortcutIconWrapper} svg, ${ShortcutText}, ${FolderColoredIcon}, ${CaretColoredIcon} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    ${ShortcutIconWrapper} svg, ${ShortcutText}, ${CaretColoredIcon} {
        top: 56%; // center of the folder icon smaller part
    }

    ${CaretColoredIcon} {
        transform: translate(${props => (props._folderIconSize / 2)}px, -50%) translate(-35%, 0) rotate(-90deg);
    }

    // background is set to only the folder icon
    ${FolderColoredIcon} .IC_4L_main {
        fill: ${props => getFileStripItemColor(props.theme, props.isHighlighted, props.isSelected, true)};
    }

    // therefore only shortcut text/icon reverts it's color
    ${ShortcutText}, ${ShortcutIconWrapper} {
        color: ${props => getFileStripItemColor(props.theme, props.isHighlighted, props.isSelected)};
    }

    //highlight also when focused
    &:hover, &:active, &:focus {
        ${FolderColoredIcon} .IC_4L_main {
            fill: ${props => getFileStripItemColor(props.theme, true, props.isSelected, true)};
        }

        ${ShortcutText}, ${ShortcutIconWrapper} {
            color: ${props => getFileStripItemColor(props.theme, true, props.isSelected)};
        }

    }
`;
