import { IOriginalBatchBody, IOriginalBatchResult } from "@odata/OData";

import { BATCH_API_URL } from "../../constants";

/** When we want to check headers or status of request, it usually means we want to check either the main request,
 * or in case of batch request, of its nested responses.
 * These functions unify the way we check responses in both cases.*/

type TGetOrigResponseCheckValFn<T> = (response: Response) => T;
type TGetBatchResponseCheckValFn<T> = (response: IOriginalBatchResult) => T;
type TCheckCallbackFn<T> = (args: T) => boolean;

interface ICheckResponseArgs<T> {
    response: Response;
    getOrigResponseCheckVal: TGetOrigResponseCheckValFn<T>;
    getBatchResponseCheckVal: TGetBatchResponseCheckValFn<T>;
    checkCallback: TCheckCallbackFn<T>;
}

const checkResponse = async <T>(args: ICheckResponseArgs<T>): Promise<boolean | void> => {
    const { response, getOrigResponseCheckVal, getBatchResponseCheckVal, checkCallback } = args;
    const origResponseCheckVal = getOrigResponseCheckVal(response);

    if (checkCallback(origResponseCheckVal)) {
        return true;
    }

    const isBatch = response.url.endsWith(BATCH_API_URL);

    if (!isBatch) {
        return false;
    }
    const bodyPromise = response.json() as Promise<IOriginalBatchBody>;
    // override response.json, so that it can be consumed again without throwing exception
    response.json = async () => bodyPromise;

    const body = await bodyPromise;

    if (!body.responses) {
        return false;
    }

    for (const batchResult of body.responses) {
        const batchResponseCheckVal = getBatchResponseCheckVal(batchResult);
        const res = checkCallback(batchResponseCheckVal);

        if (res) {
            return true;
        }
    }

    return false;
};

export const checkResponseStatus = async (response: Response, checkFn: TCheckCallbackFn<number>): Promise<boolean | void> => {
    return checkResponse({
        response,
        getOrigResponseCheckVal: (res) => res.status,
        getBatchResponseCheckVal: (res) => res.status,
        checkCallback: checkFn
    });
};


export const checkResponseHeader = async (response: Response, checkFn: TCheckCallbackFn<Headers>): Promise<boolean | void> => {
    return checkResponse({
        response,
        getOrigResponseCheckVal: (res) => res.headers,
        getBatchResponseCheckVal: (res) => new Headers(res.headers),
        checkCallback: checkFn
    });
};