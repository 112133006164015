import ColumnAutoSizer, { IColumnAutoSizerChildren } from "@components/columnAutoSizer";
import { getIcon } from "@components/icon";
import {
    EntitySetName,
    IGeneralPermissionEntity,
    IGeneralRoleEntity,
    IGeneralRolePermissionEntity
} from "@odata/GeneratedEntityTypes";
import React, { Component } from "react";

import { IconSize } from "../../../enums";
import TestIds from "../../../testIds";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { PermissionsOverviewGrid } from "./Users.styles";

interface IProps {
    storage: FormStorage;
}

class PermissionsOverview extends Component<IProps> {
    allPermissions: IGeneralPermissionEntity[];

    getAllPermissions = memoizeOne(async (): Promise<IGeneralPermissionEntity[]> => {
        const permissions = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.GeneralPermissions)
                .query()
                .fetchData();
        return permissions.value;
    });

    getRolePermissions = (generalRole: IGeneralRoleEntity): string[] => {
        return generalRole?.GeneralRolePermissions?.filter((p: IGeneralRolePermissionEntity) => p.IsEnabled)
                .map((p: IGeneralRolePermissionEntity) => p.PermissionCode);
    };

    componentDidMount = async (): Promise<void> => {
        this.allPermissions = await this.getAllPermissions();
        this.forceUpdate();
    };

    render() {
        const selectedCodes = this.getRolePermissions(this.props.storage.data.entity?.GeneralRoles?.[0]?.GeneralRole);

        if (!selectedCodes || !this.allPermissions?.length) {
            return null;
        }

        return <ColumnAutoSizer maxNumColumns={10}>
            {(args: IColumnAutoSizerChildren) => {
                return <PermissionsOverviewGrid
                        data-testid={TestIds.PermissionsGrid}
                        ref={args.observeRef}
                        columns={args.numColumns}
                        rows={Math.ceil(this.allPermissions.length / args.numColumns)}>
                    {this.allPermissions?.sort((a, b) => a.Name.localeCompare(b.Name)).map(permission => {
                        const iconName = selectedCodes?.includes(permission.Code) ? "StatusYes" : "StatusNo";
                        const Icon = getIcon(iconName);
                        return <div data-testid={TestIds.PermissionComponent} key={permission.Code}>
                            <Icon width={IconSize.S}/>
                            <span data-testid={TestIds.Name}>{permission.Name}</span>
                        </div>;
                    })}
                </PermissionsOverviewGrid>;
            }}
        </ColumnAutoSizer>;
    }
}

export default PermissionsOverview;