import { isExternalLink } from "@components/clickable/Clickable.utils";
import CustomResizeObserver from "@components/customResizeObserver";
import React, { AnchorHTMLAttributes, useCallback, useContext } from "react";
import { Link, LinkProps } from "react-router-dom";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { Theme } from "../../theme";
import { enhanceLinkWithDrilldownParams, THistoryLocation } from "../drillDown/DrillDown.utils";
import { LinkStyled, StyledDashboardTileBackground } from "./Dashboard.styles";

interface IProps extends Partial<Pick<LinkProps, "to">> {
    inEditMode?: boolean;
    backgroundColor?: keyof Theme;
    className?: string;
    isLoading?: boolean;
    hideBackground?: boolean;
    overflowed?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onResize?: () => void;
}

const DashboardTileBackground: React.FC<IProps> = (props) => {
    const { inEditMode, backgroundColor, to, onClick, className, onResize, children } = props;
    const context = useContext(AppContext);

    const handleDragStart = useCallback((e: React.DragEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    }, []);

    const handleClick = useCallback((e) => {
        if (inEditMode) {
            e.preventDefault();
        } else {
            onClick?.(e);
        }
    }, [inEditMode, onClick]);

    const handleClickOnBg = onClick && !to;

    const content = (
        <StyledDashboardTileBackground className={className}
                                       $backgroundColor={backgroundColor}
                                       $overflowed={props.overflowed}
                                       $isLoading={props.isLoading}
                                       $inEditMode={inEditMode}
                                       $cursor={handleClickOnBg || to ? "pointer" : "inherit"}
                                       onClick={handleClickOnBg ? handleClick : undefined}
                                       $hideBackground={props.hideBackground}>
            {children}
            {onResize && <CustomResizeObserver onResize={onResize}/>}
        </StyledDashboardTileBackground>
    );

    if (to) {
        const isExternal = typeof to === "string" && isExternalLink(to);
        const element = isExternal ? "a" : Link;
        const linkProps: AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps = {};
        if (isExternal) {
            linkProps.href = to;
            linkProps.target = "_blank";
        } else {
            (linkProps as LinkProps).to = enhanceLinkWithDrilldownParams(to as THistoryLocation, context);
        }

        return (
                <LinkStyled as={element}
                            $isEdit={inEditMode}
                            onDragStart={handleDragStart}
                            onClick={handleClick}
                            {...linkProps}>
                    {content}
                </LinkStyled>
        );
    }

    return content;
};

export default DashboardTileBackground;