import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import { ToolbarButton } from "@components/toolbar/ToolbarButton";
import React from "react";

import { Direction, IconSize, ToolbarItemType } from "../../enums";
import { ButtonGroup } from "../button";
import { MoreIcon } from "../icon";
import IconSelect from "../inputs/select/IconSelect";

interface IProps {
    /**  All items in the toolbar */
    items: IToolbarItem[];

    /** Count of visible item, rest will be hidden under the more button */
    visibleItemsCount?: number;

    onItemChange: (id: string, value?: string) => void;
}

export class VerticalToolbar extends React.PureComponent<IProps> {
    _refStyledToolbar = React.createRef<HTMLDivElement>();

    createElement = (item: IToolbarItem) => {
        switch (item.itemType) {
            case ToolbarItemType.Icon:
                return <ToolbarButton key={item.id} item={item} onClick={this.handleClick}/>;

            case ToolbarItemType.WriteLine:
            case ToolbarItemType.SegmentedButton:
            case ToolbarItemType.Button:
                throw new Error(`Invalid vertical toolbar itemType: ${item.itemType}`);

            default:
                break;
        }

        return undefined;
    };

    handleClick = (item: IToolbarItem) => {
        this.props.onItemChange(item.id);
    };

    handleMenuChange = (arg: ISelectionChangeArgs) => {
        // TODO wrong event expected, IconSelect doens't fire event with ISelectItem, but ISelectionChangeArgs
        // this.props.onItemChange(arg.id);
    };

    getItem = (item: IToolbarItem): any => {
        return this.createElement(item);
    };

    renderStaticItems = (items: IToolbarItem[]) =>
        items.map((item: IToolbarItem) =>
            this.getItem(item));

    render() {
        const { visibleItemsCount, items = [] } = this.props;
        const size = visibleItemsCount < items.length ? visibleItemsCount - 1 : items.length;

        const visibleItems = items.slice(0, size),
            hiddenItems = items.slice(size).map((item): ISelectItem => {
                return {
                    id: item.id,
                    label: item.label,
                    iconName: item.iconName
                };
            });

        return (
            <ButtonGroup direction={Direction.Vertical}
                         ref={this._refStyledToolbar}>
                {visibleItems.length > 0 &&
                this.renderStaticItems(visibleItems)
                }
                {hiddenItems?.length > 0 &&
                <IconSelect items={hiddenItems}
                            hotspotId={"VerticalToolbarMenu"}
                            icon={<MoreIcon width={IconSize.M}/>}
                            value={undefined}
                            onChange={this.handleMenuChange}
                />}
            </ButtonGroup>
        );
    }
}
