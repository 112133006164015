import { getWorkDate } from "@components/inputs/date/utils";
import { TCellValue } from "@components/table";
import {
    EntitySetName,
    EntityTypeName,
    ICompanyEntity,
    ICompanySettingEntity,
    ICurrencyEntity
} from "@odata/GeneratedEntityTypes";
import { CountryCode, CurrencyCode, VatStatementFrequencyCode, VatStatusCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { OData } from "@odata/OData";
import { companyIsSecondaryAddressPath } from "@pages/companies/Company.shared.utils";
import { CompanyAvatarName, CompanyAvatarWithNameWrapper } from "@pages/companies/Company.styles";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

import Avatar from "../../components/avatar/Avatar";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { AvatarSize } from "../../enums";
import BindingContext from "../../odata/BindingContext";
import { getUtcDayjs } from "../../types/Date";
import { IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";

export const stampPath = BindingContext.localContext("Stamp");
/** VatStatus is not modeled as temporal property on backend => use custom local context to show current values on form */
export const currentVatStatus = "##CurrentVatStatus##";
export const currentVatStatusFrequency = "##CurrentVatStatusFrequency##";

export interface ICompanyExtendedEntity extends ICompanyEntity {
    [companyIsSecondaryAddressPath]?: boolean;
    [currentVatStatus]?: VatStatusCode;
    [currentVatStatusFrequency]?: VatStatementFrequencyCode;
}

export interface ICompanyFormCustomData extends IFormStorageDefaultCustomData {
    hasPermission?: boolean;
    stampImage?: string | File;
    isAvatarSelectionOpen?: boolean;
}

export function getCompanyCountryCode(context: IAppContext): CountryCode {
    return (context.getCompany()?.LegalAddress?.CountryCode ?? CountryCode.CzechRepublic) as CountryCode;
}

export function getCompanyCurrencyCode(context: IAppContext): CurrencyCode {
    return (context.getCompany()?.CurrencyCode ?? CurrencyCode.CzechKoruna) as CurrencyCode;
}

export function getCompanyCurrencyEntity(context: IAppContext): ICurrencyEntity {
    const Code = CurrencyCode.CzechKoruna;
    return (context.getCompany()?.Currency) ?? {
        Code,
        Name: getEnumDisplayValue(EntityTypeName.Currency, Code),
        MinorUnit: 2
    };
}

/** This list includes even CZK, even though currenciesUsedByCompany doesn't have it */
export function getCompanyUsedCurrencyCodes(context: IAppContext): CurrencyCode[] {
    const currencies = [...(context.getData().currenciesUsedByCompany?.map(c => c.FromCurrencyCode) ?? [])] as CurrencyCode[];
    currencies.push(CurrencyCode.CzechKoruna);
    return currencies;
}

export async function handleCompanyCreated(context: IAppContext, newId: number): Promise<void> {
    const previousCompanyId = context.getCompany()?.Id;
    await context.updateCompanies();
    await context.setCurrentCompanyId(newId);

    if (previousCompanyId === newId) {
        context.rerenderApp();
    }
}

export function getCompanyVatStatusesValidInRange(context: IAppContext, date?: Date, unit: dayjs.OpUnitType = "year"): ICompanyExtendedEntity["VatStatuses"] {
    const dayjsDate = getUtcDayjs(date);
    const rangeFrom = dayjsDate.startOf(unit);
    const rangeTo = dayjsDate.endOf(unit);
    const vatStatuses = context.getCompany().VatStatuses;
    return vatStatuses.filter(vatStatus => {
        const from = getUtcDayjs(vatStatus.DateValidFrom);
        const to = getUtcDayjs(vatStatus.DateValidTo);
        return from.isSameOrBefore(rangeTo) && to.isSameOrAfter(rangeFrom);
    });
}

export function getCompanyVatStatementFrequencyCode(context: IAppContext, date?: Date): VatStatementFrequencyCode {
    const vatPayerStatusInRange = getCompanyVatStatusesValidInRange(context, date)
        .find(status => status.VatStatusCode === VatStatusCode.VATRegistered);

    return vatPayerStatusInRange?.VatStatementFrequencyCode as VatStatementFrequencyCode;
}

export interface VatStatementPeriod {
    from: Dayjs;
    to: Dayjs;
}

type VatStatementPeriodUnit = "month" | "quarter";

export function createVatStatementPeriod(date: Dayjs, unit: VatStatementPeriodUnit): VatStatementPeriod {
    return {
        from: date.startOf(unit).startOf("day"),
        to: date.endOf(unit).startOf("day")
    };
}

export function getDayjsUnit(frequency: VatStatementFrequencyCode): VatStatementPeriodUnit {
    return frequency === VatStatementFrequencyCode.Quarterly ? "quarter" : "month";
}

export function getCompanyUseVatReducedDeduction(context: IAppContext): boolean {
    const company = context.getCompany();
    if (!company) {
        // on Vat Rules form, there is no company to take the settings from, but behave as it would be there
        return true;
    }
    return company.UseVatReducedDeduction;
}

/**
 *
 * @param context
 * @param forDate
 * @param add possibility to get next/previous period
 */
export function getCompanyVatStatementPeriod(context: IAppContext, forDate: Date, add = 0): VatStatementPeriod {
    const frequency = getCompanyVatStatementFrequencyCode(context);
    const unit = getDayjsUnit(frequency);
    const periodDate = getUtcDayjs(forDate).add(add, unit);
    return createVatStatementPeriod(periodDate, unit);
}

export function getCompanyVatStatementPeriods(context: IAppContext, year?: number): VatStatementPeriod[] {
    const frequency = getCompanyVatStatementFrequencyCode(context);
    const unit = getDayjsUnit(frequency);

    let from = getUtcDayjs().startOf("year");
    if (year) {
        from.set("year", year);
    }
    const to = from.endOf("year");

    const periods: VatStatementPeriod[] = [];

    while (from.isBefore(to)) {
        periods.push(createVatStatementPeriod(from, unit));
        from = from.add(1, unit);
    }
    return periods;
}

export const getCompanySelectContentBeforeAvatar = (context: IAppContext, selectedItemId: string) => {
    if (!selectedItemId) {
        return null;
    }

    const companies = context.getData().companies as ICompanyEntity[];
    const selectedCompany = companies.find(company => company.Id.toString() === selectedItemId);

    return (
        <Avatar src={getCompanyLogoUrl(selectedCompany)} size={AvatarSize.S}
                style={{ marginRight: "8px", top: "-1px" }}/>
    );
};

export const getCompanyAvatarWithName = (company: ICompanyEntity): React.ReactElement => {
    if (!company) {
        return null;
    }

    const logoSrc = getCompanyLogoUrl(company);


    // TODO nekam dat ty styly asi !!!
    return (
        <CompanyAvatarWithNameWrapper>
            <Avatar src={logoSrc} size={AvatarSize.S}/>
            <CompanyAvatarName>
                {company.Name.toUpperCase()}
            </CompanyAvatarName>
        </CompanyAvatarWithNameWrapper>
    );
};

export const companyCellFormatter = (company: ICompanyEntity): TCellValue => {
    if (!company) {
        return null;
    }

    return {
        value: getCompanyAvatarWithName(company),
        tooltip: company.Name,
        onlyShowTooltipWhenChildrenOverflowing: false
    };
};

export function getVatDeductionCoefficientCurrentYear(): number {
    return (getWorkDate()).getFullYear();
}

export async function getCompanySettings(oData: OData): Promise<ICompanySettingEntity> {
    let data: ICompanySettingEntity;
    try {
        const res = await oData.getEntitySetWrapper(EntitySetName.CompanySettings)
            .query().top(1)
            .fetchData<ICompanySettingEntity[]>();
        data = res.value?.[0] ?? {};
        // set default values
        data.InitialYear = data.InitialYear ?? getUtcDayjs().get("year");
    } catch (e) {
        return null;
    }

    return data;
}
