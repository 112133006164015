import { ChartType } from "@components/charts";
import { IValueInterval } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import {
    DashboardTileType,
    ICustomTileInfo,
    IGetTileDataArgs,
    IInfoTileDataCell,
    IInfoTileInfo,
    ILinkTileInfo,
    ITableTileData,
    ITableTileInfo
} from "@components/dashboard";
import { getDrillDownNavParams, IGetDrillDownParams } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString, IDateInterval, isSameMonth } from "@components/inputs/date/utils";
import { getInfoValue, ifAny, IGetValueArgs, TInfoValue } from "@components/smart/FieldInfo";
import { IReportRowDef } from "@components/smart/smartTable";
import { IColumn, IRow } from "@components/table";
import { IChartTileData } from "@components/tiles/chartTile/ChartTile";
import { infoTileCurrencyFormatter } from "@components/tiles/infoTile";
import { createPath, IEntity } from "@odata/BindingContext";
import {
    AccountEntity,
    BankTransactionEntity,
    CashBoxEntity,
    CashReceiptEntity,
    CompoundJournalEntryEntity,
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    ElectronicSubmissionEntity,
    EntitySetName,
    EntityTypeName,
    IBankTransactionEntity,
    ICashBoxEntity,
    ICashReceiptEntity,
    ICompoundJournalEntryEntity,
    IDocumentEntity,
    IElectronicSubmissionEntity,
    IFiscalYearEntity,
    IUserEntity,
    JournalEntryEntity
} from "@odata/GeneratedEntityTypes";
import {
    AccountingCode,
    ClearedStatusCode,
    CompanyPermissionCode,
    CountryCode,
    DocumentTypeCode,
    ElectronicSubmissionTypeCode,
    GeneralPermissionCode,
    PaymentStatusCode,
    VatStatementFrequencyCode,
    VatStatementStatusCode
} from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { transformToODataString } from "@odata/OData.utils";
import {
    isAccountAssignmentCompanyValue,
    isCashBasisAccountingCompany,
    isDemoTenant,
    isOnOrganizationLevel,
    isVatRegisteredCompany
} from "@utils/CompanyUtils";
import { forEachKey, ifPositive } from "@utils/general";
import { UnitType } from "dayjs";
import i18next from "i18next";
import React, { lazy } from "react";
import { LinkProps } from "react-router-dom";

import AgendaWorkOverview from "../../components/tiles/agendaWorkOverview";
import CustomerSupportTile from "../../components/tiles/customerSupportTile";
import IncomeExpenseOverview from "../../components/tiles/incomeExpenseOverview";
import KeyboardShortcutsTile from "../../components/tiles/keyboardShortcutsTile/KeyboardShortcutsTile";
import PurchaseTile from "../../components/tiles/purchaseTile/PuchaseTile";
import Welcome from "../../components/tiles/welcome";
import { ACCOUNTING_JOURNAL_API_URL, DASHBOARD_DATA_API, REST_API_URL } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { Sort, Status, TextAlign, ValueType } from "../../enums";
import { TRecordAny, TRecordType } from "../../global.types";
import {
    ROUTE_AGENDA_WORK_OVERVIEW,
    ROUTE_BANK_TRANSACTIONS,
    ROUTE_CASH_RECEIPTS,
    ROUTE_DOCUMENT_JOURNAL,
    ROUTE_INBOX,
    ROUTE_INTERNAL_DOCUMENT,
    ROUTE_JOURNAL_ENTRIES_LAST_MONTH,
    ROUTE_POSTED_DOCUMENTS_LAST_MONTH,
    ROUTE_TICKETS
} from "../../routes";
import { formatCurrency } from "../../types/Currency";
import DateType, { DATE_MAX, DATE_MIN, DateFormat, getUtcDayjs } from "../../types/Date";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import memoize from "../../utils/memoize";
import { isNotYetPayedForSubscription } from "../admin/subscriptions/Subscriptions.utils";
import { CustomerGeneralRoleId, isUserOwner } from "../admin/users/Users.utils";
import { userNameWithAvatarFormatter } from "../admin/users/UsersDef";
import { getCompanyVatStatementPeriod, VatStatementPeriod } from "../companies/Company.utils";
import {
    AccrualFilterTypes,
    ACCRUED_DOCUMENTS_FILTERNAME,
    EXPENSES_AND_REVENUES_FILTERNAME,
    ExpensesAndRevenuesFilterTypes
} from "../documents/internalDocument/InternalDocumentDef";
import {
    getDocumentJournalStatusFilter,
    getVatSubmissionPeriodName
} from "../electronicSubmission/VatSubmission.utils";
import { getDateFilterFromFY, getOldestActiveFY, getSortedFYs } from "../fiscalYear/FiscalYear.utils";
import { CommonReportProps } from "../reports/CommonDefs";
import {
    IReportData,
    IReportSettings,
    NumberAggregationFunction,
    ReportFilterNodeColumnType,
    ReportNodeOperator,
    TimeAggregationFunction
} from "../reports/Report.utils";
import { getLinkTileConfig, ticketsWidgetId } from "./CustomerDashboardDef";
import { CustomerPortal } from "./CustomerPortal.utils";
import { IDashboardManager } from "./DashboardManager";
import DemoTenant from "@components/tiles/demoTenant";
import AgendaPerformanceOverview from "@components/tiles/agendaPerformanceOverview";
import { CUSTOM_DATE_RANGE_ID } from "@pages/reports/customFilterComponents/ComposedDateRange.utils";

const CrossCompanyPrefix = "CrossCompany";

const INBOX_DATA_ENDPOINT = "Inbox";
const TICKETS_DATA_ENDPOINT = "Tickets";
const ACCRUED_DATA_ENDPOINT = "Accrued";
const OPEN_ACCRUED_DATA_ENDPOINT = "OpenAccrued";
const ELECTRONIC_SUBMISSION_DATA_ENDPOINT = "ElectronicSubmission";

const POSTED_DOCUMENTS_LAST_MONTH = "DocumentCount";
const JOURNAL_ENTRIES_LAST_MONTH = "JournalEntryCount";
const POSTED_DOCUMENTS_LAST_MONTH_PER_USER = "UserDocumentCount";

export const VAT_ACCOUNT_PREFIX = "343";
export type TAccountType = JournalEntryEntity.CreditAccount | JournalEntryEntity.DebitAccount;


export function hasAnyAgenda(args: IGetValueArgs): boolean {
    const { context } = args;
    return !!context.getData()?.companies?.length;
}

export function isInCustomerPortal(): boolean {
    return CustomerPortal.isActive;
}

export function agendaIsVatRegistered(args: IGetValueArgs): boolean {
    return isVatRegisteredCompany(args.context);
}

export function hasAnyCompanyPermission(...permissions: CompanyPermissionCode[]): (args: IGetValueArgs) => boolean {
    return ({ context }) => {
        const userPermissions = context.getCompanyPermissions();
        return !!permissions.find(permission => userPermissions?.has(permission));
    };
}

export function hasAnyGeneralPermission(...permissions: GeneralPermissionCode[]): (args: IGetValueArgs) => boolean {
    return ({ context }) => {
        const userPermissions = context.getGeneralPermissions();
        return !!permissions.find(permission => userPermissions?.has(permission));
    };
}

export function getVisibleDashboards(managers: IDashboardManager[], context: IAppContext): IDashboardManager[] {
    return managers.filter(manager => getInfoValue(manager.definition, "isVisible", { context }));
}

export function companyOverviewPageOr(fallbackRoute: LinkProps["to"]): (args: IGetValueArgs) => LinkProps["to"] {
    return (args) => {
        if (isOnOrganizationLevel(args)) {
            return ROUTE_AGENDA_WORK_OVERVIEW;
        }
        return fallbackRoute;
    };
}

export function drilldownLinkIfOnCompanyLevel(params: IGetDrillDownParams): (args: IGetValueArgs) => LinkProps["to"] {
    return (args) => {
        if (params && !isOnOrganizationLevel(args)) {
            return getDrillDownNavParams(params);
        }
        return null;
    };
}

export function getInfoDataTileCellValueWithSeverity(value: number): IInfoTileDataCell {
    return {
        value: Math.abs(value),
        severity: ifPositive(value, Status.Success, Status.Error)
    };
}

/**
 * Common function to obtain data for dashboard tile in two modes - for exact company or cross all companies,
 * user is allowed to view
 * @param endpoint
 * @param oData
 * @param context
 * @param signal
 */
async function getDashboardData<T>(endpoint: string, oData: OData, context: IAppContext, signal?: AbortSignal): Promise<T> {
    const prefix = isOnOrganizationLevel({ context }) ? CrossCompanyPrefix : "";
    const url = `${DASHBOARD_DATA_API}/${prefix}${endpoint}`;

    const res = await customFetch(url, { signal });

    return (await res.json()) as T;
}

/**
 * Function to obtain data for some widgets (Inbox, Accrued and OpenAccrued widgets) and overview page
 */
async function getSummaryData(endpoint: string, isCrossCompany: boolean): Promise<IEntity> {
    const prefix = isCrossCompany ? CrossCompanyPrefix : "";
    const url = `${DASHBOARD_DATA_API}/${prefix}${endpoint}`;

    const res = await customFetch(url);
    return res.json();
}

async function getSummaryDataSummed<R>(endpoint: string, context: IAppContext, infoPropName: string, additionalProps?: string[]): Promise<R> {
    const data = await getSummaryData(endpoint, isOnOrganizationLevel({ context }));

    const ret: Record<string, number> = {};

    const _add = (row: TRecordType<Record<string, number>>) => {
        const info = row?.[infoPropName];
        info && forEachKey(info, (key) => {
            ret[key] = (ret[key] ?? 0) + info[key];
        });
    };

    if (Array.isArray(data)) {
        data.forEach((row) => _add(row));
    } else {
        if (Array.isArray(additionalProps)) {
            additionalProps.forEach(prop => ret[prop] = data[prop]);
        }
        _add(data);
    }

    return ret as R;
}

export const getManualsDef = (): ILinkTileInfo => getLinkTileDef("Home:Links.Manuals", "https://www.evala.cz/navody", "Manual");

/**
 * Inbox tile definition & data --------------------------------------------------------------------
 */
interface IInboxDashboardData {
    Sorted: number;
    Received: number;
}

const getInboxData = (context: IAppContext) => getSummaryDataSummed<IInboxDashboardData>(INBOX_DATA_ENDPOINT, context, "InboxInfo");

export function getInboxTileDef(withoutLink?: boolean): IInfoTileInfo {
    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:Inbox.Heading"),
        link: withoutLink ? null : companyOverviewPageOr(null),
        infoData: async ({ context }) => {
            const data = await getInboxData(context);
            return [{
                value: data.Received,
                severity: data.Received > 0 ? Status.Warning : Status.Success,
                label: i18next.t("Home:Inbox.Unsorted", { count: data.Received }),
                link: drilldownLinkIfOnCompanyLevel({ route: ROUTE_INBOX, context })
            }, {
                value: data.Sorted,
                severity: data.Sorted > 0 ? Status.Warning : Status.Success,
                label: i18next.t("Home:Inbox.Sorted", { count: data.Sorted }),
                link: drilldownLinkIfOnCompanyLevel({ route: ROUTE_INBOX, context })
            }];
        },
        size: { w: 2, h: 1 }
    };
}

interface IVatSubmissionDashboardData extends Pick<IElectronicSubmissionEntity, "DateSubmission" | "DatePeriodStart" | "DatePeriodEnd"> {
    NextPeriodFrequencyCode: VatStatementFrequencyCode;
}

interface ITicketsDashboardData {
    OpenTickets: number;
    UnreadTickets: number;
}

const getTicketsData = (context: IAppContext) => getSummaryDataSummed<ITicketsDashboardData>(TICKETS_DATA_ENDPOINT, context, "TicketsInfo");

// link tile info with just badge indicating open tickets count
export function getTicketsTileDef(): ILinkTileInfo {
    return getLinkTileConfig(ticketsWidgetId, {
        link: companyOverviewPageOr(ROUTE_TICKETS),
        count: async (args: IGetTileDataArgs): Promise<number> => {
            const { context } = args;
            const data = await getTicketsData(context);
            return data.OpenTickets;
        }
    });
}

// info tile with open and unread tickets count
export function getTicketsInfoTileDef(withoutLink?: boolean): IInfoTileInfo {
    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:Tickets.Title"),
        link: withoutLink ? null : companyOverviewPageOr(null),
        infoData: async ({ context }) => {
            const data = await getTicketsData(context);
            return [{
                value: data.UnreadTickets,
                severity: data.UnreadTickets > 0 ? Status.Warning : null,
                label: i18next.t("Home:Tickets.Unread", { count: data.UnreadTickets }),
                link: drilldownLinkIfOnCompanyLevel({ route: ROUTE_TICKETS, context })
            }, {
                value: data.OpenTickets,
                label: i18next.t("Home:Tickets.Open", { count: data.OpenTickets }),
                link: drilldownLinkIfOnCompanyLevel({ route: ROUTE_TICKETS, context })
            }];
        },
        size: { w: 2, h: 1 }
    };
}

/**
 * Users' agenda data -----------------------------------------------------------------------------
 */
export const getUsersAgendaData = async (oData: OData): Promise<ITableTileData> => {
    const rows: IRow[] = [];
    const columns: IColumn[] = [
        { id: "name", label: "", textAlign: TextAlign.Left, width: 180 },
        { id: "count", label: "", textAlign: TextAlign.Right, width: 62 }
    ];

    const res = await oData.getEntitySetWrapper(EntitySetName.Users).query()
        .filter(`Id ge 0 AND GeneralRoles/all(x: x/GeneralRole/Id ne ${CustomerGeneralRoleId})`)
        .select("Id", "FirstName", "LastName", "Name")
        .expand("CompanyRoles", (q) => {
            q.count().top(0);
        }).fetchData<IUserEntity[]>();

    for (const r of res.value) {
        rows.push({
            id: r.Id,
            values: {
                name: userNameWithAvatarFormatter(r.FirstName, { entity: r }, {
                    isBold: true,
                    withLink: false
                }),
                count: r._metadata.CompanyRoles.count
            }
        });
    }

    return { rows, columns };
};

/**
 * Vat assessment turnover data - Invoice issued amount summary for last 12 months as limit if agenda becomes
 * automatically VatPayer
 */
interface AggregatedTotalObject {
    Amount: number;
}

export async function getVatAssessmentTurnoverData(oData: OData, isCBA = false): Promise<number> {
    const from = getUtcDayjs().subtract(1, "year");
    const to = getUtcDayjs();
    const propName = isCBA ? DocumentEntity.DateCbaDocument : DocumentEntity.DateAccountingTransaction;
    const query = oData.getEntitySetWrapper(EntitySetName.Documents).query()
        .filter(`${createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.CountryCode)} eq '${CountryCode.CzechRepublic}' 
        AND (
            ${DocumentEntity.DocumentTypeCode} in (${transformToODataString([DocumentTypeCode.InvoiceIssued, DocumentTypeCode.CorrectiveInvoiceIssued], ValueType.String)})
            OR (${DocumentEntity.DocumentTypeCode} eq ${transformToODataString(DocumentTypeCode.OtherReceivable, ValueType.String)} AND 
                ${DocumentEntity.DocumentVatStatementStatusCode} ne '${VatStatementStatusCode.N_A}' AND ${DocumentEntity.DocumentVatStatementStatusCode} ne '${VatStatementStatusCode.Undefined}')
      ) AND ${propName} gt ${transformToODataString(from, ValueType.Date)} 
        AND ${propName} le ${transformToODataString(to, ValueType.Date)}`)
        .aggregate(`${DocumentEntity.Amount} with sum as ${DocumentEntity.Amount}`);

    const res = await query.fetchData<AggregatedTotalObject[]>();

    return res.value?.[0]?.Amount ?? 0;
}

interface INextSubmission {
    period: VatStatementPeriod;
    submissionDate: Date;
}

// Next electronic submission date
export async function getNextElectronicSubmissionDate(lastSubmission: Date, oData: OData, context: IAppContext): Promise<INextSubmission> {
    let nextTaxPeriod: VatStatementPeriod;

    // FY is needed to get correct nextElectronicSubmissionDate
    await context.getFYPromise();

    if (lastSubmission) {
        nextTaxPeriod = getCompanyVatStatementPeriod(context, lastSubmission, 1);
    } else {
        const firstActiveFY = getOldestActiveFY(context);
        nextTaxPeriod = firstActiveFY && getCompanyVatStatementPeriod(context, firstActiveFY.DateStart);
    }

    let submissionDate: Date;
    if (nextTaxPeriod) {
        // get 25. day of month, which is after nextTaxPeriod.to
        submissionDate = getUtcDayjs(nextTaxPeriod.to).add(1, "day").set("date", 25).toDate();
    }

    return { period: nextTaxPeriod, submissionDate };
}

/**
 * Future expenses and revenues tile definition & data --------------------------------------------------------------------
 */
interface IAccruedDashboardData {
    Expense: number;
    Revenue: number;
}

const getAccruedData = (context: IAppContext) => getSummaryDataSummed<IAccruedDashboardData>(ACCRUED_DATA_ENDPOINT, context, "AccruedInfo");

export const getFutureExpensesAndRevenuesDrilldownParams = (context: IAppContext, type: ExpensesAndRevenuesFilterTypes): IGetDrillDownParams => ({
    route: ROUTE_INTERNAL_DOCUMENT,
    context,
    filters: {
        [EXPENSES_AND_REVENUES_FILTERNAME]: [type]
    }
});

export function getFutureExpensesAndRevenuesDef(withoutLink?: boolean): IInfoTileInfo {
    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:FutureExpensesAndRevenues.Heading"),
        link: withoutLink ? null : companyOverviewPageOr(null),
        infoData: async ({ context }) => {
            const { Expense, Revenue } = await getAccruedData(context);
            return [{
                value: Expense ?? 0,
                label: i18next.t("Home:FutureExpensesAndRevenues.Expenses"),
                link: drilldownLinkIfOnCompanyLevel(getFutureExpensesAndRevenuesDrilldownParams(context, ExpensesAndRevenuesFilterTypes.Expenses))
            }, {
                value: Revenue ?? 0,
                label: i18next.t("Home:FutureExpensesAndRevenues.Revenues"),
                link: drilldownLinkIfOnCompanyLevel(getFutureExpensesAndRevenuesDrilldownParams(context, ExpensesAndRevenuesFilterTypes.Revenues))
            }];
        },
        isVisible: ifAny(isAccountAssignmentCompanyValue, isOnOrganizationLevel),
        size: { w: 2, h: 1 }
    };
}

/**
 * estimated accruals tile definition & data --------------------------------------------------------------------
 */
interface IOpenAccruedDashboardDataFiscalYearInfo {
    Id: number;
    DateEnd: Date;
    DateStart: Date;
}

interface IOpenAccruedDashboardData {
    ThisFiscalYear: number;
    LastFiscalYear: number;
    ThisFiscalYearInfo?: IOpenAccruedDashboardDataFiscalYearInfo;
    LastFiscalYearInfo?: IOpenAccruedDashboardDataFiscalYearInfo;
}

const getOpenAccruedData = (context: IAppContext) => getSummaryDataSummed<IOpenAccruedDashboardData>(OPEN_ACCRUED_DATA_ENDPOINT, context, "OpenAccruedInfo", ["ThisFiscalYearInfo", "LastFiscalYearInfo"]);

const getOpenAccruedFilterDrilldownParams = (context: IAppContext, filters: TRecordAny): IGetDrillDownParams => ({
    route: ROUTE_INTERNAL_DOCUMENT,
    context,
    filters: {
        [ACCRUED_DOCUMENTS_FILTERNAME]: [AccrualFilterTypes.Open],
        ...filters
    }
});

interface IOpenAccruedDrilldowns {
    ThisFiscalYearParams: IGetDrillDownParams;
    LastFiscalYearParams: IGetDrillDownParams;
}

export function getOpenAccruedDrilldowns(context: IAppContext, info?: Pick<IOpenAccruedDashboardData, "ThisFiscalYearInfo" | "LastFiscalYearInfo">): IOpenAccruedDrilldowns {
    let FYs: Partial<IFiscalYearEntity>[];
    if (info) {
        FYs = [info?.ThisFiscalYearInfo, info?.LastFiscalYearInfo];
    } else {
        FYs = getSortedFYs(context, Sort.Desc)
            .filter(fy => getUtcDayjs().isSameOrAfter(fy.DateStart, "date")); // filter out future fiscalYears
    }

    const [thisFY, lastFY] = FYs ?? [];

    const LastFiscalYearParams = lastFY && getOpenAccruedFilterDrilldownParams(context, { ...getDateFilterFromFY(lastFY) });
    const ThisFiscalYearParams = thisFY && getOpenAccruedFilterDrilldownParams(context, { ...getDateFilterFromFY(thisFY) });

    return { ThisFiscalYearParams, LastFiscalYearParams };
}

export function getEstimatedAccrualsDef(withoutLink?: boolean): IInfoTileInfo {
    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:EstimatedAccruals.Heading"),
        link: withoutLink ? null : companyOverviewPageOr(null),
        infoData: async ({ context, oData }): Promise<IInfoTileDataCell[]> => {
            const companyId = context.getCompanyId();
            const [data] = await Promise.all([getOpenAccruedData(context)]);
            if (companyId !== context.getCompanyId()) {
                // User switched company in the meantime, stop processing it then as getOpenAccruedDrilldowns
                // may fail on missing FYs
                return [];
            }

            const { ThisFiscalYear, LastFiscalYear, ThisFiscalYearInfo, LastFiscalYearInfo } = data;
            const {
                ThisFiscalYearParams,
                LastFiscalYearParams
            } = getOpenAccruedDrilldowns(context, { ThisFiscalYearInfo, LastFiscalYearInfo });

            return [{
                value: LastFiscalYear ?? 0,
                label: i18next.t("Home:EstimatedAccruals.LastYear"),
                link: drilldownLinkIfOnCompanyLevel(LastFiscalYearParams)
            }, {
                value: ThisFiscalYear ?? 0,
                label: i18next.t("Home:EstimatedAccruals.ThisYear"),
                link: drilldownLinkIfOnCompanyLevel(ThisFiscalYearParams)
            }];
        },
        isVisible: ifAny(isAccountAssignmentCompanyValue, isOnOrganizationLevel),
        size: { w: 2, h: 1 }
    };
}

export interface IAgendaWorkOverviewData extends Partial<IInboxDashboardData & ITicketsDashboardData & IAccruedDashboardData & IOpenAccruedDashboardData & IVatSubmissionDashboardData> {
}

export type TAgendaWorkOverviewDataMap = Map<number, IAgendaWorkOverviewData>;

/**
 * Data for agenda work overview
 */
export async function getAgendaWorkOverviewData(): Promise<TAgendaWorkOverviewDataMap> {
    const [inboxData, ticketsData, accruedData, openAccruedData, electronicSubmissionData] = await Promise.all([
        getSummaryData(INBOX_DATA_ENDPOINT, true),
        getSummaryData(TICKETS_DATA_ENDPOINT, true),
        getSummaryData(ACCRUED_DATA_ENDPOINT, true),
        getSummaryData(OPEN_ACCRUED_DATA_ENDPOINT, true),
        getSummaryData(ELECTRONIC_SUBMISSION_DATA_ENDPOINT, true)
    ]);

    const ret = new Map<number, IAgendaWorkOverviewData>();

    const _extractRow = (row: TRecordAny, propName: string, additionalInfos?: ("ThisFiscalYearInfo" | "LastFiscalYearInfo")[]) => {
        const companyId = row.CompanyId as number;
        const data = (ret.get(companyId) ?? {}) as IAgendaWorkOverviewData;
        row[propName] && forEachKey(row[propName], (key) => {
            // @ts-ignore
            data[key] = row[propName][key];
            additionalInfos?.forEach((additionalKey) => {
                if (row.hasOwnProperty(additionalKey)) {
                    data[additionalKey] = row[additionalKey];
                }
            });
        });
        ret.set(companyId, data);
    };

    inboxData?.forEach?.((row: TRecordAny) => _extractRow(row, "InboxInfo"));
    ticketsData?.forEach?.((row: TRecordAny) => _extractRow(row, "TicketsInfo"));
    accruedData?.forEach?.((row: TRecordAny) => _extractRow(row, "AccruedInfo"));
    openAccruedData?.forEach?.((row: TRecordAny) => _extractRow(row, "OpenAccruedInfo", ["ThisFiscalYearInfo", "LastFiscalYearInfo"]));
    electronicSubmissionData?.forEach?.((row: TRecordAny) => _extractRow(row, "ElectronicSubmissionInfo"));

    return ret;
}

interface IPostedDocumentsLastMonthDashboardData {
    Count: number;
    DateRange: {
        DateStart: string;
        DateEnd: string;
    };
}

const getPostedDocumentsLastMonth = (...args: [OData, IAppContext, AbortSignal]) => getDashboardData<IPostedDocumentsLastMonthDashboardData>(POSTED_DOCUMENTS_LAST_MONTH, ...args);

export function getPostedDocumentsLastMonthDef(): IInfoTileInfo {
    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:PostedDocumentsLastMonth.Heading"),
        link: ROUTE_POSTED_DOCUMENTS_LAST_MONTH,
        infoData: async ({ context, oData, signal }) => {
            const { Count, DateRange } = await getPostedDocumentsLastMonth(oData, context, signal);
            const { DateEnd } = DateRange;
            const period = DateType.format(getUtcDayjs(DateEnd), DateFormat.monthAndYear);
            return [{
                value: Count,
                label: i18next.t("Home:PostedDocumentsLastMonth.Label", { period })
            }];
        },
        size: { w: 2, h: 1 },
        isVisible: hasAnyGeneralPermission(GeneralPermissionCode.CompanyManagement)
    };
}

interface IJournalEntriesLastMonthLastMonthDashboardData {
    Count: number;
    DateRange: {
        DateStart: string;
        DateEnd: string;
    };
}

const getJournalEntriesLastMonth = (...args: [OData, IAppContext, AbortSignal]) => getDashboardData<IJournalEntriesLastMonthLastMonthDashboardData>(JOURNAL_ENTRIES_LAST_MONTH, ...args);

export function getJournalEntriesLastMonthDef(): IInfoTileInfo {
    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:JournalEntriesLastMonth.Heading"),
        link: ROUTE_JOURNAL_ENTRIES_LAST_MONTH,
        infoData: async ({ context, oData, signal }) => {
            const { Count, DateRange } = await getJournalEntriesLastMonth(oData, context, signal);
            const { DateEnd } = DateRange;
            const period = DateType.format(getUtcDayjs(DateEnd), DateFormat.monthAndYear);
            return [{
                value: Count,
                label: i18next.t("Home:JournalEntriesLastMonth.Label", { period })
            }];
        },
        size: { w: 2, h: 1 },
        isVisible: hasAnyGeneralPermission(GeneralPermissionCode.CompanyManagement)
    };
}

interface IPostedDocumentsLastMonthPerUserDashboardData {
    User: string;
    Count: number;
}

const getPostedDocumentsLastMonthPerUser = (...args: [OData, IAppContext, AbortSignal]) => getDashboardData<IPostedDocumentsLastMonthPerUserDashboardData[]>(POSTED_DOCUMENTS_LAST_MONTH_PER_USER, ...args);

export function getPostedDocumentsLastMonthPerUserDef(): ITableTileInfo {
    return {
        type: DashboardTileType.Table,
        title: i18next.t("Home:PostedDocumentsLastMonthPerUser.Heading"),
        size: { w: 3, h: 3 },
        tooltip: i18next.t("Home:PostedDocumentsLastMonthPerUser.Info"),
        tableData: async ({ context, oData, signal }) => {
            const data = await getPostedDocumentsLastMonthPerUser(oData, context, signal);
            const columns: IColumn[] = [
                {
                    id: "name",
                    label: i18next.t("Home:PostedDocumentsLastMonthPerUser.Name"),
                    textAlign: TextAlign.Left
                },
                {
                    id: "docsCount",
                    label: i18next.t("Home:PostedDocumentsLastMonthPerUser.DocsCount"),
                    textAlign: TextAlign.Right
                }
            ];
            const rows: IRow[] = [];

            for (const row of data) {
                rows.push({
                    id: rows.length + 1,
                    values: {
                        name: row.User,
                        docsCount: row.Count
                    }
                });
            }

            return {
                rows,
                columns
            };
        },
        isVisible: hasAnyGeneralPermission(GeneralPermissionCode.CompanyManagement)
    };
}

export function getWelcomeTileDef(): ICustomTileInfo {
    return {
        title: i18next.t("Home:Welcome.Title"),
        type: DashboardTileType.Custom,
        size: { w: 2, h: 2 },
        component: Welcome
    };
}

export function getVideoHelpTileDef(): ICustomTileInfo {
    return {
        title: i18next.t("Home:VideoHelp.Title"),
        type: DashboardTileType.Custom,
        size: { w: 2, h: 2 },
        component: lazy(() => import("@components/tiles/videoHelpTile/VideoHelpTile"))
    };
}

export function getLinkTileDef(title: string, link: TInfoValue<LinkProps["to"]>, iconName: string, props?: Partial<ILinkTileInfo>): ILinkTileInfo {
    return {
        type: DashboardTileType.Link,
        size: { w: 1, h: 1 },
        title: i18next.t(title), link, iconName,
        ...(props ?? {})
    };
}

export function getPurchaseTileDef(): ICustomTileInfo {
    return {
        title: i18next.t("Home:Purchase.Title"),
        type: DashboardTileType.Custom,
        size: { w: 2, h: 2 },
        component: PurchaseTile,
        isVisible: (args: IGetValueArgs) => {
            const data = args.context.getData();

            if (!isUserOwner(data?.userSettings)) {
                return false;
            }

            const subscription = data?.subscription;

            return isNotYetPayedForSubscription(subscription);
        },
        cannotBeRemovedInSettings: true
    };
}

export function getKeyboardShortcutsTileDef(): ICustomTileInfo {
    return {
        title: i18next.t("Home:KeyboardShortcuts.Heading"),
        type: DashboardTileType.Custom,
        size: { w: 2, h: 3 },
        component: KeyboardShortcutsTile
    };
}

export function getBankAccountsTileDef(): ITableTileInfo {
    return {
        type: DashboardTileType.Table,
        title: i18next.t("Home:BankAccounts.Heading"),
        size: { w: 3, h: 2 },
        tableData: args => getBalancesData(args, EntitySetName.CompanyBankAccounts),
        isVisible: hasAnyCompanyPermission(CompanyPermissionCode.CustomerSettings)
    };
}

export function getPaymentDocumentTileDef(entitySet: EntitySetName): IInfoTileInfo {
    const entity = entitySet === EntitySetName.BankTransactions ? BankTransactionEntity : CashReceiptEntity;
    return {
        type: DashboardTileType.Info,
        title: i18next.t(`Home:${entitySet}.Heading`),
        link: entitySet === EntitySetName.BankTransactions ? ROUTE_BANK_TRANSACTIONS : ROUTE_CASH_RECEIPTS,
        infoData: async ({ oData }) => {
            const res = await oData.getEntitySetWrapper(entitySet)
                .query()
                .filter(`${entity.PaymentStatusCode} in ('${PaymentStatusCode.NeedsApproval}', '${PaymentStatusCode.WaitsForProcessing}', '${PaymentStatusCode.PartiallyProcessed}')`)
                .select(`${entity.ExchangeRatePerUnit}, ${entity.TransactionAmountDue}`)
                .fetchData<(IBankTransactionEntity | ICashReceiptEntity)[]>();

            const transactions = res.value ?? [];
            const totalAmountDue = transactions.reduce((sum: number, t) => {
                sum += Math.abs(t.TransactionAmountDue) * t.ExchangeRatePerUnit;
                return sum;
            }, 0);

            return [{
                value: transactions.length,
                severity: transactions.length ? Status.Warning : Status.Success,
                label: i18next.t(`Home:${entitySet}.Documents`, { count: transactions.length })
            }, {
                value: totalAmountDue,
                formatter: infoTileCurrencyFormatter,
                label: i18next.t(`Home:${entitySet}.Amount`),
                unit: "Kč",
                size: 2
            }];
        },
        size: { w: 3, h: 1 },
        isVisible: hasAnyCompanyPermission(entitySet === EntitySetName.BankTransactions ? CompanyPermissionCode.Bank : CompanyPermissionCode.CashBox)
    };
}

export function getCashBoxTileDef(): ITableTileInfo {
    return {
        type: DashboardTileType.Table,
        title: i18next.t("Home:CashBox.Heading"),
        size: { w: 3, h: 2 },
        tableData: args => getBalancesData(args, EntitySetName.CashBoxes),
        isVisible: hasAnyCompanyPermission(CompanyPermissionCode.CashBox)
    };
}

export function getCustomerSupportTileDef(): ICustomTileInfo {
    return {
        type: DashboardTileType.Custom,
        title: i18next.t("Home:CustomerSupport.Heading"),
        size: { w: 2, h: 1 },
        component: CustomerSupportTile
    };
}

const getBalancesData = async (args: IGetTileDataArgs, entitySet: EntitySetName): Promise<ITableTileData> => {
    const tKey = entitySet === EntitySetName.CompanyBankAccounts ? "BankAccounts" : "CashBox";
    const columns: IColumn[] = [
        { id: "name", label: i18next.t(`Home:${tKey}.Name`), textAlign: TextAlign.Left },
        { id: "balance", label: i18next.t(`Home:${tKey}.Balance`), textAlign: TextAlign.Right }
    ];

    const res = await args.oData.getEntitySetWrapper(entitySet)
        .query()
        .select(CashBoxEntity.Id, CashBoxEntity.Name, CashBoxEntity.Balance, CashBoxEntity.TransactionCurrencyCode)
        .expand(CashBoxEntity.Balance)
        .fetchData<ICashBoxEntity[]>();

    const rows: IRow[] = [];

    for (const acc of res.value) {
        rows.push({
            id: acc.Id,
            values: {
                name: {
                    tooltip: acc.Name,
                    value: (<b>{acc.Name}</b>)
                },
                balance: formatCurrency(acc.Balance?.TransactionBalance, acc.TransactionCurrencyCode)
            }
        });
    }

    return { rows, columns };
};

export function getNotClearedDocumentsDef(): IInfoTileInfo {
    const filteredStatuses = [ClearedStatusCode.NotCleared, ClearedStatusCode.PartiallyCleared];

    return {
        type: DashboardTileType.Info,
        title: i18next.t("Home:NotClearedDocuments.Heading"),
        link: ({ context }: IGetValueArgs) => {
            const journalFilter = getDocumentJournalStatusFilter(EntityTypeName.ClearedStatus, filteredStatuses);
            const previousYearDateRange: IValueInterval = {
                from: formatDateToDateString(DATE_MIN),
                to: formatDateToDateString(DATE_MAX)
            };

            return getDrillDownNavParams({
                route: ROUTE_DOCUMENT_JOURNAL,
                context,
                filters: {
                    [journalFilter.id]: journalFilter.value,
                    [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
                    [CommonReportProps.dateRangeCustomValue]: previousYearDateRange
                }
            });
        },
        infoData: async ({ oData }) => {
            const res = await oData.getEntitySetWrapper(EntitySetName.Documents)
                .query()
                .filter(`${DocumentEntity.ClearedStatusCode} in (${transformToODataString(filteredStatuses, ValueType.String)})`)
                .select(`${DocumentEntity.Id}`)
                .count()
                .top(0)
                .fetchData<IDocumentEntity[]>();

            const count = res._metadata.count ?? 0;

            return [{
                value: count,
                label: i18next.t("Home:NotClearedDocuments.Label", { count }),
                severity: Status.Warning
            }];
        },
        size: { w: 2, h: 1 },
        isVisible: hasAnyCompanyPermission(CompanyPermissionCode.Reports)
    };
}

export function getIncomeExpenseOverviewTileDef(): ICustomTileInfo {
    return {
        type: DashboardTileType.Custom,
        title: i18next.t("Home:IncomeExpenseOverview.Heading"),
        isVisible: (args: IGetValueArgs) => {
            return isCashBasisAccountingCompany(args.context);
        },
        component: IncomeExpenseOverview,
        size: { w: 3, h: 2 }
    };
}

export function getAgendaWorkOverviewTileDef(isCrossCompany: boolean): ICustomTileInfo {
    return {
        type: DashboardTileType.Custom,
        title: i18next.t(`Home:AgendaWorkOverview.${isCrossCompany ? "Title" : "SingleCompanyTitle"}`),
        component: AgendaWorkOverview,
        size: { w: 4, h: 2 },
        isVisible: (args: IGetValueArgs) => {
            return !isCashBasisAccountingCompany(args.context);
        }
    };
}

export function getAgendaWorkOverviewTileDefCashBasisAccounting(): ICustomTileInfo {
    return {
        type: DashboardTileType.Custom,
        title: i18next.t("Home:AgendaWorkOverview.SingleCompanyTitle"),
        component: AgendaWorkOverview,
        size: { w: 2, h: 2 },
        isVisible: (args: IGetValueArgs) => {
            return isCashBasisAccountingCompany(args.context);
        }
    };
}

export function getAgendaPerformanceOverviewTileDef(): ICustomTileInfo {
    return {
        type: DashboardTileType.Custom,
        title: i18next.t("Home:AgendaPerformanceOverview.Title"),
        component: AgendaPerformanceOverview,
        size: { w: 2, h: 2 }
    };
}

export function getDemoTenantTileDef(): ICustomTileInfo {
    return {
        type: DashboardTileType.Custom,
        title: i18next.t("Home:DemoTenant.Title"),
        isVisible: (args: IGetValueArgs) => {
            return !isDemoTenant(args.context);
        },
        component: DemoTenant,
        size: { w: 1, h: 1 }
    };
}

export async function getVatOverviewChartTileData(args: IGetTileDataArgs): Promise<IChartTileData<ChartType.Bar>> {
    const electronicSubmissionsRes = await args.oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions).query()
        .filter(`${ElectronicSubmissionEntity.ElectronicSubmissionTypeCode} eq '${ElectronicSubmissionTypeCode.VatStatement}'`)
        .orderBy(ElectronicSubmissionEntity.DatePeriodEnd, false)
        .top(12)
        .fetchData<IElectronicSubmissionEntity[]>();
    const electronicSubmissions = electronicSubmissionsRes.value?.reverse() ?? [];
    let entries: ICompoundJournalEntryEntity[] = [];
    if (electronicSubmissions[0]?.DatePeriodStart) {
        const dateESVSpath = createPath(CompoundJournalEntryEntity.Document, DocumentEntity.DateDocumentVatStatement);
        const entriesRes = await args.oData.getEntitySetWrapper(EntitySetName.CompoundJournalEntries).query()
            .filter(`startswith(${CompoundJournalEntryEntity.Account}/${AccountEntity.Number}, '${VAT_ACCOUNT_PREFIX}')
            AND ${dateESVSpath} ge ${transformToODataString(electronicSubmissions[0]?.DatePeriodStart, ValueType.Date)} AND ${createPath(CompoundJournalEntryEntity.Document, DocumentEntity.VatStatementStatusCode)} in ('${VatStatementStatusCode.Filed}','${VatStatementStatusCode.FiledAndModified}')`)
            .groupBy(dateESVSpath)
            .aggregate(`${CompoundJournalEntryEntity.CreditAmount} with sum as CreditAmount, ${CompoundJournalEntryEntity.DebitAmount} with sum as DebitAmount`)
            .fetchData<ICompoundJournalEntryEntity[]>();

        entries = entriesRes.value;
    }
    const labels = electronicSubmissions.map(es => {
        const freq = isSameMonth(es.DatePeriodStart, es.DatePeriodEnd) ? VatStatementFrequencyCode.Monthly : VatStatementFrequencyCode.Quarterly;
        return getVatSubmissionPeriodName(es.DatePeriodStart, freq, true);
    }) ?? [];

    const credit: number[] = electronicSubmissions.map((es) => {
        let sum = 0;
        for (const entry of entries) {
            if (getUtcDayjs(entry.Document.DateDocumentVatStatement).isBetween(es.DatePeriodStart, es.DatePeriodEnd, "day", "[]")) {
                sum += entry.CreditAmount;
            }
        }
        return sum;
    });

    const debit: number[] = electronicSubmissions.map((es) => {
        let sum = 0;
        for (const entry of entries) {
            if (getUtcDayjs(entry.Document.DateDocumentVatStatement).isBetween(es.DatePeriodStart, es.DatePeriodEnd, "day", "[]")) {
                sum += entry.DebitAmount;
            }
        }
        return sum;
    });

    return {
        type: ChartType.Bar,
        chartProps: {
            labels,
            values: [credit, debit],
            colors: ["C_CHART_green", "C_CHART_red"],
            dataSetLabels: [i18next.t("Home:VatOverviewChart.Income"), i18next.t("Home:VatOverviewChart.Expense")]
        }
    };
}


export function timeAggregationToOpUnitType(aggregation: TimeAggregationFunction): UnitType {
    switch (aggregation) {
        case TimeAggregationFunction.Day:
            return "day";
        case TimeAggregationFunction.Month:
            return "month";
        case TimeAggregationFunction.Year:
            return "year";
        case TimeAggregationFunction.Week:
        case TimeAggregationFunction.Quarter:
            console.error("Not supported aggregation", aggregation);
            return null;
    }
}

export function getChartLabels(interval: IDateInterval, step: TimeAggregationFunction = TimeAggregationFunction.Month, format = "M/YY"): string[] {
    const labels: string[] = [];
    let i = getUtcDayjs(interval.from);
    const unit = timeAggregationToOpUnitType(step);
    while (i.isSameOrBefore(interval.to, unit)) {
        labels.push(i.format(format));
        i = i.add(1, unit);
    }
    return labels;
}

function getAccountingJournalParams(type: TAccountType, account: string, interval: IDateInterval, aggregationFn?: TimeAggregationFunction): IReportSettings {
    const filterColumn = {
        ColumnAlias: type === JournalEntryEntity.CreditAccount ? "CreditAccount_Number" : "DebitAccount_Number"
    };
    const Groups = aggregationFn ? [{
        ColumnAlias: "JournalEntry_DateAccountingTransaction",
        AggregationFunction: aggregationFn
    }] : [];

    return {
        DateRange: {
            DateStart: formatDateToDateString(interval.from),
            DateEnd: formatDateToDateString(interval.to)
        },
        Filter: {
            Left: filterColumn,
            Right: { Value: account },
            Type: ReportFilterNodeColumnType.String,
            Operator: ReportNodeOperator.StartsWith
        },
        ReportHierarchy: {
            Aggregate: true,
            Groups,
            Columns: [filterColumn],
            Aggregations: [{
                ColumnAlias: "JournalEntry_Amount",
                AggregationFunction: NumberAggregationFunction.Sum
            }]
        }
    };
}

/**
 * Returns summary for journalEntries per account
 * @param account
 * @param interval
 * @param aggregationFn
 */
export const getJournalSummaryData = memoize(async (account: string, interval: IDateInterval, aggregationFn?: TimeAggregationFunction): Promise<number[]> => {
    const types = [JournalEntryEntity.CreditAccount, JournalEntryEntity.DebitAccount] as TAccountType[];
    const aggregatedPoints = getChartLabels(interval, aggregationFn, "YYYY-MM-DD");

    const _extractTotalRowAmount = (rows: IReportRowDef[]): number => {
        const totalRow = rows?.find(row => row.Type === "Total");
        return (totalRow?.Value["JournalEntry_Amount_SUM"] ?? 0) as number;
    };

    const _extractAggregatedData = (rows: IReportRowDef[]): number[] => {
        return aggregatedPoints.map(formattedDate => {
            const groupedResult = rows?.find(row => row.Type === "Group" && row.Value[`JournalEntry_DateAccountingTransaction_${aggregationFn}`].toString().startsWith(formattedDate));
            return groupedResult ? _extractTotalRowAmount(groupedResult.Rows) : 0;
        });
    };

    const ret: number[] = [];

    try {
        const promises = types.map(accountType => {
            const params = getAccountingJournalParams(accountType, account, interval, aggregationFn);

            return customFetch(ACCOUNTING_JOURNAL_API_URL, {
                ...getDefaultPostParams(),
                body: JSON.stringify(params)
            })
                .then(response => response.json())
                .then((data: IReportData) => aggregationFn ? _extractAggregatedData(data.Rows) : [_extractTotalRowAmount(data.Rows)]);
        });

        const [credit, debit] = await Promise.all(promises);
        credit.forEach((num, idx) => {
            ret.push(num - debit[idx]);
        });

    } catch (e) {
        // todo: handleError
        throw e;
    }

    return ret;
}, (account, interval, aggregationFn) => [account, interval.from, interval.to, aggregationFn]);

export interface IFyCloseOverviewData {
    AccountingCode: AccountingCode;
    Company: string;
    CompanyId: number;
    DateEnd: Date;
    DateStart: Date;
    FiscalYearNumber: string;
    SectionCount: number;
    SectionDone: number;
}

export type TFyCloseOverviewData = Map<number, IFyCloseOverviewData>;

export const getFYcloseOverviewData = async (): Promise<TFyCloseOverviewData> => {
    const res = await customFetch(`${REST_API_URL}/DashboardData/CrossCompanyFiscalYearClosed`);
    const fycData = await res.json() as IFyCloseOverviewData[];
    const ret = new Map<number, IFyCloseOverviewData>();
    fycData?.forEach((row) => {
        ret.set(row.CompanyId, row);
    });

    return ret;
};

export const getAssetAnalysisData = memoize(async (FYId: number): Promise<number> => {
    const assetAnalysisProps: TRecordAny = {
        "FiscalYearId": FYId,
        "ReportHierarchy": {
            "Aggregate": true,
            "Groups": [],
            "Columns": [],
            "Aggregations": [{
                ColumnAlias: "Asset_CalculatedPrice",
                AggregationFunction: NumberAggregationFunction.Sum
            }]
        }
    };

    const res = await customFetch(`${REST_API_URL}/AssetAnalysis`, {
        ...getDefaultPostParams(),
        body: JSON.stringify(assetAnalysisProps)
    });

    const data = await res.json();

    return data?.Rows?.[0]?.Value?.["Asset_CalculatedPrice_SUM"] ?? 0;
});