import { IAlertProps } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { BreadCrumbProvider } from "@components/breadCrumb/BreadCrumbProvider";
import BusyIndicator from "@components/busyIndicator/BusyIndicator";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IconButton } from "@components/button";
import Dialog from "@components/dialog/Dialog";
import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import { AddIcon } from "@components/icon";
import { IInputOnChangeEvent } from "@components/inputs/input";
import WriteLine from "@components/inputs/writeLine/WriteLine";
import { WriteLineWrapper } from "@components/navigation/NavDashboard.styles";
import { IListItem, ListItemContentRow } from "@components/objectList";
import ObjectList, { ISection } from "@components/objectList/ObjectList";
import { ScrollBar } from "@components/scrollBar/ScrollBar";
import ViewHeader from "@components/smart/smartHeader/SmartHeader";
import { Toolbar } from "@components/toolbar";
import { IEntity, TEntityKey } from "@odata/BindingContext";
import { isODataError } from "@odata/Data.types";
import { EntitySetName, IChartOfAccountsTemplateEntity, ICompanySettingEntity } from "@odata/GeneratedEntityTypes";
import { AccountingCode } from "@odata/GeneratedEnums";
import { withOData } from "@odata/withOData";
import { isVisibleInContainer, scrollIntoView } from "@utils/domUtils";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import { anyPartStartsWithAccentsInsensitive } from "@utils/string";
import dayjs from "dayjs";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { IconSize, Status, TextAlign, ToolbarItemType } from "../../enums";
import { TDefinitionFn, TRecordAny } from "../../global.types";
import { IHistoryState } from "../../model/Model";
import { ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES, ROUTE_NOT_FOUND } from "../../routes";
import DateType from "../../types/Date";
import KeyboardShortcutsManager from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { TableButtonsAction } from "../../views/table/TableToolbar.utils";
import {
    cloneChartOfAccountsTemplate,
    createMinimalChartOfAccountsTemplate
} from "../chartOfAccounts/ChartOfAccounts.utils";
import NewTemplateDialog from "../chartOfAccounts/NewTemplateDialog";
import { IPageState } from "../Page";
import ParentChildPage, { IParentPageProps } from "../ParentChildPage";
import { ChartOfAccountsTemplatesViewStyled } from "./ChartOfAccountsTemplates.styles";
import { getUniqName } from "./ChartOfAccountsTemplates.utils";
import { ChartOfAccountTemplateContext, ICOATemplateContext } from "./ChartOfAccountsTemplatesContext";
import { getDefinitions } from "./ChartOfAccountsTemplatesDef";
import ChartOfAccountsTemplatesTableView from "./ChartOfAccountsTemplatesTableView";
import { ObjectListAlertWrapper } from "@components/objectList/ObjectList.styles";

interface IProps extends IParentPageProps, WithAlert, WithBusyIndicator {
    className?: string;
    renderWithoutHeader?: boolean;
    renderWithoutBreadCrumbs?: boolean;
    withoutPadding?: boolean;
    renderScrollBar?: boolean;
}

interface ITemplateAction {
    templateId?: TEntityKey;
    type: ChartOfAccountsTemplateAction;
    confirmed?: boolean;
}

interface IState extends IPageState {
    action?: ITemplateAction;

    definition?: TDefinitionFn;
    filter?: string;
    companySettings?: ICompanySettingEntity;
}

enum ChartOfAccountsTemplateAction {
    EDIT = "edit",
    DELETE = "delete",
    COPY = "copy",
    ADD = "add",
    VIEW = "view"
}

interface IAlertMessageData {
    key: string;
    alert: IAlertProps;
}

class ChartOfAccountsTemplates extends ParentChildPage<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        tableView: ChartOfAccountsTemplatesTableView,
        getDef: getDefinitions,
        renderScrollBar: true
    };

    _scrollRef = React.createRef<HTMLDivElement>();

    // usually, this is stored on a Model, but we don't use any here => use custom variable
    initialHistoryState?: IHistoryState;
    templateContext: ICOATemplateContext;
    _shouldCloseOnSaveName = false;
    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        this.initialHistoryState = this.props.location.state as IHistoryState;

        let action;
        if (this.initialHistoryState?.customData?.created) {
            // Redirected from ChartOfAccountsTableView, where the actual template was created
            //  -> adds action to state, so the flow is same as when we create a new template on this page
            action = {
                templateId: this.getParentKey(),
                type: ChartOfAccountsTemplateAction.ADD
            };
        }

        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_N],
            callback: this.handleKeyboardShortcut
        });

        this.templateContext = {
            isChangingName: false,
            isChanged: false,
            onTemplateNameChange: this.handleTemplateNameChange,
            onTemplateNameChangeFailed: this.handleTemplateNameChangeFailed
        };

        this.state = {
            parentId: null,
            childId: null,
            filter: "",
            action
        };
    }

    getContext = () => {
        return this.context as IAppContext;
    };

    get showDialog() {
        return !!this.getParentKey();
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.setupMessage();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        await super.componentDidUpdate(prevProps, prevState);

        const templateId = this.getParentKey();
        const prevId = this.props.dontUseUrlParams ? prevState.parentId : prevProps.match.params.ParentId;
        if ((!!templateId || !!prevId) && templateId !== prevId) {
            this.setupMessage();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._unsubscribeKeyboardShortcuts?.();
    }

    handleKeyboardShortcut = (shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.ALT_N) {
            this.handleAdd();
            return true;
        }

        return false;
    };

    redirectToFirstParent() {
        /* Templates has no default parent - just parent list is displayed when there is no parentId,
           so we don't want to redirect to the first one (do not call "super()" here) */

        // but if ID is defined, and it's not in parents -> redirect to error route (as it's invalid ID)
        if (this.parents?.length) {
            const templateId = this.getParentKey();
            if (templateId && !this.parents?.find(item => item.Id.toString() === templateId)) {
                // invalid template ID
                this.props.history.replace(ROUTE_NOT_FOUND);
            }
        }
    }

    setBreadCrumbs = () => {
        this.context.setViewBreadcrumbs({
            lockable: false,
            items: [{
                key: "templates",
                link: ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES,
                title: this.props.t("ChartsOfAccountsTemplates:Title")
            }]
        });
    };

    setupMessage = () => {
        const template = this.getParentEntity();
        if (!template) {
            // Id was removed from URL - do same actions as dialog would be closed
            this.prepareMessage();

            // clears action
            this.confirmAction(false);
        }
        this.templateContext.isChanged = false;
    };

    createTemplateProperties = (item: IEntity): IListItem => {
        const actions = [];
        if (item.Id > 0) {
            actions.push({
                id: ChartOfAccountsTemplateAction.EDIT,
                label: this.props.t("Common:General.Edit"),
                iconName: "Edit",
                itemType: ToolbarItemType.Icon
            }, {
                id: ChartOfAccountsTemplateAction.DELETE,
                label: this.props.t("Common:General.Remove"),
                iconName: "Bin",
                itemType: ToolbarItemType.Icon
            });
        } else {
            actions.push({
                id: ChartOfAccountsTemplateAction.VIEW,
                label: this.props.t("Common:General.View"),
                iconName: "Visible",
                itemType: ToolbarItemType.Icon
            });
        }

        actions.push({
            id: ChartOfAccountsTemplateAction.COPY,
            label: this.props.t("ChartsOfAccountsTemplates:CreateCopy"),
            itemType: this.context.getAddingNewCompany() ? ToolbarItemType.TransparentButton : ToolbarItemType.Button
        });

        return {
            id: item.Id,
            name: item.Name,
            iconName: "DocTemplate",
            isDefault: item.Id < 0,
            contents: [{
                content: (
                        <>
                            <ListItemContentRow label={this.props.t(`ChartsOfAccountsTemplates:DateCreated`)}>
                                {DateType.localFormat(item.DateCreated)}
                            </ListItemContentRow>
                            {item.CreatedBy &&
                                    <ListItemContentRow label={this.props.t(`ChartsOfAccountsTemplates:CreatedBy`)}>
                                        {item.CreatedBy.Name}
                                    </ListItemContentRow>}
                            {!dayjs(item.DateLastModified).isSame(dayjs(item.DateCreated)) &&
                                    <ListItemContentRow
                                            label={this.props.t(`ChartsOfAccountsTemplates:DateLastModified`)}>
                                        {DateType.localFormat(item.DateLastModified, DateType.defaultDateTimeFormat)}
                                    </ListItemContentRow>}
                        </>
                )
            }],
            actions
        };
    };

    getFilteredTemplates = (): IChartOfAccountsTemplateEntity[] => {
        return this.parents.filter((template: IChartOfAccountsTemplateEntity) => {
            return anyPartStartsWithAccentsInsensitive(template.Name, this.state.filter);
        });
    };

    getSections = (children: IEntity[]): ISection[] => {
        const defaultSection: ISection = {
                    id: "default",
                    children: []
                },
                customSection: ISection = {
                    id: "custom",
                    children: []
                };
        children.forEach((item) => {
            const section = item.Id < 0 ? defaultSection : customSection;
            section.children.push(this.createTemplateProperties(item));
        });

        return [defaultSection, customSection].filter(item => item.children.length);
    };

    handleFilterChange = (e: IInputOnChangeEvent) => {
        this.setState({
            filter: e.value as string
        });
    };

    handleAdd = async () => {
        const action = { type: ChartOfAccountsTemplateAction.ADD };
        this.setState({ action });
    };

    handleTemplateNameChange = async (template: IEntity, name: string) => {
        template.Name = name;
        if (this._shouldCloseOnSaveName) {
            this.handleCloseDialog();
            this._shouldCloseOnSaveName = false;
        }
    };

    handleTemplateNameChangeFailed = () => {
        this._shouldCloseOnSaveName = false;
    };

    handleAction = async (templateId: TEntityKey, type: string) => {
        const template = this.parents.find(item => item.Id === templateId);
        // set action to show busy indicator or other stuff
        const action = { templateId, type: (type as ChartOfAccountsTemplateAction) };
        this.setState({ action });

        switch (type) {
            case ChartOfAccountsTemplateAction.COPY:
                const newTemplate = await this.cloneTemplate(template);
                this.showMessage(newTemplate?.Id, { status: Status.Success, key: "TemplateCreated" });
                this.setState({ action: null });
                break;
            case ChartOfAccountsTemplateAction.VIEW:
            case ChartOfAccountsTemplateAction.EDIT:
                this.handleChangeParent(template.Id);
                break;
            case ChartOfAccountsTemplateAction.DELETE:
                // no special handling, just set the action to state
                break;
        }
    };

    cloneTemplate = async (template: IEntity): Promise<TRecordAny> => {
        const newName = await getUniqName(this.props.oData, template.Name),
            newTemplate = await cloneChartOfAccountsTemplate(template.Id, newName);

        this.parents = [newTemplate, ...this.parents];
        return newTemplate;
    };

    handleDeleteCancel = () => {
        this.confirmAction(false);
    };

    confirmAction = (confirmed = true) => {
        if (this.state.action?.confirmed !== confirmed) {
            const action = confirmed ? { confirmed, ...this.state.action }
                : undefined;
            this.setState({ action });
        }
    };

    handleDeleteConfirm = async () => {
        const templateId = this.state.action.templateId;
        // close the dialog
        this.confirmAction();

        try {
            this.props.setBusy(true);
            const result = await this.props.oData.getEntitySetWrapper(EntitySetName.ChartOfAccountsTemplates)
                .delete(templateId);

            const templateIndx = this.parents.findIndex(t => t.Id === templateId);
            const template = this.parents[templateIndx];

            if (result) {
                this.props.setAlert({
                    status: Status.Success,
                    title: this.props.t("Components:Table.UpdateOk"),
                    subTitle: this.props.t("ChartsOfAccountsTemplates:DeleteConfirm.Deleted", { name: template?.Name })
                });

                // todo: if we have "disabled" state for ObjectListItem, we may just show message here and
                //  remove the template in clearMessage callback
                if (templateIndx !== -1) {
                    this.parents.splice(templateIndx, 1);
                }
            }

        } catch (error) {
            const title = isODataError(error) ? error._message : `${error}`;

            this.showMessage(templateId, { status: Status.Error, title });
        }

        this.props.setBusy(false);
        this.setState({
            action: undefined
        });
    };

    showMessage = (templateId: TEntityKey, {
        status,
        title,
        key
    }: { status: Status, key?: string, title?: string }) => {
        if (key) {
            const name = this.parents.find(template => template.Id === templateId)?.Name;
            title = this.props.t(`ChartsOfAccountsTemplates:${key}`, { name });
        }
        const isSuccess = status === Status.Success;
        const alert: IAlertProps = {
            status,
            title: isSuccess ? this.props.t("Common:Validation.SuccessTitle") : this.props.t("Common:Errors.ErrorHappened"),
            subTitle: title,
        };
        this.props.setAlert(alert);
    };

    /**
     * Editable window has been closed, confirm changes...
     */
    handleCloseDialog = async () => {
        if (this.templateContext.isChangingName) {
            // writeline with changed name is present, it's automatically confirmed on blur, which may be triggered
            // by hitting the editable window cross. If it's this case, we need to postpone closing the window, in case
            // any error happen
            this._shouldCloseOnSaveName = true;
        } else {
            if (this.templateContext.isChanged) {
                this.areParentsLoaded = false;
                await this.loadParents();
            }
            this.handleChangeParent();
        }
    };

    prepareMessage = () => {
        // show message for Edit, Add, Copy
        const type = this.state.action?.type;
        if (this.templateContext.isChanged && (!type || type === ChartOfAccountsTemplateAction.EDIT)) {
            this.showMessage(this.state.action?.templateId, { status: Status.Success, key: "TemplateChanged" });
        }
    };

    scrollToTemplate = (templateId: string) => {
        const item = document.querySelector(`[data-listitemid="${templateId}"]`) as HTMLElement;

        if (this._scrollRef.current && item && !isVisibleInContainer(this._scrollRef.current, item)) {
            scrollIntoView(this._scrollRef.current, item, { behavior: "smooth", block: "center" });

            item.focus({
                preventScroll: true
            });
        }
    };

    _showBusyIndicator = (): boolean => {
        return [ChartOfAccountsTemplateAction.COPY].includes(this.state.action?.type);
    };

    _showDeleteConfirm = () => {
        return this.state.action?.type === ChartOfAccountsTemplateAction.DELETE && !this.state.action?.confirmed;
    };

    isReady() {
        // templates should render even if there is no parent key (list is rendered)
        return this.props.tReady && !!this.definition && this.areParentsLoaded;
    }

    handleNewTemplate = async (newTemplateName: string): Promise<void> => {
        try {
            const newTemplate = await createMinimalChartOfAccountsTemplate(AccountingCode.AccountingForBusiness, newTemplateName);

            // reload all parents
            this.areParentsLoaded = false;
            await this.loadParents();

            this.handleCloseTemplateDialog();

            this.showMessage(newTemplate?.Id, { status: Status.Success, key: "TemplateCreated" });
        } catch (e) {
            return e;
        }
    };

    handleCloseTemplateDialog = (): void => {
        this.setState({
            action: undefined
        });
    };

    render() {
        if (!this.isReady()) {
            return null;
        }

        const templates = !!this.state.filter ? this.getFilteredTemplates() : this.parents;
        const isFirstDefault = templates[templates.length - 1]?.Id < 0;

        let name;
        if (this.state.action?.templateId) {
            name = this.parents.find(template => template.Id === this.state.action.templateId)?.Name;
        }

        const content = (
            <ObjectList
                listId={"Templates"}
                busy={!this.areParentsLoaded}
                sections={this.getSections(templates)}
                onTriggerAction={this.handleAction}
                appliedText={this.props.t("ChartsOfAccountsTemplates:TemplateUsed")}
                noDataText={this.props.t(`ChartsOfAccountsTemplates:NoData${this.state.filter ? "Filtered" : ""}`)}/>
        );

        return (
            <>
                {!this.props.renderWithoutBreadCrumbs && <BreadCrumbProvider back={this.initialHistoryState?.back}
                                                                             customBreadCrumbs={this.initialHistoryState?.breadCrumbs}/>}
                <ChartOfAccountsTemplatesViewStyled hotspotContextId={HotspotViewIds.ChartOfAccountsTemplatesTable}
                                                    nonScrollableContent={this._showBusyIndicator() && (
                                                        <BusyIndicator isDelayed/>)}
                                                    renderScrollbar={this.props.renderScrollBar !== false}
                                                    isFirstDefault={isFirstDefault}
                                                    className={this.props.className}>
                    {!this.props.renderWithoutHeader &&
                        <ViewHeader title={this.props.t("ChartsOfAccountsTemplates:Title")}
                                    shouldHideVariant/>}

                    {this.props.alert && (
                            <ObjectListAlertWrapper>{this.props.alert}</ObjectListAlertWrapper>
                    )}

                    <Toolbar>
                        <WriteLineWrapper>
                            <WriteLine value={this.state.filter}
                                       width="240px"
                                       withClearButton={true}
                                       placeholder={this.props.t("ChartsOfAccountsTemplates:Search")}
                                       textAlign={TextAlign.Left}
                                       onChange={this.handleFilterChange}/>
                        </WriteLineWrapper>
                        <IconButton title={this.props.t("Common:General.Create")}
                                    hotspotId={TableButtonsAction.Add}
                                    onClick={this.handleAdd}>
                            <AddIcon width={IconSize.M} isLight={true}/>
                        </IconButton>
                    </Toolbar>

                    {this.props.renderScrollBar && <ScrollBar primary
                                                              scrollableNodeProps={{
                                                                  ref: this._scrollRef
                                                              }}
                                                              style={{ overflowX: "hidden", position: "relative" }}>
                        {content}
                    </ScrollBar>}
                    {!this.props.renderScrollBar && content}

                    {this.showDialog &&
                            <ChartOfAccountTemplateContext.Provider value={this.templateContext}>
                                <Dialog onClose={this.handleCloseDialog}
                                        onConfirm={null}
                                        isEditableWindow>
                                    {/* Render split page in dialog */}
                                    {super.render()}
                                </Dialog>
                            </ChartOfAccountTemplateContext.Provider>
                    }
                    {this.state.action?.type === ChartOfAccountsTemplateAction.ADD &&
                            <NewTemplateDialog
                                    newTemplateName={""}
                                    onSave={this.handleNewTemplate}
                                    onClose={this.handleCloseTemplateDialog}
                                    parent={this.getParentEntity()}
                            />
                    }
                    {this._showDeleteConfirm() &&
                            <Dialog isConfirmation
                                    onClose={this.handleDeleteCancel}
                                    onConfirm={this.handleDeleteConfirm}
                                    footer={<ConfirmationButtons onConfirm={this.handleDeleteConfirm}
                                                                 onCancel={this.handleDeleteCancel}
                                                                 useWrapper={false}/>}>
                                {this.props.t("Common:General.DeleteMessage", { item: name })}
                            </Dialog>
                    }
                    </ChartOfAccountsTemplatesViewStyled>
                </>
        );
    }
}

export default withAlert({
    autoHide: true
})(withRouter(withOData(withTranslation(["ChartsOfAccountsTemplates", "ChartsOfAccounts", "Error", "Common"])(withBusyIndicator({ passBusyIndicator: true })(ChartOfAccountsTemplates)))));
