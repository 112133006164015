import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { arrayMove, composeRefHandlers, findLastRowItemInFlex, TabIndex } from "@utils/general";
import React, { useCallback } from "react";

import { DASH_CHARACTER } from "../../constants";
import { IconSize, Status } from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import { buttonAlertPopperOptions } from "../../views/table/ConfirmationButtons";
import { IAlertProps } from "../alert/Alert";
import { ClickableWithAlertBase } from "../clickableWithAlert/ClickableWithAlert";
import CustomResizeObserver from "../customResizeObserver/CustomResizeObserver";
import FocusManager, { FOCUSABLE_ATTR } from "../focusManager/FocusManager";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import { CaretIcon, getIcon, IProps as IIconProps } from "../icon";
import IconSelect from "../inputs/select/IconSelect";
import Tooltip from "../tooltip";
import {
    Count,
    Divider,
    IconWrapper,
    Prefix,
    StatusIndicator,
    StyledDroppingArea,
    StyledTab,
    TabContent,
    TabsMenu,
    TabsWrapper,
    TabWrapper,
    Title
} from "./Tabs.styles";

const TAB_NAME = "tab";

// TODO proper behavior and keyboard navigation
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role

const DroppingArea = ({ index, isDraggable }: { index: number, isDraggable: boolean }) => {
    // const [{ canDrop, isOver }, drop] = useDrop({
    //     accept: TAB_NAME,
    //     drop: () => ({ index }),
    //     collect: monitor => ({
    //         isOver: monitor.isOver(),
    //         canDrop: monitor.canDrop()
    //     })
    // });
    // const isActive = canDrop && isOver;
    const backgroundColor = "transparent";
    // if (isActive) {
    //     backgroundColor = "darkgreen";
    // } else if (canDrop) {
    //     backgroundColor = "transparent";
    // }
    return (
        <StyledDroppingArea ref={null} style={{ backgroundColor }}>
        </StyledDroppingArea>
    );
};

interface ITabProps {
    item: ITabData;
    index: number;
    onDrop: (from: number, to: number) => void;
    onClick: (event: React.MouseEvent, id: string) => void;
    onKeyDown: (event: React.KeyboardEvent, id: string) => void;
    selected: boolean;
    isDraggable: boolean;
    value: number;
    passProps: TRecordAny;
    passRef?: React.Ref<HTMLDivElement>;
    select?: React.ReactElement;
    disableFocus?: boolean;
}

export interface ITabValue {
    id: string;
    value: number;
}

export interface ITabData {
    id: string;
    // cases where tab is not enum but bool or number
    value?: TValue;
    hotspotId?: string;
    content?: (props: ITabsProps) => React.ReactElement;
    title?: string;
    isDisabled?: boolean;
    // hover look without actually having mouse over the tab
    isActive?: boolean;
    disabledAlert?: IAlertProps;
    tooltip?: string;
    iconName?: string;
    status?: Status;
    prefix?: string;
}

export interface ITabsProps {
    /** Values displayed after the title*/
    values?: ITabValue[];

    /** Tabs definition*/
    data: ITabData[];
    additionalData?: ITabData[];
    additionalTabPrefix?: string;
    showSearchBoxInMenu?: boolean;
    selectWidth?: string;

    height?: string;
    isDisabled?: boolean;
    isDraggable?: boolean;
    isEditable?: boolean;
    onChange?: (selectedTab: string) => void;
    selectedTabId?: string;

    style?: React.CSSProperties;
    className?: string;
}

interface ITabsState {
    selectedTabId: string;
    selectedAdditionalTabId: string;
    data: ITabData[];
    lastItemFlags: boolean[];
}

const Tab = ({
                 item,
                 index,
                 onDrop,
                 onClick,
                 onKeyDown,
                 selected,
                 isDraggable,
                 value,
                 passProps,
                 passRef,
                 select,
                 disableFocus
             }: ITabProps): React.ReactElement => {
    // const [{ isDragging }, drag] = useDrag({
    //     item: { name: item.id, type: TAB_NAME },
    //     end: (item, monitor) => {
    //         const dropResult = monitor.getDropResult();
    //         if (item && dropResult) {
    //             onDrop(index, dropResult.index);
    //         }
    //     },
    //     collect: monitor => ({
    //         isDragging: monitor.isDragging()
    //     })
    // });

    const opacity = 1;
    let Icon: React.ComponentType<IIconProps>;
    if (item.iconName) {
        Icon = getIcon(item.iconName);
    }

    if (item.isDisabled && passProps) {
        delete passProps[FOCUSABLE_ATTR];
    }

    return (
        <>
            <StyledTab {...passProps}
                       tabIndex={item.isDisabled ? null : passProps.tabIndex ?? 0}
                       ref={composeRefHandlers(null, passRef)}
                       {...{ [HOTSPOT_ID_ATTR]: item.hotspotId ?? item.id ?? "tabWithNullId" }}
                       _opacity={isDraggable ? opacity : null}
                       hasIcon={!!Icon}
                       isDraggable={isDraggable}
                       tooltip={item.tooltip}
                       isDisabled={item.isDisabled}
                       disableFocus={disableFocus}
                       isActive={item.isActive}
                       selected={selected}
                       onClick={useCallback((event) => {
                           onClick(event, item.id);
                       }, [item.id])}
                       onKeyDown={useCallback((event: React.KeyboardEvent) => onKeyDown(event, item.id), [item.id, item.isDisabled])}
                       role="tab" aria-selected={selected}
                       data-testid={TestIds.Tab}>
                <Tooltip content={item.tooltip}>
                    {(ref) => {
                        return <div ref={ref} style={{ display: "flex" }}>
                            {Icon &&
                                <IconWrapper>
                                    <Icon width={IconSize.L} height={IconSize.L}/>
                                </IconWrapper>
                            }
                            {!Icon &&
                                <Title>
                                    {item.prefix && <Prefix>{item.prefix}&nbsp;{DASH_CHARACTER}&nbsp;</Prefix>}
                                    {item.status && <StatusIndicator status={item.status}/>}
                                    {(item.title || "")}
                                </Title>
                            }
                            {select && <>&nbsp;{select}</>}
                        </div>;
                    }}
                </Tooltip>
            </StyledTab>
            {value !== undefined &&
                <Count
                    _opacity={isDraggable ? opacity : null}
                    isDisabled={item.isDisabled}
                    data-testid={TestIds.TabCount}
                >
                    /{value}/
                </Count>
            }
        </>
    );
};

const TabWithAlert = (props: ITabProps): React.ReactElement => {
    if (props.disableFocus) {
        return <Tab {...props}/>;
    }

    return <ClickableWithAlertBase
        alert={props.item.disabledAlert}
        onClick={props.onClick}
        isDisabled={props.item.isDisabled}
        alertPopperOptions={buttonAlertPopperOptions}
        renderClickable={
            (onClick, handleRef) => (
                <Tab {...props} onClick={onClick} passRef={handleRef}/>
            )
        }/>;
};

// TODO remove arbitrary 'data' property and use Tab as children instead
class Tabs extends React.Component<ITabsProps, ITabsState> {
    _refMenu = React.createRef<HTMLDivElement>();
    _selectRef = React.createRef<any>();

    static defaultProps: Partial<ITabsProps> = {
        additionalData: []
    };

    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            // tab "ALL" tends to have id: null so we check for undefined because null indicates this ALL tab, not that no tab is selected
            // used in page load with ALL tab preset (f.e. BankTransactions with defaultTab set in URL query)
            selectedTabId: props.selectedTabId === undefined ? props?.data?.[0]?.id : props.selectedTabId,
            data: props.data,
            lastItemFlags: [],
            selectedAdditionalTabId: props.selectedTabId
        };
    }

    componentDidUpdate(prevProps: ITabsProps, prevState: ITabsState): void {
        if (prevProps.data !== this.props.data) {
            this.setState((state) => {
                const newData = this.props.data.map((tab) => {
                    const tabState = state.data.find((t) => t.id === tab.id);

                    if (tabState) {
                        return { ...tabState, ...tab };
                    } else {
                        return tab;
                    }
                });

                const additionalItems = this.getAdditionalItems(this.props.additionalData) ?? [];
                let selectedTabId = this.props.selectedTabId ?? state.selectedTabId;

                if (newData.length > 0 && !newData.find((tab) => tab.id === selectedTabId)
                    && !additionalItems.find((item) => item.id === selectedTabId)) {
                    selectedTabId = newData[0].id;
                }

                return {
                    data: newData,
                    selectedTabId
                };
            }, this.handleResize);
        }

        if (prevProps.selectedTabId !== this.props.selectedTabId) {
            this.setState({
                selectedTabId: this.props.selectedTabId
            });
        }

        if (prevProps.additionalData !== this.props.additionalData && !!this.props.additionalData?.find(d => d.id === this.props.selectedTabId)) {
            this.setState({
                selectedAdditionalTabId: this.props.selectedTabId
            });
        }

        if (prevProps.values?.length !== this.props.values?.length) {
            this.handleResize();
        }
    }

    handleDrop = (from: number, to: number): void => {
        this.setState((state) => ({
            data: arrayMove(state.data, from, to)
        }));
    };

    handleClick = (event: React.MouseEvent, id: string): void => {
        this.setState({
            selectedTabId: id
        });

        this.props.onChange?.(id);
    };

    handleResize = (): void => {
        setTimeout(() => {
            if (this._refMenu.current) {
                this.setState({
                    lastItemFlags: findLastRowItemInFlex(this._refMenu.current)
                });
            }
        }, 0);
    };

    getTabValue = (id: string): number | undefined => {
        if (this.props.values) {
            const number = this.props.values.find((item: ITabValue) => id === item.id);
            if (number) {
                return number.value;
            }
        }
        return undefined;
    };

    handleKeyDown = (event: React.KeyboardEvent, id: string): void => {
        const secondEnterClickCondition = !this._selectRef?.current?.state.isOpen && event.key === KeyName.Enter && this.state.selectedTabId === id;
        // this is here for special keyboard navigation on extra tab with select, which should act like tab and select combined
        if ((event.key === KeyName.Space || secondEnterClickCondition) && this.props.additionalData?.find((item) => item.id === id)) {
            this._selectRef?.current?.handleIconClick(event as unknown as React.MouseEvent);
        } else if (this._selectRef?.current?.state.isOpen) {
            this._selectRef.current.handleKeyDown(event);
            if (event.key === KeyName.Tab) {
                this._selectRef?.current?.handleIconClick(event as unknown as React.MouseEvent);
            }
        } else if (event.key === KeyName.Enter) {
            event.stopPropagation();
            this.setState({
                selectedTabId: id
            });
            this.props.onChange?.(id);
        }
    };

    getAdditionalItems = (additionalData: ITabData[]): ISelectItem[] => {
        if (!additionalData) {
            return [];
        }
        return additionalData.map((data: ITabData) => {
            return {
                id: data.id,
                label: data.title
            };
        });
    };

    handleSelectAdditionalItem = (e: ISelectionChangeArgs): void => {
        const value = e.value as string;

        this.setState({ selectedTabId: value, selectedAdditionalTabId: value });
        this.props.onChange?.(value);
    };

    handleIsOpenChange = () => {
        // select open state is not controlled via props,
        // but we still need to set isActive of the tab based on select state
        this.forceUpdate();
    };

    render() {
        const additionalItems = this.getAdditionalItems(this.props.additionalData);
        const selectedAdditionalItem = additionalItems.find((item) => item.id === this.state.selectedAdditionalTabId) ?? additionalItems?.[0];
        const selectedAdditionalTabItem = this.props.additionalData.find(item => item.id === this.state.selectedAdditionalTabId) ?? this.props.additionalData?.[0];
        const extraItem: ITabData = {
            id: selectedAdditionalItem?.id as string,
            hotspotId: selectedAdditionalTabItem?.hotspotId,
            title: selectedAdditionalItem?.label,
            prefix: this.props.additionalTabPrefix,
            isActive: !!this._selectRef?.current?.state.isOpen,
            isDisabled: this.props.isDisabled
        };
        const disableFocus = this.state.data.length === 1 && !additionalItems.length;

        return (
            <>
                {this.state.data && this.state.data.length > 0 &&
                    <FocusManager isDisabled={disableFocus}>
                        {({ itemProps, wrapperProps }) => (
                            <TabsWrapper className={this.props.className}
                                         {...wrapperProps as any}
                                         height={this.props.height} role="tablist"
                                         data-testid={TestIds.Tabs}>
                                <CustomResizeObserver onResize={this.handleResize}/>

                                <TabsMenu style={this.props.style} ref={this._refMenu} data-testid={TestIds.TabsMenu}>
                                    {this.state.data.map((item, index) => {
                                        // only change item object if necessary, to prevent pointless re-renders
                                        // even better - rewrite Tabs and use better approach to pass data
                                        if (!item.isDisabled && this.props.isDisabled) {
                                            item = {
                                                ...item,
                                                isDisabled: this.props.isDisabled
                                            };
                                        }

                                        return (
                                            <TabWrapper key={item.id} isLastOnRow={this.state.lastItemFlags[index]}>
                                                <TabWithAlert item={item} index={index} onDrop={this.handleDrop}
                                                              passProps={item.isDisabled || disableFocus ? { tabIndex: TabIndex.Disabled } : itemProps}
                                                              onClick={this.handleClick}
                                                              onKeyDown={this.handleKeyDown}
                                                              value={this.getTabValue(item.id)}
                                                              disableFocus={disableFocus}
                                                              isDraggable={this.props.isDraggable}
                                                              selected={item.id === this.state.selectedTabId}/>
                                                <DroppingArea isDraggable={this.props.isDraggable} index={index}/>
                                            </TabWrapper>
                                        );
                                    })}
                                    {this.props.additionalTabPrefix && !!additionalItems.length &&
                                        <TabWrapper key={"extra"} isLastOnRow={false}>
                                            <Divider/>
                                            <TabWithAlert item={extraItem} index={this.state.data.length}
                                                          onDrop={this.handleDrop}
                                                          passProps={extraItem.isDisabled ? { tabIndex: TabIndex.Disabled } : itemProps}
                                                          onClick={this.handleClick}
                                                          onKeyDown={this.handleKeyDown}
                                                          value={this.getTabValue(extraItem.id)}
                                                          isDraggable={this.props.isDraggable}
                                                          select={(additionalItems.length > 1 && <IconSelect
                                                              hotspotId={"TabsAdditionalMenu"}
                                                              title={""}
                                                              iconButtonProps={{
                                                                  tabIndex: TabIndex.Disabled
                                                              }}
                                                              isDisabled={this.props.isDisabled}
                                                              showSearchBoxInMenu={this.props.showSearchBoxInMenu}
                                                              selectRef={this._selectRef}
                                                              isDecorative={true}
                                                              width={this.props.selectWidth ?? "92px"}
                                                              onChange={this.handleSelectAdditionalItem}
                                                              value={extraItem.id}
                                                              onKeyDown={(e) => e.stopPropagation()}
                                                              onIsOpenChange={this.handleIsOpenChange}
                                                              icon={<CaretIcon width={IconSize.XS}/>}
                                                              items={additionalItems}/>)}
                                                          selected={extraItem.id === this.state.selectedTabId}/>
                                        </TabWrapper>}
                                </TabsMenu>
                                {this.props.children}
                                {this.state.data.map((item) => {
                                    const isSelected = item.id === this.state.selectedTabId;
                                    return isSelected && item.content ?
                                        <TabContent key={item.id} role="tabpanel" data-testid={TestIds.TabContent}>
                                            {item.content(this.props)}
                                        </TabContent>
                                        : null;
                                })}
                            </TabsWrapper>
                        )}
                    </FocusManager>
                }
            </>
        );
    }
}

export default Tabs;
export { Tab };