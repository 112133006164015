import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";

import { Status } from "../../enums";
import TestIds from "../../testIds";
import Alert, { AlertAction, AlertPosition, IAlertProps } from "./Alert";

export interface WithAlert {
    setAlert: (alert: IAlertProps) => void;
    alert: React.ReactElement;
    alertProps: IAlertProps;
}

export interface IWithAlertSettings {
    style?: React.CSSProperties;
    autoHide?: boolean;
    position?: AlertPosition;
    // rendered via Portal into document.body
    isGlobal?: boolean;
    isFullWidth?: boolean;
    isOneLiner?: boolean;
}

export const withAlert = (settings: IWithAlertSettings = {}) => (
    <P extends WithAlert>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithAlert>> => {
        return React.forwardRef((props: Omit<P, keyof WithAlert>, ref) => {
            const { alert, setAlert, alertProps } = useAlert(settings);

            return (
                <Component alert={alert}
                           setAlert={setAlert}
                           alertProps={alertProps}
                           ref={ref}
                    // cast props as per https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                           {...props as P}/>
            );
        }) as any;
    }
);

export function getFilteredAlert(alert: React.ReactElement, props: IAlertProps, position: AlertPosition): React.ReactElement {
    return props?.position === position ? alert : null;
}

export function useAlert(settings: IWithAlertSettings = {}): WithAlert {
    // use key to reset animation if setAlert is called again before previous animation ended
    const [key, setKey] = useState(0);
    const [alert, setAlert] = useState(null);
    const useFade = alert?.status !== Status.Error && (alert?.useFade ?? settings.autoHide ?? alert?.status === Status.Success);
    const position = settings.position ?? (alert?.status === Status.Success ? AlertPosition.CenteredBottom : AlertPosition.Default);
    const isFullWidth = settings.isFullWidth ?? position !== AlertPosition.CenteredBottom;
    const isOneLiner = settings.isOneLiner ?? false;
    const action = alert?.status === Status.Success ? AlertAction.None : AlertAction.Close;

    const onFadeEnd = useCallback(() => {
        if (useFade) {
            setAlert(null);
        }
        alert?.onFadeEnd?.();
    }, [alert, useFade]);

    const onClose = useCallback(() => {
        setAlert(null);
    }, []);

    const componentSetAlert = useCallback((alert: IAlertProps) => {
        setAlert(alert);

        if (useFade) {
            setKey(key + 1);
        }
    }, [key]);

    let alertComponent = (
        <Alert position={position}
               isFullWidth={isFullWidth}
               isOneLiner={isOneLiner}
               action={action}
               {...alert}
               key={`alert${key}`}
               style={settings.style}
               useFade={useFade}
               onFadeEnd={onFadeEnd}
               onClose={onClose}/>
    );

    if (settings.isGlobal) {
        alertComponent = ReactDOM.createPortal(
            alertComponent,
            document.querySelector(`[data-testid=${TestIds.Content}]`) ?? document.body
        );
    }

    return { alert: alert ? alertComponent : null, setAlert: componentSetAlert, alertProps: alert };
}