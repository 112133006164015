import { StyledButtonGroup } from "@components/button";
import styled from "styled-components/macro";

import { T_PLAIN_big, T_PLAIN_small, T_PLAIN_small_hig, T_TITLE_2 } from "../../../global.style";

export const UserNameStyled = styled.span<{ isBold: boolean; isBig?: boolean; }>`
    margin-left: ${props => props.isBig ? 12 : 8}px;
    ${props => props.isBig ? T_TITLE_2 : props.isBold ? T_PLAIN_small_hig : T_PLAIN_small};
`;

export const PermissionName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const PermissionsOverviewGrid = styled.div<{
    rows: number;
    columns: number;
}>`
    width: 100%;
    display: grid;
    grid-template-columns: ${props => `repeat(${props.columns}, max-content)`};
    grid-template-rows: ${props => `repeat(${props.rows}, 1fr)`};
    grid-auto-flow: column;
    ${T_PLAIN_big};
    row-gap: 17px;
    column-gap: 24px;
    margin-top: -3px;

    & > div {
        display: flex;
        min-width: 150px;
        max-width: 300px;
        align-items: flex-start;

        & > span,
        & > ${PermissionName} > span {
            margin-top: 2px;
        }

        & > svg {
            margin-right: 10px;
        }
    }
`;

export const AgendasSpan = styled.div`
    color: ${props => props.theme.C_ACT_main};
    cursor: pointer;
`;

export const CompanyRolesListStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    & > ${StyledButtonGroup} {
        margin: 20px 0 34px 0;
    }
`;

export const CompanyNameWithAvatar = styled.div`
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;