import React from "react";
import { getIcon } from "@components/icon";
import { ToolbarItemType } from "../../enums";
import { IconButton } from "@components/button";

import { IToolbarButtonProps } from "@components/toolbar/Toolbar.utils";

export class ToolbarButton extends React.PureComponent<IToolbarButtonProps> {
    handleClick = (): void => {
        this.props.onClick(this.props.item);
    };

    render() {
        const props = this.props.item.itemProps || {};
        const Icon = getIcon(this.props.item.iconName);
        const isDecorative = this.props.item.itemType === ToolbarItemType.SmallIcon;

        return (
            <IconButton title={this.props.item.label}
                        isActive={this.props.item.isActive}
                        isDisabled={this.props.item.isDisabled}
                        isDecorative={isDecorative}
                        isLight={this.props.item.isLight}
                        isTransparent={!isDecorative}
                        onClick={this.handleClick}
                        isBusy={this.props.item.isBusy}
                        hotspotId={this.props.item.id}
                        link={this.props.link}
                        {...props}>
                <Icon/>
            </IconButton>
        );
    }
}