import styled from "styled-components/macro";

import SmartFilterBar from "../../components/smart/smartFilterBar/SmartFilterBar";

export const StyledSmartFilterBar = styled(SmartFilterBar)`
    margin-bottom: 42px;
`;

export const DrillDownFiltersWrapper = styled.div`
    position: relative;
    top: -18px;
    margin-bottom: 18px;
`;