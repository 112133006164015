import { withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { BankTransactionTypeCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";

import { DASH_CHARACTER } from "../../../constants";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import DateType from "../../../types/Date";
import { createDefaultPairFilter, getCashTransactionType } from "../../banks/bankTransactions/BankTransactions.utils";
import { PairTableViewForExtend } from "../../banks/PairTableView";
import { IPairTableViewBaseProps } from "../../banks/PairTableViewBase";


class CashReceiptsPairTableView extends PairTableViewForExtend {
    constructor(props: IPairTableViewBaseProps) {
        super(props);
        this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        this.presetDefaultFilters();
    }

    getTitle = () => {
        return this.payment === BankTransactionTypeCode.IncomingPayment ? i18next.t("Cash:PairDialog.TitleReceived") : i18next.t("Cash:PairDialog.Title");
    };

    get payment() {
        const type = this.getRootStorage().getCustomData().type;
        return type === DocumentTypeCode.CashReceiptReceived ? BankTransactionTypeCode.IncomingPayment : BankTransactionTypeCode.OutgoingPayment;
    }

    getTransactionType = () => {
        return getCashTransactionType(this.props.rootStorage);
    };

    get successSubtitle(): string {
        return i18next.t("Cash:PairDialog.Paired");
    }

    presetDefaultFilters() {
        createDefaultPairFilter(this.props.storage, this.payment);
        this.props.storage.applyFilters();
    }

    _format = (val: string) => {
        return val || DASH_CHARACTER;
    };

    getHeaderData = (): IReadOnlyListItem[] => {
        const cashBox = this.props.rootStorage.data.entity.CashBox?.BalanceSheetAccountNumberSuffix;
        const name = this.props.rootStorage.data.entity.CashBox?.Name;

        const isCba = isCashBasisAccountingCompany(this.props.rootStorage.context);

        const cashBoxItem = isCba ? {
            label: this.props.rootStorage.t("Cash:PairDialog.CashBox"),
            value: name ?? DASH_CHARACTER,
        } : {
            label: this.props.rootStorage.t("Cash:PairDialog.CashBox"),
            value: cashBox ? `211${this._format(cashBox)}` : DASH_CHARACTER,
            description: name ? ` | ${name}` : ""
        };

        return [{
            label: this.props.rootStorage.t("Cash:PairDialog.Date"),
            value: this._format(DateType.format(this.props.rootStorage.data.entity.DateIssued))
        }, {
            label: this.props.rootStorage.t("Cash:PairDialog.Amount"),
            value: this._format(this.formatCurrency(this.props.rootStorage.data.entity.TransactionAmount))
        }, {
            ...cashBoxItem
        }, {
            label: this.props.rootStorage.t("Cash:PairDialog.Note"),
            value: this._format(this.props.rootStorage.data.entity.Purpose)
        }];
    };
}

export default withBusyIndicator({ passBusyIndicator: true, isDelayed: true })(withPermissionContext(CashReceiptsPairTableView));