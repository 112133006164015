import { IconButton } from "@components/button";
import { UnchainedIcon } from "@components/icon";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import { setNestedValue } from "@odata/Data.utils";
import { getFieldTypeFromProperty, getValidatorTypeFromProperty, IFieldInfo } from "@odata/FieldInfo.utils";
import {
    DocumentEntity,
    DocumentItemCbaCategoryEntity,
    EntitySetName,
    IPaymentDocumentItemCbaCategoryEntity,
    PaymentDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    CbaCategoryTaxImpactCode,
    DocumentLinkTypeCode,
    DocumentTypeCode,
    PaymentStatusCode
} from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { ICopyEntityArgs } from "@utils/DraftUtils";
import { arrayInsert, isObjectEmpty } from "@utils/general";
import i18next from "i18next";
import React from "react";
import { DefaultTheme } from "styled-components";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { IconSize, ToolbarItemType } from "../../enums";
import { ColoredText } from "../../global.style";
import { TClickAction, TRecordAny, TValue } from "../../global.types";
import { Validator } from "../../model/Validator";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import { ISplitPageTableDef, ITableDefTabData } from "../../views/table/TableView.utils";
import { PAIR_ACTION_ID } from "../asset/PairingWithAssetTableView";
import {
    CBA_CATEGORY_PATH,
    getCbaCategoryItemDef,
    getCbaEntriesTable
} from "../cashBasisAccounting/CashBasisAccounting.utils";
import { DRAFT_ITEM_ID_PATH, getAccountJournalTable, getDocumentPairedTable } from "../documents/Document.utils";
import { TFieldsDefinition } from "../PageUtils";

/**
 * Utils file for common code shared between Banks and CashBoxes
 */

export function getThemeColorForPaymentStatus(status: PaymentStatusCode): keyof DefaultTheme {
    return status === PaymentStatusCode.WaitsForProcessing ? "C_SEM_text_bad" :
            status === PaymentStatusCode.NeedsApproval ? "C_SEM_text_warning" : "C_TEXT_primary";
}

export const PaymentStatusSummaryAdditionalData = [{ id: "PaymentStatus/Name" }];

export function getPaymentStatusSummaryItem(): ISummaryItem {
    return {
        label: i18next.t("Banks:Transactions.State"),
        id: "PaymentStatus",
        formatter: (value: TValue, args) => {
            if ((args.storage as FormStorage).data.origEntity.IsUnrelatedToBusiness) {
                return args.storage.t("Banks:CashBasisAccounting.NotBusinessRelated");
            }
            return (value as TRecordAny)?.Name;
        },
        colorFormatter: (entity: TValue) => {
            return getThemeColorForPaymentStatus((entity as TRecordAny)?.Code);
        }
    };
}

export function getPaymentStatusTableColumnDef(): TFieldsDefinition {
    return {
        PaymentStatus: {
            additionalProperties: [{ id: "/IsUnrelatedToBusiness" }],
            formatter: (value: TValue, args: IFormatOptions) => {
                if (args.entity.IsUnrelatedToBusiness) {
                    const title = args.storage.t("Banks:CashBasisAccounting.NotBusinessRelated");
                    return {
                        value: <b>{title}</b>,
                        tooltip: title
                    };
                }
                const color = getThemeColorForPaymentStatus(args.entity.PaymentStatus?.Code);

                return {
                    value: <b><ColoredText color={color}> {value} </ColoredText></b>,
                    tooltip: value as string
                };
            },
            fieldSettings: {
                displayName: "Name"
            }
        }
    };
}

export const isExchangeRateVisible = (storage: FormStorage): boolean => {
    const code = storage.data?.entity?.TransactionCurrency?.Code;
    return !!(code && code !== getCompanyCurrency(storage.context));
};

export function getPaymentStatusTabData(): Pick<ISplitPageTableDef, "tabs" | "tabsSettings"> {
    const tabs: ITableDefTabData[] = [{
        id: PaymentStatusCode.WaitsForProcessing,
        title: i18next.t("Banks:Transactions.ToBeProcessed"),
        filterNames: [PaymentStatusCode.NeedsApproval, PaymentStatusCode.WaitsForProcessing, PaymentStatusCode.PartiallyProcessed]
    }, {
        id: PaymentStatusCode.Processed,
        filterNames: [PaymentStatusCode.Processed],
        title: i18next.t("Banks:Transactions.Cleared")
    }, {
        id: null,
        title: i18next.t("Components:FilterBar.All")
    }];

    return {
        tabs,
        tabsSettings: {
            filterFieldName: "PaymentStatusCode"
        },
    };
}

export function getPaymentTabsDef(isCbaCompany: boolean, context: IAppContext): IFormGroupDef {
    const entriesTabDef = isCbaCompany ? {
        id: "cbaEntryLedger",
        title: i18next.t("Document:FormTab.CbaEntries"),
        table: getCbaEntriesTable(DocumentTypeCode.BankTransaction, context)
    } : {
        id: "accountingJournal",
        title: i18next.t("Document:FormTab.JournalEntries"),
        table: getAccountJournalTable({
            hasDocItem: false,
            isPaymentDocument: true
        })
    };

    return {
        id: "Tabs",
        isVisible: (args: IGetValueArgs) => {
            return !args.storage.data.bindingContext.isNew();
        },
        tabs: [
            entriesTabDef
            , {
                id: "PairedDocuments",
                title: i18next.t("Banks:Transactions.TabAssignment"),
                table: getDocumentPairedTable({
                    selectQuery: `TypeCode eq '${DocumentLinkTypeCode.Payment}'`,
                    entitySet: EntitySetName.UndirectedPaymentDocumentLinks,
                    isCbaCompany
                })
            }]
    };
}

export function makeCbaDefAdjustments(form: IFormDef, itemsGroup: IFormGroupDef): void {
    form.fieldDefinition = {
        ...form.fieldDefinition,
        ...getCbaCategoryItemDef("Items", true, false)
    };
    itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: CBA_CATEGORY_PATH }, 1);
    itemsGroup.lineItems.additionalFields = [...(itemsGroup.lineItems.additionalFields ?? []),
        {
            id: createPath(PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.IsAssetAcquisition)
        }, {
            id: createPath(PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxImpact)
        }, {
            id: createPath(PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxPercentage)
        }, {
            id: createPath(PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.Category)
        }, {
            id: createPath(PaymentDocumentItemEntity.LinkedDocument, DocumentEntity.DocumentItems)
        }];
}

export function getPairIconDef(action: string, onClick: TClickAction, isDisabled: boolean): IToolbarItem {
    return {
        id: "pair",
        itemType: ToolbarItemType.Custom,
        render: () => {
            return (
                    <IconButton title={i18next.t("Banks:Transactions.IconTooltip")} isTransparent
                                key={"PairIconDef"}
                                onClick={onClick}
                                isActive={action === PAIR_ACTION_ID}
                                isDisabled={isDisabled || (!!action && action !== PAIR_ACTION_ID)}>
                        <UnchainedIcon width={IconSize.S}/>
                    </IconButton>
            );
        }
    };
}

export function createPaymentEntityCopy<E extends IEntity>(storage: FormStorage, args: ICopyEntityArgs, allowedNavigation: string[]): E {
    const EXCLUDED_PROPERTIES = [
        "Id", "DocumentTypeCode", "ClearedStatusCode", "PostedStatusCode", "PaymentStatusCode", "IsUnrelatedToBusiness"
    ];

    if (args.includeDocumentLinks) {
        allowedNavigation.push("DocumentLinks");
    }

    const entity = args.entity ?? storage.data.entity;
    const copy: E = {} as E;
    BindingContext.each(entity, storage.data.bindingContext, (value, bc) => {
        if (bc.isRoot()) {
            return true;
        }
        if (bc.getPath() === "NumberOurs" && (args.copyDataToNewDocument || !isObjectEmpty(entity.NumberRange))) {
            return false;
        }
        if (bc.getPath() === "Items") {
            let items = [];
            if (!value) {
                return false;
            }
            items = (value as IEntity[]).map((item, index) => {
                item.Order = index + 1; // todo: find out if we want sortable fast entry/ if we need Order, so far it is here because of BE constraint check
                const newItemId = item[BindingContext.NEW_ENTITY_ID_PROP];
                if (newItemId) {
                    const newItemBc = bc.addKey(item[BindingContext.NEW_ENTITY_ID_PROP], true);
                    if (storage.isDirty(newItemBc) || item.LinkedDocument?.Id || item[DRAFT_ITEM_ID_PATH]) {
                        return item;
                    }
                }
                return item;
            });
            value = items;
        }
        const path = bc.getPath(true);
        const isNavigation = bc.isNavigation();
        const isAllowedNavigation = isNavigation && bc.getParent().isRoot() && allowedNavigation.includes(path);
        const isNotExcludedProperty = !isNavigation && !EXCLUDED_PROPERTIES.includes(path);

        let isValid: boolean;
        if (args.skipInvalidProps && !isNavigation) {
            const info: IFieldInfo = {
                id: bc.getPath(),
                type: getFieldTypeFromProperty(bc.getProperty()),
                bindingContext: bc,
                validator: {
                    type: getValidatorTypeFromProperty(bc.getProperty())
                }
            };
            const schema = Validator.createValidationObject(info, storage);
            isValid = schema.isValidSync(value);
        }

        if (isAllowedNavigation || (isNotExcludedProperty && (!args.skipInvalidProps || isValid))) {
            copy[path as keyof E] = value as E[keyof E];
        }

        return false;
    });

    return copy;
}

export const setNewItemDefaultCategoryData = (item: IEntity): void => {
    const defaultCbaCategory: IPaymentDocumentItemCbaCategoryEntity = {
        IsAssetAcquisition: false,
        TaxPercentage: 100,
        TaxImpactCode: CbaCategoryTaxImpactCode.Tax
    };
    setNestedValue(defaultCbaCategory, PaymentDocumentItemEntity.CbaCategory, item);
};