import { BusyIndicatorSize } from "@components/busyIndicator/BusyIndicator.utils";
import { IMenuSelected } from "@components/navigation";
import { IEntity } from "@odata/BindingContext";
import { ICompany } from "@odata/EntityTypes";
import { getFieldInfoMemoized } from "@odata/FieldInfo.utils";
import {
    EntitySetName,
    ICashBoxEntity,
    ICompanyBankAccountEntity,
    ICompanyEntity,
    ICurrencyUsedByCompanyEntity,
    IEnabledFeatureEntity,
    IFiscalYearEntity,
    ISubscriptionEntity,
    ITenantEntity,
    IUserEntity,
    SubscriptionEntity,
    TenantEntity
} from "@odata/GeneratedEntityTypes";
import {
    CompanyPermissionCode,
    CompanyStateCode,
    CurrencyCode,
    FeatureCode,
    WebSocketMessageTypeCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { Wrapper } from "@odata/OData";
import {
    IModuleInfo,
    isSubscriptionCancelled,
    SUBSCRIPTION_MODULES_URL
} from "@pages/admin/subscriptions/Subscriptions.utils";
import fetchWithMiddleware, {
    IFetchMiddleWareAfterArgs,
    TFetchMiddlewareAfter
} from "@utils/fetchWithMiddleware/FetchWithMiddleware";
import { checkResponseHeader } from "@utils/fetchWithMiddleware/FetchWithMiddleware.utils";
import { getValue, isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import dayjs from "dayjs";
import Emittery from "emittery";
import { cloneDeep, isEqual } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { DefaultTheme } from "styled-components/macro";

import BusyIndicator from "../../components/busyIndicator";
import { ODATA_API_URL } from "../../constants";
import { getBackendTestModeTime, setFeTimeTravelDate } from "../../devtools/Devtools.utils";
import { HTTPStatusCode, QueryParam } from "../../enums";
import { TRecordAny, TRecordString, ValueOf } from "../../global.types";
import { isEvalaProduction } from "../../global.utils";
import i18n from "../../i18n";
import { ROUTE_NEW_COMPANY } from "../../routes";
import { getQueryParameters } from "../../routes/Routes.utils";
import { TTheme } from "../../theme";
import DateType, { getUtcDate } from "../../types/Date";
import NumberType from "../../types/Number";
import customFetch from "../../utils/customFetch";
import LocalSettings, { DevelLocalSettings } from "../../utils/LocalSettings";
import memoizeOne from "../../utils/memoizeOne";
import p13n from "../../utils/p13n";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import { FormStorage } from "../../views/formView/FormStorage";
import { ExtendedShellContent } from "../../views/main/Shell.types";
import { EVALA_FORBIDDEN_HEADER, EvalaAuthorized, SessionType } from "../authContext/Auth.utils";
import { withAuthContext } from "../authContext/withAuthContext";
import {
    AppContext,
    AppMode,
    ContextEvents,
    IAppContext,
    IAppContextData,
    IBreadcrumb,
    IBreadcrumbWithRelatedPathname,
    IContextProps
} from "./AppContext.types";
import { changeCompany, getUrlCompanyId, IPersonalizationSettings, personalizationSettings } from "./AppContext.utils";

export const companiesColorCode: (keyof DefaultTheme)[] = ["C_GRD_blue", "C_GRD_red", "C_GRD_pink", "C_GRD_purple"];

let getQueryParametersBackup: () => TRecordString;

const initialData: IAppContextData = {
    userSettings: {},
    personalizationSettings: {
        currentCompany: null
    },
    custom: {},
    enabledFeatures: []
};

class AppContextProvider extends Component<IContextProps, IAppContext> {
    data: IAppContextData;
    emitter = new Emittery();
    _unsubscribeWebsocket: () => void;

    constructor(props: IContextProps) {
        super(props);
        this.data = cloneDeep(initialData);
        this.state = this.getInitialState();

        if (LocalSettings.get("App").isFirstRenderAfterLogin) {
            LocalSettings.remove("App", "isFirstRenderAfterLogin");
            this.setAppMode(AppMode.OrganizationSettings, true);
        }
    }

    getInitialState = (): IAppContext => {
        return {
            eventEmitter: this.emitter,
            rerenderApp: this.rerenderApp.bind(this),
            // user getters instead of direct access,
            // otherwise values won't propagate when context is used outside of default state architecture (e.g. in storage)
            getCompany: this.getCompany.bind(this),
            getCompanyId: this.getCompanyId.bind(this),
            getDefaultCurrency: this.getDefaultCurrency.bind(this),
            getCompanyBankAccounts: this.getCompanyBankAccounts.bind(this),
            getCashBoxes: this.getCashBoxes.bind(this),
            getCompanyPermissions: this.getCompanyPermissions.bind(this),
            getGeneralPermissions: this.getGeneralPermissions.bind(this),
            updateFiscalYears: this.updateFiscalYears.bind(this),
            updateCompanyBankAccounts: this.updateCompanyBankAccounts.bind(this),
            updateCashBoxes: this.updateCashBoxes.bind(this),
            updateCurrenciesUsedByCompany: this.updateCurrenciesUsedByCompany.bind(this),
            // customData: {},
            setAppMode: this.setAppMode.bind(this),
            getAppMode: this.getAppMode.bind(this),
            setCurrentCompanyId: this.setCurrentCompanyId.bind(this),
            updateCompany: this.updateCompany.bind(this),
            updateCompanySpecificData: this.updateCompanySpecificData.bind(this),
            openExtendedShell: this.openExtendedShell.bind(this),
            updateCompanies: this.updateCompanies.bind(this),
            getFYPromise: this.getFYPromise.bind(this),
            getUpdateCompaniesPromise: this.getUpdateCompaniesPromise.bind(this),
            updateTenantAndSubscription: this.updateTenantAndSubscription.bind(this),
            updateModulesInfo: this.updateModulesInfo.bind(this),
            p13n,
            feP13nSettings: personalizationSettings,
            loaded: false,
            isLoadingCompany: false,
            error: null,
            resetError: this.resetError.bind(this),
            changeUserSetting: this.changeUserSetting.bind(this),
            changePersonalizationSetting: this.changePersonalizationSetting.bind(this),
            getCurrentTheme: this.getCurrentTheme.bind(this),
            getSelectedMenu: this.getSelectedMenu.bind(this),
            setSelectedMenu: this.setSelectedMenu.bind(this),
            getViewBreadcrumbs: this.getViewBreadcrumbs.bind(this),
            setViewBreadcrumbs: this.setViewBreadcrumbs.bind(this),
            setCustomData: this.setCustomData.bind(this),
            getAddingNewCompany: this.getAddingNewCompany.bind(this),
            hasLimitedAccess: this.hasLimitedAccess.bind(this),
            refreshAppData: this.refreshAppData.bind(this),
            getData: this.getData.bind(this),
            setData: this.setData.bind(this),
            setAppBusy: this.setAppBusy.bind(this),
            isAppBusy: false,
            modalOpened: 0,
            isModalOpened: this.isModalOpened.bind(this),
            isFullscreenMode: false,
            setFullscreenMode: this.setFullscreenMode.bind(this),
            openAuditTrailDialog: this.openAuditTrailDialog.bind(this),
            closeAuditTrailDialog: this.closeAuditTrailDialog.bind(this),
            auditTrailData: {},
            isFeatureSwitchEnabled: this.isFeatureSwitchEnabled.bind(this)
        };
    };

    componentDidMount = async () => {
        if (this.props.authContext.isAuthenticated) {
            await this.fetchData();

            this.injectCompanyIdIntoOdataRequests();
            customFetch.setCompanyId(this.getCompanyId());
        }

        fetchWithMiddleware.useAfter(this.afterFetchMiddleware);

        this.setState(() => ({
            loaded: true
        }));

        this._unsubscribeWebsocket = WebsocketManager.subscribe({
            callback: this.handleSessionInvalidated,
            types: [WebSocketMessageTypeCode.SessionInvalidated]
        });

        this.state.eventEmitter.on(ContextEvents.ModalToggled, this.handleModalToggle);
    };

    // TODO change the error handling
    componentDidUpdate(prevProps: Readonly<IContextProps>, prevState: Readonly<IAppContext>): void {
        if (!prevProps.authContext.isAuthenticated && this.props.authContext.isAuthenticated) {
            this.fetchData();
        } else if (prevProps.authContext.isAuthenticated && !this.props.authContext.isAuthenticated) {
            this.reset();
        }

        if (this.state.loaded && this.props.companyPermissions !== prevProps.companyPermissions) {
            const currentCompanyId = this.getCompanyId();
            if (currentCompanyId && !this.props.companyPermissions?.[currentCompanyId]?.size) {
                // user lost permissions for selected company, fetch again all data
                this.refreshAppData();
            } else {
                // just update list of companies with CanAccessCustomerPortal
                this.updateCanAccessPermissions();
            }
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            // so far, we only want full screen mode for table (view)
            // => if user navigates elsewhere, either by clicking on a link or back button,
            //    we want to exit full screen mode
            if (this.state.isFullscreenMode) {
                this.setState({
                    isFullscreenMode: false
                });
            }

            if (this.state.error) {
                this.setState({
                    error: null
                });
            }
        }
    }

    componentWillUnmount() {
        fetchWithMiddleware.removeAfter(this.afterFetchMiddleware);
        this.state.eventEmitter.off(ContextEvents.ModalToggled, this.handleModalToggle);
        this._unsubscribeWebsocket?.();
    }

    /* Fired when backend session changes (not login cookies session, but BE api/auth/session whatever that means)
    * => re-fetch roles (probably could be changed),
    * also enabled featured could've been changed */
    handleSessionInvalidated = async (): Promise<void> => {
        await Promise.all([
            this._fetchUserSettings(true),
            this._fetchEnabledFeatures(),
            // subscription/tenant could've been canceled
            this.updateTenantAndSubscription()
        ]);
        this.rerenderApp();
    };

    async refreshAppData() {
        this.setState({ loaded: false, isLoadingCompany: true });
        await this.fetchData();
        this.setState({ loaded: true, isLoadingCompany: false });
    }

    afterFetchMiddleware: TFetchMiddlewareAfter = async ({ response }: IFetchMiddleWareAfterArgs): Promise<void> => {
        // used when tenant is changed from Standard to Edu while having Evala opened,
        // => requests will start to return 403 Forbidden with this header
        // ==> we need to reload session and redirect to goodbye screen
        const foundLimitedAccess = await checkResponseHeader(response, (headers: Headers) => {
            return headers?.get(EVALA_FORBIDDEN_HEADER) === EvalaAuthorized.SessionHasLimitedAccess;
        });

        if (foundLimitedAccess && !this.hasLimitedAccess()) {
            this.handleSessionInvalidated();
        }
    };

    getSelectedMenuMemoized = memoizeOne(
        (): IMenuSelected => {
            if (!this.data.selectedMenu) {
                return null;
            }

            // translate the selected menu untranslated titles
            const selectedMenu = { ...this.data.selectedMenu };

            if (selectedMenu.group) {
                selectedMenu.group = {
                    ...selectedMenu.group,
                    title: this.props.t(`Common:${selectedMenu.group.title}`)
                };
            }

            if (selectedMenu.item) {
                selectedMenu.item = {
                    ...selectedMenu.item,
                    title: this.props.t(`Common:${getValue(selectedMenu.item.title)}`)
                };
            }

            return selectedMenu;
        }, () => [this.props.tReady, this.data.selectedMenu]);

    getData = () => {
        return this.data;
    };

    getCompany = (): ICompany => {
        return this.data.company as ICompany;
    };

    getCompanyId = (): number => {
        const loadedCompanyId = this.getCompany()?.Id;
        if (this.state.loaded) {
            // fallback undefined to null to prevent page remount when new form is saved ?!?!?
            return loadedCompanyId ?? null;
        }
        // fallback to URL or personalization only when not loaded yet
        return loadedCompanyId ?? getUrlCompanyId() ?? this.data.personalizationSettings?.currentCompany;
    };

    getDefaultCurrency = (): CurrencyCode => {
        const company = this.getCompany();
        return company.Accounting.Country.DefaultCurrencyCode as CurrencyCode;
    };

    getCompanyPermissions = () => {
        const currentCompanyId = this.getCompanyId();
        return this.props.companyPermissions[currentCompanyId] ?? new Set();
    };

    getGeneralPermissions = () => {
        return this.props.generalPermissions;
    };

    getCashBoxes = (activeOnly = false) => {
        const accounts = this.data.cashBoxes;

        if (activeOnly) {
            return accounts.filter((account) => account.IsActive);
        }

        return accounts;
    };

    getCompanyBankAccounts = (activeOnly = false) => {
        const accounts = this.data.companyBankAccounts;

        if (activeOnly) {
            return accounts.filter((account) => account.IsActive);
        }

        return accounts;
    };

    removeMetaProperties = (entity: IEntity | IEntity[]) => {
        const arr = Array.isArray(entity) ? entity : [entity];

        for (const data of arr) {
            delete data["@evala.metadata"];
            delete data["@odata.etag"];
            delete data["@odata.context"];

            for (const val of Object.values(data)) {
                if (val && typeof val === "object") {
                    this.removeMetaProperties(val);
                }
            }
        }
    };

    async _fetchData<T>(url: string): Promise<T> {
        const response = await fetch(url);

        if (!response.ok) {
            // HTTPStatusCode.Forbidden is returned by BE for canceled tenants
            // => we don't want the app to fail, just show goodbye screen
            if (response.status !== HTTPStatusCode.Forbidden) {
                this.setErrorState(`${response.status},${response.statusText}`,
                    `Failed to load ${url}, error code: ${response.status}`);
            }

            return null;
        }

        const parsed = await response.json();
        const data = parsed.value ?? parsed;

        this.removeMetaProperties(data);

        return data;
    }

    _fetchUserSettings = async (withoutUpdates?: boolean) => {
        const userId = this.props.authContext.userId;
        const user = await this._fetchData<IUserEntity>(`${ODATA_API_URL}/${EntitySetName.Users}(${userId})?$expand=DateFormat, TimeFormat, GeneralRoles($expand=GeneralRole), CompanyRoles($expand=CompanyRole,Company), NotificationSetting`);

        if (!user) {
            return;
        }

        if (!withoutUpdates) {
            await this.updateFrontEndLanguage(user.LanguageCode);
            this.updateDateFormat(user.DateFormat.Format);
            this.updateTimeFormat(user.TimeFormat.Format);
        }

        this.setData({
            userSettings: {
                ...this.data.userSettings,
                ...user
            }
        });
    };

    updateCanAccessPermissions(): void {
        const { companies } = this.data;
        const updated = companies.map(company => ({
            ...company,
            CanAccessCustomerPortal: this.canAccessCustomerPortalForCompany(company.Id)
        }));

        const currentCompanyId = this.getCompanyId();
        const company = updated.find(item => item.Id === currentCompanyId);

        this.setData({ companies: updated, company });
    }

    canAccessCustomerPortalForCompany(companyId: number): boolean {
        const companyPermissions = this.props.companyPermissions?.[companyId];
        return !!companyPermissions?.has(CompanyPermissionCode.CustomerPortal);
    }

    _fetchCompanies = async () => {
        const companies: ICompany[] = [];
        let company: ICompany;

        const currentCompanyId = this.getCompanyId();

        try {
            const filter = `StateCode in ('${CompanyStateCode.New}','${CompanyStateCode.Initialized}','${CompanyStateCode.Archived}')`;
            const expand = "Logo,Accounting($expand=Country),CompanySetting,Currency($expand=DefaultCountry),CommunicationContact,FinancialAdministration($expand=Country),LegalAddress($expand=Country),PrPayrollSetting,Stamp,VatStatuses($orderby=DateValidFrom%20asc)";
            const companiesUrl = `${ODATA_API_URL}/${EntitySetName.Companies}?$filter=${filter}&$expand=${expand}&$orderBy=Name`;
            const fetchedCompanies = await this._fetchData<ICompanyEntity[]>(companiesUrl);

            fetchedCompanies.forEach((companyData, idx) => {

                const CanAccessCustomerPortal = this.canAccessCustomerPortalForCompany(companyData.Id);
                const companyEntity = {
                    ...companyData,
                    CanAccessCustomerPortal,
                    ColorCode: companiesColorCode[idx % 4]
                };

                // data don't go through oData parser => parse them manually
                for (const vatStatus of companyEntity.VatStatuses) {
                    if (vatStatus.DateValidFrom) {
                        vatStatus.DateValidFrom = getUtcDate(vatStatus.DateValidFrom);
                    }

                    if (vatStatus.DateValidTo) {
                        vatStatus.DateValidTo = getUtcDate(vatStatus.DateValidTo);
                    }
                }

                if (currentCompanyId && currentCompanyId === companyEntity.Id) {
                    company = companyEntity;
                }

                companies.push(companyEntity);
            });
        } catch (e) { /* fall silently */
            logger.error(e.toString());
            // in case there is an error in fetching companies, we don't want to proceed with further code as it
            // may unset current company, e.g. in cases when user navigates to
            // different page and the fetch request is just cancelled... The page may not be available.
            return;
        }

        const isCustomerPortalSession = this.props.authContext.sessionType === SessionType.Customer;

        // todo: may be we want to process this IF only first time when app is loaded??
        if ((currentCompanyId || isCustomerPortalSession) && !company) {
            // current company not fetched -> user most likely don't have permissions to access the company,
            // which ID is stored in personalization settings or in URL
            //  ==> in case of customer portal session, we find some customer portal company of the user
            if (isCustomerPortalSession) {
                company = companies?.find(c => true /* todo: BE most likely set the flag only for standard sessions... isCustomerPortalSession === c.CanAccessCustomerPortal*/);
                if (company) {
                    this.correctCompanyIdInUrl(company.Id);
                    await this.setCurrentCompanyId(company.Id, isCustomerPortalSession, companies);
                    // data are already set in setCurrentCompanyId, no need to do it again
                    return;
                } else {
                    // redirect to login/tenant as user don't have any customer portal company and has customer session
                    // todo: ...
                    logger.error("User don't have any customer portal company, nothing to show");
                }
            } else {
                //  ==> otherwise we can show tenant homepage
                this.correctCompanyIdInUrl(null);
                await this.setCurrentCompanyId(null, false);
            }
        }

        this.setData({
            company,
            companies: companies
        });
    };

    correctCompanyIdInUrl(currentCompanyId: number) {
        const { location, history } = this.props;

        const queryParams = getQueryParameters({ historyLocation: location });
        const urlCompanyId = queryParams.CompanyId ? parseInt(queryParams.CompanyId) : null;

        if (queryParams.CompanyId && urlCompanyId !== currentCompanyId) {
            const newQueryParams = new URLSearchParams({ ...queryParams });
            if (currentCompanyId) {
                newQueryParams.set(QueryParam.CompanyId, currentCompanyId.toString());
            } else {
                newQueryParams.delete(QueryParam.CompanyId);
            }
            history.replace({ ...location, search: newQueryParams.toString() });
        }
    }

    _fetchEnabledFeatures = async (isFirstTime?: boolean): Promise<void> => {
        try {
            const enabledFeatures = await this._fetchData<IEnabledFeatureEntity[]>(`${ODATA_API_URL}/${EntitySetName.EnabledFeatures}`);
            const featureCodes = enabledFeatures.map(feature => feature.FeatureCode as FeatureCode);

            if (!isEqual(featureCodes, this.getData().enabledFeatures)) {
                this.setData({
                    enabledFeatures: featureCodes
                });

                if (!isFirstTime) {
                    this.rerenderApp();
                }
            }
        } catch (e) {
            logger.error(e.toString());

        }
    };

    _fetchTimeTravel = async (): Promise<void> => {
        if (isEvalaProduction()) {
            DevelLocalSettings.clear();
            return;
        }

        const testModeTime = await getBackendTestModeTime();

        setFeTimeTravelDate(testModeTime);
    };

    // There is different subdomain in production for tenant session (**tenantname**.evala.cz) and
    // login session (app.evala.cz). But post login verification is done on the app session (domain).
    // when user picks his tenant, he is redirected to the tenant subdomain and app is reloaded -> tenantPicker
    // (where was the check before) is not even initialized on the app session, so the check has to be done always on load here.
    _getPostLoginAction = async (): Promise<void> => {
        // const actions = await getPostLoginActions();
        // const relatedActions = getTenantRelatedLoginOption(this.props.authContext.tenantId, actions);
        // if (relatedActions?.length) {
        //     this.props.history.push(getVerificationUrl(relatedActions[0]));
        // }
    };

    _fetchPersonalizationSettings = async () => {
        const personalization = await this.state.feP13nSettings.get();

        if (!isObjectEmpty(personalization)) {
            this.setData({
                personalizationSettings: {
                    ...this.data.personalizationSettings,
                    ...personalization
                }
            });
        }
    };

    _fetchTenant = async () => {
        const selectProps = [
            TenantEntity.DateDataDeletion,
            TenantEntity.IsActive,
            TenantEntity.IsCanceled,
            TenantEntity.ProductCode,
            TenantEntity.PhoneNumber
        ];

        const tenant = (await this._fetchData<ITenantEntity>(`${ODATA_API_URL}/${EntitySetName.Tenants}(${this.props.authContext.tenantId})?select=${selectProps.join(",")};$filter=Id eq ${this.props.authContext.tenantId})`));

        if (!tenant) {
            return;
        }

        this.setData({
            tenant
        });
    };

    _fetchSubscription = async () => {
        const selectProps = [
            SubscriptionEntity.Id, SubscriptionEntity.TrialLength,
            SubscriptionEntity.SubscriptionTypeCode, SubscriptionEntity.PurchaseStatusCode,
            SubscriptionEntity.IsGracefulPeriod, SubscriptionEntity.DateGracefulPeriodEnd, SubscriptionEntity.HasLimitedAccess
        ];
        // list of organizations is loaded, but there is always only one - first one.
        const subscriptions = (await this._fetchData<ISubscriptionEntity[]>(`${ODATA_API_URL}/${EntitySetName.Subscriptions}?select=${selectProps.join(",")}&$expand=${SubscriptionEntity.SubscriptionStatus},${SubscriptionEntity.PurchaseStatus},${SubscriptionEntity.SubscriptionType}`));

        if (!subscriptions) {
            return;
        }

        const subscription = subscriptions[0];

        this.setData({
            subscription
        });

        if (isSubscriptionCancelled(subscription)) {
            this.setAppMode(AppMode.OrganizationSettings);
        }
    };

    _fetchModulesInfo = async () => {
        const url = `${SUBSCRIPTION_MODULES_URL}/GetModulesInfo`;

        const res = await fetch(url);
        const modulesInfo = await res.json() as IModuleInfo[];

        if (!res.ok || !modulesInfo) {
            this.setData({
                modulesInfo: []
            });
            return;
        }

        this.setData({
            modulesInfo
        });
    };

    // FY promise to be awaited in case it's needed somewhere
    _fyPromise = Promise.resolve();
    getFYPromise = () => this._fyPromise;

    _fetchFiscalYears = (): Promise<void> => {
        this._fyPromise = new Promise(async (resolve, reject) => {
            let fiscalYears: IFiscalYearEntity[];
            const companyId = this.getCompanyId();

            try {
                const url = `${ODATA_API_URL}/${EntitySetName.FiscalYears}?$expand=Periods,ChartOfAccounts&CompanyId=${companyId}`;
                fiscalYears = await this._fetchData<IFiscalYearEntity[]>(url);
            } catch (e) {
                logger.error("AppContext: Error in _fetchFiscalYears response", e);
            }

            if (!fiscalYears) {
                // this state most likely should not happen. It should be at least empty array. Consider fallback
                // to empty array in case it's case for some companies in graceful period or so, unless it may happen
                // that there are left fiscal years from previous company
                logger.warn(`fiscalYears are not defined for company ${companyId}`);
                resolve();
                return;
            }

            this.setData({
                fiscalYears: fiscalYears.map(fiscalYear => ({
                    ...fiscalYear,
                    DateStart: getUtcDate(fiscalYear.DateStart),
                    DateEnd: getUtcDate(fiscalYear.DateEnd),
                    Periods: fiscalYear.Periods.map(period => {
                        return {
                            ...period,
                            DateStart: getUtcDate(period.DateStart),
                            DateEnd: getUtcDate(period.DateEnd)
                        };
                    })
                }))
            });

            resolve();
        });

        return this._fyPromise;
    };

    _fetchCompanyCashBoxes = async () => {
        const url = `${ODATA_API_URL}/${EntitySetName.CashBoxes}?$expand=Balance,TransactionCurrency,ReceiptReceivedNumberRangeDefinition,ReceiptIssuedNumberRangeDefinition&CompanyId=${this.getCompanyId()}`;
        const cashBoxes = await this._fetchData<ICashBoxEntity[]>(url);

        if (!cashBoxes) {
            return;
        }

        this.setData({
            cashBoxes
        });
    };


    _fetchCompanyBankAccounts = async () => {
        const url = `${ODATA_API_URL}/${EntitySetName.CompanyBankAccounts}?$expand=Balance,Logo,BankStatementNumberRangeDefinition,Bank,Currency,TransactionCurrency,Country,BankApiConnectionSetting($select=ErrorMessage,ApiKeyId),BankApiStatementImportSetting&$orderBy=Name asc&CompanyId=${this.getCompanyId()}`;
        const companyBankAccounts = await this._fetchData<ICompanyBankAccountEntity[]>(url);

        if (!companyBankAccounts) {
            return;
        }

        this.setData({
            companyBankAccounts
        });
    };

    isFeatureSwitchEnabled = (feature: FeatureCode): boolean => {
        return !!this.getData().enabledFeatures?.includes(feature);
    };

    updateFiscalYears = async () => {
        await this._fetchFiscalYears();
    };

    updateCashBoxes = async () => {
        await this._fetchCompanyCashBoxes();
    };

    updateCompanyBankAccounts = async () => {
        await this._fetchCompanyBankAccounts();
    };

    _fetchCurrenciesUsedByCompany = async () => {
        const url = `${ODATA_API_URL}/${EntitySetName.CurrenciesUsedByCompany}?$expand=FromCurrency&CompanyId=${this.getCompanyId()}`;
        const currenciesUsedByCompany = await this._fetchData<ICurrencyUsedByCompanyEntity[]>(url) ?? [];

        this.setData({ currenciesUsedByCompany });
    };

    updateCurrenciesUsedByCompany = async () => {
        await this._fetchCurrenciesUsedByCompany();
    };

    reset = () => {
        this.data = cloneDeep(initialData);
        this.setState(this.getInitialState());
    };

    fetchData = async () => {
        // trigger first to set current company based on personalization settings
        await this._fetchPersonalizationSettings();

        const savedCompanyId = this.state.isLoadingCompany ? null : this.getCompanyId();

        // we can't use oData helper methods in these fetch methods, because ODataProvider is not ready just yet
        const promises = [
            this._fetchUserSettings(),
            this._fetchTenant(),
            this._fetchSubscription(),
            this._fetchModulesInfo(),
            this._fetchCompanies(),
            this._fetchEnabledFeatures(true),
            this._fetchTimeTravel(),
            this._getPostLoginAction()
        ];

        if (!!savedCompanyId) {
            promises.push(...this.updateCompanySpecificData());
        }

        await Promise.all(promises);
    };

    setData = (newData: Partial<IAppContextData>) => {
        this.data = {
            ...this.data,
            ...newData
        };
    };

    setAppBusy = (isBusy: boolean) => {
        this.setState({
            isAppBusy: isBusy
        });
    };

    _updateCompaniesPromise = Promise.resolve();
    getUpdateCompaniesPromise = () => this._updateCompaniesPromise;

    updateCompanies = () => {
        this._updateCompaniesPromise = this._fetchCompanies();
        return this._updateCompaniesPromise;
    };

    updateTenantAndSubscription = () => {
        return Promise.all([
            this._fetchTenant(),
            this._fetchSubscription()
        ]);
    };

    updateModulesInfo = () => this._fetchModulesInfo();

    resetError() {
        this.setState({
            error: null
        });
    }

    setCustomData(data: TRecordAny) {
        this.setData({
            custom: {
                ...this.data.custom,
                ...data
            }
        });
    }

    getAddingNewCompany() {
        return this.props.history.location.pathname === ROUTE_NEW_COMPANY || (this.props.authContext.isAuthenticated
            && this.getAppMode() !== AppMode.OrganizationSettings
            && this.getCompanyId() &&
            this.getCompany()?.StateCode === CompanyStateCode.New);
    }

    hasLimitedAccess(): boolean {
        return !!this.data.subscription?.HasLimitedAccess || isSubscriptionCancelled(this.data.subscription);
    }

    setErrorState(userErrorMessage: string, logErrorMessage: string) {
        this.setState({
            error: userErrorMessage
        });
        logger.error(logErrorMessage);
    }

    changeDayJsLocale = async (language: string) => {
        const localeName = language.split("-")[0];

        // imports with variable are dangerous for bundlers, better not use them
        // const locale = await import(`dayjs/locale/${localeName}.js`);
        let locale: any;

        // for some reason, en locale doesn't have proper configuration in dayjs
        if (localeName === "en") {
            locale = await import(`dayjs/locale/en-gb.js`);
        } else if (localeName === "cs") {
            locale = await import(`dayjs/locale/cs.js`);
        }


        dayjs.locale(locale);
    };

    injectCompanyIdIntoOdataRequests = () => {
        // TODO this seems to be the easiest way how to inject CompanyId into OData requests
        // but it would probably be cleaner to change OData and EntitySetWrapper constructors
        // instead of changing the code in runtime
        // this approach doesn't even allow for option to opt-out from using CompanyId on .query()/fetchData call
        // but it can be overridden by setting query.queryParameters({CompanyId: "whatever i want"})
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;

        // we don't want to stack multiple functions recursively
        // this can happen when user logout and login
        if (!getQueryParametersBackup) {
            getQueryParametersBackup = Wrapper.prototype.getQueryParameters;
        }

        Wrapper.prototype.getQueryParameters = function(this: Wrapper<any>) {
            const params = getQueryParametersBackup.call(this) ?? {};
            const companyId = that.getCompanyId();

            if (companyId && !params.CompanyId) {
                params.CompanyId = companyId.toString();
            }

            return params;
        };
    };

    updateCompanySpecificData = (): Promise<void>[] => {
        return [
            this._fetchFiscalYears(),
            this._fetchCompanyCashBoxes(),
            this._fetchCompanyBankAccounts(),
            this._fetchCurrenciesUsedByCompany()
        ];
    };

    getViewBreadcrumbs = (): IBreadcrumbWithRelatedPathname => {
        return this.data.viewBreadcrumbs ?? { items: [], lockable: false };
    };

    setAppMode = (mode: AppMode, withoutRerender?: boolean): void => {
        if (mode === this.getAppMode() || this.state.error || (this.hasLimitedAccess() && mode !== AppMode.OrganizationSettings)) {
            return;
        }

        this.setCurrentCompanyId(mode === AppMode.OrganizationSettings ? null :
            this.data.personalizationSettings?.previousCompany ?? this.data.companies[0]?.Id);

        if (!withoutRerender) {
            this.rerenderApp();
        }
    };

    getAppMode = (): AppMode => {
        return !this.getCompany() ? AppMode.OrganizationSettings : AppMode.Company;
    };

    setCurrentCompanyId = async (companyId: number, isCustomerPortal = false, allCompanies?: ICompany[]) => {
        if (this.hasLimitedAccess() && companyId !== null) {
            return;
        }
        if (companyId === this.getCompanyId()) {
            // nothing to change, companyId already selected
            return;
        }

        this.setState({
            isLoadingCompany: true
        });

        const previousCompanyId = this.getCompany()?.Id;
        // in case companies are passed in params, we set them as new companies list
        const companies = (allCompanies ?? this.data.companies);
        const company = companies?.find(c => c.Id === companyId);
        this.setData({
            company,
            companyBankAccounts: [],
            companies
        });

        // common company settings
        changeCompany(companyId, isCustomerPortal);

        // store currently selected company to backend
        this.changePersonalizationSetting("currentCompany", companyId);

        if (company?.StateCode === CompanyStateCode.New) {
            await Promise.all(this.updateCompanySpecificData());
            // If company is not initialized, we store also previous company ID, so we may
            // direct user back if he skips the initial settings
            this.changePersonalizationSetting("previousCompany", previousCompanyId);
            this.props.history.replace(ROUTE_NEW_COMPANY);
        } else if (!company) {
            this.changePersonalizationSetting("previousCompany", previousCompanyId);
        } else {
            await Promise.all(this.updateCompanySpecificData());
        }

        this.setState({
            isLoadingCompany: false
        });

        this.emitter.emit(ContextEvents.CompanyChanged, companyId);
        this.setAppMode(companyId ? AppMode.Company : AppMode.OrganizationSettings, true);
        this.rerenderApp();
    };

    openExtendedShell = (type: ExtendedShellContent) => {
        this.emitter.emit(ContextEvents.OpenExtendedShell, type);
    };

    updateCompany = (companyId: number, updateValues: TRecordAny) => {
        let updatedCompany = this.data.companies.find(company => company.Id === companyId);

        updatedCompany = {
            ...updatedCompany,
            ...updateValues
        };

        this.setData({
            company: this.data.company?.Id === updatedCompany?.Id ? updatedCompany : this.data.company,
            companies: this.data.companies.map(company => company.Id === companyId ? updatedCompany : company)
        });
    };

    setViewBreadcrumbs = (viewBreadcrumbs: IBreadcrumb, relatedPathname: string = this.props.location.pathname) => {
        this.setData({
            viewBreadcrumbs: {
                ...viewBreadcrumbs,
                relatedPathname
            }
        });
        this.emitter.emit(ContextEvents.ViewBreadcrumbsChanged);
    };

    getSelectedMenu = () => {
        return this.getSelectedMenuMemoized();
    };

    setSelectedMenu = (selectedMenu: IMenuSelected) => {
        // prevent Payroll menu from being accessed when disabled
        if (selectedMenu?.group?.key === "Payroll" && !this.isFeatureSwitchEnabled(FeatureCode.Payroll)) {
            return;
        }

        this.setData({
            selectedMenu
        });

        this.emitter.emit(ContextEvents.SelectedMenuChanged);
    };

    rerenderApp = () => {
        // something like forceUpdate
        // force rerender on all consumers, by "changing" the state
        // forceUpdate doesn't work in this case, because the value of the Provider doesn't change
        this.setState({});
    };

    async updateFrontEndLanguage(lang: string) {
        await Promise.all([
            this.changeDayJsLocale(lang),
            i18n.changeLanguage(lang)
        ]);
        document.documentElement.setAttribute("lang", lang);
        NumberType.localeDidChange();

        // clear every cache related to language
        getFieldInfoMemoized.cache.clear();
        getEnumDisplayValue.cache.clear();
    }

    updateDateFormat(format: string) {
        DateType.defaultDateFormat = format;
    }

    updateTimeFormat(format: string) {
        DateType.defaultTimeFormat = format;
    }

    /** Change settings that are tied directly to the User entity */
    async changeUserSetting(key: keyof IUserEntity, val: string): Promise<void> {
        if (key === "LanguageCode") {
            await this.updateFrontEndLanguage(val);
        } else if (key === "DateFormatCode") {
            this.updateDateFormat(val);
        } else if (key === "TimeFormatCode") {
            this.updateTimeFormat(val);
        }

        this.setData({
            userSettings: {
                ...this.data.userSettings,
                [key]: val
            }
        });
    }

    /** Change settings stored in the p13n service */
    async changePersonalizationSetting(key: keyof IPersonalizationSettings, val: ValueOf<IPersonalizationSettings>) {
        const personalizationSettings: IPersonalizationSettings = {
            ...this.data.personalizationSettings,
            [key]: val
        };

        // immediately store in to context, to propagate the change on frontend, even if it doesn't get stored on BE
        this.setData({
            personalizationSettings
        });

        this.state.feP13nSettings.update(key, val);
    }

    getCurrentTheme(): TTheme {
        return this.getData()?.userSettings?.ColourThemeCode?.toLowerCase() as TTheme;
    }

    openAuditTrailDialog = (storage: FormStorage) => {
        this.setState({
            auditTrailData: {
                isShown: true,
                storage: storage
            }
        });
    };

    closeAuditTrailDialog = () => {
        this.setState({
            auditTrailData: {
                isShown: false
            }
        });
    };

    isModalOpened = (): boolean => {
        return this.state.modalOpened > 0;
    };

    setFullscreenMode = (isFullscreenMode: boolean) => {
        this.setState({
            isFullscreenMode
        });
    };

    handleModalToggle = (isOpen: boolean) => {
        this.setState(({ modalOpened }) => ({ modalOpened: modalOpened + (isOpen ? 1 : -1) }));
    };

    render() {
        if (this.state.loaded) {
            return (
                <AppContext.Provider value={this.state}>
                    {this.state.isAppBusy && <BusyIndicator/>}
                    {this.props.children}
                </AppContext.Provider>
            );
        } else {
            return (
                <BusyIndicator size={BusyIndicatorSize.L} isDelayed/>
            );
        }
    }
}

const AppContextProviderWithRouter = withTranslation(["Common"])(withRouter(withAuthContext(AppContextProvider)));

export { AppContextProviderWithRouter as AppContextProvider };