import { ISelectItem } from "@components/inputs/select/Select.types";
import { ODataError } from "@odata/Data.types";
import { setBoundValue } from "@odata/Data.utils";
import {
    EntitySetName,
    EntityTypeName,
    FileMetadataEntity,
    IEntityAttachmentEntity,
    IFileMetadataEntity
} from "@odata/GeneratedEntityTypes";
import { BatchRequest, isBatchResultOk } from "@odata/OData";
import { attachInboxFile, loadInboxFileMetadata } from "@pages/inbox/Inbox.utils";
import dayjs from "dayjs";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { NEW_ITEM_DETAIL } from "../../../constants";
import { FileAction, Status } from "../../../enums";
import { ModelEvent } from "../../../model/Model";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import FileStorage, { getFileStorageUploadErrorMessage, IFileProgress } from "../../../utils/FileStorage";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { AlertPosition } from "../../alert/Alert";
import { WithAlert, withAlert } from "../../alert/withAlert";
import { WithConfirmationDialog, withConfirmationDialog } from "../../dialog/withConfirmationDialog";
import { IFile } from "../../fileUploader/File";
import { getUniqueFileName, isValidFileName } from "../../fileUploader/File.utils";
import FileInfoDialog from "../../fileUploader/FileInfoDialog";
import FileRenameDialog from "../../fileUploader/FileRenameDialog";
import FileUploader, { IFileActionEvent } from "../../fileUploader/FileUploader";
import { TId } from "../../table";

interface IProps extends WithTranslation, WithAlert, WithConfirmationDialog {
    storage: FormStorage;
    /** Collection inside current binding context, that handles the files */
    collection: string;
    /**
     * Name of file property
     * e.g. documents use Attachments set, but the file it self is in File property - complete path /InvoicesReceived(20)/Attachments(1)/File
     */
    fileProperty?: string;
    isReadOnly?: boolean;
    isLocalDropArea?: boolean;
    isHidden?: boolean;
    customFileActions?: (file: IFileMetadataEntity, storage: FormStorage) => ISelectItem[];
    onCustomFileAction?: (fileMetadata: IFileMetadataEntity, actionId: string, attachId: number) => void;
    /** New files were added into FileUploader either via "Add" button or with drag and drop */
    onNewFiles?: () => void;
    /** Files that were previously added to file uploader were successfully uploaded */
    onFilesUploaded?: (files: IFileMetadataEntity[]) => void;
    onFileClick?: (file: IFileMetadataEntity) => void;
    onDragEnter?: (event: DragEvent) => void;
    onDragLeave?: (event: DragEvent) => void;
    /** List of elements, that handles file drop for themselves,
     * and when user hovers files over them, in case this FileDropArea is global,
     * we should disable hover state in this FileDropArea */
    otherFileDropAreas?: HTMLElement[];
    ref?: React.Ref<any>;

    className?: string;
    style?: React.CSSProperties;
}

interface IState {
    busy: boolean;
    showInfoForFile: number;
    showRenameFile?: TId;
}

/** Handles upload/download/removal of files that combines OData and backend file storage.
 * Accepts defaultFiles from parent, so that we can save one backend request. */
class SmartFileUploader extends React.Component<IProps, IState> {
    state: IState = {
        busy: false,
        showInfoForFile: null
    };

    // in case another files are dropped to SmartFileUploader while previous files are still uploading
    // we want to wait for all the previous to finish
    uploadInProgress: Promise<void>[] = [];

    getCollectionBcMemoized = memoizeOne(
        () => {
            return this.props.storage.data.bindingContext.navigate(this.props.collection);
        }
        , () => [this.props.storage.data.bindingContext]);

    get isNew() {
        return this.props.storage.data.bindingContext.isNew();
    }

    // rerender file uploader on item change, to prevent it from keeping wrong state
    // e.g. to not keep removing state when another document in table is selected
    // when bindingContext is new, bindingContext.toString() may change due to savedDraft,
    // however we don't want to remount the FileUploader (it is still the same document)
    // e.g. bc.isNew() -> draft is saved -> same file uploader, so it's not rerendered
    //      bc.isNew() with saved draft -> row is changed to different draft -> key has to change, so fileUploader is remounted
    _lastBindingContext: BindingContext;
    _fileUploaderKey: string;
    get fileUploaderKey(): string {
        const { bindingContext } = this.props.storage.data;

        // new(new) -> draftId(new) -> differentDraftId(key)
        // draftId(key) -> differentDraftId(key)
        // Id(key) -> new(new) -> draftId(new)
        if (this._fileUploaderKey && ((this._lastBindingContext.getKey()?.toString().startsWith(NEW_ITEM_DETAIL) && bindingContext.isNew())
            || this._lastBindingContext.getKey() === bindingContext.getKey())) {
            // switching from new entity to new entity with possible draft key -> keep "new"
            // or when entity key is same
            // -> no change to fileUploaderKey
        } else {
            this._fileUploaderKey = bindingContext.getKey()?.toString();
        }
        this._lastBindingContext = bindingContext;

        return this._fileUploaderKey;
    }

    getCollectionBc = () => {
        return this.getCollectionBcMemoized();
    };

    getCollection = () => {
        return this.props.storage.getValue(this.getCollectionBc()) ?? [];
    };

    fileSortByDateCreated = (file1: IFile, file2: IFile) => {
        return dayjs(file2.metadata.DateCreated).diff(file1.metadata.DateCreated);
    };

    getFiles = (): IFile[] => {
        const items: IEntity[] = this.getCollection();

        return items
            .map(item => {
                const fileItem = this.props.fileProperty ? item[this.props.fileProperty] : item;

                return {
                    // combine id in case same file was added twice as two different attachments
                    id: this.props.fileProperty ? `${item.Id}-${fileItem.Id}` : fileItem.Id,
                    name: fileItem.Name,
                    metadata: fileItem
                };
            })
            .sort(this.fileSortByDateCreated);
    };

    getFileMetadata = (fileId: number): IFileMetadataEntity => {
        const item = this.getItemByFileId(fileId);
        let fileMetadata = item;

        if (this.props.fileProperty) {
            fileMetadata = item[this.props.fileProperty];
        }

        return fileMetadata;
    };

    getAttachmentId = (fileId: string) => {
        const split = fileId.split("-");
        return parseInt(split[0]);
    };

    getCleanFileId = (fileId: string) => {
        const split = fileId.split("-");

        return parseInt(split[1] ?? split[0]);
    };

    itemContainsFile = (fileId: number) => {
        return (item: IEntity) => this.props.fileProperty ? item[this.props.fileProperty].Id === fileId : item.Id === fileId;
    };

    getItemByFileId = (fileId: number) => {
        const items = [...this.getCollection()];
        return items.find(this.itemContainsFile(fileId));
    };

    getFileBindingContext = (fileId: number) => {
        const item = this.getItemByFileId(fileId);
        let bc = this.getCollectionBc().addKey(item.Id);

        if (this.props.fileProperty) {
            bc = bc.navigate(this.props.fileProperty);
        }

        return bc;
    };

    handleUploadProgress = (event: IFileProgress) => {
        // TODO how to handle file upload properly? loading indicator?
    };

    updateEntity = (newItems: IEntity[], isSaved = true) => {
        const { storage } = this.props;
        // store in the local entity as well, because that's where we show the files from
        const itemsBc = this.getCollectionBc();
        if (isSaved) {
            // origEntity needs to be updated as well, otherwise the data would be malformed on form save
            storage.setValueToEntityAndOrigEntity(itemsBc, newItems);
            // update also draft so the new files are not duplicated
            storage.getCustomData().draft = setBoundValue({
                bindingContext: itemsBc,
                dataBindingContext: storage.data.bindingContext,
                data: storage.getCustomData().draft,
                newValue: newItems
            });
        } else {
            storage.setValue(itemsBc, newItems);
        }
    };

    handleNewFiles = async (files: File[]) => {
        let resolveUploadInProgress: () => void;

        this.uploadInProgress.push(new Promise((resolve) => {
            resolveUploadInProgress = resolve;
        }));

        if (this.uploadInProgress.length > 1) {
            // wait for the previous uploads to finish
            await Promise.allSettled(this.uploadInProgress.slice(0, -1));
        }

        this.props.onNewFiles?.();
        this.setBusy(true);

        const draftId = this.props.storage.data.entity[this.props.storage.data.definition?.draftDef?.draftProperty]?.Id;
        const isDraftSaved = !!draftId;
        const isNew = this.isNew && !isDraftSaved;
        const newItems = [];
        const oldFileNames = this.getFiles().map(f => f.name);
        const newFileNames: string[] = [];
        const badFileNames: string[] = [];
        const promises = [];

        for (const file of files) {
            if (!isValidFileName(file.name)) {
                badFileNames.push(file.name);
                continue;
            }

            const fileName = getUniqueFileName(file.name, [...oldFileNames, ...newFileNames]);

            newFileNames.push(fileName);
            promises.push(FileStorage.upload({ file, name: fileName, progressCallback: this.handleUploadProgress }));
        }

        if (badFileNames.length > 0) {
            this.props.setAlert({
                title: this.props.t("Components:FileUploader.BadFileName", {
                    count: badFileNames.length,
                    files: badFileNames.join(", ")
                }),
                subTitle: this.props.t("Components:FileUploader.FileNameRules"),
                status: Status.Error
            });
        }

        if (promises.length > 0) {
            const responses = await Promise.allSettled(promises);
            const batch: BatchRequest = this.props.storage.oData.batch();
            batch.beginAtomicityGroup("group1");

            const queryableEntityPath = this.getQueryableEntityPath();
            const queryableEntity = queryableEntityPath ? batch.fromPath(queryableEntityPath) : null;

            for (const res of responses) {
                if (res.status === "fulfilled") {
                    let item: IEntity = res.value;

                    if (this.props.fileProperty) {
                        item = {
                            [this.props.fileProperty]: item
                        };
                        if (isNew) {
                            item.Id = item[this.props.fileProperty].Id;
                        }
                    }

                    if (isNew) {
                        newItems.push(item);
                    } else {
                        queryableEntity.create(item);
                    }
                } else {
                    this.showUploadErrorAlert(this.getOdataErrorMessage((res.reason ?? res) as unknown as ODataError));
                }
            }

            if (!isNew && !batch.isEmpty()) {
                const batchResponse = await batch.execute();
                let error = null;

                for (const res of batchResponse) {
                    if (isBatchResultOk(res)) {
                        newItems.push(res.body.value);
                    } else if (!error) {
                        error = res.body as ODataError;
                    }
                }

                if (error) {
                    this.showUploadErrorAlert(this.getOdataErrorMessage(error));
                }
            }

            this.updateEntity([
                ...this.getCollection(),
                ...newItems
            ], !!queryableEntity);
        }

        this.setBusy(false);

        // if all uploads failed don't fire onFilesUploaded
        if (newItems.length > 0) {
            this.props.onFilesUploaded?.(newItems.map(item => {
                const fileItem = this.props.fileProperty ? item[this.props.fileProperty] : item;

                return this.getFileMetadata(fileItem.Id);
            }));
        }

        resolveUploadInProgress?.();
        // remove promise of this upload
        this.uploadInProgress.shift();
    };

    loadAllAttachments = async (): Promise<IEntityAttachmentEntity[]> => {
        const { oData, data: { bindingContext, entity } } = this.props.storage;
        const isNew = bindingContext.isNew();

        const draftDef = this.props.storage.data.definition?.draftDef;
        const draftProp = draftDef?.draftProperty;
        const es = draftDef?.draftEntitySet;

        const entitySet = isNew ? es : bindingContext.getEntitySet().getName();

        const Id = isNew ? entity?.[draftProp]?.Id : bindingContext.getKey();
        if (Id) {
            const query = oData.getEntitySetWrapper(entitySet as EntitySetName).query(Id)
                .select("Id")
                .expand(this.props.collection, (subQuery) => {
                    if (this.props.fileProperty) {
                        subQuery.expand(this.props.fileProperty,
                            (q) => q.select(FileMetadataEntity.Id, FileMetadataEntity.IsIsdocReadable, FileMetadataEntity.IsRossumReadable, FileMetadataEntity.Name, FileMetadataEntity.Size));
                    }
                });
            const response = await query.fetchData<IEntity>();
            return response.value[this.props.collection] as IEntityAttachmentEntity[];
        }
        return [];
    };

    handleAttachInboxFiles = async (inboxFiles: TId[]): Promise<boolean> => {
        const { storage } = this.props;
        const { bindingContext, entity } = storage.data;
        // unselect possibly selected file, switch splitPageState, so fileView is visible
        this.props.onNewFiles?.();

        const _attachInboxFile = async (entityType: EntityTypeName, documentId: number) => {
            await attachInboxFile(inboxFiles, entityType, documentId as number);
            const items = await this.loadAllAttachments();
            this.updateEntity(items);

            if (items.length && this.props.fileProperty) {
                this.props.onFilesUploaded?.(items.map(item => item[this.props.fileProperty as keyof IEntityAttachmentEntity] as IFileMetadataEntity));
            }
            this.forceUpdate();
        };

        const draftProp = storage.data.definition?.draftDef?.draftProperty;
        const _getDraftId = (entity: IEntity): number => +entity?.[draftProp]?.Id;

        if (!this.isNew) {
            // document is saved, just attach new files
            const inboxEntityTypeCode = bindingContext.getEntityType().getName() as EntityTypeName;
            await _attachInboxFile(inboxEntityTypeCode, +bindingContext.getKey());
        } else if (draftProp) {
            const entityType = draftProp as EntityTypeName;
            const draftId = _getDraftId(entity);
            if (!draftId) {
                await (new Promise<void>((resolve, reject) => {
                    // for new documents, we need to save draft first and then attach file to the draft
                    storage.emitter.emit(ModelEvent.RequestCreateDraft, async () => {
                        // attachInboxFile after draft has been created
                        try {
                            await _attachInboxFile(entityType, _getDraftId(entity));
                            resolve();
                        } catch (e) {
                            reject(e);
                        }
                    });
                }));
            } else {
                await _attachInboxFile(entityType, draftId);
            }
        } else {
            // new entity which does not support draft
            const inboxEntities = (await loadInboxFileMetadata(storage.oData, inboxFiles));
            storage.setCustomData({
                inboxFilesToConvert: [
                    ...(storage.getCustomData().inboxFilesToConvert ?? []),
                    ...(inboxEntities ?? [])
                ]
            });
            const attachments = inboxEntities.map(inboxFile => {
                return this.props.fileProperty ? {
                    Id: inboxFile.FileMetadata?.Id,
                    [this.props.fileProperty]: inboxFile.FileMetadata
                } : inboxFile.FileMetadata;
            }) as IEntityAttachmentEntity[];
            this.updateEntity([
                ...this.getCollection(),
                ...attachments
            ], false);
            if (attachments.length && this.props.fileProperty) {
                this.props.onFilesUploaded?.(attachments.map(item => item[this.props.fileProperty as keyof IEntityAttachmentEntity] as IFileMetadataEntity));
            }
            this.forceUpdate();
        }
        return true;
    };

    getOdataErrorMessage = (error: ODataError | Error) => {
        return getFileStorageUploadErrorMessage(error);
    };

    showUploadErrorAlert = (error: string) => {
        this.showErrorAlert(this.props.storage.t(`Components:FileUploader.UploadError`), error);
    };

    showUpdateErrorAlert = (error: string) => {
        this.showErrorAlert(this.props.storage.t(`Components:FileUploader.UpdateError`), error);
    };

    showErrorAlert = (title: string, subTitle: string) => {
        this.props.setAlert({
            title,
            subTitle,
            status: Status.Error
        });
    };

    getQueryableEntityPath = () => {
        const isNew = this.isNew;
        const bindingContext = this.getCollectionBc();
        const draftId = this.props.storage.data.entity[this.props.storage.data.definition?.draftDef?.draftProperty]?.Id;
        const isDraftSaved = !!draftId;

        return !isNew ? bindingContext.toString()
            : (isDraftSaved ? `${this.props.storage.data.definition.draftDef.draftEntitySet}(${draftId})/${this.props.collection}` : null);
    };

    handleRemoveFiles = async (files: TId[]) => {
        return this.removeFiles(
            files.map(file => this.getCleanFileId(file.toString()))
        );
    };

    removeFiles = async (fileIds: number[]) => {
        if (!fileIds || fileIds.length === 0) {
            return;
        }

        this.setBusy(true);

        const items = [...this.getCollection()];

        const batch: BatchRequest = this.props.storage.oData.batch();
        batch.beginAtomicityGroup("group1");

        const queryableEntityPath = this.getQueryableEntityPath();
        const queryableEntity = queryableEntityPath ? batch.fromPath(this.getQueryableEntityPath()) : null;

        for (const fileId of fileIds) {
            const index = items.findIndex(this.itemContainsFile(fileId));
            const item: IEntity = items.splice(index, 1)[0];

            queryableEntity?.delete(item.Id);
        }

        if (queryableEntity) {
            await batch.execute();
        }

        const { storage } = this.props;
        const inboxFiles = storage.getCustomData().inboxFilesToConvert;
        if (inboxFiles?.length) {
            const newInboxFiles = inboxFiles.filter(item => !fileIds?.includes(item.FileMetadata?.Id));
            storage.setCustomData({ inboxFilesToConvert: newInboxFiles });
        }

        this.updateEntity(items);
        this.setBusy(false);
    };

    updateFile = async (fileId: number, values: IEntity) => {
        this.setBusy(true);

        const items = [...this.getCollection()];
        const itemIndex = items.findIndex(this.itemContainsFile(fileId));
        const fileEntitySet = this.getCollectionBc().navigate("File").getEntitySet();
        const queryable = this.props.storage.oData.fromPath(fileEntitySet.getName());

        try {
            await queryable.update(fileId, values);

            if (this.props.fileProperty) {
                items[itemIndex][this.props.fileProperty] = {
                    ...items[itemIndex][this.props.fileProperty],
                    ...values
                };
            } else {
                items[itemIndex] = {
                    ...items[itemIndex],
                    ...values
                };
            }

            this.updateEntity(items);
        } catch (error) {
            this.showUpdateErrorAlert(this.getOdataErrorMessage(error));
        }

        this.setBusy(false);
    };

    handleFileClick = (fileId: TId) => {
        const fileMetadata = this.getFileMetadata(this.getCleanFileId(fileId.toString()));
        this.props.onFileClick?.(fileMetadata);
    };

    handleFileRename = async (fileName: string) => {
        const fileId = this.state.showRenameFile;
        const cleanFileId = this.getCleanFileId(fileId.toString());
        const fileNames = this.getFiles().filter(f => f.id !== fileId).map(f => f.name);

        await this.updateFile(cleanFileId, { Name: getUniqueFileName(fileName, fileNames) });
        this.handleRenameDialogClose();
    };

    handleFileAction = async (args: IFileActionEvent) => {
        const fileId = this.getCleanFileId(args.id.toString());

        switch (args.action) {
            case FileAction.Download:
                FileStorage.download(fileId);
                break;
            case FileAction.Info:
                this.setInfoDialog(fileId);
                break;
            case FileAction.Rename:
                this.setState({ showRenameFile: args.id });
                break;
            case FileAction.Delete:
                const isConfirmed = await this.props.confirmationDialog.open({
                    content: this.props.t("Common:Confirmations.ConfirmDelete")
                });

                if (isConfirmed) {
                    this.removeFiles([fileId]);
                }

                break;
            default:
                if (this.props.onCustomFileAction) {
                    const fileMetadata = this.getFileMetadata(fileId);
                    const attachId = this.getAttachmentId(args.id.toString());
                    this.props.onCustomFileAction(fileMetadata, args.action, attachId);
                }

                break;
        }
    };

    setBusy = (busy: boolean) => {
        this.setState({
            busy
        });
    };

    setInfoDialog = (file: number) => {
        this.setState({
            showInfoForFile: file
        });
    };

    handleCloseInfoDialog = () => {
        this.setInfoDialog(null);
    };

    renderInfoDialog = () => {
        const fileBindingContext = this.getFileBindingContext(this.state.showInfoForFile);
        const fileMetadata = this.props.storage.getValue(fileBindingContext);

        return (
            <FileInfoDialog file={fileMetadata}
                            onClose={this.handleCloseInfoDialog}/>
        );
    };

    handleRenameDialogClose = () => {
        this.setState({ showRenameFile: null });
    };

    renderRenameDialog = () => {
        const fileId = this.getCleanFileId(this.state.showRenameFile.toString());
        const fileBindingContext = this.getFileBindingContext(fileId);
        const fileMetadata = this.props.storage.getValue(fileBindingContext);

        return (
            <FileRenameDialog currentName={fileMetadata.Name}
                              onConfirm={this.handleFileRename}
                              onClose={this.handleRenameDialogClose}/>
        );
    };

    getCustomFileActions = (fileId: TId): ISelectItem[] => {
        if (!this.props.customFileActions) {
            return null;
        }

        const fileMetadata = this.getFileMetadata(this.getCleanFileId(fileId.toString()));

        return this.props.customFileActions(fileMetadata, this.props.storage);
    };

    hideInboxFileIds = memoizeOne(() => {
        return this.props.storage.getCustomData().inboxFilesToConvert?.map(item => item.Id);
    }, () => [this.props.storage.getCustomData().inboxFilesToConvert]);

    render() {
        const { bindingContext } = this.props.storage?.data ?? {};
        if (!bindingContext) {
            return null;
        }

        return (
            <>
                {this.props.alert}
                {!!this.state.showInfoForFile && this.renderInfoDialog()}
                {!!this.state.showRenameFile && this.renderRenameDialog()}
                <FileUploader files={this.getFiles()}
                              isReadOnly={this.props.isReadOnly}
                              isLocalDropArea={this.props.isLocalDropArea}
                              isDeleteDisabled={!!this.props.storage.getBackendDisabledFieldMetadata(this.getCollectionBcMemoized())?.cannotDelete}
                              isHidden={this.props.isHidden}
                              customFileActions={this.getCustomFileActions}
                              onNewFiles={this.handleNewFiles}
                              onAttachInboxFiles={this.handleAttachInboxFiles}
                              onRemoveFiles={this.handleRemoveFiles}
                              onFileAction={this.handleFileAction}
                              onFileClick={this.handleFileClick}
                              onDragEnter={this.props.onDragEnter}
                              onDragLeave={this.props.onDragLeave}
                              otherFileDropAreas={this.props.otherFileDropAreas}
                              busy={this.state.busy}
                              className={this.props.className}
                              style={this.props.style}
                              key={this.fileUploaderKey}
                              hideInboxFileIds={this.hideInboxFileIds()}
                />
            </>
        );
    }
}

export default withTranslation(["Common", "Components"], { withRef: true })(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(withConfirmationDialog(SmartFileUploader)));