import { IEntity } from "@odata/BindingContext";
import { EntityTypeName, IDefaultVariantEntity } from "@odata/GeneratedEntityTypes";
import { AccountingCode, VariantAccessTypeCode, VatStatusCode } from "@odata/GeneratedEnums";
import { ODataError } from "@odata/ODataParser";
import { isOnOrganizationLevel } from "@utils/CompanyUtils";
import { isDefined } from "@utils/general";
import memoizeOne from "@utils/memoizeOne";
import i18next, { TFunction } from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BasicInputSizes, IconSize, Status } from "../../enums";
import { TRecordString, TRecordType } from "../../global.types";
import { KeyName } from "../../keyName";
import { ModelType } from "../../model/Model";
import { StorageModel } from "../../model/StorageModel";
import { IValidationError } from "../../model/Validator.types";
import TestIds from "../../testIds";
import Alert from "../alert";
import { Button, IconButton } from "../button";
import Dialog from "../dialog/Dialog";
import { CheckboxGroup } from "../inputs/checkboxGroup";
import { ICheckboxGroupChange } from "../inputs/checkboxGroup/CheckboxGroup";
import Field from "../inputs/field/Field";
import FieldsWrapper from "../inputs/field/FieldsWrapper";
import Input, { IInputOnChangeEvent } from "../inputs/input/Input";
import Tabs, { ITabData } from "../tabs";
import {
    getCompanyVariantContext,
    getDefaultSystemVariant,
    ICompanyVariantContext,
    updateNames,
    Variant,
    VariantType
} from "./VariantOdata";
import { VariantDialogState } from "./VariantSelector";
import {
    ActiveText,
    EditVariantContent,
    Grid,
    HeaderTitle,
    Icons,
    MiddleColumn,
    StyledBinIcon,
    StyledEditIcon,
    StyledRefreshIcon,
    TableRadioButton,
    TextParent,
    VariantInput
} from "./VariantSelector.styles";


export type VariantDialogType = VariantDialogState.Edit | VariantDialogState.Clone;

interface IVariantItem {
    id: string;
    title: string;
    isSystem?: boolean;
    isEditing?: boolean;
    isDeleting?: boolean;
    accounting?: AccountingCode;
    vatStatus?: VatStatusCode;
}

interface IVariantRowProps {
    item: IVariantItem;
    t: TFunction;
    activeVariantId: string;
    defaultVariantId: string;

    onCheck?: (id: string) => void;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    onRefreshClick: (id: string) => void;
    onInputChange?: (id: string, val: string) => void;
    onConfirm?: (id: string) => void;
}

class EditInput extends React.PureComponent<IVariantRowProps> {
    private _refInput = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        this._refInput.current.select();
    }

    handleInputChange = (e: IInputOnChangeEvent) => {
        this.props.onInputChange(this.props.item.id, e.value as string);
    };

    handleConfirm = () => {
        this.props.onConfirm(this.props.item.id);
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === KeyName.Enter) {
            this.props.onConfirm(this.props.item.id);
        }
    };

    render() {
        return (
                <VariantInput
                        isDisabled={this.props.item.isDeleting}
                        passRef={this._refInput}
                        onKeyDown={this.handleKeyDown}
                        onBlur={this.handleConfirm}
                        onChange={this.handleInputChange}
                        value={this.props.item.title}
                        isSharpLeft
                        isSharpRight/>
        );
    }
}

const VariantRow = (props: IVariantRowProps) => {
    const handleEditClick = () => {
        props.onEditClick(props.item.id);
    };

    const handleDeleteClick = () => {
        props.onDeleteClick(props.item.id);
    };

    const handleRefreshClick = () => {
        props.onRefreshClick(props.item.id);
    };

    const getItemTitle = (isActive: boolean) => {
        return (
                <TextParent isDisabled={props.item.isDeleting}>
                    <span>{props.item.title}</span>
                    {isActive &&
                            <ActiveText>&nbsp;({props.t("Components:VariantSelector.Active")})</ActiveText>
                    }
                </TextParent>
        );
    };

    const isActive = props.item.id === props.activeVariantId;
    return (
            <>
                <TableRadioButton
                        isDisabled={props.item.isDeleting}
                        checked={props.item.id === props.defaultVariantId}
                        id={props.item.id}
                        onChecked={props.onCheck}/>
                <MiddleColumn>
                    {props.item.isEditing &&
                            <EditInput
                                    {...props} />
                    }
                    {!props.item.isEditing && getItemTitle(isActive)}
                </MiddleColumn>
                {!props.item.isSystem &&
                        <Icons>
                            <IconButton
                                    isDisabled={props.item.isDeleting}
                                    onClick={handleEditClick} isDecorative title={props.t("Common:General.Edit")}>
                                <StyledEditIcon width={IconSize.S} height={IconSize.S} isLightHover/>
                            </IconButton>
                            {!props.item.isDeleting &&
                                    <IconButton
                                            isDisabled={props.item.isDeleting}
                                            hoverAlert={isActive ? {
                                                isSmall: true,
                                                status: Status.Warning,
                                                title: props.t("Components:VariantSelector.ActiveWarning")
                                            } : null}
                                            onClick={handleDeleteClick}
                                            title={isActive ? null : props.t("Common:General.Remove")}
                                            isDecorative>
                                        <StyledBinIcon width={IconSize.S} height={IconSize.S} isLightHover/>
                                    </IconButton>
                            }
                            {props.item.isDeleting &&
                                    <IconButton
                                            onClick={handleRefreshClick} title={props.t("Common:General.Refresh")}
                                            isDecorative>
                                        <StyledRefreshIcon width={IconSize.S} height={IconSize.S} isLightHover/>
                                    </IconButton>
                            }
                        </Icons>
                }
                {props.item.isSystem && <div/>}
            </>
    );
};

export interface IVariantSaveArgs {
    items?: IVariantItem[];
    variantName?: string;
    isDefault?: boolean;
    overrideExisting?: boolean;
}

interface IProps extends WithTranslation {
    type: VariantDialogType;
    onClose: () => void;
    storage: StorageModel;
    defaultVariants: IDefaultVariantEntity[];
    activeVariantId: string;
    variants: Record<string, Variant>;
}

interface IState {
    variantName?: string;
    newVariantSetAsDefault?: boolean;
    overrideExisting?: boolean;

    items?: IVariantItem[];
    error?: IValidationError;

    // variant tabs
    accounting: AccountingCode;
    vatStatus: VatStatusCode;
    // defaults per tab
    defaultVariants: TRecordString;
}

function isVariantEditable(variant: IEntity) {
    return variant && variant?.accessType !== VariantAccessTypeCode.SystemVariant;
}

const CONTEXT_SEPARATOR = "_";

function getTabId(accounting: AccountingCode, vatStatus: VatStatusCode) {
    if (!isDefined(accounting) && !isDefined(vatStatus)) {
        return CompanyLevelFakeTabId;
    }
    return `${accounting}${CONTEXT_SEPARATOR}${vatStatus}`;
}

function getVariantContextFromTabId(tabId: string): { accounting: AccountingCode, vatStatus: VatStatusCode } {
    const [accounting, vatStatus] = tabId.split(CONTEXT_SEPARATOR);
    return { accounting: accounting as AccountingCode, vatStatus: vatStatus as VatStatusCode };
}

function getCombinedContexts(): TRecordType<ICompanyVariantContext> {
    const accountingCodes = [AccountingCode.AccountingForBusiness, AccountingCode.CashBasisAccounting];
    const vatStatusCodes = [VatStatusCode.VATRegistered, VatStatusCode.NotVATRegistered];
    const ids: TRecordType<ICompanyVariantContext> = {};
    accountingCodes.forEach(accounting => {
        vatStatusCodes.forEach(vatStatus => {
            const id = getTabId(accounting, vatStatus);
            ids[id] = { accounting, vatStatus };
        });
    });
    return ids;
}

const CompanyLevelFakeTabId = "";

class VariantDialog extends React.Component<IProps, IState> {
    _defaultName: string;

    _origDefaultVariants: TRecordString;

    constructor(props: IProps) {
        super(props);

        const currentVariant = props.variants[props.activeVariantId];
        const isEditable = isVariantEditable(currentVariant);

        const { accounting, vatStatus } = getCompanyVariantContext(props.storage.context);

        const allVariants = Object.values(props.variants);
        const items = allVariants.map(item => ({
            id: item.id,
            title: item.name,
            isSystem: item.accessType === VariantAccessTypeCode.SystemVariant,
            accounting: item.accountingCode,
            vatStatus: item.vatStatusCode
        }));

        const defaultVariants: TRecordString = {};
        if (!this.isNewVariantDialog) {
            if (accounting && vatStatus) {
                const combinations = getCombinedContexts();
                Object.keys(combinations).forEach(id => {
                    defaultVariants[id] = props.defaultVariants.find(variant => {
                        return variant.AccountingCode === combinations[id].accounting && variant.VatStatusCode === combinations[id].vatStatus;
                    })?.Variant?.Id?.toString();
                    if (!defaultVariants[id]) {
                        defaultVariants[id] = getDefaultSystemVariant(allVariants)?.id;
                    }
                });
            } else {
                // company level, no tabs
                defaultVariants[CompanyLevelFakeTabId] = props.defaultVariants[0]?.Variant?.Id?.toString();
                if (!defaultVariants[CompanyLevelFakeTabId]) {
                    defaultVariants[CompanyLevelFakeTabId] = getDefaultSystemVariant(allVariants)?.id;
                }
            }

            this._origDefaultVariants = { ...defaultVariants };
        }

        this.state = {
            variantName: isEditable ? currentVariant?.name : "",
            overrideExisting: isEditable,
            newVariantSetAsDefault: false,
            items,
            accounting, vatStatus,
            defaultVariants
        };
    }

    get variantContext(): ICompanyVariantContext {
        const { context } = this.props.storage;
        return getCompanyVariantContext(context);
    }

    get isNewVariantDialog(): boolean {
        return this.props.type === VariantDialogState.Clone;
    }

    getTabs = memoizeOne<[], ITabData[]>(() => {
        if (isOnOrganizationLevel({ context: this.props.storage.context })) {
            return [];
        }
        const combinations = getCombinedContexts();
        return Object.keys(combinations).map(id => ({
            id,
            title: `${this.props.t(`Components:VariantSelector.${combinations[id].accounting}`)} ${this.props.t(`Components:VariantSelector.${combinations[id].vatStatus}`)}`
        }));
    }, () => [this.props.tReady]);

    handleVariantNameChange = (e: IInputOnChangeEvent) => {
        this.setState({
            variantName: e.value as string,
            error: null
        });
    };

    handleOverrideExisting = (checked: boolean) => {
        const variant = this.props.variants[this.props.activeVariantId];
        if (checked) {
            this._defaultName = this.state.variantName;
        }

        this.setState({
            variantName: checked ? variant?.name : this._defaultName,
            overrideExisting: checked
        });
    };

    handleSetAsDefaultChange = (checked: boolean) => {
        this.setState({
            newVariantSetAsDefault: checked
        });
    };

    handleDefaultCheck = (id: string) => {
        const { accounting, vatStatus } = this.state;
        const selectedTabId = getTabId(accounting, vatStatus);
        this.setState({
            defaultVariants: {
                ...this.state.defaultVariants,
                [selectedTabId]: id
            }
        });
    };

    handleCheckboxGroupChange = (args: ICheckboxGroupChange) => {
        if (args.id === "overrideExisting") {
            this.handleOverrideExisting(args.isChecked);
        } else if (args.id === "newVariantSetAsDefault") {
            this.handleSetAsDefaultChange(args.isChecked);
        }
    };

    handleDialogCloneSave = async (args: IVariantSaveArgs): Promise<void> => {
        let variant: Variant;
        const { storage } = this.props;
        const { additionalFieldData, bindingContext } = this.props.storage.data;
        const context = this.variantContext;

        if (args.overrideExisting) {
            variant = this.props.storage.data.variants.currentVariant;
        } else {
            variant = new Variant({
                viewId: storage.id,
                accessType: VariantAccessTypeCode.UserVariant,
                accountingCode: context.accounting,
                vatStatusCode: context.vatStatus,
                name: args.variantName,
                variantType: storage.type === ModelType.Form ? VariantType.Form : VariantType.Table,
                // entity type doesn't exist for local binding context (reports)
                entityType: bindingContext.getRootParent().getEntityType()?.getName() as EntityTypeName
            });
        }

        variant.setVariant(storage.getVariant());
        args.isDefault ? await variant.saveAsDefault(this.props.storage.oData, this.variantContext) : await variant.save(this.props.storage.oData);

        this.props.storage.clearLocalStorageVariant();
        this.props.storage.store({
            variants: {
                allVariants: {
                    ...this.props.storage.data.variants.allVariants,
                    [variant.id]: variant
                },
                defaultVariant: args.isDefault ? variant : this.props.storage.data.variants.defaultVariant,
                currentVariant: variant
            }
        });

        this.forceUpdate();
    };

    getSystemVariant = () => {
        return Object.values(this.props.storage.data.variants.allVariants).find(variant => variant.accessType === VariantAccessTypeCode.SystemVariant);
    };

    handleDialogEditSave = async (args: IVariantSaveArgs) => {
        const { storage } = this.props;
        const variantsToUpdate: Variant[] = [];
        const defaultVariants = { ...this.state.defaultVariants };

        for (const changedVar of args.items) {
            const variant = this.props.variants[changedVar.id];
            const changedVarTabId = getTabId(changedVar.accounting, changedVar.vatStatus);

            if (variant) {
                const isChangedDefault = defaultVariants[changedVarTabId] === changedVar.id;
                if (changedVar.isDeleting) {
                    // if we are deleting defaultVariant we need to set default variant any system variant
                    const systemVariant = this.getSystemVariant();

                    if (isChangedDefault) {
                        defaultVariants[changedVarTabId] = systemVariant.id;
                    }

                    if (changedVar.id === this.props.activeVariantId) {
                        await storage.changeVariant(systemVariant);
                    }

                    await variant.delete(storage.oData);
                    delete storage.data.variants.allVariants[changedVar.id];
                    continue;
                }

                if (variant.name !== changedVar.title) {
                    variant.name = changedVar.title;
                    variantsToUpdate.push(variant);
                }
            }
        }
        // default variant of current context to be pushed to storage
        let defaultVariant: Variant;
        const companyContext = this.variantContext;
        const currentTabId = getTabId(companyContext.accounting, companyContext.vatStatus);

        // process default variants per tab (system variant may be set as default for more tabs, so we need to go per tab)
        const promises = Object.keys(defaultVariants).map(tabId => {
            if (this._origDefaultVariants[tabId] !== defaultVariants[tabId]) {
                const variant = this.props.variants[defaultVariants[tabId]];
                const context = getVariantContextFromTabId(tabId);
                if (currentTabId === tabId) {
                    defaultVariant = variant;
                }
                return variant.saveAsDefault(storage.oData, context);
            }
            return null;
        });
        await Promise.all(promises);

        // deleted default variant
        storage.store({
            variants: {
                ...storage.data.variants,
                defaultVariant: defaultVariant ?? storage.data.variants.defaultVariant
            }
        });

        if (variantsToUpdate.length > 0) {
            await updateNames(storage.oData, variantsToUpdate);

            // update also current model data
            variantsToUpdate.forEach(changedVar => {
                // variant could be from different context, so it's not present in allVariants
                const variant = storage.data.variants.allVariants[changedVar.id];
                if (variant) {
                    variant.name = changedVar.name;
                }
            });
        }
    };

    createNewVariantContent = () => {
        const variant = this.props.variants[this.props.activeVariantId];
        const checkboxGroupValues: string[] = [];

        if (this.state.overrideExisting) {
            checkboxGroupValues.push("overrideExisting");
        }

        if (this.state.newVariantSetAsDefault) {
            checkboxGroupValues.push("newVariantSetAsDefault");
        }

        return (
                <FieldsWrapper isColumn>
                    <FieldsWrapper>
                        <Field label={this.props.t("Components:VariantSelector.VariantName")}
                               isRequired={true}>
                            <Input width={BasicInputSizes.L}
                                   value={this.state.variantName}
                                   isDisabled={this.state.overrideExisting}
                                   name="variant-name"
                                   error={this.state.error}
                                   onChange={this.handleVariantNameChange}/>
                        </Field>
                    </FieldsWrapper>
                    <FieldsWrapper style={{ paddingLeft: "12px" }}>
                        <CheckboxGroup
                                values={checkboxGroupValues}
                                items={[
                                    {
                                        id: "overrideExisting",
                                        label: this.props.t("Components:VariantSelector.SaveAsCurrent"),
                                        isDisabled: !isVariantEditable(variant)
                                    },
                                    {
                                        id: "newVariantSetAsDefault",
                                        label: this.props.t("Components:VariantSelector.SetAsDefault")
                                    }
                                ]}
                                onChange={this.handleCheckboxGroupChange}
                        />
                    </FieldsWrapper>
                </FieldsWrapper>
        );
    };

    handleDialogSave = async () => {
        try {
            const args: IVariantSaveArgs = {
                variantName: this.state.variantName,
                items: this.state.items,
                isDefault: this.state.newVariantSetAsDefault,
                overrideExisting: this.state.overrideExisting
            };

            if (this.props.type === VariantDialogState.Clone) {
                await this.handleDialogCloneSave(args);
            }

            if (this.props.type === VariantDialogState.Edit) {
                await this.handleDialogEditSave(args);
            }
            this.props.onClose();
        } catch (e) {
            const error = e as ODataError;
            const translKey = `Error:${error?._validationMessages?.[0]?.code}`;
            const message = (error._validationMessages?.[0]?.code && i18next.exists(translKey) ? this.props.storage.t(translKey) : e._message) ?? e.message;

            this.setState({ error: { message: message } });
        }
    };

    setProperty = (id: string, propName: keyof IVariantItem, shouldClear?: boolean, preserve?: boolean) => {
        this.setState(state => ({
            items: state.items.map(item => ({
                ...item,
                [propName]: !shouldClear && item.id === id ? true : preserve ? item[propName] : false
            }))
        }));
    };

    handleEditConfirm = (id: string) => {
        this.setProperty(id, "isEditing", true);
    };

    handleEditClick = (id: string) => {
        this.setProperty(id, "isEditing", false);
    };

    handleDeleteClick = (id: string) => {
        this.setProperty(id, "isDeleting", false, true);
    };

    handleRefreshClick = (id: string) => {
        this.setState(state => ({
            items: state.items.map(item => ({
                ...item,
                isDeleting: item.id === id ? false : item.isDeleting
            }))
        }));
    };

    handleInputChange = (id: string, val: string) => {
        this.setState(state => ({
            items: state.items.map(item => ({
                ...item,
                title: item.id === id ? val : item.title
            }))
        }));
    };

    handleTabChange = (tabId: string) => {
        const { accounting, vatStatus } = getVariantContextFromTabId(tabId);
        this.setState({ accounting, vatStatus });
    };

    createEditVariantContent = () => {
        const { accounting, vatStatus } = this.state;
        const isCompanyContext = isOnOrganizationLevel({ context: this.props.storage.context });
        const selectedTabId = getTabId(accounting, vatStatus);
        const companyContext = this.variantContext;
        const currentCompanyTabId = getTabId(companyContext.accounting, companyContext.vatStatus);
        const isActiveTabOpened = selectedTabId === currentCompanyTabId;
        let renderedItems = this.state.items;
        if (!isCompanyContext) {
            renderedItems = renderedItems
                    .filter(item => (item.accounting === accounting && item.vatStatus === vatStatus) || item.isSystem);
        }
        renderedItems = renderedItems
                .sort((a, b) => (a.isSystem ? 0 : 1) - (b.isSystem ? 0 : 1));
        return (
                <EditVariantContent>
                    {this.state.error && <Alert status={Status.Error}
                                                title={this.props.t("Common:Errors.ErrorHappened")}
                                                subTitle={this.state.error.message}
                                                isOneLiner
                                                isFullWidth
                                                shouldAddBottomMargin
                    />}
                    {!isCompanyContext && (
                            <Tabs data={this.getTabs()} isDraggable={false}
                                  selectedTabId={selectedTabId}
                                  onChange={this.handleTabChange}/>
                    )}
                    <Grid data-testid={TestIds.EditVariantsGrid}>
                        <HeaderTitle>{this.props.t("Components:VariantSelector.Default")}</HeaderTitle>
                        <HeaderTitle>{this.props.t("Components:VariantSelector.Name")}</HeaderTitle>
                        <div/>
                        {renderedItems.map(item => {
                            return <VariantRow
                                    t={this.props.t}
                                    key={item.id}
                                    item={item}
                                    activeVariantId={isActiveTabOpened ? this.props.activeVariantId : null}
                                    defaultVariantId={this.state.defaultVariants[selectedTabId]}
                                    onConfirm={this.handleEditConfirm}
                                    onInputChange={this.handleInputChange}
                                    onEditClick={this.handleEditClick}
                                    onRefreshClick={this.handleRefreshClick}
                                    onDeleteClick={this.handleDeleteClick}
                                    onCheck={this.handleDefaultCheck}/>;
                        })}
                    </Grid>
                </EditVariantContent>
        );
    };

    render() {
        return (
                <Dialog aretateWidth
                        title={this.props.type === VariantDialogState.Edit ? this.props.t("Components:VariantSelector.Edit") : this.props.t("Components:VariantSelector.Clone")}
                        onConfirm={this.handleDialogSave}
                        onClose={this.props.onClose}
                        removePadding={!this.isNewVariantDialog}
                        footer={(
                                <>
                                    <Button isTransparent
                                            onClick={this.props.onClose}>
                                        {this.props.t("Common:General.Cancel")}
                                    </Button>
                                    <Button onClick={this.handleDialogSave}>
                                        {this.props.t("Common:General.Confirm")}
                                    </Button>
                                </>
                        )}>

                    {this.isNewVariantDialog ? this.createNewVariantContent() : this.createEditVariantContent()}
                </Dialog>
        );
    }
}

export default withTranslation(["Components", "Common"])(VariantDialog);