import { getGroupingProperties, groupAndOptimizeRanges, TemporalEntityProp, TTemporal } from "@odata/TemporalUtils";
import React, { useCallback } from "react";

import BindingContext from "../../../odata/BindingContext";
import { getUtcDayjs } from "../../../types/Date";
import { FormStorage } from "../../../views/formView/FormStorage";
import { TimeDependentFilledIcon, TimeDependentIcon } from "../../icon";
import { IconButtonStyled } from "./SmartTemporalPropertyButton.styles";

interface IProps {
    storage: FormStorage;
    bindingContext: BindingContext;
    customTemporalNavigationPath?: string;
    customTemporalPropertyPath?: string;
}

const SmartTemporalPropertyButton: React.FC<IProps> = ({
                                                           storage,
                                                           bindingContext,
                                                           customTemporalNavigationPath,
                                                           customTemporalPropertyPath
                                                       }) => {
    let baseBindingContext = storage.data.bindingContext;

    if (bindingContext.isAnyPartCollection()) {
        const collectionBc = bindingContext.getParentCollection(storage.data.bindingContext);
        baseBindingContext = baseBindingContext.navigate(collectionBc.getPath());
    }

    const temporalPropertyBagBc = baseBindingContext
        .navigate(customTemporalNavigationPath ?? TemporalEntityProp.TemporalPropertyBag);
    bindingContext = temporalPropertyBagBc
            .navigate(customTemporalPropertyPath ?? bindingContext.getPath());

    const temporalBag = (storage.getValue(temporalPropertyBagBc) as TTemporal[])?.filter(propertyBag => propertyBag.DateValidFrom && propertyBag.DateValidTo) ?? [];
    const properties = getGroupingProperties(bindingContext, storage);
    const groupedRanges = groupAndOptimizeRanges(temporalBag, properties, true);
    const today = getUtcDayjs();
    const hasFuturePlannedValue = groupedRanges.find(range => today.isBefore(range.DateValidFrom));
    const Icon = !hasFuturePlannedValue ? TimeDependentIcon : TimeDependentFilledIcon;

    const handleClick = useCallback(() => {
        storage.setCustomData({
            temporalPropertyDialogBc: bindingContext
        });
        storage.refresh();
    }, [storage, bindingContext]);

    return (
        <IconButtonStyled title={storage.t("Components:TemporalProperty.OpenDialog")}
                          hotspotId={"temporalPropertyOpenButton"}
                          isTransparent
                          onClick={handleClick}>
            <Icon/>
        </IconButtonStyled>
    );
};

export default SmartTemporalPropertyButton;