import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import SmartFileInput from "@components/smart/smartFileInput/SmartFileInput";
import { isDateTimeType, isDateType } from "@evala/odata-metadata/src";
import { IStorageSmartFieldValues } from "@odata/Data.utils";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, IconSize, Status } from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext from "../../../odata/BindingContext";
import { getUtcDayjs } from "../../../types/Date";
import NumberType, { getScale, IParserArgs } from "../../../types/Number";
import { FormStorage } from "../../../views/formView/FormStorage";
import { SegmentedButton } from "../../button/SegmentedButton";
import { FieldValidationNegativeIcon, FieldValidationPositiveIcon, FieldValidationWarningIcon } from "../../icon";
import SingleBusinessPartnerSelect from "../../inputs/businessPartnerSelect/SingleBusinessPartnerSelect";
import Checkbox, { ICheckboxChange } from "../../inputs/checkbox/Checkbox";
import { AutoSizedCheckboxGroup } from "../../inputs/checkboxGroup/AutoSizedCheckboxGroup";
import { ICheckboxGroupChange } from "../../inputs/checkboxGroup/CheckboxGroup";
import { ISmartCheckboxGroupChangeEvent, SmartCheckboxGroup } from "../../inputs/checkboxGroup/SmartCheckboxGroup";
import ColorPicker from "../../inputs/colorPicker/ColorPicker";
import { DatePicker, MonthYearPicker, TimePicker } from "../../inputs/date";
import EditableText from "../../inputs/editableText/EditableText";
import { FieldsWithOwnLabel } from "../../inputs/field/Field";
import Input, { IInputOnBlurEvent, IInputOnChangeEvent, IInputRowsChangeEvent } from "../../inputs/input";
import PasswordInput from "../../inputs/input/PasswordInput";
import { IFormatterFns } from "../../inputs/input/WithFormatter";
import NumericInput from "../../inputs/numericInput/NumericInput";
import ResponsiveInput from "../../inputs/responsiveInput/ResponsiveInput";
import { IMultiSelectionChangeArgs } from "../../inputs/select/MultiSelect";
import { isSelectBasedComponent } from "../../inputs/select/SelectAPI";
import Switch from "../../inputs/switch/Switch";
import TextArea from "../../inputs/textArea/TextArea";
import TokenInput from "../../inputs/tokenInput/TokenInput";
import WriteLine from "../../inputs/writeLine/WriteLine";
import Text from "../../text";
import Tooltip from "../../tooltip";
import { getInfoValue, IFieldDef, IFieldInfoProperties, isFieldDisabled, isFieldReadOnly } from "../FieldInfo";
import { ISmartRadioGroupChange, SmartRadioButtonGroup } from "../smartRadioButtonGroup/SmartRadioButtonGroup";
import { ISmartSelectProps, SmartSelect } from "../smartSelect";
import SmartAutocomplete from "../smartSelect/SmartAutocomplete";
import SmartLabelSelect from "../smartSelect/SmartLabelSelect";
import { ISmartMultiSelectProps, SmartMultiSelect } from "../smartSelect/SmartMultiSelect";
import { isComplexFilterArr, isValueHelperField } from "../smartValueHelper";
import SmartValueHelper, { IValueHelperChangeArgs } from "../smartValueHelper/SmartValueHelper";
import { ISmartFieldChange } from "./SmartField";

export interface ISmartFieldEvents {
    onChange?: (args: ISmartFieldChange) => void;
    onBlur: (args: IInputOnBlurEvent) => void;
    onFocus: () => void;
    onDateChange: (e: IInputOnChangeEvent) => void;
    onSegmentedButtonChange: (key: string) => void;
    onWidthChange: (width: string) => void;
    onFileChange: (files: File[]) => void;
    onInputChange: (data: IInputOnChangeEvent) => void;
    onCancel: () => void;
    onCheckboxChange: (args: ICheckboxChange) => void;
    onColorChange: (value: string) => void;
    onSwitchChange: (checked: boolean) => void;
    onCheckboxGroupChange: (args: ICheckboxGroupChange) => void;
    onSmartCheckboxGroupChange: (args: ISmartCheckboxGroupChangeEvent) => void;
    onSmartRadioButtonChange: (args: ISmartRadioGroupChange) => void;
    onRowsChange: ({ numRows, newHeight }: IInputRowsChangeEvent) => void;
    onSelectChange: (args: ISelectionChangeArgs | IMultiSelectionChangeArgs) => void;
    onValueHelperChange: (args: IValueHelperChangeArgs) => void;
    onConfirm: () => void;
}

export interface IGetControlData {
    bc: BindingContext;
    storage: Model;
    type: FieldType;
    context: IAppContext;
    events: ISmartFieldEvents;
    /** Use to override field definition from storage */
    fieldDef?: IFieldInfoProperties;
    fastEntryListId?: string;
}

const getWidth = (fieldDef: IFieldDef, type: FieldType, isValueHelp: boolean, bc: BindingContext, storage: Model) => {
    let width = getInfoValue(fieldDef, "width", {
        bindingContext: bc,
        storage,
        data: storage?.data.entity
    });


    if (!width && (!FieldsWithOwnLabel[type] || isValueHelp) && !fieldDef.labelStatus && ![FieldType.Switch, FieldType.SegmentedButton].includes(type)) {
        width = BasicInputSizes.M;
    }

    return width;
};

const enhanceWithValidationResult = (props: TRecordAny, storageData: IStorageSmartFieldValues, componentProp: "icon" | "content") => {
    const validationResult = storageData.additionalFieldData?.validationResult;

    if (!!validationResult) {
        const Icon = validationResult.icon ?? (validationResult.status === Status.Success ? FieldValidationPositiveIcon : validationResult.status === Status.Warning ? FieldValidationWarningIcon : FieldValidationNegativeIcon);
        const style: React.CSSProperties = {};

        if (componentProp === "content") {
            style.position = "absolute";
            style.top = "0";
            style.right = "36px";
        }

        props[componentProp] = <Tooltip content={validationResult.message}>
            {(ref) =>
                <Icon passRef={ref} width={IconSize.M} style={style}/>
            }
        </Tooltip>;
    } else {
        props[componentProp] = null;
    }
};

export interface IGetControlDataRetValue {
    storageData: IStorageSmartFieldValues;
    Control: React.ReactNode;
    isValueHelp: boolean;
    props: TRecordAny;
}

export const isFieldValueSame = (storage: FormStorage, bc: BindingContext): boolean => {
    const fieldInfo = storage.getInfo(bc);
    const storageData = storage.getFieldValues(bc, fieldInfo);
    const val = storageData.value;
    const origVal = storageData.origValue;

    if (fieldInfo?.comparisonFunction) {
        return fieldInfo.comparisonFunction(storage.data.origEntity, storage.data.entity, bc);
    } else if (bc.isLocal()) {
        // iF we want to show changes on local context field, we have to specify comparisonFunction in fieldInfo
        return true;
    } else if (bc.isCollection()) {
        const ids = val as number[];
        const origIds = origVal as number[];
        return ids?.length === origIds?.length && origIds?.every(origId => {
            return ids.includes(origId);
        });
    }
    if (isDateType(bc.getProperty())) {
        const unit = isDateTimeType(bc.getProperty()) ? "second" : "day";
        return (isNotDefined(origVal) && isNotDefined(val)) || getUtcDayjs(val as Date).isSame(origVal as Date, unit);
    }
    if (isNotDefined(origVal)) {
        return !val; // if origVal was null, "" should also return true
    }
    return val === origVal || storage.getValue(bc) === origVal;
};

export const getControlData = (args: IGetControlData): IGetControlDataRetValue => {
    const bc = args.bc;
    const type = args.type;

    let fieldInfo = args.storage.getInfo(bc);

    if (args.fieldDef) {
        fieldInfo = { ...fieldInfo, ...args.fieldDef };
    }

    const storageData = args.storage.getFieldValues(bc, fieldInfo);
    const value = storageData.value;

    const isSameValue = isFieldValueSame(args.storage as FormStorage, bc);

    const showChange = !(args.bc.getParentCollection()?.isNew()) &&
        !args.storage.loading &&
        (args.storage as FormStorage).data.definition?.showChanges &&
        !args.storage.data.bindingContext.isNew() && !isSameValue;

    const items = fieldInfo.fieldSettings?.items as ISelectItem[];
    const name = bc.getNavigationPath(true);

    const isValueHelp = isValueHelperField(fieldInfo, (args.storage as TableStorage));

    const isReadOnly = isFieldReadOnly(fieldInfo, args.storage, bc);
    const isDisabled = isFieldDisabled(fieldInfo, args.storage, bc, args.fastEntryListId);

    const getInfoValueArgs = {
        bindingContext: bc,
        storage: args.storage,
        data: args.storage?.data.entity,
        context: args.context
    };

    const unit = getInfoValue(fieldInfo?.fieldSettings, "unit", getInfoValueArgs);
    const trailingTextWithoutFocus = getInfoValue(fieldInfo?.fieldSettings, "trailingTextWithoutFocus", getInfoValueArgs);

    const temporalData = args.storage.getTemporalData(bc);

    const isSynchronized = getInfoValue(fieldInfo?.fieldSettings, "isSynchronized", getInfoValueArgs);
    const sharedProps = {
        ...fieldInfo?.fieldSettings,
        placeholder: getInfoValue(fieldInfo?.fieldSettings, "placeholder", getInfoValueArgs),
        onRowsChange: args.events?.onRowsChange,
        error: storageData.additionalFieldData?.error,
        key: bc.toString(),
        name,
        width: getWidth(fieldInfo, type, isValueHelp, bc, args.storage),
        disabledText: getInfoValue(fieldInfo, "disabledText", getInfoValueArgs),
        isRequired: storageData.isRequired,
        // isSynchronized fields are by default disabled as well
        isDisabled: isDisabled || isSynchronized,
        isReadOnly,
        isVisible: storageData.isVisible,
        onBlur: args.events?.onBlur,
        showChange,
        unit,
        isLoading: storageData.additionalFieldData?.isBusy,
        isSynchronized,
        isFullRow: fieldInfo.isFullRow
    };

    let formatterOptions: IFormatterFns<any> = {};
    if (fieldInfo.formatter) {
        formatterOptions = {
            ...formatterOptions,
            formatter: (value: any) => fieldInfo.formatter(value, {
                entity: args.storage.data.entity,
                // temporal items don't have additional data in entity as standard select may have
                item: args.storage.getTemporalData(bc)?.additionalData ?? args.storage?.getValue(bc),
                bindingContext: bc,
                storage: args.storage,
                context: args.storage.context
            }) as string
        };
    }

    if (fieldInfo.parser) {
        formatterOptions = {
            ...formatterOptions,
            parser: (value: string) => fieldInfo.parser(value, {
                entity: args.storage.data.entity,
                item: args.storage?.getValue(bc),
                bindingContext: bc,
                storage: args.storage
            })
        };
    }

    if (fieldInfo.isValid) {
        formatterOptions = {
            ...formatterOptions,
            isValid: (value: TValue) => fieldInfo.isValid(value, {
                ...getInfoValueArgs,
                item: args.storage?.getValue(bc)
            })
        };
    }

    let smartSelectProps: ISmartSelectProps | ISmartMultiSelectProps = null;
    let Control, props: TRecordAny;

    if (isSelectBasedComponent(type) || isValueHelp) {
        smartSelectProps = {
            fieldInfo: fieldInfo,
            ...fieldInfo?.fieldSettings,
            storage: args.storage,
            bindingContext: bc,
            ...sharedProps,
            // value doesn't have to be string, but e.g. boolean
            value: isNotDefined(value) || value === "" ? null : value as any,
            onChange: args.events.onSelectChange
        };
    }

    const fnGetDateProps = () => {
        return {
            previewValue: getInfoValue(fieldInfo?.fieldSettings, "previewValue", getInfoValueArgs),
            workDate: getInfoValue(fieldInfo?.fieldSettings, "workDate", getInfoValueArgs),
            showChange,
            isDateDisabled: fieldInfo?.fieldSettings?.isDateDisabled,
            minDate: getInfoValue(fieldInfo?.fieldSettings, "minDate", getInfoValueArgs),
            maxDate: getInfoValue(fieldInfo?.fieldSettings, "maxDate", getInfoValueArgs)
        };
    };

    if (isValueHelp) {
        Control = SmartValueHelper;
        props = {
            label: fieldInfo.label,
            ...smartSelectProps,
            value: storageData.additionalFieldData?.parsedValue as any,
            onChange: args.events?.onValueHelperChange
        };
    } else {
        switch (type) {
            case FieldType.Autocomplete:
                Control = SmartAutocomplete;
                props = {
                    ...smartSelectProps
                };
                break;

            // case FieldType.Select:
            //     Control = SmartSelect;
            //     props = {
            //         inputIsReadOnly: true,
            //         ...smartSelectProps
            //     };
            //     break;

            case FieldType.ComboBox:
                Control = SmartSelect;
                props = {
                    ...smartSelectProps,
                    ...formatterOptions
                };
                break;

            case FieldType.HierarchyComboBox:
                Control = SmartSelect;
                props = {
                    ...smartSelectProps,
                    isHierarchical: true,
                    ...formatterOptions
                };
                break;

            case FieldType.LabelSelect:
                Control = SmartLabelSelect;

                props = {
                    ...smartSelectProps,
                    trailingTextWithoutFocus
                };
                break;

            case FieldType.FileInput:
                Control = SmartFileInput;

                props = {
                    value: value,
                    onChange: args.events?.onFileChange,
                    width: sharedProps.width
                };
                break;

            case FieldType.HierarchyMultiComboBox:
                Control = SmartMultiSelect;
                props = {
                    ...smartSelectProps,
                    isHierarchical: true
                };
                break;

            case FieldType.MultiSelect:
                Control = SmartMultiSelect;
                props = {
                    ...smartSelectProps
                };
                break;

            case FieldType.BusinessPartnerSelect:
                Control = SingleBusinessPartnerSelect;
                props = {
                    ...smartSelectProps,
                    columns: smartSelectProps?.fieldInfo?.columns,
                    value: value as string
                };
                break;

            case FieldType.EditableText:
                Control = EditableText;
                props = {
                    isConfirmable: fieldInfo?.isConfirmable,
                    isResponsive: sharedProps.isResponsive,
                    placeholder: sharedProps.placeholder ?? args.storage.t("Components:EditableText.EmptyText"),
                    value,
                    isMultiLine: true,
                    // force edit mode when there is an error, undefined otherwise to let component handle it's edit state
                    isInEdit: (sharedProps.error ?? temporalData?.additionalFieldData?.error) ? true : undefined,
                    onChange: args.events?.onInputChange,
                    onCancel: args.events?.onCancel,
                    onConfirm: args.events?.onConfirm,
                    onBlur: args.events?.onBlur,
                    showChange
                };
                break;

            case FieldType.NumberInput:
                Control = NumericInput;

                const { scale, minorUnit, isCurrency } = getScale(bc, args.storage);
                const opts: Intl.NumberFormatOptions = {
                    maximumFractionDigits: scale
                };

                if (isCurrency) {
                    opts.minimumFractionDigits = minorUnit;
                }

                if (scale) {
                    formatterOptions = {
                        ...formatterOptions,
                        formatter: formatterOptions.formatter ?? NumberType.format.bind(null, value as number, opts),
                        parser: formatterOptions.parser ?? ((_val: string, strict?: boolean, args?: IParserArgs) => {
                            return NumberType.parse(_val, strict, {
                                ...args,
                                maximumFractionDigits: scale
                            });
                        })
                    };
                }

                props = {
                    ...sharedProps,
                    value, // react doesn't like when input goes between undefined and set value (controled vs uncontrolled component)
                    onFocus: args.events?.onFocus,
                    onChange: args.events?.onInputChange,
                    ...formatterOptions
                };
                break;

            case FieldType.SegmentedButton:
                Control = SegmentedButton;
                props = {
                    selectedButtonId: value,
                    name: name,
                    def: items,
                    onChange: args.events?.onSegmentedButtonChange,
                    showChange
                };
                break;

            case FieldType.Date:
                Control = DatePicker;

                props = {
                    ...fnGetDateProps(),
                    ...formatterOptions,
                    value: value as Date || null,
                    onChange: args.events?.onDateChange
                };
                enhanceWithValidationResult(props, storageData, "content");
                break;

            case FieldType.MonthYear:
                Control = MonthYearPicker;
                props = {
                    ...fnGetDateProps(),
                    value: value as Date || null,
                    onChange: args.events?.onDateChange
                };
                enhanceWithValidationResult(props, storageData, "content");
                break;

            case FieldType.ResponsiveInput:
                Control = ResponsiveInput;
                props = {
                    value: value as string,
                    onChange: args.events?.onInputChange,
                    onWidthChange: args.events?.onWidthChange
                };
                break;

            case FieldType.TokenInput:
                Control = TokenInput;
                props = {
                    value: value as string,
                    onChange: args.events?.onInputChange
                };
                break;

            case FieldType.Checkbox:
                Control = Checkbox;
                props = {
                    ...fieldInfo?.fieldSettings,
                    checked: !!value,
                    label: fieldInfo.label,
                    onChange: args.events?.onCheckboxChange,
                    showChange
                };
                break;

            case FieldType.ColorPicker:
                Control = ColorPicker;

                props = {
                    ...fieldInfo?.fieldSettings,
                    value: value as string,
                    onChange: args.events?.onColorChange
                };
                break;

            case FieldType.Password:
                Control = PasswordInput;

                props = {
                    value: value as string,
                    onFocus: args.events?.onFocus,
                    onChange: args.events?.onInputChange
                };
                break;

            case FieldType.Switch:
                Control = Switch;
                /**
                 * Default behavior is that switch displays inner label (when labelStatus is not defined)
                 * When LabelStatus === Visible - there is field label displayed and therefore no inner label
                 * When LabelStatus === Removed | Hidden - no label is displayed at all (inner or field label)
                 */
                const label = isNotDefined(fieldInfo?.labelStatus) && fieldInfo?.label;
                const formattedValue = formatterOptions.formatter?.(value) ?? value;

                props = {
                    ...fieldInfo?.fieldSettings,
                    label,
                    auditTrailData: storageData?.additionalFieldData?.auditTrailData,
                    onChange: args.events?.onSwitchChange,
                    checked: !!formattedValue,
                    ...formatterOptions,
                    showChange
                };
                break;

            case FieldType.CheckboxGroup:
                // case in AccountAssignment - each checkbox points to different entity of entity set
                if (bc.isCollection()) {
                    Control = AutoSizedCheckboxGroup;
                    props = {
                        ...fieldInfo?.fieldSettings,
                        items,
                        auditTrailData: storageData?.additionalFieldData?.auditTrailData,
                        values: storageData.value as string[],
                        onChange: args.events?.onCheckboxGroupChange
                    };
                } else {
                    Control = SmartCheckboxGroup;
                    // case in FiscalYearDef - each CheckBox points to different property of one entity
                    props = {
                        bindingContext: bc.getParent(),
                        storage: args.storage,
                        onChange: args.events?.onSmartCheckboxGroupChange,
                        items: items
                    };
                }
                break;

            case FieldType.RadioButtonGroup:
                Control = SmartRadioButtonGroup;
                props = {
                    value,
                    bindingContext: bc,
                    isReadOnly,
                    storage: args.storage,
                    auditTrailData: storageData?.additionalFieldData?.auditTrailData,
                    ...args.fieldDef?.fieldSettings,
                    onChange: args.events?.onSmartRadioButtonChange,
                    showChange
                };
                break;

            case FieldType.WriteLine:
                Control = WriteLine;
                props = {
                    ...fieldInfo?.fieldSettings,
                    onConfirm: args.events?.onConfirm,
                    isConfirmable: fieldInfo?.isConfirmable,
                    onCancel: args.events?.onCancel,
                    textAlign: fieldInfo.textAlign,
                    value,
                    onChange: args.events?.onInputChange,
                    ...formatterOptions,
                    showChange
                };
                break;

            case FieldType.TextArea:
                Control = TextArea;
                props = {
                    value,
                    ...sharedProps,
                    ...args.fieldDef?.fieldSettings,
                    onFocus: args.events?.onFocus,
                    onChange: args.events?.onInputChange
                };
                break;

            case FieldType.Text:
                Control = Text;
                props = {
                    isBig: true,
                    children: formatterOptions?.formatter ? formatterOptions.formatter(value) : value,
                    showChange
                };
                break;

            case FieldType.Time:
                Control = TimePicker;
                props = {
                    ...fnGetDateProps(),
                    ...formatterOptions,
                    value,
                    onChange: args.events?.onDateChange
                };
                enhanceWithValidationResult(props, storageData, "content");
                break;

            case FieldType.Input:
            default:
                Control = Input;
                props = {
                    value,
                    onFocus: args.events?.onFocus,
                    onChange: args.events?.onInputChange,
                    textAlign: fieldInfo.textAlign,
                    ...formatterOptions,
                    showChange
                };

                enhanceWithValidationResult(props, storageData, "icon");
        }
    }

    if (isComplexFilterArr(storageData.additionalFieldData?.parsedValue)) {
        // force "Applied" placeholder for complex conditional dialog values
        props.placeholder = i18next.t("Components:ValueHelper.Applied");
    }

    return {
        storageData,
        Control,
        isValueHelp,
        props: {
            ...sharedProps,
            ...props
        }
    };
};

export function getValueHelperChangeParams(bindingContext: BindingContext, type: FieldType, args: IValueHelperChangeArgs): ISmartFieldChange {
    return {
        // ValueHelper has complex value which doesn't correspond with BindingContext for given path, so we
        // use parsedValue instead. E.g. if property is of Date type, filters could be array of dates or
        // array of date intervals, etc...
        value: undefined,
        parsedValue: args.value as TValue,
        selectedItems: args.selectedItems,
        type, bindingContext,
        additionalData: {
            isValueHelper: true
        },
        triggerAdditionalTasks: args.triggerAdditionalTasks
    };
}

export const getTooltip = (tooltip: (((storage?: FormStorage) => React.ReactElement) | string), storage: FormStorage): (React.ReactElement | string) => {
    if (isNotDefined(tooltip)) {
        return undefined;
    }

    if (typeof tooltip === "function") {
        return tooltip(storage);
    }

    return tooltip;
};