import { IconButton } from "@components/button";
import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import { ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { TCustomRowAction } from "@components/smart/smartTable/SmartTable.utils";
import { IActionRendererArgs, IRowAction, TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import React from "react";

import { AddToFilledIcon, AddToIcon, IProps as IIconProps } from "../../components/icon";
import { IProps as ISmartTableProps, SmartTable } from "../../components/smart/smartTable/SmartTable";
import { ActionState, IconSize, RowAction } from "../../enums";
import { EmptyObject } from "../../global.types";
import { ModelEvent } from "../../model/Model";
import BindingContext from "../../odata/BindingContext";
import memoizeOne from "../../utils/memoizeOne";
import { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar.utils";
import TableView, { IHandleCustomActionArgs, ITableViewBaseProps } from "../../views/table/TableView";
import { TableWrapper } from "../../views/table/TableView.styles";
import { createCustomTableToolbarItem } from "../../views/table/TableView.utils";
import View from "../../views/View";

export interface IPairingAssetTableViewProps extends ITableViewBaseProps {
    selectedItem?: BindingContext;
    onClose?: () => void;
}

export const PAIR_ACTION_ID = "PairAction";

export default abstract class PairingWithAssetTableView<P extends IPairingAssetTableViewProps> extends TableView<P> {

    _selectedItem: BindingContext;

    constructor(props: P) {
        super(props);

        this.handleCustomAction = this.handleCustomAction.bind(this);

        this._selectedItem = props.selectedItem;
        this.handleCustomAction(PAIR_ACTION_ID, { update: false });
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.formStorage?.emitter.on(ModelEvent.AfterLoad, this.handleFormAfterLoad);
        this.props.formStorage?.emitter.on(ModelEvent.AfterLoad, this.handleLineItemsAction);
    }

    componentDidUpdate(prevProps: Readonly<IPairingAssetTableViewProps>, prevState: Readonly<EmptyObject>) {
        if (prevProps.formStorage !== this.props.formStorage) {
            prevProps.formStorage?.emitter.off(ModelEvent.AfterLoad, this.handleFormAfterLoad);
            this.props.formStorage?.emitter.on(ModelEvent.AfterLoad, this.handleFormAfterLoad);
            prevProps.formStorage?.emitter.off(ModelEvent.LineItemsAction, this.handleLineItemsAction);
            this.props.formStorage?.emitter.on(ModelEvent.LineItemsAction, this.handleLineItemsAction);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.formStorage?.emitter.off(ModelEvent.LineItemsAction, this.handleLineItemsAction);
        this.props.formStorage?.emitter.off(ModelEvent.AfterLoad, this.handleFormAfterLoad);
    }

    /**
     * Subscribed to event after formStorage->lineItem action -> delete saved item and switch to original pairing state
     * @param args
     */
    async handleLineItemsAction(args: ISmartFastEntriesActionEvent): Promise<void> {
        /* No action by default */
    }

    handleFormAfterLoad(): void {
        /* No action by default */
    }

    abstract renderHeader(): React.ReactElement;

    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.Settings
        ];
    }

    get customToolbarItems(): IToolbarItem[] {
        return [this.createToolbarItem(PAIR_ACTION_ID, "AddTo", this.getConfirmText() as string)];
    }

    createToolbarItem(id: string, iconName: string, label: string, forceDisabled = false): IToolbarItem {
        const action = this.getTableAction();

        return createCustomTableToolbarItem({
            tableAction: action,
            id,
            iconName,
            label,
            forceDisabled
        });
    }

    getConfirmText = (): string | React.ReactElement => this.props.storage.t("MinorAsset:PairingTableView.Complete");

    handleCustomAction(action: string, { update = true, value }: IHandleCustomActionArgs = {}): void {
        this.setTableAction(action, true, false);
        if (update) {
            this.forceUpdate();
        }
    }

    getTableSharedProps(): ISmartTableProps & { key: string } {
        const props = super.getTableSharedProps();
        props.keepActiveRowsOnFilterChange = true;
        return props;
    }

    getIcon = (isActive: boolean) => isActive ? AddToFilledIcon : AddToIcon;

    getCustomRowAction = memoizeOne((): TCustomRowAction => {
        return {
            actionType: RowAction.Custom,
            isSingleSelect: true,
            render: (args: IActionRendererArgs) => {
                const isActive = args.actionState === ActionState.Active;
                const Icon = this.getIcon(isActive);
                return (
                        <IconButton title=""
                                    onClick={args.onClick}
                                    isDisabled={args.isDisabled}
                                    isDecorative>
                            <Icon width={IconSize.S} height={IconSize.S}/>
                        </IconButton>
                );
            }
        };
    });

    getRowIcon = (id: TId, row: IRowProps, rowAction: IRowAction): React.ComponentType<IIconProps> => {
        if (rowAction) {
            return null;
        }

        return (id.toString() === this._selectedItem?.toString()) && this.getIcon(true);
    };

    render() {
        return (
            <>
                <View hotspotContextId={HotspotViewIds.PairingWithAssetTableView}>
                    {this.renderHeader()}
                    {this.renderFilterBar()}
                    {this.renderAlert()}
                    {this.renderToolbar()}
                    <TableWrapper>
                        <SmartTable
                            {...this.getTableSharedProps()}
                            initialActiveRows={this._selectedItem && [this._selectedItem]}
                        />
                    </TableWrapper>
                    {this.renderCustomizationDialog()}
                </View>
            </>
        );
    }
}
