import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DefaultTheme, ThemeProvider, withTheme } from "styled-components";

import { IconSize, Status } from "../../enums";
import { TRecordAny } from "../../global.types";
import TestIds from "../../testIds";
import { PropsWithTheme, themes } from "../../theme";
import { IconButton } from "../button";
import ErrorDetail from "../errorDetail/ErrorDetail";
import {
    CloseIcon,
    CollapseIcon,
    ErrorIcon,
    ExpandIcon,
    IProps as IIconProps,
    SuccessIcon,
    WarningIcon
} from "../icon";
import Tooltip from "../tooltip";
import {
    ActionWrapper,
    AlertDetailWrapper,
    Body,
    StatusIconWrapper,
    StyledAlert,
    Subtitle,
    Title
} from "./Alert.styles";

export interface IBaseAlertProps {
    status: Status;
    title?: React.ReactNode;
    subTitle?: React.ReactNode | React.ReactNode[];
    useFade?: boolean;
    action?: AlertAction;
    detailData?: TRecordAny;
}

export interface IAlertProps extends IBaseAlertProps {
    /** Element title shown on hover. Use if subTitle isn't string.
     * Otherwise, title is built from subTitle/s automatically. */
    hoverTitle?: string;
    /** Renders with width 100% instead of max-content */
    isFullWidth?: boolean;
    /** Renders without smiley, with smaller padding and font */
    isSmall?: boolean;
    /** Renders both title and subtitle on one line */
    isOneLiner?: boolean;
    /** Adds margin between alert and the following content,
     * that should always be present according to specification */
    shouldAddBottomMargin?: boolean;
    position?: AlertPosition;
    /** Use inverse styled-components theme */
    isInverseTheme?: boolean;
    onClose?: () => void;
    onFadeEnd?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

interface IState {
    isExpanded: boolean;
    isFading: boolean;
}

export enum AlertAction {
    // no action by default
    None = "None",
    // adds close button which triggers onClose event
    Close = "Action",
    // adds expand/collapse functionality
    Expand = "Expand"
}

export enum AlertPadding {
    S = "S",
    M = "M",
    L = "L"
}

export enum AlertPosition {
    // rendered as is
    Default = "Default",
    // absolutely positioned in the bottom center
    CenteredBottom = "CenteredBottom",
}

class Alert extends React.PureComponent<IAlertProps & WithTranslation & PropsWithTheme, IState> {
    state: IState = {
        isExpanded: true,
        isFading: false
    };

    _fadeOutTimer: number;
    _fadeOutTimerTime: number;
    _fadeOutLimit = 5000;

    get subtitles(): React.ReactNode[] {
        return Array.isArray(this.props.subTitle) ? this.props.subTitle : [this.props.subTitle];
    }

    get action(): AlertAction {
        return this.props.action ?? (this.subtitles.length > 1 ? AlertAction.Expand : (this.props.onClose && !this.props.useFade ? AlertAction.Close : AlertAction.None));
    }

    get position(): AlertPosition {
        return this.props.position ?? (this.props.status === Status.Success ? AlertPosition.CenteredBottom : AlertPosition.Default);
    }

    componentDidMount() {
        if (this.props.useFade) {
            this.startFadeTimer();
        }
    }

    componentDidUpdate(prevProps: IAlertProps) {
        if (prevProps.useFade !== this.props.useFade) {
            if (this.props.useFade) {
                this.startFadeTimer();
            } else {
                this.stopFadeTimer();
            }
        }
    }

    componentWillUnmount() {
        this.clearFadeOutTimer();
    }

    getIcon = (status: Status) => {
        switch (status) {
            case Status.Success:
                return SuccessIcon;
            case Status.Warning:
                return WarningIcon;
            case Status.Error:
                return ErrorIcon;
        }
        return null;
    };

    renderIcon = () => {
        const Icon = this.getIcon(this.props.status);

        return (
            <StatusIconWrapper>
                <Icon preventHover/>
            </StatusIconWrapper>
        );
    };

    handleExpand = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    };

    getActionIconParams = (action: AlertAction): {
        icon: React.ComponentType<IIconProps>,
        event: () => void,
        title: string,
        style?: React.CSSProperties
    } => {
        switch (action) {
            case AlertAction.Close:
                return {
                    icon: CloseIcon,
                    event: this.props.onClose,
                    title: this.props.t("Common:General.Close")
                };
            case AlertAction.Expand:
                return {
                    icon: this.state.isExpanded ? CollapseIcon : ExpandIcon,
                    event: this.handleExpand,
                    title: this.props.t(`Common:General.${this.state.isExpanded ? "Close" : "Open"}`)
                };
            default:
                return null;
        }

    };

    renderDetail = () => {
        if (this.props.detailData) {
            return (
                <AlertDetailWrapper isInline={this.hasInlineDetail()} isSmall={this.props.isSmall}>
                    <ErrorDetail data={this.props.detailData} status={Status.Error}/>
                </AlertDetailWrapper>
            );
        }

        return null;
    };

    shouldRenderSoloDetail = () => {
        return !this.isShortened() && !this.props.isSmall && !this.props.isOneLiner && !!this.props.detailData && this.props.subTitle;
    };

    hasInlineDetail = () => {
        return this.isInline() || (this.props.detailData && !this.props.subTitle);
    };

    shouldRenderAction = () => {
        return this.action !== AlertAction.None || (!!this.props.detailData && !this.props.subTitle);
    };


    renderAction = () => {
        const actionIcon = this.getActionIconParams(this.action);

        return (
            <ActionWrapper status={this.props.status} isSmall={this.props.isSmall} isInline={this.isInline()}>
                {!this.props.subTitle && this.renderDetail()}
                {actionIcon &&
                    <IconButton onClick={actionIcon.event}
                                testid={TestIds.AlertAction}
                                title={actionIcon.title}
                                isDecorative
                                style={actionIcon.style}
                                status={this.props.status}>
                        <actionIcon.icon width={IconSize.M} height={IconSize.M}/>
                    </IconButton>
                }
            </ActionWrapper>
        );
    };

    isInline = () => {
        return this.props.isOneLiner ;
    };

    isShortened = () => {
        return this.action === AlertAction.Expand && !this.state.isExpanded;
    }

    getPaddingType = () => {
        if (this.props.isSmall || this.props.isOneLiner) {
            return AlertPadding.S;
        }

        return AlertPadding.L;
    };

    renderSubTitles = () => {
        if (this.props.subTitle) {
            let subtitles: React.ReactNode[] = this.subtitles;
            const isShortened = this.isShortened();
            if (isShortened) {
                subtitles = [subtitles[0]];
            }
            const hasMultipleSubtitles = subtitles.length > 1;

            return subtitles.map((subTitle, index: number) => {
                    let result = subTitle;

                    if (index < subtitles.length - 1) {
                        // add space to the end of subtitle to separate multiple subtitles in inline mode
                        result = (
                            <>
                                {subTitle}
                                {" "}
                            </>
                        );
                    }

                    return (
                        <Subtitle _inline={this.props.isOneLiner || isShortened}
                                  _small={this.props.isSmall || this.props.isOneLiner}
                                  _shortened={isShortened}
                                  _showDivider={hasMultipleSubtitles}
                                  status={this.props.status}
                                  data-testid={TestIds.AlertSubtitle}
                                  key={index}>
                            {result}
                        </Subtitle>
                    );
                }
            );
        }

        return null;
    };

    getHoverTitle = (): string => {
        if (this.props.hoverTitle) {
            return this.props.hoverTitle;
        }

        if (!this.props.subTitle) {
            return this.props.title as string;
        }

        const texts: React.ReactNode[] = Array.isArray(this.props.subTitle) ? this.props.subTitle : [this.props.subTitle];

        return texts.map(text => typeof text === "string" ? text : "").join(" ");
    };

    getTheme = (): DefaultTheme => {
        const currentTheme = this.props.theme;

        if (!this.props.isInverseTheme) {
            return currentTheme;
        }

        return themes.light === currentTheme ? themes.dark : themes.light;
    };

    handleFadeStart = () => {
        this.setState({ isFading: true });
        this.clearFadeOutTimer();
    };

    handleFadeEnd = () => {
        this.props.onFadeEnd?.();
    }

    clearFadeOutTimer() {
        if (this._fadeOutTimer) {
            window.clearTimeout(this._fadeOutTimer);
            this._fadeOutTimer = null;
        }
    }

    startFadeTimer = () => {
        if (!this._fadeOutTimer) {
            this._fadeOutTimer = window.setTimeout(this.handleFadeStart, this._fadeOutLimit);
            this._fadeOutTimerTime = Date.now();
        }
    };
    stopFadeTimer = () => {
        this.clearFadeOutTimer();
        this._fadeOutLimit = Math.max(this._fadeOutLimit - (this._fadeOutTimerTime ? (Date.now() - this._fadeOutTimerTime) : 0), 2000);
        this.setState({ isFading: false });
    }

    render() {
        const { isSmall, isOneLiner } = this.props;
        const isShortened = this.isShortened();
        const hasShortenedText = isShortened || isOneLiner;
        const shouldRenderIcon = !isOneLiner && !isSmall;

        return (
            <ThemeProvider theme={this.getTheme()}>
                <StyledAlert className={this.props.className}
                             style={this.props.style}
                             _small={isSmall}
                             _inline={isOneLiner}
                             _isFullWidth={this.props.isFullWidth}
                             _shouldAddBottomMargin={this.props.shouldAddBottomMargin}
                             status={this.props.status}
                             useFade={this.props.useFade}
                             position={this.position}
                             onAnimationEnd={this.handleFadeEnd}
                             onMouseEnter={this.stopFadeTimer}
                             onMouseLeave={this.startFadeTimer}
                             _isFading={this.state.isFading}
                             data-testid={TestIds.Alert}>
                    {shouldRenderIcon && this.renderIcon()}
                    {this.shouldRenderSoloDetail() && this.renderDetail()}
                    <Tooltip
                        onlyShowWhenChildrenOverflowing={!isShortened}
                        isHidden={!hasShortenedText}
                        content={hasShortenedText ? this.getHoverTitle() : ""}>
                        {(ref) => (
                            <Body _small={isSmall}
                                  _hasInlineDetailAndCloseIcon={this.shouldRenderAction()}
                                  _inline={hasShortenedText}
                                  _padding={this.getPaddingType()}
                                  ref={ref}
                                  data-testid={TestIds.AlertContent}>
                                {(this.props.title || this.props.children) &&
                                    <Title data-testid={TestIds.AlertTitle}
                                           _small={isSmall}
                                           _inline={isOneLiner}
                                           disableDefaultStyles={typeof this.props.title !== "string"}>
                                        {this.props.title}
                                    </Title>
                                }
                                {this.renderSubTitles()}
                            </Body>
                        )}
                    </Tooltip>
                    {this.shouldRenderAction() && this.renderAction()}
                </StyledAlert>
            </ThemeProvider>
        );
    }
}

export default withTheme(withTranslation(["Common"])(Alert));