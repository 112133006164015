import { EntitySetName, EntityTypeName, ProformaInvoiceIssuedEntity } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "@pages/PageUtils";
import { hasEverBeenVatRegisteredCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { Model } from "../../../../model/Model";
import { commonDocumentTranslations } from "../../Document.utils";
import {
    addDateGroupDateField,
    clearEmptyDateGroupDateFields,
    getDefinitions as getDocumentDefinitions
} from "../../DocumentDef";
import { addDateIssued } from "../../invoicesReceived/InvoicesReceivedDef";
import { enhanceWithCommonProformaDefinitions } from "../ProformaInvoice.utils";
import ProformaInvoicesIssuedFormView from "./ProformaInvoicesIssuedFormView";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.ProformaInvoicesIssued,
        documentType: DocumentTypeCode.ProformaInvoiceIssued,
        tableId: `${EntityTypeName.ProformaInvoiceIssued}Table`,
        formId: `${EntityTypeName.ProformaInvoiceIssued}Form`,
        formControl: ProformaInvoicesIssuedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("ProformaInvoicesIssued:Breadcrumbs.NewInvoice"),
                        storage.data.entity?.NumberOurs && i18next.t("ProformaInvoicesIssued:Breadcrumbs.InvoiceWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesIssued],
        context
    }));

    if (!hasEverBeenVatRegisteredCompany(context)) {
        definition.table.title = i18next.t(`ProformaInvoicesIssued:Title_WithoutVat`);
    }

    enhanceWithCommonProformaDefinitions(definition, DocumentTypeCode.ProformaInvoiceIssued, getDefinitions.translationFiles[0], context);

    addDateIssued(definition, false, true);

    addDateGroupDateField(definition.form, ProformaInvoiceIssuedEntity.DateDue);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["ProformaInvoicesIssued", "Proforma", ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.ProformaInvoiceIssued, getDefinitions);