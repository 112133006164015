import Dialog from "@components/dialog/Dialog";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { IEntityBase } from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { IDefinition, IGetDefinitionOptionalTranslation } from "@pages/PageUtils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, ValidatorType, ValueType } from "../../enums";
import { getUtcDate } from "../../types/Date";
import FormView, { FormViewForExtend } from "../../views/formView/FormView";
import PlugAndPlayForm from "../../views/formView/PlugAndPlayForm";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";

interface IProps extends WithTranslation, WithOData {
    onFinish: (dateAccountingTransaction: Date) => void;
    title: string;
    fieldLabel: string;
}

const VatLockOverrideConfirmDialogPath = BindingContext.localContext("VatLockOverrideConfirmDialog");
const VatLockOverrideDatePath = "##DateAccountingTransaction##";

interface IDialogEntity extends IEntityBase {
    [VatLockOverrideDatePath]: Date;
}


class VatLockOverrideConfirmDialog extends React.PureComponent<IProps> {
    _formRef = React.createRef<FormViewForExtend<IDialogEntity>>();

    handleConfirm = async () => {
        await this._formRef.current.props.storage.validateAll();
        this._formRef.current.props.storage.refresh();

        if (!this._formRef.current.props.storage.isAnyError()) {
            const entity = this._formRef.current.props.storage.data.entity;

            this.props.onFinish(entity[VatLockOverrideDatePath]);
        }
    };

    handleCancel = () => {
        this.props.onFinish(null);
    };

    getBindingContext = (): BindingContext => {
        return createBindingContext(VatLockOverrideConfirmDialogPath, this.props.oData.getMetadata());
    };

    renderButtons = () => {
        return (
            <ConfirmationButtons onConfirm={this.handleConfirm}
                                 onCancel={this.handleCancel}
                                 confirmText={this.props.t("Common:General.Confirm")}/>
        );
    };

    render() {
        return (
            <Dialog
                title={this.props.title}
                onConfirm={this.handleConfirm}
                onClose={this.handleCancel}
                footer={this.renderButtons()}
            >
                <PlugAndPlayForm
                    getDef={getDefinition(this.props.fieldLabel)}
                    t={this.props.t}
                    bindingContext={this.getBindingContext()}
                    formRef={this._formRef}
                    formView={FormView}
                    formViewProps={{
                        formProps: {
                            isSimple: true,
                            renderScrollbar: false
                        }
                    }}
                />
            </Dialog>
        );
    }
}

const getDefinition = (label: string): IGetDefinitionOptionalTranslation => {
    return (context: IAppContext) => {
        const def: IDefinition = {
            entitySet: VatLockOverrideConfirmDialogPath,
            form: {
                id: "VatLockOverrideConfirmDialogSpecialForm",
                groups: [{
                    id: "Main",
                    rows: [
                        [{ id: VatLockOverrideDatePath }]
                    ]
                }],
                fieldDefinition: {
                    [VatLockOverrideDatePath]: {
                        width: BasicInputSizes.M,
                        label,
                        isRequired: true,
                        type: FieldType.Date,
                        valueType: ValueType.Date,
                        validator: {
                            type: ValidatorType.Date
                        },
                        defaultValue: getUtcDate()
                    }
                },
                translationFiles: ["Document"]
            }
        };

        return def;
    };
};

export default withTranslation(["Document"])(withOData(VatLockOverrideConfirmDialog));