import { ISelectItem } from "@components/inputs/select/Select.types";
import { isFieldDisabled } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import BindingContext, { createPath, IEntity } from "@odata/BindingContext";
import {
    CompanyEntity,
    DocumentAccountAssignmentEntity,
    DocumentAccountAssignmentSelectionEntity,
    DocumentItemEntity,
    DocumentVatClassificationEntity,
    DocumentVatClassificationSelectionEntity,
    EntitySetName,
    IDocumentCbaCategoryEntity,
    IDocumentEntity,
    IItemTemplateAccountAssignmentEntity,
    IItemTemplateEntity,
    ItemTemplateAccountAssignmentSelectionEntity,
    ItemTemplateEntity,
    ItemTemplateVatClassificationSelectionEntity,
    PaymentDocumentItemEntity,
    VatEntity
} from "@odata/GeneratedEntityTypes";
import { AccountingCode, CbaCategoryTaxImpactCode, SelectionCode, VatDeductionTypeCode } from "@odata/GeneratedEnums";
import { applyAccountFromTemplate } from "@pages/accountAssignment/AccountAssignment.utils";
import { ITEMS_VAT_DEDUCTION_PATH } from "@pages/admin/vatRules/VatRules.utils";
import { CBA_CATEGORY_PATH, CbaSelectionCode } from "@pages/cashBasisAccounting/CashBasisAccounting.utils";
import { isReceivedBc } from "@pages/documents/Document.utils";
import { memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { isDefined, isObjectEmpty } from "@utils/general";

import { TValue } from "../../../global.types";
import { FormStorage } from "../../../views/formView/FormStorage";


export const getItemTemplateSelectItems = memoizedWithCacheStrategy(async (storage: FormStorage<IDocumentEntity>): Promise<ISelectItem[]> => {
    const filters: string[] = [];
    const currentCompanyId = storage.context.getCompanyId();
    const currencyCode = storage.data.entity.TransactionCurrency?.Code ?? storage.data.entity.TransactionCurrencyCode;
    const conditionFilters: string[] = [`${ItemTemplateEntity.AlwaysOffer} eq true`];
    const isCbaCompany = isCashBasisAccountingCompany(storage.context);
    conditionFilters.push(`${ItemTemplateEntity.AccountingCode} eq '${isCbaCompany ? AccountingCode.CashBasisAccounting : AccountingCode.AccountingForBusiness}'`);
    filters.push(`((${conditionFilters.join(" AND ")}) OR ${ItemTemplateEntity.AllowedCompanies}/any(c: c/${CompanyEntity.Id} eq ${currentCompanyId}))`);
    filters.push(`${ItemTemplateEntity.TransactionCurrencyCode} eq '${currencyCode}'`);

    const query = storage.oData.getEntitySetWrapper(EntitySetName.ItemTemplates).query()
        .filter(filters.join(" AND "))
        .expand(ItemTemplateEntity.Vat)
        .expand(ItemTemplateEntity.CbaTaxImpact)
        .expand(ItemTemplateEntity.AccountAssignmentSelection, q1 => q1.expand(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment))
        .expand(ItemTemplateEntity.VatClassificationSelection, q2 => q2.expand(ItemTemplateVatClassificationSelectionEntity.VatClassification))
        .orderBy(ItemTemplateEntity.Description);

    const data = await query.fetchData<IItemTemplateEntity[]>();

    return data.value.map((item) => ({
        id: item.Id,
        label: item.Description,
        additionalData: {
            ...item
        }
    }));
}, (storage: FormStorage<IDocumentEntity>) => `${storage.context.getCompanyId()}::${storage.data.entity.TransactionCurrency?.Code ?? storage.data.entity.TransactionCurrencyCode}::${storage.data.entity?.BusinessPartner?.CountryCode}`);

export function handleItemTemplateChange(args: ISmartFieldChange, storage: FormStorage<IDocumentEntity>): boolean {
    const data = args.additionalData;
    const copyProps = [ItemTemplateEntity.Description, ItemTemplateEntity.TransactionAmount,
        ItemTemplateEntity.TransactionAmountNet, ItemTemplateEntity.TransactionAmountVat, ItemTemplateEntity.Quantity,
        ItemTemplateEntity.UnitOfMeasureCode, ItemTemplateEntity.VatCode];
    const itemBc = args.bindingContext.getParent();

    const _processField = (bc: BindingContext, value: TValue): void => {
        const info = storage.getInfo(bc);
        if (!isFieldDisabled(info, storage, bc)) {
            storage.setValue(bc, value);
            // only just after setting the value, so bc is defined and not called repeatedly
            storage.validateFieldSync(bc);
        }
    };

    copyProps.forEach(propName => {
        let bc: BindingContext;
        if (!itemBc.isValidNavigation(propName)) {
            // skip properties, which are not present in the entity
            return;
        }
        if (isDefined(data[propName]) || propName === ItemTemplateEntity.VatCode) {
            const isEnumCode = [ItemTemplateEntity.UnitOfMeasureCode, ItemTemplateEntity.VatCode].includes(propName);
            bc = itemBc.navigate(isEnumCode ? propName.replace(/Code$/, "") : propName);
            _processField(bc, data[propName]);
        }
        if (propName === ItemTemplateEntity.VatCode) {
            // we need to copy also Rate for document Vat, to make Vat formatter working
            bc = itemBc.navigate(createPath(ItemTemplateEntity.Vat, VatEntity.Rate));
            _processField(bc, data[ItemTemplateEntity.Vat][VatEntity.Rate]);
        }
    });
    // set value for account assignment
    const aaSel = data.AccountAssignmentSelection;
    if (!isObjectEmpty(aaSel)) {
        const aaSelBc = itemBc.navigate(ItemTemplateEntity.AccountAssignmentSelection);
        const aaBc = aaSelBc.navigate(DocumentAccountAssignmentSelectionEntity.AccountAssignment);
        const info = storage.getInfo(aaBc);
        if (!isFieldDisabled(info, storage, aaBc)) {
            let aaSelCode: SelectionCode = aaSel.SelectionCode as SelectionCode;
            if ([SelectionCode.Copy, SelectionCode.Own].includes(aaSelCode)) {
                aaSelCode = SelectionCode.Own;
                // find correct account IDs, copy data to VatClassification
                const aaData = aaSel.AccountAssignment as IItemTemplateAccountAssignmentEntity;
                storage.setValue(aaBc.navigate(DocumentAccountAssignmentEntity.Name), aaData.Name);
                storage.setValue(aaBc.navigate(DocumentAccountAssignmentEntity.ShortName), aaData.ShortName);
                // nonTaxAccount
                const { CreditAccountNumber, CreditAccountName, DebitAccountNumber, DebitAccountName } = aaData;
                Promise.all([
                    applyAccountFromTemplate(storage, aaBc.navigate(DocumentAccountAssignmentEntity.CreditAccount), {
                        Name: CreditAccountName,
                        Number: CreditAccountNumber
                    }),
                    applyAccountFromTemplate(storage, aaBc.navigate(DocumentAccountAssignmentEntity.DebitAccount), {
                        Name: DebitAccountName,
                        Number: DebitAccountNumber
                    })
                ]).then(() => {
                    // update short name (some of the accounts might be empty)
                    if (!storage.getValue(aaBc.navigate(DocumentAccountAssignmentEntity.CreditAccount), { useDirectValue: false }) ||
                        !storage.getValue(aaBc.navigate(DocumentAccountAssignmentEntity.DebitAccount), { useDirectValue: false })) {
                        storage.setValue(aaBc.navigate(DocumentAccountAssignmentEntity.ShortName), null);
                    }
                    storage.validateFieldSync(aaBc);
                    storage.addActiveField(aaBc);
                    storage.refresh();
                });
            } else {
                // None, Default -> just clear the VatClassification value and copy selection code (outsideo of the if)
                storage.clearValue(aaBc);
            }
            storage.setValue(aaSelBc.navigate(DocumentAccountAssignmentSelectionEntity.Selection), aaSelCode);
            storage.setValue(aaBc, aaSelCode);
            storage.addActiveField(aaBc);
        }
    }
    // set value for vat classification
    const vatSel = data.VatClassificationSelection;
    if (!isObjectEmpty(vatSel) && isReceivedBc(storage.data.bindingContext)) {
        const vatSelBc = itemBc.navigate(ItemTemplateEntity.VatClassificationSelection);
        const vatClassificationBc = vatSelBc.navigate(DocumentVatClassificationSelectionEntity.VatClassification);
        const vatDeductionBc = itemBc.navigate(ITEMS_VAT_DEDUCTION_PATH);
        const info = storage.getInfo(vatDeductionBc);
        if (!isFieldDisabled(info, storage, vatDeductionBc)) {
            let vatSelCode: SelectionCode = vatSel.SelectionCode as SelectionCode;
            let id: VatDeductionTypeCode | SelectionCode = null;
            if ([SelectionCode.Copy, SelectionCode.Own].includes(vatSelCode)) {
                vatSelCode = SelectionCode.Own;
                // find correct account IDs, copy data to VatClassification
                const vatClassData = vatSel.VatClassification;
                storage.setValue(vatClassificationBc.navigate(DocumentVatClassificationEntity.VatProportionalDeduction), vatClassData.VatProportionalDeduction);
                storage.setValue(vatClassificationBc.navigate(DocumentVatClassificationEntity.VatDeductionType), vatClassData.VatDeductionTypeCode);
                id = vatClassData.VatDeductionTypeCode;
                // nonTaxAccount
                const { NonTaxAccountName, NonTaxAccountNumber } = vatClassData;
                applyAccountFromTemplate(storage, vatClassificationBc.navigate(DocumentVatClassificationEntity.NonTaxAccount), {
                    Name: NonTaxAccountName,
                    Number: NonTaxAccountNumber
                }).then(() => {
                    storage.validateFieldSync(vatDeductionBc);
                    storage.addActiveField(vatDeductionBc);
                    storage.refresh();
                });
            } else {
                // None, Default -> just clear the VatClassification value and copy selection code (outsideo of the if)
                storage.clearValue(vatClassificationBc);
            }
            if (vatSelCode === SelectionCode.None) {
                // None means Own selection without accounts??
                vatSelCode = SelectionCode.Own;
            }
            if (vatSelCode === SelectionCode.Default) {
                id = SelectionCode.Default;
            }
            storage.setValue(vatSelBc.navigate(DocumentVatClassificationSelectionEntity.Selection), vatSelCode);
            storage.setValue(vatDeductionBc, id);
            storage.validateFieldSync(vatDeductionBc);
            storage.addActiveField(vatDeductionBc);
        }
    }

    // set value for Tax Impact
    const taxImpact = data.CbaTaxImpact;
    if (!isObjectEmpty(taxImpact)) {
        const catBc = itemBc.navigate(PaymentDocumentItemEntity.CbaCategory);
        const catPathBc = itemBc.navigate(CBA_CATEGORY_PATH);
        const { TaxImpactCode } = taxImpact;
        const cbaSelection = TaxImpactCode === CbaCategoryTaxImpactCode.Partial || taxImpact.IsAssetAcquisition ? CbaSelectionCode.Own : TaxImpactCode;
        const category: IDocumentCbaCategoryEntity = {
            TaxPercentage: taxImpact.TaxPercentage ?? (TaxImpactCode === CbaCategoryTaxImpactCode.Tax ? 100 : 0),
            TaxImpactCode,
            IsAssetAcquisition: taxImpact.IsAssetAcquisition,
            Category: null
        };
        storage.setValue(catBc, category);
        storage.setValue(catPathBc, cbaSelection);
        storage.addActiveField(catPathBc);
    }

    // force update item amounts - change bc to amount field which should recalculate the item according to.
    args.bindingContext = itemBc.navigate(isVatRegisteredCompany(storage.context) ? ItemTemplateEntity.TransactionAmountNet : ItemTemplateEntity.TransactionAmount);
    return true;
}

export function invalidateItemTemplateSelectItems(storage: FormStorage<IEntity>): void {
    const { entity, bindingContext } = storage.data;
    const { Items } = entity;
    const itemBc = bindingContext.navigate("Items");
    Items?.forEach((item: IEntity) => {
        const bc = itemBc.addKey(item).navigate(DocumentItemEntity.Description);
        const info = storage.getInfo(bc);
        info.fieldSettings.items = null;
    });
}