import React, { useCallback, useState } from "react";

import TestIds from "../../testIds";
import BusyIndicator, { IProps as IBusyIndicatorProps } from "./BusyIndicator";
import { BusyWrapper } from "./BusyIndicator.styles";

export interface WithBusyIndicator {
    busy?: boolean;
    setBusy?: (isBusy: boolean) => void;
    /** Instantiated BusyIndicator which is passed if passBusyIndicator prop is used.*/
    busyIndicator?: React.ReactElement;
    customBusyContent?: React.ReactNode;
    hideBusyIndicator?: boolean;
    updateBusyIndicatorSettings?: (args: Partial<IBusyIndicatorProps>) => void;
}

interface IBusyIndicatorWrapperProps extends Pick<IBusyIndicatorProps, "isInverse" | "isDelayed" | "size" | "isOverComponent"> {
    /** If false, withBusyIndicator wrapper will render BusyIndicator on its own.
     * If true, BusyIndicator component is passed via busyIndicator prop to the wrapped component
     * the component has to render the busyIndicator on its own (e.g. dialog which can't be easily wrapped by BusyWrapper) */
    passBusyIndicator?: boolean;
    defaultBusyState?: boolean;
}

export const withBusyIndicator = (args: IBusyIndicatorWrapperProps = {}) => (
    <P extends WithBusyIndicator>(Component: React.ComponentType<P>): React.ComponentType<P> => {
        return React.forwardRef((props: P, ref) => {
            const { passBusyIndicator, defaultBusyState, ...busyIndicatorProps } = args;
            // control the busy indicator either
            // with busy prop from parent component
            // or from within the component, by calling setBusy
            const [busy, setBusy] = useState(defaultBusyState ?? false);
            const [customBusySettings, setCustomBusySettings] = useState<Partial<IBusyIndicatorProps>>({});
            const updateBusyIndicatorSettings = useCallback((busySettings: Partial<IBusyIndicatorProps>) => {
                setCustomBusySettings(busySettings);
            }, []);
            const isBusy = props.busy || busy;
            const BusyIndicatorInstance = props.hideBusyIndicator ? null : (
                    <BusyIndicator {...busyIndicatorProps}
                                   {...customBusySettings}
                                   customContent={props.customBusyContent}/>
            );

            let result = (
                <>
                    {isBusy && !passBusyIndicator && BusyIndicatorInstance}
                    <Component busy={isBusy} setBusy={setBusy}
                               busyIndicator={isBusy && passBusyIndicator && BusyIndicatorInstance}
                               updateBusyIndicatorSettings={updateBusyIndicatorSettings}
                               ref={ref}
                               {...props as P}/>
                </>
            );

            if (!passBusyIndicator) {
                /* always render BusyWrapper, otherwise inner Component is completely re mounted on busy prop change */
                result = (
                    <BusyWrapper data-testid={TestIds.BusyWrapper}>
                        {result}
                    </BusyWrapper>
                );
            }

            return result;
        }) as any;
    }
);