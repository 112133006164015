import { IGetDrillDownParams } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString, IDayInterval } from "@components/inputs/date/utils";
import { IFieldData } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportColumnDef } from "@components/smart/smartTable";
import { isEmptyValue } from "@odata/OData.utils";
import { dateTimeToDateRange, isDefined } from "@utils/general";
import { logger } from "@utils/log";

import {
    Condition,
    ConditionType,
    getComplexFilterValue,
    IComplexFilter,
    isComplexFilter,
    isComplexFilterArr,
    isInterval,
    IValueInterval,
    TFilterValue
} from "../../components/smart/smartValueHelper";
import { EMPTY_VALUE } from "../../constants";
import { LogicOperator, ValueType } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import { DATE_MAX, DATE_MIN, getUtcDate, getUtcDayjs } from "../../types/Date";
import { IChangedFilter } from "../../views/table/TableView.utils";
import { CommonReportProps } from "./CommonDefs";
import {
    cleanSettings,
    getColumnFromColumnAlias,
    getNumberAggFuncItem,
    NumberAggregationFunction,
    ReportColumnType,
    ReportFilterNodeColumnType,
    ReportNodeOperator
} from "./Report.utils";
import { ReportStorage } from "./ReportStorage";
import { IReportFilterNode } from "./ReportView";
import { CUSTOM_DATE_RANGE_ID } from "@pages/reports/customFilterComponents/ComposedDateRange.utils";

const complexFilterConditionToReportOperator: Partial<Record<Condition, ReportNodeOperator>> = {
    [Condition.Equals]: ReportNodeOperator.Equal,
    [Condition.IsBefore]: ReportNodeOperator.LessThan,
    [Condition.IsAfter]: ReportNodeOperator.GreaterThan,
    [Condition.IsBeforeOrEqualsTo]: ReportNodeOperator.LessOrEqual,
    [Condition.IsAfterOrEqualsTo]: ReportNodeOperator.GreaterOrEqual,
    [Condition.GreaterThan]: ReportNodeOperator.GreaterThan,
    [Condition.LesserThan]: ReportNodeOperator.LessThan,
    [Condition.GreaterOrEqual]: ReportNodeOperator.GreaterOrEqual,
    [Condition.LesserOrEqual]: ReportNodeOperator.LessOrEqual,
    [Condition.Contains]: ReportNodeOperator.Substring,
    [Condition.BeginsWith]: ReportNodeOperator.StartsWith,
    [Condition.EndsWith]: ReportNodeOperator.EndsWith
};

const getDefaultNodeTypeForColumnType = (columnType: ReportColumnType, isColumnAliasFilterPrefix?: boolean): ReportFilterNodeColumnType => {
    let newColType: ReportFilterNodeColumnType;

    switch (columnType) {
        case ReportColumnType.Delta:
        case ReportColumnType.Currency:
        case ReportColumnType.Number:
            newColType = ReportFilterNodeColumnType.Decimal;
            break;
        case ReportColumnType.Integer:
            newColType = ReportFilterNodeColumnType.Integer;
            break;
        case ReportColumnType.String:
        case ReportColumnType.StringyInteger:
        case ReportColumnType.Label:
            newColType = ReportFilterNodeColumnType.String;
            break;
        case ReportColumnType.Boolean:
            newColType = ReportFilterNodeColumnType.Boolean;
            break;
        default:
            newColType = columnType as unknown as ReportFilterNodeColumnType;
    }

    // Special types for "multi" columns that have IsColumnAliasFilterPrefix property set to true.
    // This is done for columns like "Netto" which uses one filter field to filter over multiple virtual columns
    if (isColumnAliasFilterPrefix) {
        newColType = `MultiColumn${newColType}` as ReportFilterNodeColumnType;
    }

    return newColType;
};

const getDefaultOperatorForColumnType = (columnType: ReportColumnType) => {
    switch (columnType) {
        case ReportColumnType.Delta:
        case ReportColumnType.Currency:
        case ReportColumnType.Number:
        case ReportColumnType.Integer:
            return ReportNodeOperator.Equal;
        case ReportColumnType.Label:
            return ReportNodeOperator.Equal;
        case ReportColumnType.Boolean:
            return ReportNodeOperator.Equal;
        default:
            return ReportNodeOperator.Equal;
    }
};

const getNodeValue = (supportedColumn: IReportColumnDef, value: TValue) => {
    if (supportedColumn.Type === ReportColumnType.DateTimeOffset) {
        return getUtcDate(value as Date)?.toISOString();
    }

    if (supportedColumn.Type === ReportColumnType.Date) {
        return formatDateToDateString(value as Date);
    }

    return value;
};

const getLeftNodeValue = (column: IReportColumnDef): IReportFilterNode => {
    const node: IReportFilterNode = {
        ColumnAlias: column.ColumnAlias
    };

    if (column.AggregationFunction) {
        node.AggregationFunction = column.AggregationFunction;
    }

    return node;
};

const getArrayValueNode = (filter: TBuildReportNodeFilter, getCustomNode?: (filter: IFieldData) => IReportFilterNode): IReportFilterNode => {
    const value = filter.value as [];

    // if filter represents multiple values
    // we have to add OR filters for all the values, with same ColumnAlias
    if (value.length === 0) {
        return null;
    }

    const clonedFilters = (filter.value as []).map(value => {
        return {
            ...filter,
            value
        };
    });

    return buildFilterTree({
        filters: clonedFilters,
        operator: isComplexFilterArr(filter.value) ? LogicOperator.And : LogicOperator.Or,
        getCustomNode
    });
};

const getIntervalNode = (supportedColumn: IReportColumnDef, { from, to }: IValueInterval): IReportFilterNode => {
    const leftNodeValue = getLeftNodeValue(supportedColumn);
    const type = getDefaultNodeTypeForColumnType(supportedColumn.Type);

    return {
        Type: ReportFilterNodeColumnType.Logic,
        Operator: LogicOperator.And,
        Left: {
            Type: type,
            Operator: ReportNodeOperator.GreaterOrEqual,
            Left: { ...leftNodeValue },
            Right: {
                Value: getNodeValue(supportedColumn, from)
            }
        },
        Right: {
            Type: type,
            Operator: ReportNodeOperator.LessOrEqual,
            Left: { ...leftNodeValue },
            Right: {
                Value: getNodeValue(supportedColumn, to)
            }
        }
    };
};

export const getComplexValueNode = (supportedColumn: IReportColumnDef, complexValue: IComplexFilter): IReportFilterNode => {
    const value = getComplexFilterValue(complexValue);

    if (Array.isArray(value)) {
        // array of enum values
        let filterTree: IReportFilterNode;
        // => use OR for Included | Not(...OR...) for excluded
        const isExcludedEnum = complexValue.type === ConditionType.Excluded;
        // type to build a single entry node will be always "included"
        const type = ConditionType.Included;

        for (const val of value) {
            const node = getComplexValueNode(supportedColumn, { ...complexValue, type, value: val as TFilterValue });

            if (!filterTree) {
                filterTree = node;
            } else {
                filterTree = {
                    Type: ReportFilterNodeColumnType.Logic,
                    Operator: LogicOperator.Or,
                    Left: filterTree,
                    Right: node
                };
            }
        }

        if (isExcludedEnum && filterTree) {
            filterTree = {
                Type: ReportFilterNodeColumnType.Not,
                Node: filterTree
            };
        }

        return filterTree;
    }

    let node: IReportFilterNode;

    if (isInterval(value)) {
        node = getIntervalNode(supportedColumn, (complexValue.value as IValueInterval) ?? value);
    } else if (supportedColumn.Type === ReportColumnType.DateTimeOffset && complexValue.condition === Condition.Equals) {
        // DateTimeOffset has to be sent as interval, because user can't select exact minutes and seconds
        node = getIntervalNode(supportedColumn, dateTimeToDateRange(value as Date));
    } else {
        node = {
            Left: getLeftNodeValue(supportedColumn),
            Right: {
                Value: getNodeValue(supportedColumn, value)
            },
            Type: getDefaultNodeTypeForColumnType(supportedColumn.Type),
            Operator: complexFilterConditionToReportOperator[complexValue.condition]
        };
    }

    if (complexValue.type === ConditionType.Excluded) {
        node = {
            Type: ReportFilterNodeColumnType.Not,
            Node: node
        };
    }

    return node;
};

export const getNode = (filter: TBuildReportNodeFilter): IReportFilterNode => {
    let node: IReportFilterNode;
    const filterName = BindingContext.cleanLocalContext(filter.bindingContext.getPath());
    const cleanColumn = getColumnFromColumnAlias(filterName);
    let supportedColumn = filter.columnDef;

    if (!supportedColumn) {
        logger.warn(`ReportView: trying to create filter on non existing column: ${filterName}`);
        return null;
    }

    supportedColumn = {
        ...supportedColumn,
        // use the complete name with agg function
        ColumnAlias: filterName
    };

    if (filter.filterName) {
        // filter of column points to different ColumnAlias (e.g. DocumentType_Name is actually filtered by Document_DocumentTypeCode)
        supportedColumn = {
            ...supportedColumn,
            ColumnAlias: filter.filterName as string
        };
    }
    const hasNumberAggFn = cleanColumn.AggregationFunction && !!getNumberAggFuncItem(cleanColumn.AggregationFunction as NumberAggregationFunction);

    if (hasNumberAggFn) {
        supportedColumn.Type = cleanColumn.AggregationFunction === NumberAggregationFunction.Count ? ReportColumnType.Integer : ReportColumnType.Number;
    }

    const isComplexValue = isComplexFilter(filter.value);

    if (isComplexValue) {
        return getComplexValueNode(supportedColumn, filter.value as unknown as IComplexFilter);
    }

    node = {
        Left: getLeftNodeValue(supportedColumn),
        Right: {
            Value: filter.value === EMPTY_VALUE ? null : getNodeValue(supportedColumn, filter.value)
        }
    };

    if (supportedColumn.Type === ReportColumnType.DateTimeOffset || (supportedColumn.Type === ReportColumnType.Date && isInterval(filter.value))) {
        // DateTimeOffset has to be send as interval, because user can't select exact minutes and seconds
        let value: IDayInterval;

        if (isInterval(filter.value)) {
            value = filter.value as unknown as IDayInterval;
        } else {
            value = dateTimeToDateRange(filter.value as Date);
        }

        node = getIntervalNode(supportedColumn, value);
    } else {
        node.Type = getDefaultNodeTypeForColumnType(supportedColumn.Type, supportedColumn.IsColumnAliasFilterPrefix);
        node.Operator = getDefaultOperatorForColumnType(supportedColumn.Type);
    }

    return node;
};

export type TBuildReportNodeFilter = IFieldData & { columnDef: IReportColumnDef };

export interface IBuildReportFilterTreeArgs {
    filters: TBuildReportNodeFilter[];
    operator?: LogicOperator;
    allColumns?: IReportColumnDef[];
    parentFilter?: IFieldData;
    getCustomNode?: (filter: IFieldData) => IReportFilterNode;
}

export const buildFilterTree = ({
                                    filters,
                                    operator = LogicOperator.And,
                                    getCustomNode
                                }: IBuildReportFilterTreeArgs): IReportFilterNode => {
    let filterTree;

    for (const filter of filters) {
        let filterNode;

        if (Array.isArray(filter.value)) {
            filterNode = getArrayValueNode(filter, getCustomNode);

            if (!filterNode) {
                continue;
            }
        } else {
            const customNode = getCustomNode?.(filter);
            filterNode = customNode ?? getNode(filter);
        }

        if (filterTree && filterNode) {
            filterTree = {
                Type: ReportFilterNodeColumnType.Logic,
                Operator: operator,
                Left: filterTree,
                Right: filterNode
            };
        } else if (filterNode) {
            filterTree = filterNode;
        }
    }

    return filterTree;
};

// FE implementation of the filters. Used in reports to build items for value helpers.
// This way we don't need to send request to BE for each filter (which wouldn't be viable for reports)
type TApplyFilter<T> = (val1: T, val2: T | IValueInterval<T>, condition?: Condition, conditionType?: ConditionType) => boolean;

const fixStringValue = (val: string): string => {
    return isEmptyValue(val) ? null : val;
};

export const applyStringFilter = (val1: string, val2: string, condition = Condition.Equals, conditionType = ConditionType.Included): boolean => {
    let isTrue: boolean;

    const fixedVal1 = fixStringValue(val1);
    const fixedVal2 = fixStringValue(val2);

    switch (condition) {
        case Condition.Equals:
            isTrue = fixedVal1 === fixedVal2;
            break;
        case Condition.Contains:
            isTrue = !!fixedVal1?.includes(fixedVal2);
            break;
        case Condition.BeginsWith:
            isTrue = !!fixedVal1?.startsWith(fixedVal2);
            break;
        case Condition.EndsWith:
            isTrue = !!fixedVal1?.endsWith(fixedVal2);
    }

    if (conditionType === ConditionType.Excluded) {
        isTrue = !isTrue;
    }

    return isTrue;
};

export const applyNumberFilter = (val1: number, val2: number | IValueInterval<number>, condition = Condition.Equals, conditionType = ConditionType.Included): boolean => {
    let isTrue: boolean;

    switch (condition) {
        case Condition.Equals:
            isTrue = val1 === val2;
            break;
        case Condition.Between:
            const interval = val2 as IValueInterval<number>;

            isTrue = isDefined(val1) && val1 >= interval.from && val1 <= interval.to;
            break;
        case Condition.GreaterThan:
            isTrue = isDefined(val1) && val1 > (val2 as number);
            break;
        case Condition.GreaterOrEqual:
            isTrue = isDefined(val1) && val1 >= (val2 as number);
            break;
        case Condition.LesserThan:
            isTrue = isDefined(val1) && val1 < (val2 as number);
            break;
        case Condition.LesserOrEqual:
            isTrue = isDefined(val1) && val1 <= (val2 as number);
            break;
    }

    if (conditionType === ConditionType.Excluded) {
        isTrue = !isTrue;
    }

    return isTrue;
};

export const applyBooleanFilter = (val1: boolean, val2: boolean): boolean => {
    return val1 === val2;
};

export const applyDateFilter = (val1: Date, val2: Date | IValueInterval<Date>, condition = Condition.Equals, conditionType = ConditionType.Included): boolean => {
    let isTrue: boolean;

    switch (condition) {
        case Condition.Equals:
            isTrue = val1 && getUtcDayjs(val1).isSame(val2 as Date, "day");
            break;
        case Condition.Between:
            const interval = val2 as IValueInterval<Date>;

            isTrue = val1 && getUtcDayjs(val1).isBetween(interval.from, interval.to, "day", "[]");
            break;
        case Condition.IsAfter:
            isTrue = val1 && getUtcDayjs(val1).isAfter(val2 as Date, "day");
            break;
        case Condition.IsAfterOrEqualsTo:
            isTrue = val1 && getUtcDayjs(val1).isSameOrAfter(val2 as Date, "day");
            break;
        case Condition.IsBefore:
            isTrue = val1 && getUtcDayjs(val1).isBefore(val2 as Date, "day");
            break;
        case Condition.IsBeforeOrEqualsTo:
            isTrue = val1 && getUtcDayjs(val1).isSameOrBefore(val2 as Date, "day");
            break;
    }

    if (conditionType === ConditionType.Excluded) {
        isTrue = !isTrue;
    }

    return isTrue;
};

/** Test filter against value. Returns true if value is valid for this filter. */
export const applyFilter = (value: TValue, filter: IChangedFilter): boolean => {
    let filterValues = Array.isArray(filter.value) ? filter.value as TValue : [filter.value];

    if (filter.info?.filter?.transformFilterValue) {
        filterValues = filter.info.filter.transformFilterValue(filterValues as TValue, filter.info);
    }

    let compareFn: TApplyFilter<any>;

    switch (filter.info.valueType) {
        case ValueType.Date:
            compareFn = applyDateFilter;
            break;
        case ValueType.Number:
            compareFn = applyNumberFilter;
            break;

        case ValueType.Boolean:
            compareFn = applyBooleanFilter;
            break;
        case ValueType.String:
        default:
            compareFn = applyStringFilter;
            break;
    }

    if (isComplexFilterArr(filterValues)) {
        // AND for complex filters
        return filterValues.every((filterVal: IComplexFilter) => {
            const condition = filterVal.condition;
            const conditionType = filterVal.type;
            const preparedFilterVal = getComplexFilterValue(filterVal);
            const preparedFilterValues = (Array.isArray(preparedFilterVal) ? preparedFilterVal : [preparedFilterVal]) as (string | number | boolean | Date | IValueInterval)[];

            // OR for arrays (enums)
            return preparedFilterValues.some((val) => compareFn(value, val, condition, conditionType));
        });
    } else {
        // OR for value helper arrays
        return (filterValues as (string | number | Date | IValueInterval)[]).some((filterVal) => {
            let preparedFilterVal = filterVal;
            const valIsInterval = isInterval(preparedFilterVal);
            const condition = valIsInterval ? Condition.Between : undefined;

            if (filter.info.valueType === ValueType.Number && !valIsInterval) {
                preparedFilterVal = parseFloat(preparedFilterVal as string);
            }

            return compareFn(value, preparedFilterVal, condition);
        });
    }
};

export const getReportLogActionDetail = (storage: ReportStorage): string => {
    return JSON.stringify(cleanSettings(storage.settings));
};

/**
 * Returns partial drilldown params for DateRange filter for whole time
 */
export function getWholeDateRangeFilter(): Pick<IGetDrillDownParams, "filters" | "customQueryString"> {
    const dateRange: IValueInterval = {
        from: formatDateToDateString(DATE_MIN),
        to: formatDateToDateString(DATE_MAX)
    };

    return {
        filters: {
            [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
            [CommonReportProps.dateRangeCustomValue]: dateRange
        }
    };
}