import { WithAlert, withAlert } from "@components/alert/withAlert";
import { BreadCrumbProvider } from "@components/breadCrumb/index";
import BusyIndicator from "@components/busyIndicator";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { Button, ButtonGroup } from "@components/button";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { ArrowIcon } from "@components/icon";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { EntitySetName, ICompanySettingEntity } from "@odata/GeneratedEntityTypes";
import { CompanyStateCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import COATemplateSelection from "@pages/companies/COATemplateSelection";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import { NEW_ITEM_DETAIL } from "../../constants";
import { AppContext, IAppContext, IBreadcrumbItem } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IconSize, Status } from "../../enums";
import { ROUTE_COMPANIES, ROUTE_HOME } from "../../routes";
import TestIds from "../../testIds";
import memoizeOne from "../../utils/memoizeOne";
import { FormFooter } from "../../views/formView/FormView.styles";
import PlugAndPlayForm, { TFormViewProps } from "../../views/formView/PlugAndPlayForm";
import View from "../../views/View";
import Users from "../admin/users/Users";
import { getDefinitions as getUsersDef } from "../admin/users/UsersDef";
import {
    getDefinitions as getChartOfAccountTemplatesDef
} from "../chartOfAccountsTemplates/ChartOfAccountsTemplatesDef";
import { getFiscalYears } from "../fiscalYear/FiscalYear.utils";
import { getDefinitions as getFiscalYearDef } from "../fiscalYear/FiscalYearDef";
import FiscalYearFormView from "../fiscalYear/FiscalYearFormView";
import { CbaInitialYearSelection } from "./CbaInitialYearSelection";
import { getDefinitions as getCompanyFormDef } from "./CompanyDef";
import CompanyFormView from "./CompanyFormView";
import { HeaderStyled, StepSubtitle } from "./CompanyWizard.styles";
import { getDefinitions as getNewCustomersDef } from "./NewCustomers.def";
import NewCustomersFormView from "./NewCustomersFormView";
import { IGetDefinition } from "@pages/PageUtils";
import { getCompanySettings } from "@pages/companies/Company.utils";


interface IProps extends WithBusyIndicator, WithOData, WithTranslation, RouteComponentProps, WithAlert, WithPermissionContext, WithPromisedComponent {
}

interface IState {
    step: WizardStep;
    isBusy: boolean;
    usersActionIsActive: boolean;
}

enum WizardStep {
    CompanyForm = 0,
    FiscalYear,
    CoATemplates,
    UserSettings,
    CustomerUsers,
    SelectYear
}

enum NextStep {
    Previous = -1,
    CloseWizard = 0,
    Next = 1,
}

class CompanyWizard extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    formViewRef = React.createRef<any>();
    companySettings: ICompanySettingEntity = undefined;
    companyId: number;
    _scrollRef = React.createRef<HTMLDivElement>();

    constructor(props: IProps, context: IAppContext) {
        super(props);

        const step: WizardStep = this.getSavedStep(context);
        this.state = {
            step,
            isBusy: true,
            usersActionIsActive: false
        };
    }

    async componentDidMount() {
        if (this.props.tReady) {
            this.init();
        }
        this.updateWizardCompanySettings();
        this.forceUpdate();
    }

    async componentDidUpdate(prevProps: IProps) {
        if (!prevProps.tReady && this.props.tReady) {
            this.init();
        }

        this.updateWizardCompanySettingsIfCompanyHasChanged();
    }

    get isCompanyForm(): boolean {
        return this.state.step === WizardStep.CompanyForm;
    }

    get orderedSteps(): WizardStep[] {
        if (isCashBasisAccountingCompany(this.context)) {
            return [WizardStep.CompanyForm, WizardStep.SelectYear, WizardStep.UserSettings, WizardStep.CustomerUsers];
        }
        return [WizardStep.CompanyForm, WizardStep.FiscalYear, WizardStep.CoATemplates, WizardStep.UserSettings, WizardStep.CustomerUsers];
    }

    get isBusy(): boolean {
        /* isBusy or is loading company settings (loaded company settings is different then selected company in context) */
        return this.state.isBusy || (this.context.getCompanyId() && this.companyId !== this.context.getCompanyId());
    }

    init = () => {
        this.props.setAlert(null);
        this.context.setViewBreadcrumbs({
            items: this.getViewBreadCrumbs(),
            lockable: false
        });
    };

    getViewBreadCrumbs = memoizeOne((): IBreadcrumbItem[] => {
        return [
            {
                key: "Companies",
                title: this.props.t("Companies:Title"),
                link: ROUTE_COMPANIES
            }, {
                key: "new_company",
                title: this.props.t("Companies:Wizard.Title"),
                link: ""
            }
        ];
    }, () => [this.props.tReady]);

    async updateWizardCompanySettingsIfCompanyHasChanged() {
        const currentCompanyId = this.context.getCompanyId();
        if (currentCompanyId && this.companyId !== currentCompanyId) {
            await this.updateWizardCompanySettings();
        }
    }

    isFetchingCompanySettings: boolean;
    updateWizardCompanySettings = async () => {
        const companyId = this.context.getCompanyId();
        if (companyId && !this.isFetchingCompanySettings) {
            this.isFetchingCompanySettings = true;
            this.companySettings = await getCompanySettings(this.props.oData);
            this.companyId = companyId;
            this.isFetchingCompanySettings = false;
        }
        this.setState({ step: this.getSavedStep(this.context) });
    };

    getSavedStep(context: IAppContext): WizardStep {
        let savedStep: WizardStep;
        if (this.companyId) {
            savedStep = context.getData()?.personalizationSettings?.companyWizardStep?.[this.companyId] as WizardStep;
        }
        return savedStep ?? WizardStep.CompanyForm;
    }

    initializeCompany = async () => {
        const context = this.context as IAppContext;
        const companyId = this.context.getCompany().Id;
        await this.props.oData.getEntitySetWrapper(EntitySetName.Companies)
                .update(companyId, {
                    StateCode: CompanyStateCode.Initialized
                });

        await this.props.permissionContext.refreshUserPermissions();

        context.updateCompany(companyId, {
            StateCode: CompanyStateCode.Initialized
        });
        await Promise.all(context.updateCompanySpecificData());
    };

    handleActivation = async () => {
        let isOk = false;

        try {
            this.setState({ isBusy: true });
            await this.initializeCompany();
            isOk = true;
        } catch (e) {
            const errorTranslationKey = `Error:${e._validationMessages?.[0]?.code}`;
            this.props.setAlert({
                status: Status.Error,
                title: this.props.t("Common:Errors.ErrorHappened"),
                subTitle: i18next.exists(errorTranslationKey) ? this.props.t(errorTranslationKey) : null
            });
        }

        this.setState({ isBusy: false });

        if (isOk) {
            const url = addCompanyIdToUrl(ROUTE_HOME, this.context.getCompanyId());
            this.props.history.push(url);
        }
    };

    saveStep = (step: WizardStep): void => {
        const companyId = this.context.getCompanyId();

        if (companyId) {
            const currentSettings = this.context.getData()?.personalizationSettings?.companyWizardStep ?? {};
            this.context.changePersonalizationSetting("companyWizardStep", {
                ...currentSettings,
                [companyId]: step
            });
        }
    };

    saveCurrentPage = async () => {
        if (this.state.isBusy) {
            return;
        }
        if (this.state.step === WizardStep.UserSettings) {
            // change step immediately, no need to save
            this.changeStep();
        } else if ([WizardStep.SelectYear, WizardStep.CoATemplates].includes(this.state.step)) {
            this.handleCompanySettingsSave();
        } else if (this.formViewRef.current) {
            this.formViewRef.current.save({ skipLoad: true });
        }
    }

    handleFormBeforeSave = () => {
        this.setState({ isBusy: true });
    }

    handleFormSaveFail = () => {
        if (this._scrollRef?.current) {
            this._scrollRef.current.scrollTop = 0;
        }
        this.setState({ isBusy: false });
    }

    handleFormAfterSave = async (isNew: boolean) => {
        if (this.isCompanyForm) {
            await this.context.getFYPromise();
            await this.updateWizardCompanySettingsIfCompanyHasChanged();
        }
        // change step after save
        this.changeStep();
    };

    nextStep: NextStep;
    changeStep() {
        if (this.nextStep === NextStep.CloseWizard) {
            this.props.history.push(ROUTE_COMPANIES);
            return;
        }
        const currentIndex = this.orderedSteps.indexOf(this.state.step);
        const nextIndex = currentIndex + (this.nextStep ?? NextStep.Next);
        this.nextStep = null;

        if (nextIndex < this.orderedSteps.length && nextIndex >= WizardStep.CompanyForm) {
            const step = this.orderedSteps[nextIndex];
            this.saveStep(step);
            this.setState({ step, isBusy: step === WizardStep.UserSettings });
        } else {
            this.handleActivation();
        }
    }

    handleTemplateSelect = async (templateId: number) => {
        this.companySettings.InitialChartOfAccountsTemplateId = templateId;
        this.forceUpdate();
    }

    handleInitialYearChange = (value: number) => {
        this.companySettings.InitialYear = value;
        this.forceUpdate();
    };

    handleCompanySettingsSave = () => {
        const data: Partial<ICompanySettingEntity> = {};
        if (this.state.step === WizardStep.SelectYear) {
            data.InitialYear = this.companySettings.InitialYear;
        } else if (this.state.step === WizardStep.CoATemplates) {
            data.InitialChartOfAccountsTemplateId = this.companySettings.InitialChartOfAccountsTemplateId;
        }

        // note: no busy indicator for this kind of steps, so we don't await the update
        this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
                .update(this.companySettings.Id, data);

        this.changeStep();
    };

    handleAfterLoad = () => {
        this.setState({ isBusy: false });
    };

    handleUsersTableActionChange = (action: string) => {
        this.setState({ usersActionIsActive: !!action });
    };

    shouldSaveCurrentPageBeforeCancel = () => {
        return ![WizardStep.CompanyForm, WizardStep.CustomerUsers].includes(this.state.step);
    };

    handleWizardCancel = async () => {
        this.nextStep = NextStep.CloseWizard;
        if (this.shouldSaveCurrentPageBeforeCancel()) {
            this.saveCurrentPage();
        } else {
            // immediately close wizard without saving
            this.changeStep();
        }
    };

    handlePrevButtonClick = () => {
        this.nextStep = NextStep.Previous;
        this.saveCurrentPage();
    };

    handleNextButtonClick = () => {
        this.nextStep = NextStep.Next;
        this.saveCurrentPage();
    };

    formViewProps = memoizeOne(() => ({
        onBeforeSave: this.handleFormBeforeSave,
        onSaveFail: this.handleFormSaveFail,
        hideBusyIndicator: true,
        formProps: {
            hideBreadcrumbs: true,
            hideHeader: true,
            withoutPadding: true,
            renderScrollbar: false,
            shouldHideVariant: true,
            shouldHideAuditTrail: true,
            hideBusyIndicator: true,
        }
    }));

    renderStep = (step: WizardStep): ReactElement => {
        switch (step) {
                // render specific company settings page
            case WizardStep.SelectYear:
                return <CbaInitialYearSelection
                        initialYear={this.companySettings?.InitialYear}
                        onChange={this.handleInitialYearChange}
                        onSave={this.handleCompanySettingsSave}/>;

            case WizardStep.CoATemplates:
                return <COATemplateSelection templateId={this.companySettings?.InitialChartOfAccountsTemplateId}
                                             onChange={this.handleTemplateSelect}
                                             onSave={this.handleCompanySettingsSave}/>;
        }

        if (![WizardStep.CompanyForm, WizardStep.FiscalYear, WizardStep.CustomerUsers].includes(step)) {
            return null;
        }

        // render existing PlugAndPlayForm with specific form definition
        const companyId = this.context.getCompanyId();
        let bc: BindingContext;
        let getDef: IGetDefinition;
        let formView: React.ComponentType<TFormViewProps>;

        switch(step) {
            case WizardStep.CompanyForm:
                bc = createBindingContext(EntitySetName.Companies, this.props.oData.getMetadata()).addKey(companyId ?? NEW_ITEM_DETAIL, !companyId);
                getDef = getCompanyFormDef;
                formView = CompanyFormView;
                break;
            case WizardStep.FiscalYear:
                const FY = getFiscalYears(this.context)?.[0];
                bc = createBindingContext(EntitySetName.FiscalYears, this.props.oData.getMetadata()).addKey(FY.Id);
                getDef = getFiscalYearDef;
                formView = FiscalYearFormView;
                break;
            case WizardStep.CustomerUsers:
                bc = createBindingContext(BindingContext.localContext("NewCustomers"), this.props.oData.getMetadata());
                getDef = getNewCustomersDef;
                formView = NewCustomersFormView;
                break;
        }

        return (
                <PlugAndPlayForm key={step}
                                 t={this.props.t}
                                 history={this.props.history}
                                 ignoreVariants
                                 showButtons={false}
                                 formViewProps={this.formViewProps()}
                                 getDef={getDef}
                                 formRef={this.formViewRef}
                                 onAfterLoad={this.handleAfterLoad}
                                 onAfterSave={this.handleFormAfterSave}
                                 bindingContext={bc}
                                 formView={formView}
            />
        );
    };

    getStepSubtitle = () => {
        let stepTitle = '';
        const order = `${this.orderedSteps.indexOf(this.state.step)}/${this.orderedSteps.length - 1}:`;
        switch (this.state.step) {
            case WizardStep.CompanyForm:
                return this.props.t("Companies:Wizard.BasicInformation");
            case WizardStep.FiscalYear:
                stepTitle = this.props.t("Companies:Wizard.FirstFiscalYear");
                break;
            case WizardStep.CoATemplates:
                stepTitle = this.props.t("Companies:Wizard.ChartOfAccountsTemplates");
                break;
            case WizardStep.UserSettings:
                stepTitle = this.props.t("Companies:Wizard.UserSettings");
                break;
            case WizardStep.CustomerUsers:
                stepTitle = this.props.t("Companies:Wizard.CustomerPortalAccess");
                break;
            case WizardStep.SelectYear:
                stepTitle = this.props.t("Companies:Wizard.SetInitialYear");
                break;
        }

        return `${order} ${stepTitle}`;
    };

    renderHeader = () => {
        const title = this.props.t(`Companies:Wizard.${this.isCompanyForm ? "Title" : "InitialSettings"}`);
        return <>
            <HeaderStyled title={title}
                          shouldHideVariant={true}
            />
            <StepSubtitle data-testid={TestIds.Subtitle}>{this.getStepSubtitle()}</StepSubtitle>
        </>;
    };

    renderFooter = () => {
        const isLastStep = this.orderedSteps.indexOf(this.state.step) === this.orderedSteps.length - 1;
        const cancelText = this.isCompanyForm ? this.props.t("Common:General.Cancel") : this.props.t("Companies:Wizard.SetNextTime");
        const confirmText = this.isCompanyForm ? this.props.t("Common:General.Create") : isLastStep ? this.props.t("Companies:Wizard.Finish") : this.props.t("Common:General.Continue");
        const shouldDisableButtons = this.state.usersActionIsActive || this.state.isBusy;

        return <FormFooter data-testid={TestIds.FormFooter}>
            <ButtonGroup>
                {this.orderedSteps.indexOf(this.state.step) > 1 &&
                        <Button isTransparent
                                isDisabled={shouldDisableButtons}
                                icon={<ArrowIcon width={IconSize.S} height={IconSize.S}/>}
                                onClick={this.handlePrevButtonClick}>{this.props.t("Common:General.Back")}</Button>
                }
                <Button isTransparent
                        isDisabled={shouldDisableButtons}
                        onClick={this.handleWizardCancel}>{cancelText}</Button>
                <Button isDisabled={shouldDisableButtons}
                        onClick={this.handleNextButtonClick}>{confirmText}</Button>
            </ButtonGroup>
        </FormFooter>;
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }

        let content: React.ReactElement;
        if (this.state.step === WizardStep.UserSettings) {
            content = (
                    <Users oData={this.props.oData}
                           t={this.props.t}
                           tReady={this.props.tReady}
                           i18n={this.props.i18n}
                           getDef={getUsersDef}
                           onTableActionChange={this.handleUsersTableActionChange}
                           onTableLoad={this.handleAfterLoad}
                           customHeader={this.renderHeader()}
                           customFooter={this.renderFooter()}
                           hideBusyIndicator={this.state.isBusy}
                           dontUseUrlParams={true}/>
            );
        } else {
            content = (<>
                <BreadCrumbProvider/>
                <View hotspotContextId={"companyWizard"}
                      scrollProps={{
                          scrollableNodeProps: {
                              ref: this._scrollRef
                          }
                      }}
                      fixedFooter={this.renderFooter()}>
                    {this.renderHeader()}
                    {this.props.alert}
                    {this.renderStep(this.state.step)}
                </View>
            </>);
        }

        return (
                <>
                    {content}
                    {this.state.isBusy && <BusyIndicator/>}
                </>
        );
    }
}

export default withTranslation([
    "Common",
    ...getCompanyFormDef.translationFiles,
    ...getFiscalYearDef.translationFiles,
    ...getChartOfAccountTemplatesDef.translationFiles,
    ...getUsersDef.translationFiles,
    ...getNewCustomersDef.translationFiles
])(withPromisedComponent(withPermissionContext(withRouter(withOData(withBusyIndicator()(withAlert()(CompanyWizard)))))));