import { IAlertProps } from "@components/alert/Alert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { IFieldDef } from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { isODataError } from "@odata/Data.types";
import { createEntity } from "@odata/Data.utils";
import {
    EntitySetName,
    IAssetItemTypeEntity,
    IDocumentItemEntity,
    IMinorAssetItemEntity
} from "@odata/GeneratedEntityTypes";
import { AssetItemTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { uuidv4 } from "@utils/general";
import { cloneDeep } from "lodash";
import React from "react";

import ReadOnlyList from "../../../components/readOnlyList/ReadOnlyList";
import { Status } from "../../../enums";
import { TRecordAny } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { PureForm } from "../../../views/formView/PureForm";
import { SecondaryTableViewTitle } from "../../../views/table/TableView.styles";
import PairingWithAssetTableView, { IPairingAssetTableViewProps } from "../PairingWithAssetTableView";
import { PureFormWrapperStyled } from "./MinorAsset.styles";
import { correctMinorAssetItems, createMinorAssetStorage, handleMinorAssetLineItemsChange } from "./MinorAsset.utils";

interface IProps extends IPairingAssetTableViewProps {
    onCreateItem?: (bindingContext: BindingContext, assetItem: IMinorAssetItemEntity[]) => void;
    items?: IDocumentItemEntity[];
}


class PairingMinorAssetTableView extends PairingWithAssetTableView<IProps & WithBusyIndicator> {

    _formRef = React.createRef<PureForm>();

    // Prepared storage with new minor asset item data
    _lineItemStorage: FormStorage;
    _types: IAssetItemTypeEntity[];

    // Items, which were created by the pairing confirm
    _createdIds: number[];

    constructor(props: IProps) {
        super(props);
        this.init();
    }

    async init(): Promise<void> {
        const [storage, types] = await Promise.all([this.createStorage(), this.loadTypes()]);
        // unwraps already resolved promise
        this._lineItemStorage = storage;
        this._types = types;
        this.forceUpdate();
    }

    async loadTypes(): Promise<IAssetItemTypeEntity[]> {
        const res = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.AssetItemTypes)
            .query()
            .fetchData<IAssetItemTypeEntity[]>();

        return res.value;
    }

    async createStorage(): Promise<FormStorage> {
        return createMinorAssetStorage(this.props.rootStorage, {
            refresh: () => {
                this._formRef.current?.forceUpdate();
            }
        }, true, this.props.items);
    }

    get type() {
        // we currently do not support different codes
        return AssetItemTypeCode.Acquisition;
    }

    getColumns = memoizeOne((): IFieldDef[] => {
        const itemGroup = this._lineItemStorage.data.definition.groups.find(grp => grp.id === "Items");
        return itemGroup.lineItems.columns;
    });

    getFields = memoizeOne((): BindingContext[] => {
        const { data } = this._lineItemStorage;
        const itemsBc = data.bindingContext.navigate("Items").addKey(data.entity.Items[0], true);
        return this.getColumns().map(def => itemsBc.navigate(def.id));
    });

    handleToolbarConfirm = async (): Promise<void> => {
        const rows = await this.props.storage.tableAPI.getAffectedRows();

        const assetBc = rows?.[0]?.bc;

        // validate only the visible line item (other fields are not filled by the user)
        const fields = this.getFields();
        await this._lineItemStorage.validateFields(fields);

        if (fields.some(bc => this._lineItemStorage.getError(bc))) {
            // todo: may be some message?
            this._formRef.current?.forceUpdate();
            return;
        }

        let alert: IAlertProps;
        let result;

        this.props.setBusy(true);

        try {
            const batch: BatchRequest = this.props.storage.oData.batch();
            batch.beginAtomicityGroup("group1");

            const wholeEntityTree = cloneDeep(this._lineItemStorage.data.entity);
            const itemsForSave = correctMinorAssetItems(wholeEntityTree.Items as IMinorAssetItemEntity[], this._lineItemStorage);

            // Remove previously created entities if present
            (this._createdIds || []).forEach(idToRemove => {
                batch
                    .fromPath(this._selectedItem.navigate("Items").toString())
                    .delete(idToRemove);
            });

            // Create new entities
            if (assetBc) {
                const lineItemsBc = assetBc?.navigate("Items");
                itemsForSave.forEach(newItem => {
                    const itemBc = lineItemsBc.addKey(newItem, true);

                    createEntity({
                        entity: newItem,
                        wholeEntityTree,
                        bindingContext: itemBc,
                        dataBindingContext: this._lineItemStorage.data.bindingContext,
                        queryParams: {},
                        updateEnabledFor: [],
                        batch
                    });
                });
            }

            result = (await batch.execute()) as TRecordAny[];

            if (result.find(res => res?.status >= 300)) {
                // todo: how to handle errors from the batch request?
                // result[0]?.status >= 300
                // const storageItemBc = this._lineItemStorage.data.bindingContext.navigate("Items").addKey(itemForSave, true);
                // this._lineItemStorage.handleOdataError({
                //     error: result[0]?.body,
                //     bindingContext: storageItemBc,
                //     entity: wholeEntityTree
                // });
                // const { status, title, subTitle } = this._lineItemStorage.data.alert;
                alert = {
                    status: Status.Error,
                    title: this.props.storage.t("Common:Errors.ErrorHappened")
                };
            } else {
                alert = {
                    status: Status.Success,
                    title: this.props.storage.t("Common:Validation.SuccessTitle"),
                    subTitle: this.props.storage.t("MinorAsset:PairingTableView.MinorAssetPaired")
                };
            }
        } catch (error) {
            const _isODataError = isODataError(error);
            alert = {
                status: Status.Error,
                title: _isODataError && error._code,
                subTitle: _isODataError ? error._message : `${error}`
            };
        }

        if (!alert.title) {
            alert.title = this.props.storage.t("Common:Errors.ErrorHappened");
        }

        this.props.storage.setTableAlert(alert);
        this.props.setBusy(false);

        if (alert.status === Status.Success) {
            const createdItems = result.reduce((items, res) => {
                if (res?.status === 200 && res?.body?.value) {
                    items.push(res?.body?.value);
                }
                return items;
            }, []) as IMinorAssetItemEntity[];
            const createdIds = createdItems.map(item => item.Id);
            // store newly created items
            this._selectedItem = assetBc;
            this._createdIds = createdIds;
            // notify parent
            this.props.onCreateItem?.(this._selectedItem, createdItems);
            // disable the whole form - locking will do the job
            this._lineItemStorage.data.locked = true;
            this.setTableAction(null);
            this.props.storage.tableAPI?.reloadTable();

            this.forceUpdate();

            if (this.props.formStorage) {
                // reload form if open, so newly added item is visible in the list
                this.props.formStorage.store({ uuid: uuidv4() });
                this.props.formStorage.loadDataAfterSave();
            }
        }
    };

    getConfirmText = () => this.props.storage.t("MinorAsset:PairingTableView.Complete");

    getCurrentItemType = memoizeOne(() => {
        return this._types.find(item => item.Code === this.type);
    });

    handleChange = (e: ISmartFieldChange): void => {
        this._lineItemStorage.handleChange(e);

        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    };

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        await this._lineItemStorage.handleBlur(args);
        this._lineItemStorage.refreshFields();
    };

    handleLineItemsChange = (args: ISmartFieldChange): void => {
        handleMinorAssetLineItemsChange(this._lineItemStorage, args);
    };

    get documentData(): IReadOnlyListItem[] {
        const { rootStorage } = this.props;
        return [
            {
                label: rootStorage.t("MinorAsset:NumberOurs"),
                value: rootStorage.data.entity.NumberOurs
            }
        ];
    }

    renderHeader(): React.ReactElement {
        const storage = this.props.rootStorage;
        const titleKey = this.type === AssetItemTypeCode.Acquisition ? "MinorAssetForPairing" : "MinorAssetForDeletion";

        return (<>
            <ReadOnlyList title={storage.t(storage?.data?.definition?.title)}
                          data={this.documentData}
            />
            <SecondaryTableViewTitle>{storage.t(`MinorAsset:PairingTableView.${titleKey}`)}</SecondaryTableViewTitle>
            {this._lineItemStorage?.loaded &&
            <PureFormWrapperStyled>
                {/* ToDo: refactor to plugAndPlay form */}
                <PureForm ref={this._formRef}
                          storage={this._lineItemStorage}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          onLineItemChange={this.handleLineItemsChange}
                />
            </PureFormWrapperStyled>}
        </>);
    }
}

export default withBusyIndicator({ isDelayed: true })(PairingMinorAssetTableView);