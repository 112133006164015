import {
    ConditionType,
    IComplexFilter,
    isComplexFilterArr
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getWorkDate } from "@components/inputs/date/utils";
import { ISelectItem, SelectGroups } from "@components/inputs/select/Select.types";
import { ifAny, IFieldDef, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    AttachmentsAdditionalProperties,
    AttachmentsDef,
    CurrencyDef,
    ExchangeRatePerUnit,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    getLabelsDef,
    getLabelsFilterDef,
    LabelsTableColumnDef,
    NoteFieldDef,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef,
    TFilterDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { IExportCellValue, TExportFormatterFn, TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import {
    fetchValueHelperData,
    IFetchValueHelperItemsOptions
} from "@components/smart/smartValueHelper/ValueHelper.utils";
import { TCellValue } from "@components/table";
import { ITokenProps } from "@components/token";
import Tokenizer from "@components/token/Tokenizer";
import { IToolbarItem } from "@components/toolbar/Toolbar.types";
import { isInVatPayerVariantContext } from "@components/variantSelector/VariantOdata";
import { getBoundValue } from "@odata/Data.utils";
import { getDocumentTypeCodeFromEntityType, getEntityTypeFromBindingContext } from "@odata/EntityTypes";
import {
    DocumentDraftEntity,
    DocumentEntity,
    DocumentItemCbaCategoryEntity,
    DocumentItemDraftEntity,
    DocumentVatClassificationEntity,
    DocumentVatClassificationSelectionEntity,
    EntitySetName,
    EntityTypeName,
    FiscalPeriodEntity,
    IDocumentEntity,
    IDocumentItemLabelEntity,
    IFileMetadataEntity,
    IItemTemplateEntity,
    ILabelEntity,
    InvoiceIssuedEntity,
    RegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    CompanyPermissionCode,
    DocumentLinkTypeCode,
    DocumentStatusCode,
    DocumentTypeCode,
    FiscalYearStatusCode,
    GeneralPermissionCode,
    PayableReceivableTypeCode,
    PostedStatusCode,
    SelectionCode,
    VatStatementStatusCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { getItemTemplateSelectItems } from "@pages/admin/itemTemplates/ItemTemplates.utils.shared";
import { isRossumModuleActivated } from "@pages/admin/subscriptions/Subscriptions.utils";
import {
    hasEverBeenVatRegisteredCompany,
    isAccountAssignmentCompany,
    isCashBasisAccountingCompany,
    isIdentifiedPersonCompany
} from "@utils/CompanyUtils";
import { getFilterForDocumentDrafts } from "@utils/DraftUtils";
import { arrayInsert, isDefined } from "@utils/general";
import { getOneFetch } from "@utils/oneFetch";
import { isFileRossumEligible } from "@utils/Rossum.utils";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import {
    ClearedStatusClearedIcon,
    ClearedStatusNotClearedIcon,
    ClearedStatusPartiallyClearedIcon,
    IProps as IIconProps,
    PostedStatusNotApplicableIcon,
    PostedStatusNotPostedIcon,
    PostedStatusPostedIcon,
    VatStatementStatusNotApplicableIcon,
    VatStatementStatusNotPostedIcon,
    VatStatementStatusPartialIcon,
    VatStatementStatusPostedIcon
} from "../../components/icon";
import { DASH_CHARACTER } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    CacheStrategy,
    EditableTextSizes,
    FastEntryInputSizes,
    FieldType,
    IconSize,
    Sort,
    TextAlign,
    ToolbarItemType,
    ValidatorType,
    ValueType
} from "../../enums";
import { IEnumEntity, TRecordType, TValue, ValueOf } from "../../global.types";
import { Model } from "../../model/Model";
import { TableStorage } from "../../model/TableStorage";
import { ValidationMessage } from "../../model/Validator.types";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import { formatCurrencyVariableDecimals } from "../../types/Currency";
import DateType from "../../types/Date";
import { APPLY_ISDOC_ACTION, APPLY_ROSSUM_ACTION } from "../../views/fileView/FileViewUtils";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import {
    IChangedFilter,
    ISplitPageTableDef,
    ITableDef,
    ITableDefTabData,
    SECONDARY_FILTERS_ALL
} from "../../views/table/TableView.utils";
import {
    getAccAssignmentGroup,
    getAccountAssignmentItemDef,
    getAccountSelectionFieldsDef
} from "../accountAssignment/AccountAssignment.utils";
import {
    getGeneralSavedVats,
    getItemsDeductionDef,
    getVatFieldsDef,
    getVatRowFields,
    getVatTableColumnsDef,
    getVatTableFilterDefs,
    isAgendaDomesticRule,
    isDocumentReverseCharged,
    isInAutomatedVatInclusionMode,
    isThirdWorldsRule,
    ITEMS_VAT_DEDUCTION_PATH,
    SAVED_VATS_PATH
} from "../admin/vatRules/VatRules.utils";
import { BusinessPartnerDef } from "../businessPartner/BusinessPartner.utils";
import {
    CBA_CATEGORY_PATH,
    getCashBasisAccountingFieldsDef,
    getCashBasisAccountingGroup,
    getCbaCategoryItemDef,
    getCbaEntriesTable
} from "../cashBasisAccounting/CashBasisAccounting.utils";
import { getFiscalYearByDate } from "../fiscalYear/FiscalYear.utils";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../numberRange/NumberRange.utils";
import { IDefinition } from "../PageUtils";
import {
    DocumentStatusLocalPath,
    getAllDocumentStatusItems,
    getStatusFilterId,
    getStatusGroups,
    splitStatusFilterId,
    StatusEntities,
    StatusEntitiesToDocumentEntitiesMapping
} from "../reports/CommonDefs";
import {
    getReceivableAdjustmentsTab,
    REC_ADJ_LINK_TYPES
} from "../reports/receivableAdjustments/ReceivableAdjustments.utils";
import { StatusIconsWrapper, StyledTransactionCurrencyAmountText } from "./Document.style";
import {
    ATTACHMENTS_COLUMN_LOCAL_CONTEXT,
    DOCUMENT_CLEARING_FORM_TAB_ID,
    draftNumberOursFormatter,
    getAccountJournalTable,
    getAutomatedVatInclusionTable,
    getCurrencyUnit,
    getDocumentAttachmentsColumnDef,
    getDocumentAttachmentsFilterDef,
    getDocumentClearingTableDef,
    getDocumentPairedTable,
    getItemLabelTitleFromSelectionCode,
    isDocumentType,
    isIssued,
    isProforma,
    isReceived,
    isSavedDocument,
    readOnlyCurrencyItemFormatter,
    shouldDocumentBehaveLikeVatRegisteredCompany,
    shouldFieldBeVisibleForIdentifiedPerson,
    tWithFallback,
    VAT_ASSIGNMENT_GROUP_ID
} from "./Document.utils";
import {
    draftInfoPath,
    getDocumentStatusColumnDefinition,
    getDraftInfoColumnDef,
    isDraftViewCallback
} from "./DocumentCommonDefs";
import { IDocumentCustomData } from "./DocumentInterfaces";
import { ACCRUED_DOC_LINK_ALL_TYPES } from "./extensions/accruals/Accruals.utils";
import { DEFERRED_PLANS_LINK_TYPES } from "./extensions/timeResolution/TimeResolution.utils";

export const DOCUMENT_TABLES_GROUP_ID = "Tabs";

const _getDocumentStatusIcon = (entity: IDocumentEntity, propName: DocumentEntity, getIconFn: (status: any) => React.ComponentType<IIconProps>) => {
    const { Code, Name } = (entity[propName] ?? {}) as IEnumEntity;
    const StatusIcon = getIconFn(Code);
    const title = Name ?? getEnumDisplayValue(propName, Code);

    if (!StatusIcon) {
        return null;
    }

    return {
        tooltip: title,
        value: (<StatusIcon title={title} height={IconSize.S} width={IconSize.S}/>)
    };
};

export const documentStatusFormatter = (val: TValue, args: IFormatOptions): TCellValue => {
    if (args.bindingContext?.getParent()?.getEntitySet()?.getName() === EntitySetName.DocumentDrafts) {
        return null;
    }
    const context = args.context ?? args.storage?.context;
    if (!context) {
        throw new Error("Context has to be provided for documentStatusFormatter directly in args or through storage");
    }
    const _isAccountAssignmentComp = isAccountAssignmentCompany(context);
    const statuses = [
        _isAccountAssignmentComp ? _getDocumentStatusIcon(args.entity, DocumentEntity.PostedStatus, getPostedStatusIcon) : null,
        _getDocumentStatusIcon(args.entity, DocumentEntity.ClearedStatus, getClearedStatusIcon),
        _getDocumentStatusIcon(args.entity, DocumentEntity.DocumentVatStatementStatus, getVatStatementStatusIcon)
    ].filter(isDefined);

    return {
        tooltip: statuses.map(item => item.tooltip).join(", "),
        value: (
            <StatusIconsWrapper>
                {statuses.map(item => (<React.Fragment key={item.tooltip}>{item.value}</React.Fragment>))}
            </StatusIconsWrapper>
        )
    };
};

export const documentStatusExportFormatter = ({ entity, storage }: IFormatOptions): IExportCellValue[] => {
    // we don't have document type when called from reports
    const documentType = storage ? getDocumentTypeCodeFromEntityType(getEntityTypeFromBindingContext(storage.data.bindingContext)) : null;
    const statusEntities = documentType ? getUsedStatuses(storage.context, documentType) : StatusEntities;
    return statusEntities.map(statusEntity => ({
        id: statusEntity,
        // when entity is defined, row values are formatted, otherwise it's called to format the column label
        label: i18next.t(`Document:Status.${statusEntity}`),
        value: entity?.[StatusEntitiesToDocumentEntitiesMapping[statusEntity]]?.Name
    }));
};

export function getDocumentStatusEnumsTranslationNamespaces(): string[] {
    return [
        getEnumNameSpaceName(EntityTypeName.ClearedStatus),
        getEnumNameSpaceName(EntityTypeName.PostedStatus),
        getEnumNameSpaceName(EntityTypeName.VatStatementStatus)
    ];
}

/**
 * For report view, there are 3 dependent columns with the statuses, which we join to document entity and use common documentStatusFormatter
 **/
function getReportViewStatusFormatterArgs(prefix: string, args: IFormatOptions): IFormatOptions {
    const entity: IEntity = {};
    const row = args.entity;
    if (row) {
        StatusEntities.forEach(statusEntity => {
            const navigationProperty = StatusEntitiesToDocumentEntitiesMapping[statusEntity];
            const Code = row[`${prefix}_${navigationProperty}Code`];
            entity[navigationProperty] = {
                Code, Name: getEnumDisplayValue(statusEntity, Code)
            };
        });
    }
    return { ...args, entity };
}

export function getReportViewDocumentStatusFormatter(prefix: string): TFormatterFn {
    return (val: TValue, args: IFormatOptions): TCellValue => {
        if (!val) {
            return null;
        }
        return documentStatusFormatter(val, getReportViewStatusFormatterArgs(prefix, args));
    };
}

export function getReportViewDocumentStatusExportFormatter(prefix: string): TExportFormatterFn {
    return (args: IFormatOptions): IExportCellValue[] => {
        return documentStatusExportFormatter(getReportViewStatusFormatterArgs(prefix, args));
    };
}

export function getEnumBuildFilterMethod<T>(buildSingleFilter: (value: T[], negate?: boolean) => string) {
    return (item: IChangedFilter): string => {
        const filters: string[] = [];

        if (isComplexFilterArr(item.value)) {
            item.value?.forEach((complexFilterVal: IComplexFilter) => {
                const query = buildSingleFilter(complexFilterVal.value as T[], complexFilterVal.type === ConditionType.Excluded);
                if (query) {
                    filters.push(query);
                }
            });
            return filters?.length ? `(${filters.join(" AND ")})` : "";
        }

        return buildSingleFilter(item.value as T[]) ?? "";
    };
}

export const getClearedStatusIcon = (status: ClearedStatusCode): React.ComponentType<IIconProps> => {
    switch (status) {
        case ClearedStatusCode.NotCleared:
            return ClearedStatusNotClearedIcon;
        case ClearedStatusCode.Cleared:
            return ClearedStatusClearedIcon;
        case ClearedStatusCode.PartiallyCleared:
            return ClearedStatusPartiallyClearedIcon;
        case ClearedStatusCode.N_A:
        default:
            return null;
    }
};

export const getPostedStatusIcon = (status: PostedStatusCode): React.ComponentType<IIconProps> => {
    switch (status) {
        case PostedStatusCode.NotPosted:
            return PostedStatusNotPostedIcon;
        case PostedStatusCode.Posted:
            return PostedStatusPostedIcon;
        case PostedStatusCode.N_A:
            return PostedStatusNotApplicableIcon;
        default:
            return null;
    }
};

export const getVatStatementStatusIcon = (status: VatStatementStatusCode): React.ComponentType<IIconProps> => {
    switch (status) {
        case VatStatementStatusCode.Filed:
            return VatStatementStatusPostedIcon;
        case VatStatementStatusCode.NotFiled:
            return VatStatementStatusNotPostedIcon;
        case VatStatementStatusCode.PartiallyFiled:
            return VatStatementStatusPartialIcon;
        case VatStatementStatusCode.N_A:
        case VatStatementStatusCode.Undefined:
            return VatStatementStatusNotApplicableIcon;
        default:
            return null;
    }
};

export function getUsedStatuses(context: IAppContext, documentType?: DocumentTypeCode): EntityTypeName[] {
    const isInternal = documentType === DocumentTypeCode.InternalDocument;
    const isCashReceipt = [DocumentTypeCode.CashReceiptReceived, DocumentTypeCode.CashReceiptIssued].includes(documentType);
    const isAACompany = isAccountAssignmentCompany(context);

    return StatusEntities.filter(entity =>
        (!isCashReceipt || entity !== EntityTypeName.VatStatementStatus)
        && (!isInternal || entity !== EntityTypeName.ClearedStatus)
        && (isAACompany || entity !== EntityTypeName.PostedStatus));
}

export const DocumentStatusFilterNames: string[] = [DocumentStatusLocalPath];
export const getDocumentStatusFilterDefinition = (usedStatuses: EntityTypeName[]): Record<string, TFilterDef> => {
    const isEnum = true;
    const DocumentStatusFilterOneFetch = getOneFetch();

    return {
        [DocumentStatusLocalPath]: {
            id: DocumentStatusLocalPath,
            valueType: ValueType.String,
            label: i18next.t("Document:Table.Status"),
            type: FieldType.MultiSelect,
            defaultValue: [],
            // doesn't work for drafts
            isDisabled: isDraftViewCallback,
            filter: {
                // only use statuses from "usedStatuses" to prevent filter errors
                groupByProperties: usedStatuses.map((status: EntityTypeName) => `${StatusEntitiesToDocumentEntitiesMapping[status]}Code`),
                buildFilter: getEnumBuildFilterMethod((filterValues: string[], negate = false) => {
                    const statusMap = new Map<EntityTypeName, string[]>();
                    filterValues.forEach(val => {
                        const { entityType, code } = splitStatusFilterId(val);
                        const curr: string[] = statusMap.get(entityType) ?? [];
                        curr.push(code);
                        statusMap.set(entityType, curr);
                    });

                    const statusFilters = StatusEntities
                        .map((entityType: EntityTypeName) => {
                            const values = statusMap.get(entityType);
                            if (!values?.length) {
                                return null;
                            }
                            return `${StatusEntitiesToDocumentEntitiesMapping[entityType]}Code in (${transformToODataString(values, ValueType.String)})`;
                        })
                        .filter(item => !!item);

                    if (!statusFilters?.length) {
                        return null;
                    }
                    const query = statusFilters.join(" AND ");
                    return negate ? `not(${query})` : query;
                })
            },
            // we need to fetch items manually, so skip SmartValueHelper component in render
            isValueHelp: false,
            fieldSettings: {
                isEnum,
                groups: getStatusGroups(),
                cacheFetchedItemsInFieldInfo: false,
                initialItems: getAllDocumentStatusItems(usedStatuses),
                itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                    const { storage, bindingContext } = args;
                    const fieldInfo = storage.getInfo(bindingContext);

                    const opts: IFetchValueHelperItemsOptions = {
                        storage: storage as TableStorage,
                        bindingContext, fieldInfo,
                        isEnum
                    };
                    const data = await fetchValueHelperData(opts, DocumentStatusFilterOneFetch);
                    // prepare uniq items
                    const allItems: ISelectItem[] = fieldInfo.fieldSettings.initialItems.map(i => ({
                        ...i,
                        isDisabled: true
                    })) ?? [];

                    const counts: TRecordType<number> = {};
                    data.forEach(row => {
                        usedStatuses.forEach((entityType) => {
                            const code = row[`${StatusEntitiesToDocumentEntitiesMapping[entityType]}${BindingContext.ENUM_KEY_PROP}`];
                            const copiedItem = allItems.find(item => item.id === getStatusFilterId(entityType, code) && item.groupId === entityType);
                            if (copiedItem.isDisabled) {
                                copiedItem.isDisabled = false;
                                counts[entityType] = (counts[entityType] ?? 0) + 1;
                            }
                        });
                    });

                    return allItems.filter(item => !item.isDisabled /*&& (counts[item.groupId] > 1)*/);
                },
                itemsForRender(items: ISelectItem[]): ISelectItem[] {
                    // little hack, so getFilterBarItemRenderValue from FilterBar.utils.ts don't treat this as enum
                    // and uses actual items as values to render read only data.
                    return items;
                }
            }
        }
    };
};

export const summaryItemAmountFormatter = (val: TValue, args: IFormatOptions, amountProp: string) => {
    const entity = args.entity as IDocumentEntity;

    return <>
        {entity.Currency?.Code && entity.TransactionCurrency?.Code !== entity.Currency.Code &&
            <StyledTransactionCurrencyAmountText>
                {formatCurrencyVariableDecimals((entity[amountProp as keyof IDocumentEntity] ?? 0) as number, entity.Currency.Code)}&nbsp;|&nbsp;
            </StyledTransactionCurrencyAmountText>
        }
        {formatCurrencyVariableDecimals(val ?? 0, entity.TransactionCurrency.Code)}
    </>;
};

export const addIfIsVatRegistered = <T, >(value: T, context: IAppContext): T => {
    // identified person company should also have the vat fields in definition,
    // because for invoices tied to business partners from EEA countries, vat should be calculated
    return hasEverBeenVatRegisteredCompany(context, false) ? value : null;
};

const getLineItemsColumns = (context: IAppContext, isReceived: boolean): IFieldDef[] => {
    return [
        { id: "Description" }, { id: "Quantity" },
        ...(addIfIsVatRegistered([
            { id: "Vat" },
            { id: "TransactionAmountNet" },
            { id: "TransactionAmountVat" }
        ], context) ?? []),
        { id: "TransactionAmount" },
        ...(isReceived ? addIfIsVatRegistered([{ id: ITEMS_VAT_DEDUCTION_PATH }], context) ?? [] : [])
    ].filter(value => value);
};

export interface IDocumentDefinitionArgs {
    entitySet: string;
    documentType: DocumentTypeCode;
    tableId: ITableDef["id"];
    formId: IFormDef["id"];
    formControl: any;
    translationFiles: string[];
    getItemBreadCrumbText: (storage: Model) => string;
    permissions?: (CompanyPermissionCode | GeneralPermissionCode)[];
    context: IAppContext;
}

export const DOCUMENT_DATE_CHANGE_DEBOUNCE = 1000;

// Date fields are inserted into definitions from multiple different places in code,
// => this is the one place that stores the correct rows and order which should be used
// use addDateGroupDateField function to add it into the document definition.
// Empty indexes should be filtered out in the end of the document getDefinitions function by calling clearEmptyDateGroupDateFields
export const DocumentDateGroupRowsAndOrder: ValueOf<DocumentDraftEntity>[][] = [
    [DocumentDraftEntity.DateIssued, DocumentDraftEntity.DateTaxableSupply, DocumentDraftEntity.DateDue, DocumentDraftEntity.DatePaymentReceived],
    [DocumentDraftEntity.DateReceived, DocumentDraftEntity.DateAccountingTransaction, DocumentDraftEntity.FiscalPeriod, DocumentDraftEntity.DateVatDeduction, DocumentDraftEntity.DateProcessed]
];

export const addDateGroupDateField = (form: IFormDef, key: ValueOf<DocumentDraftEntity>): void => {
    const dateGroup = form.groups.find(group => group.id === "date");
    let fieldIndex: number;
    const rowIndex = DocumentDateGroupRowsAndOrder.findIndex(row => {
        const index = row.findIndex(field => field === key);

        if (index >= 0) {
            fieldIndex = index;
            return true;
        }

        return false;
    });

    if (rowIndex < 0) {
        throw new Error("date field is missing in DocumentDateGroupRowsAndOrder");
    }

    dateGroup.rows[rowIndex][fieldIndex] = { id: key as string };
};

export const clearEmptyDateGroupDateFields = (definition: IDefinition): IDefinition => {
    const dateGroup = definition.form.groups.find(group => group.id === "date");

    if (dateGroup) {
        dateGroup.rows = dateGroup.rows.map(row => {
            return row.filter(val => val);
        }).filter(row => row.length > 0);
    }

    return definition;
};

export const dateAccountingCustomValidator = (value: TValue, args: IGetValueArgs) => {
    const fy = getFiscalYearByDate(args.storage.context, value as Date);

    if (!DateType.isValid(value as Date)) {
        return new ValidationError(ValidationMessage.NotADate, false, args.bindingContext.getNavigationPath(true));
    }

    // document that is already saved should have some parts of it editable even in closed FiscalYear
    const shouldValidateFY = args.storage.data.bindingContext.isNew() || args.storage.isDirty(args.bindingContext);

    if (shouldValidateFY && fy?.StatusCode !== FiscalYearStatusCode.Active) {
        return new ValidationError(i18next.t("Document:Form.DateAccountingTransactionValidation"), false, args.bindingContext.getNavigationPath(true));
    }

    return true;
};

/** Returns definition only for the shared properties of Documents entity.
 * Each of the inherited documents has to extend the definition with it special properties. */
export const getDefinitions = (args: IDocumentDefinitionArgs): IDefinition => {
    const lockProperty = DocumentEntity.Locks;

    const _isReceived = isReceived(args.documentType);
    const _isIssued = isIssued(args.documentType);
    // proforma invoices are always treated as vat registered
    const _isProforma = isProforma(args.documentType);
    const isCashBasisAccounting = isCashBasisAccountingCompany(args.context);
    const hasAccountAssignment = isAccountAssignmentCompany(args.context);

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "NumberOurs",
            "Amount",
            "Items/Description",
            ATTACHMENTS_COLUMN_LOCAL_CONTEXT
        ],
        filterDefinition: {
            NumberOurs: {},
            Amount: {},
            "Items/Description": { isValueHelp: false },
            // definitions common for all document types
            // rest of them are added in each respective document def
            ...getDocumentStatusFilterDefinition(getUsedStatuses(args.context, args.documentType)),
            "Note": {},
            ...getLabelsFilterDef("Labels", "Items/LabelSelection/Labels"),
            "Items/Order": { isValueHelp: false },
            "Items/Amount": { isValueHelp: false },
            "Items/AmountNet": { isValueHelp: false },
            "Items/AmountVat": { isValueHelp: false },
            ...withDisplayName("DocumentSourceType"),
            "DocumentSourceName": {},
            [ATTACHMENTS_COLUMN_LOCAL_CONTEXT]: getDocumentAttachmentsFilterDef(),
            ...getFilterForDocumentDrafts(),
            ...getCommonFilterDefs(),
            ...(addIfIsVatRegistered(getVatTableFilterDefs({
                isIssued: _isIssued,
                isReceived: _isReceived
            }), args.context) ?? {})
        }
    }];

    if (hasAccountAssignment) {
        filterBarDef[0].filterDefinition = {
            ...filterBarDef[0].filterDefinition,
            DateAccountingTransaction: {}
        };
    }

    const tabs: ITableDefTabData[] = [{
        id: SECONDARY_FILTERS_ALL,
        title: i18next.t("Components:FilterBar.All")
    }, {
        id: DocumentStatusCode.NotCleared,
        title: i18next.t("Enums:DocumentStatus.NotCleared"),
        filterNames: [DocumentStatusCode.NotCleared, DocumentStatusCode.NotPaid, DocumentStatusCode.Posted]
    }, {
        id: DocumentStatusCode.PartiallyCleared,
        title: i18next.t("Enums:DocumentStatus.PartiallyCleared"),
        filterNames: [DocumentStatusCode.PartiallyCleared, DocumentStatusCode.PartiallyPaid]
    }, {
        id: DocumentStatusCode.Cleared,
        title: i18next.t("Enums:DocumentStatus.Cleared"),
        filterNames: [DocumentStatusCode.Cleared, DocumentStatusCode.Paid]
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        tabs,
        tabsSettings: {
            filterFieldName: "DocumentStatusCode"
        },
        lockProperty,
        draftDef: {
            draftEntitySet: EntitySetName.DocumentDrafts,
            draftFilter: `Document/Id eq null AND DocumentTypeCode eq '${args.documentType}' AND TemplateForRecurringTask eq null`,
            draftProperty: DocumentEntity.DocumentDraft,
            draftPropsBlacklist: [
                "/DocumentDraft", "Items/TransactionCurrencyCode",
                "OppositeDocumentLinks/TypeCode", "OppositeDocumentLinks/SourceDocument/NumberOurs", "OppositeDocumentLinks/SourceDocument/DocumentTypeCode",
                InvoiceIssuedEntity.TransactionAmountToPay, InvoiceIssuedEntity.TransactionAmountToReceive,
                DocumentEntity.ClearedStatus, DocumentEntity.PostedStatus, DocumentEntity.DocumentVatStatementStatus,
                DocumentEntity.DocumentVatStatementStatusCode
            ],
            draftAdditionalProps: [{
                id: DocumentDraftEntity.CountByDocumentTypeCodeAndNumberOurs
            }]
        },
        id: args.tableId,
        massEditableDef: {
            Note: { ...NoteFieldDef },
            "BusinessPartner/Name": BusinessPartnerDef(),
            DateDue: {},
            Labels: getLabelsDef("Document")
        },
        additionalProperties: [
            { id: "TransactionAmountToPay" },
            { id: "PaymentMethodCode" },
            { id: "BankAccount" }
        ], // TransactionAmountToPay, PaymentMethodCode and BankAccount needed for Payment table action
        columnDefinition: {
            // definitions common for all document types
            // rest of them are added in each respective document def
            ...getDocumentStatusColumnDefinition(),
            ...getDraftInfoColumnDef(),
            NumberOurs: {
                formatter: draftNumberOursFormatter(EntitySetName.DocumentDrafts, DocumentEntity.DocumentDraft),
                additionalProperties: [{
                    id: "/LastModifiedBy/Name"
                }, {
                    id: "/DateLastModified"
                }, {
                    id: "/DocumentDraft"
                }]
            },
            Amount: {},
            TransactionAmount: {},
            [DocumentEntity.Attachments]: getDocumentAttachmentsColumnDef(args.documentType),
            Note: {},
            ...getCommonTableColumnsDefs(),
            ...LabelsTableColumnDef,
            ...(addIfIsVatRegistered(getVatTableColumnsDef({
                isIssued: _isIssued,
                isReceived: _isReceived
            }), args.context) ?? {}),
            ...withDisplayName("DocumentSourceType"),
            DocumentSourceName: {}
        },
        columns: [
            draftInfoPath,
            DocumentEntity.NumberOurs,
            DocumentStatusLocalPath,
            DocumentEntity.BusinessPartner,
            DocumentEntity.Amount,
            DocumentEntity.Attachments,
            DocumentEntity.Note
        ],
        title: i18next.t(`${args.translationFiles[0]}:Title`),
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
    };

    if (hasAccountAssignment) {
        table.columnDefinition = {
            ...table.columnDefinition,
            DateAccountingTransaction: {}
        };
    }

    const auditTrailSummary: ISummaryItem[] = [{
        id: "NumberOurs",
        label: tWithFallback(args.translationFiles[0], "Summary.NumberOursAudit", "Document")
    }];


    const summary: ISummaryItem[] = [
        getNumberOursSummaryDef(args.translationFiles[0]),
        {
            id: "TransactionAmount",
            label: i18next.t("Document:Summary.ItemsSum"),
            formatter: (val: TValue, args: IFormatOptions) => {
                return summaryItemAmountFormatter(val, args, "Amount");
            },
            additionalProperties: [{ id: "/Amount" }],
            updateFromLiveValue: true
        }];

    summary.push({
        label: i18next.t("Enums:DocumentStatus.Cleared"),
        id: "ClearedStatus/Code",
        formatter: (val: TValue) => {
            return val === ClearedStatusCode.Cleared ? i18next.t("Common:General.Yes").toUpperCase() : i18next.t("Common:General.No").toUpperCase();
        },
        colorFormatter: (val: TValue) => {
            return val === ClearedStatusCode.Cleared ? "C_SEM_el_good" : "C_SEM_text_bad";
        },
        isVisible: isSavedDocument
    });

    const type = _isReceived ? PayableReceivableTypeCode.Payable
        : (_isIssued ? PayableReceivableTypeCode.Receivable : PayableReceivableTypeCode.InternalDocument);

    const _isInternalDocument = type === PayableReceivableTypeCode.InternalDocument;

    const addVatRule = type !== PayableReceivableTypeCode.InternalDocument;
    const addNonTaxAccount = _isReceived;

    const form: IFormDef = {
        id: args.formId,
        translationFiles: args.translationFiles,
        title: `${args.translationFiles[0]}:TitleSingular`,
        files: AttachmentsDef,
        summary,
        getCustomAttachmentFileActions: (file: IFileMetadataEntity, storage: FormStorage<IDocumentEntity, IDocumentCustomData>): IToolbarItem[] => {

            const isDocumentEntity = isDocumentType(storage.data.bindingContext);

            if (!isDocumentEntity) {
                return null;
            }

            const rossumActivated = isRossumModuleActivated(storage);
            const isRossumEligible = rossumActivated && isFileRossumEligible(file, true);
            const isApplyingIsdoc = !!storage.getCustomData().isApplyingIsdocToDocument;
            const isApplyingRossum = !!storage.getCustomData().isApplyingRossumToDocument;

            return [
                {
                    id: APPLY_ISDOC_ACTION,
                    label: i18next.t("Document:Isdoc.ApplyIsdoc"),
                    itemType: ToolbarItemType.Icon,
                    iconName: "UseIsDoc",
                    isDisabled: !file.IsIsdocReadable || isApplyingRossum,
                    isBusy: isApplyingIsdoc
                },
                {
                    id: APPLY_ROSSUM_ACTION,
                    label: i18next.t("Document:Isdoc.ApplyRossum"),
                    itemType: ToolbarItemType.Icon,
                    iconName: "UseRossum",
                    isDisabled: !isRossumEligible || isApplyingIsdoc,
                    isBusy: isApplyingRossum
                }
            ].filter(v => v);

        },
        showChanges: true,
        draftDef: {
            draftProperty: DocumentEntity.DocumentDraft,
            draftEntitySet: EntitySetName.DocumentDrafts,
            navigationToItem: DocumentItemDraftEntity.DocumentItem,
            navigationFromDraft: DocumentDraftEntity.Document,
            draftAdditionalProps: [{
                id: "DateLastModified"
            }, {
                id: "Items/DocumentItem"
            }, {
                id: DocumentDraftEntity.DateSourceSystemDocumentLastModified
            }, {
                id: DocumentDraftEntity.InvoiceServiceIntegrationId
            }, {
                id: DocumentDraftEntity.SourceSystemDocumentId
            }]
        },
        isDeletable: true,
        lockProperty,
        auditTrailSummary,
        permissions: args.permissions,
        formControl: args.formControl,
        getItemBreadCrumbText: args.getItemBreadCrumbText,
        additionalProperties: [
            { id: createPath(DocumentEntity.CreatedBy, "Id") },
            { id: "VatClassificationSelection/Selection/Code" },
            { id: createPath(DocumentEntity.VatClassificationSelection, DocumentVatClassificationSelectionEntity.VatClassification, DocumentVatClassificationEntity.Name) },
            { id: `${lockProperty}/CreatedBy` },
            { id: `${lockProperty}/Type` },
            { id: "DocumentTypeCode" },
            { id: "DocumentStatusCode" },
            { id: "ClearedStatus" },
            { id: "DocumentDraft/DateLastModified" },
            ...AttachmentsAdditionalProperties,
            ...NumberRangeAdditionalProperties,
            { id: "TransactionCurrency" },
            { id: "Currency" },
            { id: "AmountNet" },
            { id: "Amount" },
            { id: "TransactionAmountNet" },
            { id: DocumentEntity.ExchangeRatePerUnit },
            // https://solitea-cz.atlassian.net/browse/DEV-19266
            // we need Vat to be part of definition and the field cannot be made required (because 0% is null value)
            // reason for the other Items prices is probably similar
            { id: "Items/Vat" },
            // needed for items add button isDisabled check https://solitea-cz.atlassian.net/browse/DEV-19183
            { id: DocumentEntity.DocumentVatStatementStatus }
        ],
        fieldDefinition: {
            ...getNumberRangeFieldDefs(args.translationFiles[0]),
            Note: {
                ...NoteFieldDef,
                width: EditableTextSizes.L
            },
            Labels: getLabelsDef("Document"),
            DocumentSourceType: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                },
                isReadOnly: true
            },
            [DocumentEntity.DocumentSourceName]: {
                isReadOnly: true
            },
            TransactionCurrency: {
                ...CurrencyDef,
                label: i18next.t("Document:Form.Currency"),
                affectedFields: [{ id: "CashBox" }],
                customizationData: {
                    dependents: ["ExchangeRatePerUnit"]
                }
            },
            "Items/Description": {
                width: FastEntryInputSizes.L,
                additionalProperties: [
                    { id: "/Items/Amount" },
                    { id: "/Items/AmountNet" }
                ],
                // item templates, should not be used on Internal Documents
                ...(_isInternalDocument ? {} : {
                    type: FieldType.Autocomplete,
                    fieldSettings: {
                        useAutoSelection: false,
                        useTypingForward: false,
                        minCharsToOpenMenu: 3,
                        itemsFactory: (args: IGetValueArgs): Promise<ISelectItem[]> => {
                            return getItemTemplateSelectItems(args.storage as FormStorage, CacheStrategy.Company);
                        },
                        itemsForRender: (items: ISelectItem[], args: IGetValueArgs): ISelectItem[] => {
                            // itemsFactory getItemTemplateSelectItems cannot have VatStatusCode filter,
                            // in IdentifiedPerson the form can behave both as vatRegistered and notVatRegistered
                            // => instead filter the items here, dynamically
                            const vatStatusCode = shouldDocumentBehaveLikeVatRegisteredCompany({
                                storage: args.storage,
                                bindingContext: args.storage.data.bindingContext,
                                context: args.storage.context,
                                data: args.storage.data.entity
                            }) ? VatStatusCode.VATRegistered : VatStatusCode.NotVATRegistered;

                            return items.filter(item => (item.additionalData as IItemTemplateEntity)?.VatStatusCode === vatStatusCode);
                        }
                    }
                })
            },
            "Items/TransactionAmount": {
                width: FastEntryInputSizes.S,
                label: i18next.t("Document:Form.ItemAmount"),
                // TransactionAmountNet is required on internal Document, but is not selected from saved draft entity,
                // because field for TransactionAmountNet is not visible
                additionalProperties: [{ id: "/Items/TransactionAmountNet" }],
                formatter: readOnlyCurrencyItemFormatter,
                fieldSettings: {
                    unit: getCurrencyUnit
                },
                // TODO: IsInAutomatedVatInclusionMode isVisible => return true, even if it's not visible when BP on document is VatRegisteredEntity, discuss with T.Kral
                isDisabled: hasEverBeenVatRegisteredCompany(args.context) ? ifAny(isDocumentReverseCharged, isInAutomatedVatInclusionMode) : false,
                // set default value, so that the field can be removed in variant configuration
                defaultValue: null,
                customizationData: {
                    useForCustomization: !_isInternalDocument
                }
            },
            "Items/TransactionAmountNet": {
                width: FastEntryInputSizes.S,
                label: i18next.t("Document:Form.ItemAmountNet"),
                formatter: readOnlyCurrencyItemFormatter,
                fieldSettings: {
                    unit: getCurrencyUnit
                },
                isVisible: shouldFieldBeVisibleForIdentifiedPerson,
                // set default value, so that the field can be removed in variant configuration
                defaultValue: null,
                customizationData: {
                    useForCustomization: !_isInternalDocument,
                    isRequired: isInVatPayerVariantContext
                }
            },
            "Items/TransactionAmountVat": {
                width: FastEntryInputSizes.S,
                label: i18next.t("Document:Form.ItemAmountVat"),
                formatter: readOnlyCurrencyItemFormatter,
                fieldSettings: {
                    unit: getCurrencyUnit
                },
                isDisabled: ifAny(isInAutomatedVatInclusionMode, isDocumentReverseCharged),
                isVisible: shouldFieldBeVisibleForIdentifiedPerson,
                // set default value, so that the field can be removed in variant configuration
                defaultValue: null,
                customizationData: {
                    useForCustomization: !_isInternalDocument,
                    isRequired: isInVatPayerVariantContext
                }
            },
            "Items/LabelSelection/Labels": {
                ...getLabelsDef("Document"),
                width: FastEntryInputSizes.M,
                textAlign: TextAlign.Left,
                additionalProperties: [
                    { id: "/Items/LabelSelection/Labels/Label/Name" },
                    { id: "/Items/LabelSelection/Labels/LabelHierarchy/Color" }
                ],
                formatter: (val, args) => {
                    const labelSelection = args.storage.getValue(args.bindingContext.getParent());
                    const selectionCodeTitle = getItemLabelTitleFromSelectionCode(labelSelection);
                    const tokensDef: ITokenProps[] = labelSelection?.Labels?.map((label: IDocumentItemLabelEntity) => ({
                        id: label.Id,
                        title: label.Label.Name,
                        color: label.LabelHierarchy.Color
                    })) ?? [];
                    if (tokensDef.length === 0) {
                        return `${DASH_CHARACTER} ${selectionCodeTitle}`;
                    }
                    return {
                        value: <Tokenizer tokens={tokensDef}
                                          maxVisibleTokens={4}
                                          trailingText={selectionCodeTitle}
                                          showMoreTokensTooltip/>,
                        tooltip: ""
                    };
                },
                fieldSettings: {
                    ...(getLabelsDef("Document").fieldSettings),
                    noRecordText: i18next.t("Document:Labels.None"),
                    renderDefaultGroupWithoutCheckboxes: true,
                    additionalItems: [{
                        label: i18next.t("Document:Labels.Default"),
                        id: SelectionCode.Default,
                        groupId: SelectGroups.Default,
                        additionalData: {
                            closeOnSelection: true
                        }
                    }],
                    trailingTextWithoutFocus: args => {
                        return getItemLabelTitleFromSelectionCode(args.storage.getValue(args.bindingContext.getParent()));
                    }
                },
                comparisonFunction: (entity1: IEntity, entity2: IEntity, bc: BindingContext): boolean => {
                    const selectionCodeBc = bc.getParent().navigate("SelectionCode");
                    const dataBindingContext = bc.getRootParent();

                    const selection1 = getBoundValue({
                        bindingContext: selectionCodeBc,
                        data: entity1,
                        dataBindingContext
                    });
                    const selection2 = getBoundValue({
                        bindingContext: selectionCodeBc,
                        data: entity2,
                        dataBindingContext
                    });

                    if (selection1 !== selection2) {
                        return false;
                    }

                    const labels1 = getBoundValue({ bindingContext: bc, data: entity1, dataBindingContext });
                    const labels2 = getBoundValue({ bindingContext: bc, data: entity2, dataBindingContext });

                    return (labels1?.length === labels2?.length) && (!labels1?.length || labels1?.every((l1: ILabelEntity) => !!labels2.find((l2: ILabelEntity) => l2.Id === l1.Id)));
                }
            },
            "Items/LabelSelection/SelectionCode": {
                customizationData: {
                    useForCustomization: false
                },
                defaultValue: SelectionCode.Default
            },
            TransactionAmountVat: {
                isRequired: false,
                isReadOnly: true,
                customizationData: {
                    isRequired: false
                }
            },
            ...(addVatRule && addNonTaxAccount ? { [`Items/${ITEMS_VAT_DEDUCTION_PATH}`]: getItemsDeductionDef(type) } : {}),
            ...(addVatRule ? { [SAVED_VATS_PATH]: getGeneralSavedVats(type, false, addNonTaxAccount) } : {}),
            ...getVatFieldsDef(true, type, "VatClassificationSelection/VatClassification/"),
            ...getVatFieldsDef(true, type, "Items/VatClassificationSelection/VatClassification/"),
            "Items/VatClassificationSelection/SelectionCode": {
                useForValidation: false,
                customizationData: {
                    useForCustomization: false
                }
            },
            ExchangeRatePerUnit: {
                ...ExchangeRatePerUnit,
                label: i18next.t("Document:Form.CurrencyRate")
            }
        },
        groups: [
            {
                id: "Note",
                title: i18next.t("Document:FormGroup.Note"),
                rows: [[{ id: "Note" }]]
            },
            {
                id: "document",
                title: i18next.t("Document:FormGroup.Document"),
                rows: [[{ id: "TransactionCurrency" }, { id: DocumentEntity.ExchangeRatePerUnit }, { id: "Labels" }]]
            },
            {
                id: "date",
                title: i18next.t("Document:FormGroup.Date"),
                rows: [[], []]
            },
            addVatRule && hasEverBeenVatRegisteredCompany(args.context, false) ? {
                id: VAT_ASSIGNMENT_GROUP_ID,
                rows: [[{ id: SAVED_VATS_PATH }]],
                title: i18next.t("Document:FormGroup.Vat"),
                isVisible: (args: IGetValueArgs) => {
                    // proforma invoices are always treated as vat registered
                    if (_isProforma) {
                        return true;
                    }

                    if (isIdentifiedPersonCompany(args.context)) {
                        return !isAgendaDomesticRule(args) && !(_isIssued && isThirdWorldsRule(args));
                    }

                    return true;
                }
            } : null,
            {
                id: "Items", // to be compatible with FormView.load
                title: i18next.t("Document:FormGroup.LineItems"),
                lineItems: {
                    additionalFields: [
                        {
                            id: "LabelSelection/Labels"
                        },
                        {
                            id: "VatClassificationSelection/SelectionCode"
                        }, ...(addVatRule ? getVatRowFields({
                            prefix: "VatClassificationSelection/VatClassification/",
                            addNonTaxAccount
                        }) : [])],
                    collection: "Items",
                    showLineNumbers: true,
                    order: "Order",
                    columns: getLineItemsColumns(args.context, _isReceived)
                }
            },
            {
                id: DOCUMENT_TABLES_GROUP_ID,
                isVisible: (args: IGetValueArgs) => {
                    return !args.storage.data.bindingContext.isNew();
                },
                tabs: [
                    ...(isCashBasisAccounting ? [{
                        id: `cbaEntryLedger_${args.entitySet}`,
                        title: i18next.t("Document:FormTab.CbaEntries"),
                        table: getCbaEntriesTable(args.documentType, args.context)
                    }] : []),
                    ...(hasAccountAssignment ? [{
                        id: `accountingJournal_${args.entitySet}`,
                        title: i18next.t("Document:FormTab.JournalEntries"),
                        table: getAccountJournalTable({
                            hasDocItem: true,
                            isPaymentDocument: false
                        })
                    }] : []),
                    {
                        id: "PairedDocuments",
                        title: i18next.t("Document:FormTab.PairedDocuments"),
                        table: getDocumentPairedTable({
                            selectQuery: `not(TypeCode in (${transformToODataString([
                                DocumentLinkTypeCode.CorrectiveWithClearing, DocumentLinkTypeCode.CorrectiveWithoutClearing,
                                DocumentLinkTypeCode.InternalDocumentClearing, DocumentLinkTypeCode.Payment,
                                DocumentLinkTypeCode.ExchangeRateGain, DocumentLinkTypeCode.ExchangeRateLoss, ...DEFERRED_PLANS_LINK_TYPES,
                                ...REC_ADJ_LINK_TYPES, ...ACCRUED_DOC_LINK_ALL_TYPES,
                                ...(args.documentType !== DocumentTypeCode.InternalDocument ? [DocumentLinkTypeCode.AutomatedVATInclusionInputVAT, DocumentLinkTypeCode.AutomatedVATInclusionOutputVAT] : []),
                                DocumentLinkTypeCode.ProformaInvoiceDeduction
                            ], ValueType.String)}))`,
                            isCbaCompany: isCashBasisAccounting
                        })
                    },
                    getReceivableAdjustmentsTab(args.documentType === DocumentTypeCode.InternalDocument, isCashBasisAccounting),
                    {
                        id: DOCUMENT_CLEARING_FORM_TAB_ID,
                        title: i18next.t("Document:FormTab.Clearing"),
                        table: getDocumentClearingTableDef()
                    },
                    {
                        id: "automatedVatInclusion",
                        title: i18next.t("Document:FormTab.AutomatedVatInclusion"),
                        table: getAutomatedVatInclusionTable({ isCbaCompany: isCashBasisAccounting })
                    }
                ]
            }
        ].filter(value => value)
    };

    const itemsGroup = form.groups.find(g => g.id === "Items");

    if (hasAccountAssignment) {
        form.additionalProperties.push({ id: "AccountAssignmentSelection/Selection/Code" });
        form.fieldDefinition = {
            ...form.fieldDefinition,
            FiscalPeriod: {
                additionalProperties: [{
                    id: FiscalPeriodEntity.Name
                }],
                formatter: (val: TValue, args: IFormatOptions) => {
                    return args.entity?.FiscalPeriod?.Name ?? "";
                },
                isDisabled: true,
                // FiscalPeriod is readOnly in metadata, but we want to show the field as disabled
                isReadOnly: false,
                // FiscalPeriod disabled (lock) status is tied to DateAccountingTransaction
                backendPath: "DateAccountingTransaction"
            },
            DateAccountingTransaction: {
                defaultValue: () => {
                    return getWorkDate();
                },
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: dateAccountingCustomValidator
                    }
                },
                isRequired: true,
                affectedFields: [{ id: DocumentEntity.NumberOurs }]
            },
            ...getAccountSelectionFieldsDef(args.documentType),
            ...getAccountAssignmentItemDef("Items", args.documentType, true, hasAccountAssignment)
        };
        form.groups = arrayInsert(form.groups, {
            ...getAccAssignmentGroup()
        }, 3);

        addDateGroupDateField(form, DocumentEntity.DateAccountingTransaction);
        addDateGroupDateField(form, DocumentEntity.FiscalPeriod);

        const index = itemsGroup.lineItems.columns.findIndex(col => col.id === "TransactionAmount");

        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: "AccountAssignmentSelection/AccountAssignment" }, index !== -1 ? index + 1 : 6);
    }

    if (isCashBasisAccounting) {
        form.groups = arrayInsert(form.groups, {
            ...getCashBasisAccountingGroup()
        }, 3);

        form.fieldDefinition = {
            ...form.fieldDefinition,
            ...getCbaCategoryItemDef("Items", false, _isIssued),
            ...getCashBasisAccountingFieldsDef(DocumentEntity.CbaCategory, false, _isIssued)
        };

        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: CBA_CATEGORY_PATH }, 6);
        itemsGroup.lineItems.additionalFields = [...itemsGroup.lineItems.additionalFields,
            {
                id: createPath(RegularDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.IsAssetAcquisition)
            }, {
                id: createPath(RegularDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxImpact)
            }, {
                id: createPath(RegularDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxPercentage)
            }, {
                id: createPath(RegularDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.Category)
            }];
    }
    form.groups.find(group => group.id === "Tabs").tabs.forEach(tab => tab.table.preventAutoReset = true);

    return {
        entitySet: args.entitySet,
        table,
        form
    };
};

getDefinitions.translationFiles = null;