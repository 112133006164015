import { isJobFinished } from "@components/backgroundJobs/BackgroundJobs.utils";
import {
    EntitySetName,
    IBackgroundJobEntity,
    IDocumentDraftExtractRossumParameters,
    IFileMetadataEntity,
    OdataActionName
} from "@odata/GeneratedEntityTypes";
import { WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { ODataQueryResult } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { getOneFetch, isAbortException } from "@utils/oneFetch";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { isBackgroundJobWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { RossumProgressAnim } from "../../animations/Animations";
import { Button, ButtonGroup } from "../../components/button";
import Dialog from "../../components/dialog";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { getBackgroundJobFromWebsocketMessage } from "../../contexts/backgroundJobsContext/BackgroundJobsContext.utils";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";

// 2 mins
const RossumTimeoutLimit = 120000;

enum RossumProgressDialogScreen {
    Progress = "Progress",
    TimeOutFail = "TimeOutFail"
}

interface IProps extends WithTranslation, WithOData {
    draftId: number;
    file: IFileMetadataEntity;
    onFinish: (rossumFinished: boolean) => void;
}


interface IState {
    screen: RossumProgressDialogScreen;
}

class RossumProgressDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    rossumBackgroundJobId: number = null;
    timeout: ReturnType<typeof setTimeout>;
    oneFetch = getOneFetch();

    state: IState = {
        screen: RossumProgressDialogScreen.Progress
    };

    unsubscribeWebsocket: () => void;
    resolveFn: (value: boolean) => void;

    constructor(props: IProps) {
        super(props);

        this.handleMessage = this.handleMessage.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.oneFetch.abort();
    }

    handleMessage(message: TWebsocketMessage) {
        if (!isBackgroundJobWebsocketMessage(message)) {
            return;
        }

        const backgroundJob = getBackgroundJobFromWebsocketMessage(message);

        if (backgroundJob.Id === this.rossumBackgroundJobId && isJobFinished(backgroundJob)) {
            this.finnish(true);
        }
    }

    finnish = (resolve: boolean) => {
        this.rossumBackgroundJobId = null;
        clearTimeout(this.timeout);
        this.resolveFn(resolve);
    };

    init = async () => {
        const promise = new Promise<boolean>((resolve) => {
            this.resolveFn = resolve;
        });
        const fnFailed = () => {
            this.finnish(false);
            this.setState({
                screen: RossumProgressDialogScreen.TimeOutFail
            });
        };

        this.timeout = setTimeout(fnFailed, RossumTimeoutLimit);

        try {
            const wrapper = this.props.oData.getEntitySetWrapper(EntitySetName.DocumentDrafts);
            const backgroundJob = await wrapper.action(OdataActionName.DocumentDraftExtractRossum, this.props.draftId, {
                FileMetadata: {
                    "@odata.id": `${EntitySetName.FilesMetadata}(${this.props.file.Id})`
                }
            } as IDocumentDraftExtractRossumParameters, { fetchFn: this.oneFetch.fetch }) as ODataQueryResult<IBackgroundJobEntity>;

            this.rossumBackgroundJobId = backgroundJob.value.Id;

            this.unsubscribeWebsocket = WebsocketManager.subscribe({
                callback: this.handleMessage,
                types: [WebSocketMessageTypeCode.BackgroundJob]
            });
        } catch (error) {
            if (isAbortException(error)) {
                return;
            } else {
                fnFailed();
            }
        }

        // wait until the background job finishes
        const res = await promise;

        if (res) {
            this.props.onFinish(res);
        }
    };

    handleCancel = async () => {
        const shouldCancelCall = this.rossumBackgroundJobId !== null;

        // call finnish first, because it will clear the timeout
        this.finnish(false);

        if (shouldCancelCall) {
            const wrapper = this.props.oData.getEntitySetWrapper(EntitySetName.DocumentDrafts);

            try {
                await wrapper.action(OdataActionName.DocumentDraftCancelRossum, this.props.draftId, {
                    BackgroundJobId: this.rossumBackgroundJobId
                });
            } catch {
                // fail silently
            }
        }

        this.props.onFinish(false);
    };

    handleTryAgain = () => {
        this.setState({
            screen: RossumProgressDialogScreen.Progress
        });
        this.init();
    };

    renderContent = (): React.ReactElement => {
        if (this.state.screen === RossumProgressDialogScreen.Progress) {
            return (
                <div>
                    {this.props.t("Inbox:RossumProgressDialog.Description")}
                    <RossumProgressAnim style={{ marginTop: "19px", marginBottom: "-9px", height: "84px" }}/>
                </div>
            );
        } else {
            return this.props.t("Inbox:RossumProgressDialog.FailDescription");
        }

    };

    renderButtons = (): React.ReactElement => {
        return (
            <ButtonGroup>
                <Button onClick={this.handleCancel}
                        isTransparent={this.state.screen === RossumProgressDialogScreen.TimeOutFail}>
                    {this.props.t("Common:General.Cancel")}
                </Button>
                {this.state.screen === RossumProgressDialogScreen.TimeOutFail &&
                    <Button onClick={this.handleTryAgain}>
                        {this.props.t("Inbox:RossumProgressDialog.TryAgain")}
                    </Button>
                }
            </ButtonGroup>
        );
    };

    render() {
        return (
            <Dialog
                isConfirmation
                onConfirm={null}
                onClose={this.handleCancel}
                footer={this.renderButtons()}
                width={"380px"}
            >
                {this.renderContent()}
            </Dialog>
        );
    }
}

export default withTranslation(["Inbox", "Common"])(withOData(RossumProgressDialog));