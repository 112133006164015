import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ButtonSize } from "@components/button/Button.utils";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";

import { Button } from "../components/button";
import { SessionType } from "../contexts/authContext/Auth.utils";
import {
    ROUTE_COMPANIES,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_LOGIN_RESET_PASS,
    ROUTE_LOGIN_TENANT,
    ROUTE_LOGOUT
} from "../routes";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import LoginButtonGroup from "./components/LoginButtonGroup";
import Redirects from "./components/Redirects";
import { AnimationType, LoginTranslationNamespaces } from "./Login.utils";
import { BasicLoginText, LoginTitle } from "./LoginPage.styles";
import RoleConfirmation from "./RoleConfirmation";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import {
    activateVerification,
    getVerificationInfo,
    selectIsInvalidVerificationCode,
    selectLoginOption,
    selectVerificationInfo,
    setAnimationType,
    VerificationType
} from "./state/loginSlice";
import { loadSession, selectSessionData } from "./state/sessionSlice";

interface IProps extends WithBusyIndicator {
}

const VERIFICATION_TYPES_WITH_CUSTOM_SCREEN = [VerificationType.Password, VerificationType.AcceptOwnerRole];


const Verify: React.FC<IProps> = ({ busy, setBusy }) => {
    const { t } = useTranslation([...LoginTranslationNamespaces]);
    const { code } = useParams<{ code: string }>();

    const dispatch = useLoginDispatch();
    const session = useLoginSelector(selectSessionData);
    const verificationInfo = useLoginSelector(selectVerificationInfo);
    // verificationInfo contains type, but is set to null after the activateVerification call,
    // we need to remember the type to choose the right redirect
    const [verificationType, setVerificationType] = useState<VerificationType>();
    const hasInvalidVerificationCode = useLoginSelector(selectIsInvalidVerificationCode);

    const loginOption = useLoginSelector(selectLoginOption);

    useEffect(() => {
        dispatch(setAnimationType(AnimationType.StaticBiker));
    });

    // first, fetch the Verification object, so that we can find out the VerificationType
    useEffect(() => {
        if (code) {
            dispatch(getVerificationInfo(code));
        }
    }, [dispatch, code]);

    useEffect(() => {
        if (verificationInfo) {
            setVerificationType(verificationInfo.Verification.Type);
        }
    }, [verificationInfo]);

    // then, if its VerificationType without custom screen, activate it instantly
    useEffect(() => {
        (async function verify() {
            if (hasInvalidVerificationCode || VERIFICATION_TYPES_WITH_CUSTOM_SCREEN.includes(verificationType)) {
                if (busy) {
                    setBusy(false);
                }

                return;
            }

            if (!verificationInfo || !verificationType) {
                return;
            }

            await dispatch(activateVerification(code));
            // reload session as it most likely has changed after verification
            dispatch(loadSession());

            setBusy(false);
        })();
    }, [code, busy, setBusy, dispatch, verificationInfo, verificationType, hasInvalidVerificationCode]);

    if (busy) {
        return null;
    }

    if (hasInvalidVerificationCode) {
        const next = !session ? ROUTE_LOGIN : (session.Type === SessionType.Login ? ROUTE_LOGIN_TENANT : ROUTE_HOME);

        return (<>
            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={session ? ROUTE_LOGOUT : ROUTE_LOGIN}/>
                {t("Login:Verify.InvalidCode")}
            </LoginTitle>
            <BasicLoginText data-testid={TestIds.Text}>{i18next.t("Login:Verify.InvalidVerCode")}</BasicLoginText>
            <LoginButtonGroup>
                <Button size={ButtonSize.Big}
                        link={next}>{t("Login:Verify.Continue")}</Button>
            </LoginButtonGroup>
        </>);
    }

    // use original verificationInfo?.Verification.Type
    // it is set to null after activation => another Redirect will be triggered instead of RoleConfirmation
    if (verificationInfo?.Verification.Type === VerificationType.AcceptOwnerRole) {
        return <RoleConfirmation/>;
    } else if (verificationType === VerificationType.Password) {
        // ResetPassword has custom url and handling => redirect there instead
        return <Redirect to={`${ROUTE_LOGIN_RESET_PASS}/${code}`}/>;
    } else if (session && verificationType === VerificationType.Device) {
        return (
            <Redirect to={ROUTE_LOGIN_TENANT}/>
        );
    } else if (session?.Type === SessionType.Application && verificationType === VerificationType.Company) {
        return (
            <Redirect to={ROUTE_COMPANIES}/>
        );
    } else {
        // render redirects according to current session
        return (
            <Redirects skipActiveSessionCheck={verificationType === VerificationType.AcceptOwnerRole && !!loginOption}/>
        );
    }
};

export default withBusyIndicator({ defaultBusyState: true, isDelayed: true })(Verify);