import { ellipsis } from "polished";
import styled from "styled-components/macro";

import { FullScreenViewZIndex, VIEW_PADDING_VALUE } from "../../views/View.styles";
import { TooltipIconCopyLink } from "../tooltipIcon";

export const TooltipIconCopyLinkBreadcrumb = styled(TooltipIconCopyLink)`
    visibility: hidden;
`;

export const BreadCrumbWrapper = styled.div<{ isFullscreen?: boolean }>`
    position: absolute;
    display: flex;
    align-items: center;

    top: 10px;
    left: ${VIEW_PADDING_VALUE}px;
    // provide space for the maximize and close icon in split page
    right: 93px;

    &:hover ${TooltipIconCopyLinkBreadcrumb} {
        visibility: visible;
    }

    ${props => props.isFullscreen && `
        z-index: ${FullScreenViewZIndex + 1};
    `}
`;

export const StyledBreadCrumb = styled.ul`
    position: relative;
    padding: 0;
    margin: 0;
    // truncate text (ellipsis) from left side instead of right
    direction: rtl;
    unicode-bidi: isolate-override;
    ${ellipsis()};
`;